import { all } from 'redux-saga/effects';
import Auth from './Auth';
import Categories from './Categories';
import Measurements from './Measurements';
import Organisation from './Organisation';
import Products from './Products';
import Site from './Site';
import Snackbar from './Snackbar';
import Suppliers from './Suppliers';
import Brands from './Brands';
import Stores from './Stores';
import Translations from './Translations';
import Users from './Users';
import Roles from './Roles';
import Inventories from './Inventories';
import Barcodes from './Barcodes';
import Printers from './Printers';
import Quotes from './Quotes';
import Movements from './Movements';
import Displays from './Displays';
import Dashboard from './Dashboard';
import Warehouses from './Warehouses';
import StoreProducts from './Stores/Products';
import PlaceProductAlerts from './Places/Products/Alerts';
import PlaceProductInventories from './Places/Products/Inventories';
import Goods from './Goods';
import Foods from './Foods';
import SupplierProducts from './Supplier/Products';
import Menu from './Menu';
import Sets from './Sets';

export default function* rootSaga() {
  yield all([
    Auth(),
    Organisation(),
    Measurements(),
    Products(),
    Categories(),
    Snackbar(),
    Suppliers(),
    Brands(),
    Stores(),
    Users(),
    Roles(),
    Inventories(),
    Barcodes(),
    Printers(),
    Quotes(),
    Movements(),
    Displays(),
    Dashboard(),
    Warehouses(),
    StoreProducts(),
    PlaceProductAlerts(),
    PlaceProductInventories(),
    Goods(),
    Foods(),
    SupplierProducts(),
    Menu(),
    Sets(),
    Translations(),
    Site(),
  ]);
}
