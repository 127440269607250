import Tooltip from '@material-ui/core/Tooltip';
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import MaterialIconButton from '@material-ui/core/IconButton';

const IconButton = forwardRef(({
  className, onPress, label, color, children, disabled,
}, ref) => {
  const onPressButton = (e) => {
    e.currentTarget.blur();
    onPress(e);
  };

  if (label !== null) {
    return (
      <Tooltip title={label}>
        <MaterialIconButton
          ref={ref}
          className={className}
          onClick={onPressButton}
          disabled={disabled}
          color={color}
          aria-label={label}
        >
          {children}
        </MaterialIconButton>
      </Tooltip>
    );
  }

  return (
    <MaterialIconButton
      ref={ref}
      className={className}
      onClick={onPressButton}
      disabled={disabled}
      color={color}
      aria-label={label}
    >
      {children}
    </MaterialIconButton>
  );
});

IconButton.defaultProps = {
  className: null,
  label: null,
  color: 'secondary',
  disabled: false,
};

IconButton.propTypes = {
  className: PropTypes.string,
  onPress: PropTypes.func.isRequired,
  label: PropTypes.string,
  color: PropTypes.oneOf([
    'primary',
    'default',
    'secondary',
    'inherit',
  ]),
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
};

export default IconButton;
