import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { Instance, Resources } from '../../API';
import {
  actions,
  suppliersGet,
  suppliersItemPut,
  suppliersItemReset,
  suppliersItemSelect,
  suppliersEditProcessed,
  suppliersEditProcessing,
  suppliersPut,
  suppliersPutSingle,
  suppliersReset,
  suppliersEditSuccess,
  suppliersEditErrors,
  suppliersCreateErrors,
  suppliersCreateProcessed,
  suppliersCreateSuccess,
  suppliersCreateProcessing,
  suppliersAutocompleteClear,
  suppliersAutocompletePut,
} from '../actions/Suppliers';
import {
  snackbarDefaultError,
  snackbarDefaultSuccess,
} from '../actions/Snackbar';
import hasErrors from '../../hasErrors';

const resource = Resources.suppliers;

const suppliersCreateCall = ({
  organisationId,
  name,
}) => Instance.post(
  resource,
  {
    organisation_id: organisationId,
    name,
  },
);

export const suppliersAutocompleteCall = ({
  organisationId, text,
}) => Instance.get(
  `${resource}/${Resources.autocomplete}`,
  {
    params: {
      organisation_id: organisationId,
      search: text !== '' ? text : null,
    },
  },
);

export const suppliersIndexCall = ({
  organisationId, page, timestamp, search, filters,
}) => Instance.get(
  resource,
  {
    params: {
      organisation_id: organisationId,
      page: page !== 1 ? page : null,
      search: search !== '' ? search : null,
      timestamp: timestamp || null,
      filters: filters || null,
      paginate: 1,
    },
  },
);

export const suppliersViewCall = ({ id }) => Instance.get(`${resource}/${id}`);

export const suppliersPutCall = ({
  id,
  name,
}) => Instance.put(
  `${resource}/${id}`,
  {
    id,
    name,
  },
);

export const suppliersDeleteCall = ({ id }) => Instance.delete(`${resource}/${id}`);

const siteData = state => ({
  organisationId: state.organisationReducer.data.id,
});

const suppliersData = state => ({
  data: state.suppliersReducer.data,
});

const suppliersMetaData = state => ({
  page: state.suppliersReducer.page,
  timestamp: state.suppliersReducer.timestamp,
  search: state.suppliersReducer.search,
});

/**
 * loadSuppliersSaga
 * @returns {IterableIterator<*>}
 */
function* loadSuppliersSaga({
  payload: {
    search, filters, reset,
  },
}) {
  try {
    const { organisationId } = yield select(siteData);
    const {
      search: originalSearch,
      page,
      timestamp,
    } = yield select(suppliersMetaData);

    if (search !== originalSearch) {
      yield put(suppliersReset());
    }

    const { data } = yield call(
      suppliersIndexCall,
      {
        organisationId,
        page: reset || !page || search !== originalSearch ? 1 : page + 1,
        timestamp,
        search: search === '' ? null : search,
        filters,
      },
    );

    const {
      data: {
        page: newPage,
        timestamp: newTimestamp,
        items,
      },
    } = data;

    let newData;
    if (newPage === 1) {
      newData = [...items];
    } else {
      const { data: originalData } = yield select(suppliersData);
      newData = originalData ? [...originalData, ...items] : [...items];
    }

    yield put(suppliersPut({
      page: newPage,
      timestamp: newTimestamp,
      data: newData,
      hasMore: items.length > 0,
      search,
    }));
  } catch (e) {
    yield put(snackbarDefaultError({ e }));
  }
}

/**
 * createSuppliersSaga
 * @param supplier
 */
function* createSuppliersSaga({
  payload: {
    name,
  },
}) {
  try {
    yield put(suppliersCreateProcessing());
    const { organisationId } = yield select(siteData);
    const { data } = yield call(
      suppliersCreateCall,
      {
        organisationId,
        name,
      },
    );
    yield put(suppliersPutSingle({ supplier: data.data }));
    yield put(suppliersGet({ reset: true }));
    yield put(suppliersCreateSuccess());
    yield put(suppliersItemSelect({ id: data.data.id }));
    yield put(snackbarDefaultSuccess({ data }));
  } catch (e) {
    const errors = hasErrors(e);
    if (errors) {
      yield put(
        suppliersCreateErrors({ errors }),
      );
    }
    yield put(snackbarDefaultError({ e }));
  } finally {
    yield put(suppliersCreateProcessed());
  }
}

/**
 * editSuppliersSaga
 * @param supplier
 */
function* editSuppliersSaga({
  payload: {
    id,
    name,
  },
}) {
  try {
    yield put(suppliersEditProcessing());
    const { data } = yield call(
      suppliersPutCall,
      {
        id,
        name,
      },
    );
    yield put(suppliersItemPut({ item: data.data }));
    yield put(suppliersPutSingle({ supplier: data.data }));
    yield put(suppliersEditProcessed());
    yield put(snackbarDefaultSuccess({ data }));
    yield put(suppliersEditSuccess());
  } catch (e) {
    const errors = hasErrors(e);
    if (errors) {
      yield put(
        suppliersEditErrors({ errors }),
      );
    }
    yield put(snackbarDefaultError({ e }));
  } finally {
    yield put(suppliersEditProcessed());
  }
}

/**
 * deleteSuppliersSaga
 * @param id
 */
function* deleteSuppliersSaga({ payload: id }) {
  try {
    yield put(suppliersEditProcessing());
    const response = yield call(suppliersDeleteCall, { id });

    const { data } = yield select(suppliersData);
    const newData = [...data];
    yield put(
      suppliersPut({
        data: newData.filter(({ id: supplierId }) => supplierId !== id),
      }),
    );
    yield put(suppliersItemReset());
    yield put(suppliersEditSuccess());
    yield put(snackbarDefaultSuccess({ data: response.data }));
  } catch (e) {
    yield put(snackbarDefaultError({ e }));
  } finally {
    yield put(suppliersEditProcessed());
  }
}

/**
 * selectSupplierSaga
 * @param id
 */
function* selectSupplierSaga({ payload: id }) {
  try {
    const { data: { data } } = yield call(suppliersViewCall, { id });
    yield put(suppliersItemPut({ item: data }));
  } catch (e) {
    yield put(snackbarDefaultError({ e }));
  }
}

/**
 * updateSuppliersSaga
 * @param supplier
 */
function* updateSuppliersSaga({ payload: supplier }) {
  const { data } = yield select(suppliersData);
  const index = data.findIndex(({ id }) => supplier.id === id);
  if (index !== undefined) {
    data[index] = { ...data[index], ...supplier };
  } else {
    data.unshift(supplier);
  }

  yield put(suppliersPut({ data }));
}

/**
 * @returns {IterableIterator<*>}
 */
function* suppliersAutocompleteGetSaga({ payload: text }) {
  try {
    if (text === '') {
      yield put(suppliersAutocompleteClear());
      return;
    }

    const { organisationId } = yield select(siteData);
    const { data } = yield call(suppliersAutocompleteCall, {
      organisationId,
      text,
    });

    yield put(suppliersAutocompletePut({ data: data.data }));
  } catch (e) {
    yield put(snackbarDefaultError({ e }));
  }
}

export default function* Suppliers() {
  yield all([
    takeLatest(actions.GET, loadSuppliersSaga),
    takeLatest(actions.DELETE, deleteSuppliersSaga),
    takeLatest(actions.ITEM.SELECT, selectSupplierSaga),
    takeEvery(actions.UPDATE, updateSuppliersSaga),
    takeLatest(actions.EDIT.REQUEST, editSuppliersSaga),
    takeLatest(actions.CREATE.REQUEST, createSuppliersSaga),
    takeLatest(actions.AUTOCOMPLETE.GET, suppliersAutocompleteGetSaga),
  ]);
}
