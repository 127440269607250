import { actions } from '../actions/Organisation';

const initialState = {
  initialLoad: true,
  loading: false,
  data: null,
  error: false,
  selected: {
    loading: false,
    data: {},
  },
  list: {
    loading: true,
    data: [],
  },
  create: {
    loading: false,
    success: false,
    errors: {},
  },
  update: {
    loading: false,
    success: false,
    errors: {},
  },
  destroy: {
    loading: false,
    success: false,
    errors: {},
  },
  payments: {
    store: {
      loading: false,
      success: false,
      errors: {},
    },
    update: {
      loading: false,
      success: false,
      errors: {},
    },
    destroy: {
      loading: false,
      success: false,
      errors: {},
    },
  },
};

const organisationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOAD:
      return {
        ...state,
        initialLoad: false,
        loading: true,
      };
    case actions.LOADED:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case actions.LIST.LOAD:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      };
    case actions.LIST.PUT:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          data: action.payload,
        },
      };
    case actions.CREATE.REQUEST:
      return {
        ...state,
        create: {
          ...state.create,
          loading: true,
          success: false,
          errors: {},
        },
      };
    case actions.CREATE.RESET:
      return {
        ...state,
        create: {
          loading: false,
          success: false,
          errors: {},
        },
      };
    case actions.CREATE.SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          success: true,
        },
      };
    case actions.CREATE.FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          success: false,
          errors: action.payload,
        },
      };
    case actions.UPDATE.REQUEST:
      return {
        ...state,
        update: {
          ...state.update,
          loading: true,
          success: false,
          errors: {},
        },
      };
    case actions.UPDATE.SUCCESS:
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
          success: true,
        },
      };
    case actions.UPDATE.FAILED:
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
          success: false,
          errors: action.payload,
        },
      };
    case actions.DESTROY.REQUEST:
      return {
        ...state,
        destroy: {
          ...state.destroy,
          loading: true,
          success: false,
          errors: {},
        },
      };
    case actions.DESTROY.SUCCESS:
      return {
        ...state,
        destroy: {
          ...state.destroy,
          loading: false,
          success: true,
        },
      };
    case actions.DESTROY.FAILED:
      return {
        ...state,
        destroy: {
          ...state.destroy,
          loading: false,
          success: false,
          errors: action.payload,
        },
      };
    case actions.DESTROY.RESET:
      return {
        ...state,
        destroy: {
          loading: false,
          success: false,
          errors: {},
        },
      };
    case actions.ITEM.SELECT:
      return {
        ...state,
        selected: {
          ...state.selected,
          loading: true,
          data: {},
        },
      };
    case actions.ITEM.PUT:
      return {
        ...state,
        selected: {
          ...state.selected,
          loading: false,
          data: action.payload,
        },
      };

    case actions.PAYMENTS.STORE.REQUEST:
      return {
        ...state,
        payments: {
          ...state.payments,
          store: {
            ...state.store,
            loading: true,
            success: false,
            errors: {},
          },
        },
      };
    case actions.PAYMENTS.STORE.RESET:
      return {
        ...state,
        payments: {
          ...state.payments,
          store: {
            loading: false,
            success: false,
            errors: {},
          },
        },
      };
    case actions.PAYMENTS.STORE.SUCCESS:
      return {
        ...state,
        payments: {
          ...state.payments,
          store: {
            ...state.store,
            loading: false,
            success: true,
          },
        },
      };
    case actions.PAYMENTS.STORE.FAILED:
      return {
        ...state,
        payments: {
          ...state.payments,
          store: {
            ...state.store,
            loading: false,
            success: false,
            errors: action.payload,
          },
        },
      };
    case actions.PAYMENTS.UPDATE.REQUEST:
      return {
        ...state,
        payments: {
          ...state.payments,
          update: {
            ...state.update,
            loading: true,
            success: false,
            errors: {},
          },
        },
      };
    case actions.PAYMENTS.UPDATE.SUCCESS:
      return {
        ...state,
        payments: {
          ...state.payments,
          update: {
            ...state.update,
            loading: false,
            success: true,
          },
        },
      };
    case actions.PAYMENTS.UPDATE.FAILED:
      return {
        ...state,
        payments: {
          ...state.payments,
          update: {
            ...state.update,
            loading: false,
            success: false,
            errors: action.payload,
          },
        },
      };
    case actions.PAYMENTS.DESTROY.REQUEST:
      return {
        ...state,
        payments: {
          ...state.payments,
          destroy: {
            ...state.destroy,
            loading: true,
            success: false,
            errors: {},
          },
        },
      };
    case actions.PAYMENTS.DESTROY.SUCCESS:
      return {
        ...state,
        payments: {
          ...state.payments,
          destroy: {
            ...state.destroy,
            loading: false,
            success: true,
          },
        },
      };
    case actions.PAYMENTS.DESTROY.FAILED:
      return {
        ...state,
        payments: {
          ...state.payments,
          destroy: {
            ...state.destroy,
            loading: false,
            success: false,
            errors: action.payload,
          },
        },
      };
    case actions.PAYMENTS.DESTROY.RESET:
      return {
        ...state,
        payments: {
          ...state.payments,
          destroy: {
            loading: false,
            success: false,
            errors: {},
          },
        },
      };
    case actions.ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

export default organisationReducer;
