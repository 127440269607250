import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';
import {
  NotificationsActiveRounded,
  NotificationsOffRounded,
  NotificationsPausedRounded,
} from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import AlertIcon from '../AlertIcon';
import Container from '../Base/Container';
import FormatPrice from '../Format/FormatPrice';
import IconButton from '../Base/Button/IconButton/IconButton';
import Approval from '../Base/Dialog/Approval';
import {
  alertsDestroyRequest,
  alertsUpdateRequest,
} from '../../providers/redux/actions/Places/Products/Alerts';
import Form from '../Form';
import AlertQuantities from '../AlertQuantities';

const useStyles = makeStyles(
  theme => ({
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      borderBottom: '1px solid rgba(224, 224, 224, 1)',
      '& > *': {
        padding: theme.spacing(2),
      },
    },
    iconCell: {
      textAlign: 'center',
      width: 80,
    },
    icon: {
      width: 40,
      height: 40,
    },
    textCell: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    nameCell: {
      flex: 1,
    },
    name: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '100%',
    },
    price: {
      width: 150,
      textAlign: 'right',
    },
    quantity: {
      width: 200,
      textAlign: 'right',
    },
    alert: {
      width: 250,
    },
    actions: {
      whiteSpace: 'nowrap',
    },
    inline: {
      display: 'inline-block',
    },
    input: {
      display: 'inline-block',
      marginLeft: theme.spacing(),
    },
  }),
);

const StoreProductListItem = ({
  className,
  placeId,
  productId,
  name,
  price,
  quantity,
  alertMessage,
  replenishQuantity: originalReplenishQuantity,
  fallBelowQuantity: originalFallBelowQuantity,
  alert,
  style,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    updateLoading,
    updateSuccess,
    destroyLoading,
    destroySuccess,
  } = useSelector(
    state => ({
      updateLoading: state.placesProductsAlertsReducer.update.loading,
      updateSuccess: state.placesProductsAlertsReducer.update.success,
      destroyLoading: state.placesProductsAlertsReducer.destroy.loading,
      destroySuccess: state.placesProductsAlertsReducer.destroy.success,
    }),
  );

  const [open, setOpen] = useState(false);
  const [replenishQuantity, setReplenishQuantity] = useState(
    originalReplenishQuantity
      ? originalReplenishQuantity.toString()
      : '',
  );

  const [fallBelowQuantity, setFallBelowQuantity] = useState(
    originalFallBelowQuantity
      ? originalFallBelowQuantity.toString()
      : '',
  );

  const [pause, setPause] = useState(false);
  const [remove, setRemove] = useState(false);

  const onOpen = () => setOpen(true);

  const onClose = () => setOpen(false);

  const onPause = () => setPause(true);

  const onClosePause = () => setPause(false);

  const onRemove = () => setRemove(true);

  const onCloseRemove = () => setRemove(false);

  const setAlerts = () => dispatch(
    alertsUpdateRequest(
      placeId,
      productId,
      {
        fallBelowQuantity,
        replenishQuantity,
      },
    ),
  );

  const pauseAlerts = () => dispatch(
    alertsUpdateRequest(
      placeId,
      productId,
      {
        fallBelowQuantity: null,
        replenishQuantity: null,
      },
    ),
  );

  const removeAlerts = () => dispatch(
    alertsDestroyRequest(
      placeId,
      productId,
    ),
  );

  useEffect(() => {
    if (updateSuccess) {
      onClosePause();
      onClose();
    }
  }, [updateLoading]);

  useEffect(() => {
    if (destroySuccess) {
      onCloseRemove();
    }
  }, [destroyLoading]);

  return (
    <Container className={classNames([classes.row, className])} style={style}>
      <Form
        label="Add alert"
        loading={updateLoading}
        onClose={onClose}
        onSubmit={setAlerts}
        open={open}
      >
        <AlertQuantities
          setReplenishQuantity={setReplenishQuantity}
          setFallBelowQuantity={setFallBelowQuantity}
          fallBelowQuantity={fallBelowQuantity}
          replenishQuantity={replenishQuantity}
        />
      </Form>
      <Approval
        processing={updateLoading}
        closeDialog={onClosePause}
        content="Pause alerts for this product? You will no longer see any alerts for this product for this store."
        open={pause}
        title="Pause alerts"
        triggerEvent={pauseAlerts}
      />
      <Approval
        processing={destroyLoading}
        closeDialog={onCloseRemove}
        content="Remove alerts for this store? We will default to the global alert set for this product."
        open={remove}
        title="Remove alerts"
        triggerEvent={removeAlerts}
      />
      <Container className={classes.iconCell}>
        <AlertIcon className={classes.icon} level={alert} />
      </Container>
      <Container className={classNames([classes.textCell, classes.nameCell])}>
        <Tooltip placement="right-end" title={name}>
          <Typography className={classes.name} variant="body1">{name}</Typography>
        </Tooltip>
      </Container>
      <Container className={classNames([classes.price, classes.textCell])}>
        <FormatPrice variant="body1">{price}</FormatPrice>
      </Container>
      <Container className={classNames([classes.quantity, classes.textCell])}>
        <Typography variant="body1">
          {`${quantity} left in stock`}
        </Typography>
      </Container>
      <Container className={classes.alert}>
        <Typography variant="caption">
          {alertMessage}
        </Typography>
      </Container>
      <Container className={classes.actions}>
        <IconButton onPress={onOpen} color="default">
          <NotificationsActiveRounded />
        </IconButton>
        <IconButton onPress={onPause} color="default">
          <NotificationsPausedRounded />
        </IconButton>
        <IconButton onPress={onRemove} color="default">
          <NotificationsOffRounded />
        </IconButton>
      </Container>
    </Container>
  );
};

StoreProductListItem.defaultProps = {
  alert: 0,
  className: null,
  style: null,
  fallBelowQuantity: null,
  replenishQuantity: null,
};

StoreProductListItem.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  name: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  alertMessage: PropTypes.string.isRequired,
  alert: PropTypes.number,
  placeId: PropTypes.number.isRequired,
  productId: PropTypes.string.isRequired,
  fallBelowQuantity: PropTypes.number,
  replenishQuantity: PropTypes.number,
};

export default StoreProductListItem;
