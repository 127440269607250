import { makeStyles } from '@material-ui/core';

const Styles = makeStyles(theme => ({
  success: {
    color: theme.palette.common.white,
    backgroundColor: theme.status.success[500],
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.status.success[600],
    },
  },
}));

export default Styles;
