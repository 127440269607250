import React from 'react';
import PropTypes from 'prop-types';
import Edit from '@material-ui/icons/Edit';
import {
  DeleteRounded,
  PostAddRounded,
  RemoveRedEyeRounded,
  SendRounded,
} from '@material-ui/icons';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from './Base/Container';
import IconButton from './Base/Button/IconButton/IconButton';

const useStyles = makeStyles(
  theme => ({
    button: {
      marginRight: theme.spacing(3),
    },
  }),
);

const PlaceActionBar = ({
  className, onEdit, onReceive, onSend, onProducts, onDestroy,
}) => {
  const classes = useStyles();
  return (
    <Container className={className}>
      <IconButton
        className={classes.button}
        color="default"
        onPress={onEdit}
      >
        <Edit />
      </IconButton>
      <IconButton
        className={classes.button}
        color="default"
        onPress={onReceive}
      >
        <PostAddRounded />
      </IconButton>
      <IconButton
        className={classes.button}
        color="default"
        onPress={onSend}
      >
        <SendRounded />
      </IconButton>
      <IconButton
        className={classes.button}
        color="default"
        onPress={onProducts}
      >
        <RemoveRedEyeRounded />
      </IconButton>
      <IconButton
        className={classes.button}
        color="primary"
        onPress={onDestroy}
      >
        <DeleteRounded />
      </IconButton>
    </Container>
  );
};

PlaceActionBar.defaultProps = {
  className: null,
};

PlaceActionBar.propTypes = {
  className: PropTypes.string,
  onEdit: PropTypes.func.isRequired,
  onReceive: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  onProducts: PropTypes.func.isRequired,
  onDestroy: PropTypes.func.isRequired,
};

export default PlaceActionBar;
