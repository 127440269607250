import { Close } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import IconButton from './IconButton/IconButton';

const CloseIconButton = ({ onClick }) => (
  <IconButton
    onPress={onClick}
    color="inherit"
  >
    <Close />
  </IconButton>
);

CloseIconButton.defaultProps = {
  onClick: null,
};

CloseIconButton.propTypes = {
  onClick: PropTypes.func,
};

export default CloseIconButton;
