import React from 'react';
import classNames from 'classnames';
import { makeStyles, Typography } from '@material-ui/core';
import { Fade } from 'react-reveal';
import { useSelector } from 'react-redux';
import Container from '../../components/Base/Container';
import BackgroundHeader from '../../assets/images/background-header@2x.jpg';
import BackgroundPos from '../../assets/images/pos-screen@2x.png';
import BackgroundControl from '../../assets/images/background-control-payment@2x.jpg';
import BackgroundSettlement from '../../assets/images/pos-settlements@2x.png';
import BackgroundSubmissions from '../../assets/images/background-auto-submission@2x.jpg';
import BackgroundInformation from '../../assets/images/background-information@2x.jpg';
import AuraLogo from '../../components/AuraLogo';
import Package from '../../components/Package';
import Button from '../../components/Base/Button';
import ButtonRegister from '../../components/Buttons/ButtonRegister';
import ButtonLogin from '../../components/Buttons/ButtonLogin';
import UserDropdown from '../../components/Layout/UserDropdown';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    flex: 1,
  },
  header: {
    display: 'flex',
    background: `url('${BackgroundHeader}') center / cover no-repeat`,
    height: '100%',
    maxHeight: 800,
  },
  headerBar: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: 78,
    top: 0,
    left: 0,
    right: 0,
  },
  headerButtonText: {
    textShadow: '0px 0px 17px #00000029',
    color: theme.palette.common.white,
  },
  headerButton: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginRight: theme.spacing(4),
  },
  posScreen: {
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'relative',
    top: -100,
    maxWidth: 1030,
    width: '100%',
    '& > img': {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: 700,
    },
  },
  package: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 28,
    marginBottom: 128,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  packageTitle: {
    fontWeight: 500,
  },
  packageDetails: {
    marginTop: 64,
    marginBottom: 64,
    display: 'flex',
    maxWidth: 600,
    width: '100%',
    alignItems: 'center',
  },
  text: {
    color: theme.palette.common.white,
  },
  content: {
    background: '#FAFAFA',
  },
  contentTitle: {
    padding: theme.spacing(8),
    textAlign: 'center',
  },
  contentTitleText: {
    fontWeight: 'bold',
  },
  infoPanelHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: `transparent url('${BackgroundControl}') center / cover no-repeat padding-box`,
    textAlign: 'center',
    minHeight: 568,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  infoPanelHeaderTitle: {
    fontWeight: 'bold',
    color: theme.palette.common.white,
    textShadow: '0px 1px 6px #00000029',
  },
  infoPanelHeaderText: {
    maxWidth: 800,
    width: '100%',
    fontSize: '1.6rem',
    marginTop: theme.spacing(8),
    color: theme.palette.common.white,
    textShadow: '0px 1px 9px #00000047',
  },
  infoPanelHeaderWrapper: {
    marginTop: theme.spacing(12.5),
    marginBottom: theme.spacing(12.5),
  },
  infoPanel: {
    display: 'flex',
    marginTop: theme.spacing(9.5),
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  infoPanelContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
  },
  infoPanelContentWrapper: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(15),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  infoPanelImage: {
    [theme.breakpoints.up('sm')]: {
      flex: 1,
    },
    [theme.breakpoints.down('sm')]: {
      height: 300,
    },
  },
  infoPanelTitle: {
    fontWeight: 'bold',
  },
  infoPanelText: {
    marginTop: theme.spacing(8),
    fontSize: '1.6rem',
  },
  posSettlements: {
    background: `url('${BackgroundSettlement}') center / contain no-repeat`,
  },
  submissions: {
    background: `url('${BackgroundSubmissions}') center / cover no-repeat`,
  },
  information: {
    background: `url('${BackgroundInformation}') center / cover no-repeat`,
  },
  footnote: {
    marginTop: theme.spacing(5),
    color: '#878787',
  },
  learnMore: {
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(15),
    },
    [theme.breakpoints.down('sm')]: {
      display: 'relative',
      top: -100,
      marginTop: theme.spacing(10),
      padding: theme.spacing(2),
    },
  },
  button: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(9),
    paddingRight: theme.spacing(9),
    boxShadow: '0px 3px 20px #FF69619E',
    borderRadius: 100,
  },
  buttonText: {
    fontSize: '1.6rem',
  },
  packageContent: {
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      top: -100,
    },
  },
}));

const SiteIndexScreen = () => {
  const { authenticated } = useSelector(state => ({
    authenticated: state.authReducer.authenticated,
  }));
  const classes = useStyles();
  return (
    <Container className={classes.container} vertical flex scroll>
      <Container className={classes.header} center alignCenter>
        <Container className={classes.headerBar}>
          { authenticated
            ? <UserDropdown nameClassName={classes.headerButtonText} />
            : (
              <>
                <ButtonRegister
                  textClassName={classes.headerButtonText}
                  className={classes.headerButton}
                />
                <ButtonLogin
                  className={classes.headerButton}
                  textClassName={classes.headerButtonText}
                />
              </>
            )}
        </Container>
        <Fade bottom>
          <AuraLogo key={1} size={160} />
        </Fade>
      </Container>
      <Container key={2} className={classes.posScreen}>
        <Fade bottom>
          <img src={BackgroundPos} alt="POS screen" />
        </Fade>
      </Container>
      <Package className={classes.package} />
      <Container
        className={
          classNames([
            classes.content,
            classes.packageContent,
          ])
        }
      >
        <Container className={classes.contentTitle}>
          <Fade bottom>
            <Typography
              key={3}
              className={classes.contentTitleText}
              variant="h4"
            >
                What am I really getting?
            </Typography>
          </Fade>
        </Container>
        <Container className={classes.infoPanelHeader}>
          <Container className={classes.infoPanelHeaderWrapper}>
            <Fade bottom>
              <Typography className={classes.infoPanelHeaderTitle} variant="h4">
                  Control your payments in one interface
              </Typography>
              <Typography className={classes.infoPanelHeaderText} variant="body1">
                  Designed with you in mind, we are constantly redesigning our
                  systems to allow our users to make sale with the shortest number
                  of steps possible. Sell and refund products all in one page, and
                  keep your queues moving all day.
              </Typography>
            </Fade>
          </Container>
        </Container>
        <Container className={classes.infoPanel}>
          <Container className={classes.infoPanelContent}>
            <Container className={classes.infoPanelContentWrapper}>
              <Fade bottom>
                <Typography className={classes.infoPanelTitle} variant="h4">
                  Never leave our platform
                </Typography>
                <Typography className={classes.infoPanelText} variant="body1">
                  Say goodbye to your calculator. With our settlement interface,
                  tally your cash balance without having to calculate anything.
                  Be informed of your stores’ statistics using our mobile
                  application. Everything will be in your hands at the end of the
                  day.
                </Typography>
              </Fade>
            </Container>
          </Container>
          <Container
            className={
                classNames([
                  classes.infoPanelImage,
                  classes.posSettlements,
                ])
              }
          />
        </Container>
        <Container className={classes.infoPanel}>
          <Container
            className={
                classNames([
                  classes.infoPanelImage,
                  classes.submissions,
                ])
              }
          />
          <Container className={classes.infoPanelContent}>
            <Container className={classes.infoPanelContentWrapper}>
              <Fade bottom>
                <Typography className={classes.infoPanelTitle} variant="h4">
                  Automatic submissions for your landlord
                </Typography>
                <Typography className={classes.infoPanelText} variant="body1">
                  When we say we want to make your life easier, we mean it. We
                  will handle the sales submission for all your stores residing in
                  a shopping mall, and that&apos;s included in the price.
                </Typography>
              </Fade>
            </Container>
          </Container>
        </Container>
        <Container className={classes.infoPanel}>
          <Container className={classes.infoPanelContent}>
            <Container className={classes.infoPanelContentWrapper}>
              <Fade bottom>
                <Typography className={classes.infoPanelTitle} variant="h4">
                  Information in your hands
                </Typography>
                <Typography className={classes.infoPanelText} variant="body1">
                  Manage your sales perfectly from anywhere in the world. We
                  intend to integrate every part of your business and put its
                  mastery before you. Make improvements to your baseline in a snap
                  while on a holiday.
                </Typography>
              </Fade>
              <Typography className={classes.footnote}>
                  Our mobile app will arrive in both Android and iOS coming
                  December 2019.
              </Typography>
            </Container>
          </Container>
          <Container
            className={
                classNames([
                  classes.infoPanelImage,
                  classes.information,
                ])
              }
          />
        </Container>
      </Container>
      <Container className={classes.learnMore}>
        <Typography variant="h4" className={classes.contentTitleText}>
            Ready to learn more?
        </Typography>
        <Button
          className={classes.button}
          textClassName={classes.buttonText}
          onClick={() => window.open('mailto:hola@aios.sg')}
          variant="contained"
          color="primary"
        >
            Contact us
        </Button>
        <Typography variant="h5">
            or e-mail us at hola@aios.sg
        </Typography>
      </Container>
    </Container>
  );
};

export default SiteIndexScreen;
