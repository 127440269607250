import React from 'react';
import PropTypes from 'prop-types';
import { Delete } from '@material-ui/icons';

import FAB from '../FAB';

const DeleteButton = props => (
  <FAB
    className={props.className}
    style={props.style}
    onClick={() => props.onClick()}
    color="primary"
    label={props.label}
    size={props.size}
    disabled={props.disabled || false}
  >
    <Delete className="white" />
  </FAB>
);

DeleteButton.propTypes = {
  size: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  style: PropTypes.object,
};

export default DeleteButton;
