import {
  all,
  call,
  put,
  putResolve, select,
  takeLatest,
} from 'redux-saga/effects';
import { Instance, Resources } from '../../../../API';
import {
  actions,
  alertsDestroyErrors,
  alertsDestroyReset,
  alertsDestroySuccess,
  alertsUpdateErrors,
  alertsUpdateReset,
  alertsUpdateSuccess,
} from '../../../actions/Places/Products/Alerts';
import {
  snackbarDefaultError,
  snackbarDefaultSuccess,
} from '../../../actions/Snackbar';
import hasErrors from '../../../../hasErrors';
import { productsItemSelect } from '../../../actions/Products';

const resource = Resources.alerts;

export const callAlertUpdate = (
  productId,
  storeId,
  {
    fallBelowQuantity,
    replenishQuantity,
  },
) => Instance.put(
  `${resource}/${productId}`,
  {
    place_id: storeId,
    fall_below_quantity: fallBelowQuantity,
    replenish_quantity: replenishQuantity,
  },
);

export const callAlertDestroy = (
  productId,
  placeId,
) => Instance.delete(
  `${resource}/${productId}`,
  {
    params: {
      place_id: placeId,
    },
  },
);

const dataCurrentProduct = state => ({
  product: state.productsReducer.selected,
});

/**
 * Sagas
 */
function* sagaUpdateProduct(productId) {
  const { product } = yield select(dataCurrentProduct);
  if (product && product.id === productId) {
    yield putResolve(
      productsItemSelect({ id: product.id }),
    );
  }
}

function* sagaAlertUpdate({
  payload: {
    storeId: placeId,
    productId,
    fallBelowQuantity,
    replenishQuantity,
  },
}) {
  try {
    const { data } = yield call(
      callAlertUpdate,
      productId,
      placeId,
      {
        fallBelowQuantity,
        replenishQuantity,
      },
    );

    yield putResolve(
      alertsUpdateSuccess(alert),
    );

    yield put(
      snackbarDefaultSuccess({ data }),
    );

    yield put(
      alertsUpdateReset(),
    );

    yield call(sagaUpdateProduct, productId);
  } catch (e) {
    const errors = hasErrors(e);
    if (errors) {
      yield put(
        alertsUpdateErrors(errors),
      );
    }
    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

function* sagaAlertDestroy({
  payload: {
    placeId,
    productId,
  },
}) {
  try {
    const { data } = yield call(
      callAlertDestroy,
      productId,
      placeId,
    );

    yield put(
      alertsDestroySuccess(),
    );

    yield put(
      snackbarDefaultSuccess({ data }),
    );

    yield putResolve(
      alertsDestroyReset(),
    );

    yield call(sagaUpdateProduct, productId);
  } catch (e) {
    const errors = hasErrors(e);
    if (errors) {
      yield put(
        alertsDestroyErrors(errors),
      );
    }
    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

export default function* Alerts() {
  yield all([
    takeLatest(actions.UPDATE.REQUEST, sagaAlertUpdate),
    takeLatest(actions.DESTROY.REQUEST, sagaAlertDestroy),
  ]);
}
