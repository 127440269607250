import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Container from '../Base/Container';
import { ReactComponent as VisaSvg } from '../../assets/icons/ic-visa.svg';
import { ReactComponent as MastercardSvg } from '../../assets/icons/ic-mastercard.svg';

const useStyles = makeStyles(() => ({
  logo: {
    height: 81,
    width: 130,
  },
}));

const PaymentLogo = ({ brand }) => {
  const classes = useStyles();
  switch (brand) {
    case 'visa':
      return (
        <VisaSvg className={classes.logo} />
      );
    case 'mastercard':
      return (
        <MastercardSvg className={classes.logo} />
      );
    default:
      return <Container />;
  }
};

PaymentLogo.propTypes = {
  brand: PropTypes.string.isRequired,
};

export default PaymentLogo;
