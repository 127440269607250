import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Container from './Container';
import Logo from '../Logo';

const useStyles = makeStyles(
  theme => ({
    container: {
      padding: theme.spacing(),
    },
    logo: {
      animation: 'spin 2s infinite linear',
    },
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '50%': {
        transform: 'rotate(180deg)',
      },
      '100%': {
        transform: 'rotate(0deg)',
      },
    },
  }),
);

const IconLoader = ({ color }) => {
  const classes = useStyles();
  return (
    <Container className={classes.container} flex center alignCenter>
      <Logo
        className={classes.logo}
        color={color}
        size={50}
      />
    </Container>
  );
};

IconLoader.defaultProps = {
  color: null,
};

IconLoader.propTypes = {
  color: PropTypes.bool,
};

export default IconLoader;
