import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import Container from '../Base/Container';
import SiteAppNavigationItem from './SiteAppNavigationItem';
import Constants from '../../constants';

const useStyles = makeStyles(theme => ({
  header: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  container: {
    maxWidth: 300,
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const SiteAppNavigation = () => {
  const classes = useStyles();
  return (
    <Container className={classes.container}>
      <Typography className={classes.header} variant="caption">
        Navigation
      </Typography>
      <SiteAppNavigationItem
        text="Your Profile"
        link={Constants.routes.Profile}
      />
      <SiteAppNavigationItem
        text="Organisations"
        link={Constants.routes.Organisations}
      />
    </Container>
  );
};

export default SiteAppNavigation;
