import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { makeStyles, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../components/Layout';
import Container from '../components/Base/Container';
import StoreDropdown from '../components/Base/Input/StoreDropdown';
import { movementsGet } from '../providers/redux/actions/Movements';
import MovementsRecord from '../components/Movements/MovementsRecord';
import Loader from '../components/Base/Loader';
import PageHeader from '../components/PageHeader';

const useStyles = makeStyles(theme => ({
  title: {
    padding: theme.spacing(6),
  },
  container: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
  },
  actions: {
    width: 140,
    display: 'flex',
    flexDirection: 'row',
  },
  text: {
    flex: 1,
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  quantity: {
    textAlign: 'right',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
}));

const ScreenMovements = () => {
  const [store, setStore] = useState(null);
  const { stores, loading, data } = useSelector(state => ({
    stores: state.authReducer.user.stores,
    loading: state.movementsReducer.loading,
    data: state.movementsReducer.data,
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    if (stores && stores.length > 0) {
      const { id, name } = stores[0];
      setStore({ label: name, value: id });
    }
  }, [stores]);

  useEffect(() => {
    dispatch(
      movementsGet({
        storeId: store ? store.id : null,
        reset: true,
      }),
    );
  }, [store]);

  const classes = useStyles();
  return (
    <Layout flex>
      <Container maxWidth>
        <PageHeader title="Movements" />
        <Container className={classes.container}>
          { stores.length > 1 && (
            <StoreDropdown
              onChange={setStore}
              selected={store}
              disableUnderline
            />
          )}
          <Container className={classes.header}>
            <Container className={classes.content}>
              <Typography
                className={classes.text}
                variant="caption"
              >
                Product
              </Typography>
              <Typography
                className={classes.text}
                variant="caption"
              >
                From
              </Typography>
              <Typography
                className={classes.text}
                variant="caption"
              >
                Sent by
              </Typography>
              <Typography
                className={
                  classNames([
                    classes.text,
                    classes.quantity,
                  ])
                }
                variant="caption"
              >
                Quantity
              </Typography>
              <Typography
                className={classes.text}
                variant="caption"
              >
                Requested at
              </Typography>
            </Container>
            <Container className={classes.actions} />
          </Container>
          <Container>
            <Container className={classes.center}>
              {loading && <Loader />}
              {(!loading && (!data || data.length === 0)) && (
                <Typography>No requests made</Typography>
              )}
            </Container>
            {(!loading && data) && data.map(({
              id, product, fromStore, fromUser, quantity, createdAt,
            }) => (
              <MovementsRecord
                key={id}
                id={id}
                product={product}
                fromStore={fromStore}
                fromUser={fromUser}
                quantity={quantity}
                createdAt={createdAt}
              />
            ))}
          </Container>
        </Container>
      </Container>
    </Layout>
  );
};

ScreenMovements.propTypes = {};

export default ScreenMovements;
