import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  InputLabel, FormControl, Select, Input, MenuItem, Checkbox, ListItemText, makeStyles,
} from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';

const styles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(0.25),
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const renderValue = (selected, stores) => stores !== null && selected.map(
  id => (stores.find(store => id === store.id)
    ? stores.find(store => id === store.id).name
    : null),
).filter(store => store !== null).join(', ');

const StoresSelect = ({
  stores, selected, onChange, onOpen, onClose, icon, open, disabled,
}) => {
  const classes = styles();
  const selectedStores = selected.map(id => id.toString());
  return (
    <FormControl component="div" className={classes.formControl} disabled={disabled}>
      <InputLabel htmlFor="select-multiple-checkbox">Stores</InputLabel>
      <Select
        value={selectedStores}
        onChange={event => onChange(event.target.value)}
        onClose={onClose}
        onOpen={onOpen}
        open={open}
        input={<Input id="select-multiple-checkbox" />}
        IconComponent={icon || ArrowDropDown}
        renderValue={newSelected => renderValue(newSelected, stores)}
        multiple
      >
        {stores !== null && stores.map(store => (
          <MenuItem component="div" key={store.id} value={store.id}>
            <Checkbox checked={selectedStores.indexOf(store.id) > -1} />
            <ListItemText primary={store.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

StoresSelect.defaultProps = {
  icon: null,
  disabled: false,
  stores: null,
};

StoresSelect.propTypes = {
  selected: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  stores: PropTypes.array,
  icon: PropTypes.func,
  disabled: PropTypes.bool,
};

const mapStateToProps = state => ({
  stores: state.storesReducer.data,
  organisation: state.organisationReducer.data,
});

export default connect(mapStateToProps)(StoresSelect);
