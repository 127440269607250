import {
  all,
  call,
  put,
  putResolve,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { Instance, Resources } from '../../API';
import hasErrors from '../../hasErrors';
import {
  actions,
  quotesAutocompleteClear,
  quotesAutocompletePut,
  quotesCreateErrors,
  quotesCreateProcessed,
  quotesCreateProcessing,
  quotesCreateSuccess, quotesDestroyErrors,
  quotesDestroySuccess,
  quotesItemPut,
  quotesPut, quotesPutItems,
  quotesPutSingle,
  quotesReset,
  quotesSuccess, quotesUpdateErrors, quotesUpdateReset, quotesUpdateSuccess,
} from '../actions/Quotes';
import {
  snackbarDefaultError,
  snackbarDefaultSuccess,
} from '../actions/Snackbar';

const resource = Resources.quotes;

const transformer = ({
  id, supplier, cost, created_diff: added,
}) => ({
  id, supplier, cost, added,
});

const quotesCreateCall = ({
  productId,
  name,
  supplierId,
  cost,
}) => Instance.post(
  resource,
  {
    product_id: productId,
    supplier_id: supplierId,
    name,
    cost,
  },
);

export const quotesAutocompleteCall = ({
  productId, text,
}) => Instance.get(
  `${resource}/${Resources.autocomplete}`,
  {
    params: {
      product_id: productId,
      search: text !== '' ? text : null,
    },
  },
);

export const quotesIndexCall = ({
  productId, page, timestamp, search, filters,
}) => Instance.get(
  resource,
  {
    params: {
      product_id: productId,
      page: page !== 1 ? page : null,
      search: search !== '' ? search : null,
      timestamp: timestamp || null,
      filters: filters || null,
      paginate: 1,
    },
  },
);

export const quotesViewCall = ({ id }) => Instance.get(`${resource}/${id}`);

export const callQuotesUpdate = (id, { name, cost }) => Instance.put(
  `${resource}/${id}`,
  {
    id,
    name,
    cost,
  },
);

export const callQuotesDestroy = id => Instance.delete(`${resource}/${id}`);

const productData = state => ({
  productId: state.productsReducer.selected.id,
});

const quotesData = state => ({
  total: state.quotesReducer.total,
  data: state.quotesReducer.data,
});

const quotesMetaData = state => ({
  page: state.quotesReducer.page,
  timestamp: state.quotesReducer.timestamp,
  search: state.quotesReducer.search,
  hasMore: state.quotesReducer.hasMore,
});

/**
 * loadQuotesSaga
 * @returns {IterableIterator<*>}
 */
function* loadQuotesSaga({
  payload: {
    search, filters, reset,
  },
}) {
  try {
    const { productId } = yield select(productData);
    const {
      search: originalSearch,
      page,
      timestamp,
      hasMore,
    } = yield select(quotesMetaData);

    if (search !== originalSearch) {
      yield put(
        quotesReset(),
      );
    }

    if (!reset && search === originalSearch && !hasMore) {
      yield put(
        quotesSuccess(),
      );

      return;
    }

    const { data } = yield call(
      quotesIndexCall,
      {
        productId,
        page: reset || !page || search !== originalSearch ? 1 : page + 1,
        timestamp,
        search: search === '' ? null : search,
        filters,
      },
    );

    const {
      data: {
        total,
        page: newPage,
        timestamp: newTimestamp,
        items,
      },
    } = data;

    const mappedItems = items.map(transformer);
    if (newPage === 1) {
      yield put(
        quotesPut({
          total,
          page: newPage,
          timestamp: newTimestamp,
          data: [...mappedItems],
          hasMore: items.length > 0,
          search,
        }),
      );
      return;
    }

    const { data: originalData } = yield select(quotesData);
    yield put(
      quotesPut({
        total,
        page: newPage,
        timestamp: newTimestamp,
        data: originalData
          ? [...originalData, ...mappedItems]
          : [...mappedItems],
        hasMore: items.length > 0,
        search,
      }),
    );
  } catch (e) {
    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

/**
 * createQuotesSaga
 * @param quote
 */
function* createQuotesSaga({
  payload: {
    name,
    supplier,
    cost,
  },
}) {
  try {
    yield put(quotesCreateProcessing());
    const { productId } = yield select(productData);
    const { data } = yield call(
      quotesCreateCall,
      {
        productId,
        supplierId: supplier ? supplier.id : null,
        name: name !== '' ? name : null,
        cost,
      },
    );

    yield put(quotesPutSingle({ quote: data.data }));
    yield put(quotesCreateSuccess());
    yield put(snackbarDefaultSuccess({ data }));
  } catch (e) {
    const errors = hasErrors(e);
    if (errors) {
      yield put(
        quotesCreateErrors({ errors }),
      );
    }
    yield put(snackbarDefaultError({ e }));
  } finally {
    yield put(quotesCreateProcessed());
  }
}

/**
 * deleteQuotesSaga
 * @param id
 */
function* sagaQuoteDestroy({ payload: id }) {
  try {
    const response = yield call(callQuotesDestroy, id);
    const { data } = yield select(quotesData);
    yield putResolve(
      quotesPut({
        data: [...data.filter(({ id: quoteId }) => quoteId !== id)],
      }),
    );

    yield put(
      quotesDestroySuccess(),
    );

    yield put(
      snackbarDefaultSuccess({ data: response.data }),
    );
  } catch (e) {
    yield put(
      snackbarDefaultError({ e }),
    );

    const errors = hasErrors(e);
    if (errors) {
      yield put(
        quotesDestroyErrors(errors),
      );
    }
  }
}

/**
 * selectQuoteSaga
 * @param id
 */
function* selectQuoteSaga({ payload: id }) {
  try {
    const { data: { data } } = yield call(quotesViewCall, { id });
    yield put(quotesItemPut({ item: data }));
  } catch (e) {
    yield put(snackbarDefaultError({ e }));
  }
}

/**
 * updateQuotesSaga
 * @param quote
 */
function* updateQuotesSaga({ payload: quote }) {
  const { total, data } = yield select(quotesData);
  const newData = [...data.filter(item => item !== undefined)];
  const index = newData.findIndex(({ id }) => quote.id === id);
  if (index !== -1) {
    newData[index] = { ...newData[index], ...transformer(quote) };
  } else {
    newData.unshift(transformer(quote));
  }

  yield put(
    quotesPut({
      total: total + 1,
      data: newData,
    }),
  );
}

/**
 * @returns {IterableIterator<*>}
 */
function* quotesAutocompleteGetSaga({ payload: text }) {
  try {
    if (text === '') {
      yield put(quotesAutocompleteClear());
      return;
    }

    const { productId } = yield select(productData);
    const { data } = yield call(quotesAutocompleteCall, {
      productId,
      text,
    });

    yield put(quotesAutocompletePut({ data: data.data }));
  } catch (e) {
    yield put(snackbarDefaultError({ e }));
  }
}
function* sagaQuotesReload() {
  try {
    const { productId } = yield select(productData);
    const {
      search,
      page,
      timestamp,
    } = yield select(quotesMetaData);

    const { data } = yield call(
      quotesIndexCall,
      {
        productId,
        page,
        timestamp,
        search,
      },
    );
    const {
      data: {
        items,
      },
    } = data;

    const mappedItems = items.map(transformer);
    yield putResolve(
      quotesPutItems([...mappedItems]),
    );
  } catch (e) {
    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

function* sagaQuotesUpdate({
  payload: {
    id,
    name,
    cost,
  },
}) {
  try {
    const { data } = yield call(
      callQuotesUpdate,
      id,
      {
        name,
        cost,
      },
    );

    yield putResolve(
      quotesUpdateSuccess(),
    );

    yield call(sagaQuotesReload);

    yield put(
      snackbarDefaultSuccess({ data }),
    );

    yield put(
      quotesUpdateReset(),
    );
  } catch (e) {
    const errors = hasErrors(e);
    if (errors) {
      yield put(
        quotesUpdateErrors(errors),
      );
    }
    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

export default function* Quotes() {
  yield all([
    takeLatest(actions.GET, loadQuotesSaga),
    takeLatest(actions.DESTROY.REQUEST, sagaQuoteDestroy),
    takeLatest(actions.ITEM.SELECT, selectQuoteSaga),
    takeEvery(actions.PUT_SINGLE, updateQuotesSaga),
    takeLatest(actions.UPDATE.REQUEST, sagaQuotesUpdate),
    takeLatest(actions.CREATE.REQUEST, createQuotesSaga),
    takeLatest(actions.AUTOCOMPLETE.GET, quotesAutocompleteGetSaga),
  ]);
}
