import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import QueueAnim from 'rc-queue-anim';
import Container from '../Base/Container';

const Layout = ({
  className, flex, scroll, horizontal, children, direction, center,
}) => (
  <QueueAnim
    className={classNames([className, 'flex'])}
    type={[direction]}
    duration={800}
  >
    <Container
      key={1}
      className={
        classNames([
          'screen-layout',
        ])
      }
      flex={!!flex}
      scroll={!!scroll}
    >
      <Container
        className="app-container"
        horizontal={horizontal}
        center={center}
        flex
        maxHeight
        maxWidth
      >
        {children}
      </Container>
    </Container>
  </QueueAnim>
);

Layout.defaultProps = {
  flex: false,
  scroll: false,
  horizontal: false,
  children: null,
  direction: 'bottom',
  className: null,
  center: false,
};

Layout.propTypes = {
  flex: PropTypes.bool,
  scroll: PropTypes.bool,
  horizontal: PropTypes.bool,
  children: PropTypes.node,
  direction: PropTypes.string,
  className: PropTypes.string,
  center: PropTypes.bool,
};

export default Layout;
