import { useSelector } from 'react-redux';
import hasRole from '../hasRole';

const useRoles = () => {
  const { user } = useSelector(
    state => ({
      user: state.authReducer.user,
    }),
  );

  return hasRole(
    user
      ? user.roles.map(
        ({ name }) => name.toLowerCase(),
      )
      : [],
  );
};

export default useRoles;
