import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { Add } from '@material-ui/icons';
import makeStyles from '@material-ui/core/styles/makeStyles';
import uuid from 'uuid';
import useRouter from '../../../services/useRouter';
import Layout from '../../../components/Layout';
import Container from '../../../components/Base/Container';
import TextInput from '../../../components/Base/Input/TextInput';
import Loader from '../../../components/Base/Loader';
import {
  foodsDestroyRequest,
  foodsShowRequest, foodsShowReset, foodsStoreRequest, foodsUpdateRequest,
} from '../../../providers/redux/actions/Foods';
import Prices from '../../../components/Prices';
import CategoryInput from '../../../components/CategoryInput';
import Option from '../../../components/Option';
import IconButton from '../../../components/Base/Button/IconButton/IconButton';
import Constants from '../../../constants';
import Toolbar
  from '../../../components/Base/ModelInteractor/Components/Toolbar';
import usePrevious from '../../../providers/hooks/usePrevious';
import ButtonBack from '../../../components/Base/Button/ButtonBack';
import PageHeader from '../../../components/PageHeader';
import ButtonGlow from '../../../components/Buttons/ButtonGlow';
import useNavigate from '../../../services/useNavigate';
import fastCompare from '../../../providers/fastCompare';
import ImagePicker from '../../../components/Base/Image/ImagePicker';

const useStyles = makeStyles(
  theme => ({
    container: {
      flexDirection: 'row',
    },
    backContainer: {
      padding: theme.spacing(6),
    },
    prices: {
      marginTop: theme.spacing(4),
      marginLeft: theme.spacing(12.5),
    },
    segment: {
      marginLeft: theme.spacing(12.5),
      padding: theme.spacing(),
    },
    options: {
      marginTop: theme.spacing(4),
      display: 'flex',
      flexDirection: 'row',
    },
    optionTitle: {
      flex: 1,
    },
    button: {
      display: 'block',
      width: '100%',
      marginTop: theme.spacing(7),
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
    },
    inputs: {
      flexDirection: 'column',
      flex: 1,
      marginLeft: theme.spacing(4),
    },
  }),
);

const ScreenFood = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const original = useRef();
  const { match } = useRouter();
  const { id } = match.params;

  const {
    destroyLoading,
    destroySuccess,
    storeSuccess,
    storeLoading,
    updateLoading,
    updateSuccess,
    loading,
    data,
    errors,
  } = useSelector(
    state => ({
      destroyLoading: state.foodsReducer.destroy.loading,
      destroySuccess: state.foodsReducer.destroy.success,
      storeLoading: state.foodsReducer.store.loading,
      storeSuccess: state.foodsReducer.store.success,
      updateLoading: state.foodsReducer.update.loading,
      updateSuccess: state.foodsReducer.update.success,
      loading: state.foodsReducer.show.loading,
      data: state.foodsReducer.show.data,
      errors: state.foodsReducer.show.errors,
    }),
  );

  const { name: nameErrors } = errors;

  const [name, setName] = useState('');
  const [category, setCategory] = useState(null);
  const [price, setPrice] = useState('');
  const [prices, setPrices] = useState([]);
  const [image, setImage] = useState(null);
  const [options, setOptions] = useState([
    {
      uuid: uuid(),
      name: '',
      choices: [
        {
          uuid: uuid(),
          ...Constants.value.choice,
        },
      ],
    },
  ]);

  const previousPrice = usePrevious(price);

  const resetSubmission = () => {
    setName('');
    setCategory(null);
    setPrice('');
    setPrices([]);
    setImage(null);
    setOptions([
      {
        uuid: uuid(),
        name: '',
        choices: [
          {
            uuid: uuid(),
            ...Constants.value.choice,
          },
        ],
      },
    ]);
  };

  const onBack = () => navigate(Constants.routes.Menu);

  const onDestroy = () => dispatch(
    foodsDestroyRequest(id),
  );

  const onEdit = () => dispatch(
    foodsUpdateRequest(id, {
      name,
      image,
      categoryId: category !== null ? category.id : null,
      price,
      prices: prices.map(
        ({ id: storeId, price: storePrice }) => ({
          storeId,
          price: storePrice,
        }),
      ),
      options,
    }),
  );

  const onSubmit = () => dispatch(
    foodsStoreRequest({
      name,
      categoryId: category !== null ? category.value : null,
      price,
      prices: prices.map(
        ({ id: storeId, price: storePrice }) => ({
          storeId,
          price: storePrice,
        }),
      ),
      options,
    }),
  );

  const setStorePrice = (storeId, storePrice) => {
    const newPrices = [
      ...prices.filter(
        ({ id: priceStoreId }) => priceStoreId !== storeId,
      ),
      {
        ...prices.find(
          ({ id: priceStoreId }) => priceStoreId === storeId,
        ),
        price: storePrice,
      },
    ];

    setPrices(newPrices);
  };

  const onChoiceNameChange = (index, newName) => {
    const newOptions = [...options];
    const option = options[index];
    option.name = newName;
    options[index] = option;
    setOptions(newOptions);
  };

  const onChoicesChange = (index, newChoices) => {
    const newOptions = [...options];
    const option = options[index];
    option.choices = newChoices;
    options[index] = option;
    setOptions(newOptions);
  };

  const onAddOption = () => {
    const newOptions = [...options];
    newOptions.push({
      uuid: uuid(),
      name: '',
      choices: [
        { ...Constants.value.choice },
      ],
    });

    setOptions(newOptions);
  };

  const onOptionDestroy = (key) => {
    const newOptions = [...options];
    newOptions.splice(key, 1);
    setOptions(newOptions);
  };

  const postLoad = () => {
    const {
      name: foodName,
      category: foodCategory,
      price: foodPrice,
      stores: foodPrices,
      options: foodOptions,
      image: foodImage,
    } = data;

    const mappedPrices = foodPrices.map(
      store => ({
        ...store,
        price: store.price.toFixed(2),
      }),
    );

    const mappedOptions = foodOptions.map(
      option => ({
        ...option,
        uuid: uuid(),
        price: option.price.toFixed(2),
        choices: option.choices.map(
          choice => ({
            ...choice,
            uuid: uuid(),
            price: choice.price.toFixed(2),
          }),
        ),
      }),
    );

    setName(foodName);
    setCategory(foodCategory);
    setPrice(foodPrice.toFixed(2));
    setPrices(mappedPrices);
    setOptions([..._.cloneDeep(mappedOptions)]);
    setImage(foodImage);

    original.current = {
      name: foodName,
      category: foodCategory,
      price: foodPrice.toFixed(2),
      prices: mappedPrices,
      options: [..._.cloneDeep(mappedOptions)],
      image: foodImage,
    };
  };

  const onEnter = id ? onEdit : onSubmit;
  const isSame = original.current ? fastCompare(original.current, {
    name,
    category,
    price,
    prices,
    options,
    image,
  }) : false;

  useEffect(() => {
    if (!destroyLoading && destroySuccess) {
      onBack();
    }
  }, [destroyLoading, destroySuccess]);

  useEffect(() => {
    if (id) {
      dispatch(
        foodsShowRequest(id),
      );
      return;
    }

    dispatch(
      foodsShowReset(),
    );
  }, [id]);

  useEffect(() => {
    if (!storeLoading && storeSuccess) {
      resetSubmission();
      onBack();
    }
  }, [storeLoading, storeSuccess]);

  useEffect(() => {
    const storePrices = [...prices];
    const formatPreviousPrice = parseFloat(previousPrice) || 0;
    setPrices(
      storePrices.map(
        (originalPrices) => {
          const formatStorePrice = parseFloat(originalPrices.price) || 0;
          if (formatPreviousPrice === formatStorePrice) {
            return {
              ...originalPrices,
              price,
            };
          }

          return originalPrices;
        },
      ),
    );
  }, [price]);

  useEffect(() => {
    if (data) {
      postLoad();
      return;
    }

    resetSubmission();
  }, [data]);

  useEffect(() => {
    if (!updateLoading && updateSuccess) {
      postLoad();
    }
  }, [updateLoading, updateSuccess]);

  if (loading && !data) {
    return (
      <Layout flex center>
        <Container flex center alignCenter>
          <Loader />
        </Container>
      </Layout>
    );
  }

  if (id && !data) {
    return (
      <Layout>
        <Typography variant="body1">Could not find item.</Typography>
      </Layout>
    );
  }

  return (
    <Layout flex>
      <Container className={classes.container} flex scroll>
        <Container className={classes.container} flex>
          <Container className={classes.backContainer}>
            <ButtonBack onPress={onBack} />
          </Container>
          <Container>
            <PageHeader title={id ? `Viewing ${name}` : 'New menu item'} />
            <Container className={classNames(classes.segment, classes.row)}>
              <ImagePicker
                key={image}
                onChange={setImage}
                image={image}
              />
              <Container className={classes.inputs}>
                <TextInput
                  label="Name"
                  onChange={setName}
                  value={name}
                  errors={nameErrors}
                  placeholder="Waffle"
                  onEnter={onEnter}
                  shrink
                />
                <CategoryInput
                  onChange={setCategory}
                  selected={category}
                  onEnter={onEnter}
                />
              </Container>
            </Container>
            <Prices
              className={classes.prices}
              standardPrice={price}
              onStandardPriceChange={setPrice}
              standardPriceErrors={errors.price}
              prices={prices}
              onEnter={onEnter}
              onChange={setStorePrice}
            />
            <Container className={classes.segment}>
              <Container className={classes.options}>
                <Typography className={classes.optionTitle} variant="h5">
                  Options
                </Typography>
                <Container>
                  <IconButton onPress={onAddOption}>
                    <Add />
                  </IconButton>
                </Container>
              </Container>
              {options.map(
                ({
                  uuid: optionUuid,
                  name: choiceName,
                  choices,
                }, key) => (
                  <Option
                    key={optionUuid}
                    uuid={optionUuid}
                    choices={choices}
                    onChangeName={newName => onChoiceNameChange(key, newName)}
                    onChangeChoices={newChoices => onChoicesChange(
                      key,
                      newChoices,
                    )}
                    name={choiceName}
                    onDestroy={() => onOptionDestroy(key)}
                    onEnter={onEnter}
                  />
                ),
              )}
            </Container>
            {!id && (
              <Container className={classes.segment}>
                <ButtonGlow className={classes.button} onPress={onSubmit}>
                  Add item
                </ButtonGlow>
              </Container>
            )}
          </Container>
        </Container>
      </Container>
      {id && (
        <Toolbar
          fireDelete={onDestroy}
          fireEdit={onEdit}
          processing={loading}
          edit={!isSame}
        />
      )}
    </Layout>
  );
};

export default ScreenFood;
