import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Switch } from '@material-ui/core';
import Container from '../Container';

const useStyles = makeStyles(() => ({
  title: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  switchInput: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));

const SwitchInput = ({
  className, onChange, title, checked, value,
}) => {
  const classes = useStyles();
  return (
    <Container className={className}>
      <Container flex>
        <div className={classes.title}>{title}</div>
        <div className={classes.switchInput}>
          <Switch
            onChange={event => onChange(event.target.checked)}
            color="secondary"
            value={value}
            checked={checked}
          />
        </div>
      </Container>
    </Container>
  );
};

SwitchInput.defaultProps = {
  className: null,
  value: undefined,
};

SwitchInput.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  checked: PropTypes.bool.isRequired,
};

export default SwitchInput;
