import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Constants from '../constants';

const useStyles = makeStyles(
  () => ({
    normal: {
      color: '#88DF64',
    },
    warning: {
      color: '#FFC681',
    },
    error: {
      color: '#FF6961',
    },
  }),
);

const PlaceStatus = ({ variant, status }) => {
  const classes = useStyles();

  switch (status) {
    case Constants.alerts.critical:
      return (
        <Typography className={classes.error} variant={variant}>
          Requires immediate action
        </Typography>
      );
    case Constants.alerts.warning:
      return (
        <Typography className={classes.warning} variant={variant}>
          Requires attention
        </Typography>
      );
    case Constants.alerts.normal:
    default:
      return (
        <Typography className={classes.normal} variant={variant}>
          Operating normally
        </Typography>
      );
  }
};

PlaceStatus.defaultProps = {
  variant: 'h6',
};

PlaceStatus.propTypes = {
  variant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'body1',
    'body2',
    'caption',
  ]),
  status: PropTypes.oneOf([
    Constants.alerts.normal,
    Constants.alerts.warning,
    Constants.alerts.critical,
  ]).isRequired,
};

export default PlaceStatus;
