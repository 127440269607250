import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DoneButton from './Base/Button/Done';
import Loader from './Base/Loader';

const Form = ({
  label, onSubmit, onClose, open, children, loading,
}) => (
  <Dialog maxWidth="sm" open={open} onClose={!loading ? onClose : null} fullWidth>
    <DialogTitle id="form-dialog-title">{label}</DialogTitle>
    <DialogContent>
      { children }
    </DialogContent>
    <DialogActions>
      {loading ? <Loader /> : <DoneButton label="Update" onClick={onSubmit} />}
    </DialogActions>
  </Dialog>
);

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default Form;
