import { SnackbarProvider } from 'notistack';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Container from '../../components/Base/Container';
import Header from '../../components/Layout/Header';
import Sidebar from '../../components/Layout/Sidebar';
import SnackbarStack from '../../components/SnackbarStack';
import Default from '../../defaults';
import ScreenOrganisation from './organisation';
import ScreenProducts from './products';
import ScreenCategories from './categories';
import ScreenBrands from './brands';
import ScreenDashboard from './dashboard';
import ScreenStore from './stores/ScreenStore';
import ScreenSuppliers from './suppliers';
import ScreenStores from './stores/ScreenStores';
import ScreenUsers from './users';
import Boot from '../../components/Boot';
import ScreenMovements from '../ScreenMovements';
import ScreenDisplay from './ScreenDisplay';
import ScreenStoreAdd from './stores/add';
import ScreenWarehouseCreate from './warehouses/ScreenWarehouseCreate';
import ScreenWarehouses from './warehouses/ScreenWarehouses';
import ScreenWarehouse from './warehouses/ScreenWarehouse';
import ScreenFood from './foods/ScreenFood';
import ScreenMenu from './menu/ScreenMenu';
import ScreenSet from './sets/ScreenSet';

const App = () => (
  <Boot>
    <SnackbarProvider
      maxSnack={Default.maxSnack}
    >
      <Container flex>
        <SnackbarStack />
        <Sidebar key={1} />
        <Container flex horizontal>
          <Header key={2} />
          <Container flex style={{ flex: 10 }}>
            <Switch>
              <Route path="/organisation" component={ScreenOrganisation} />
              <Route path="/users" component={ScreenUsers} />
              <Route
                path={['/products/:id', '/products']}
                component={ScreenProducts}
              />
              <Route
                path={['/movements/:id', '/movements']}
                component={ScreenMovements}
              />
              <Route
                path={['/categories/:id', '/categories']}
                component={ScreenCategories}
              />
              <Route
                path={['/brands/:id', '/brands']}
                component={ScreenBrands}
              />
              <Route
                path={['/suppliers/:id', '/suppliers']}
                component={ScreenSuppliers}
              />
              <Route
                path="/stores/add"
                component={ScreenStoreAdd}
              />
              <Route
                path="/stores/:id"
                component={ScreenStore}
              />
              <Route
                path="/stores"
                component={ScreenStores}
                exact
              />
              <Route
                path="/display"
                component={ScreenDisplay}
              />
              <Route
                path="/warehouses/add"
                component={ScreenWarehouseCreate}
                exact
              />
              <Route
                path="/warehouses/:id"
                component={ScreenWarehouse}
              />
              <Route
                path="/warehouses"
                component={ScreenWarehouses}
                exact
              />
              <Route
                path="/menu"
                component={ScreenMenu}
                exact
              />
              <Route
                path="/foods/add"
                component={ScreenFood}
                exact
              />
              <Route
                path="/foods/:id"
                component={ScreenFood}
              />
              <Route
                path="/sets/add"
                component={ScreenSet}
                exact
              />
              <Route
                path="/sets/:id"
                component={ScreenSet}
              />
              <Route path="/dashboard" component={ScreenDashboard} />
              <Route path="/" component={ScreenDashboard} />
            </Switch>
          </Container>
        </Container>
      </Container>
    </SnackbarProvider>
  </Boot>
);

export default App;
