import React from 'react';
import PropTypes from 'prop-types';
import TextInput from '../../../../../components/Base/Input/TextInput';

const NameInput = ({
  onEnter, name, onChange, errors,
}) => (
  <TextInput
    label="Name"
    className="input"
    onChange={onChange}
    value={name}
    errors={errors}
    placeholder="HOUSEHOLD"
    onEnter={onEnter}
    shrink
    fullWidth
  />
);

NameInput.defaultProps = {
  errors: null,
  onEnter: null,
};

NameInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  errors: PropTypes.string,
  onEnter: PropTypes.func,
};

export default NameInput;
