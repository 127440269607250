import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dialog as MaterialDialog, DialogActions, makeStyles } from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';
import SupplierDropdown from '../../../../../components/Base/Input/SupplierDropdown';
import Container from '../../../../../components/Base/Container';
import CloseIconButton from '../../../../../components/Base/Button/CloseIcon';
import DoneButton from '../../../../../components/Base/Button/Done';
import PriceInput from '../../../../../components/Base/Input/PriceInput';
import NameInput from '../NameInput';
import { quotesCreateRequest } from '../../../../../providers/redux/actions/Quotes';
import Loader from '../../../../../components/Base/Loader';

const styles = makeStyles(theme => ({
  dialogPaper: {
    overflow: 'visible',
  },
  content: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
}));

const QuotesCreate = ({
  open, close,
}) => {
  const classes = styles();
  const dispatch = useDispatch();
  const { success, processing, errors } = useSelector(state => ({
    success: state.quotesReducer.create.success,
    processing: state.quotesReducer.create.processing,
    errors: state.quotesReducer.create.errors,
  }));

  const [name, setName] = useState('');
  const [supplier, setSupplier] = useState(null);
  const [cost, setCost] = useState('');

  useEffect(() => {
    if (success) {
      close();
    }
  }, [success]);

  const submit = async () => dispatch(
    quotesCreateRequest({
      name,
      supplier,
      cost,
    }),
  );

  return (
    <MaterialDialog
      maxWidth="sm"
      PaperProps={{ className: classes.dialogPaper }}
      className="dialog"
      open={open}
      aria-labelledby="form-dialog-title"
      scroll="body"
      onClose={close}
      fullWidth
    >
      <Container className="dialog-title" flex alignCenter>
        <Container className="dialog-title-close">
          <CloseIconButton onClick={close} />
        </Container>
        <Container>Add a new quote</Container>
      </Container>
      <Container className={classes.content}>
        <NameInput
          onChange={setName}
          errors={errors.name}
          name={name}
          onEnter={submit}
        />
        <SupplierDropdown
          selected={supplier}
          errors={errors.supplier}
          onChange={setSupplier}
          onEnter={submit}
          autoFocus
        />
        <PriceInput
          label="Cost"
          onChange={setCost}
          errors={errors.cost}
          placeholder="$12.35"
          onEnter={submit}
          value={cost}
        />
      </Container>
      <DialogActions>
        {processing ? <Loader /> : (
          <DoneButton
            onClick={submit}
            color="secondary"
            label="Create"
          />
        )}
      </DialogActions>
    </MaterialDialog>
  );
};

QuotesCreate.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default QuotesCreate;
