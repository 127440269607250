import React, { useEffect, useRef } from 'react';
import { Card, makeStyles } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import PropTypes from 'prop-types';

import BareText from '../../Input/BareText';
// import SearchFiltersMulti from './SearchFilters/SearchFiltersMulti';
import Container from '../../Container';
import ButtonClear from '../../Button/ButtonClear';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
  },
  searchBar: {
    flex: 1,
    borderRadius: 60,
    padding: theme.spacing(),
  },
  icon: {
    fontSize: 20,
    color: theme.palette.icon.main,
    marginRight: theme.spacing(),
  },
  clear: {
    fontSize: 20,
    color: theme.palette.icon.main,
  },
  filters: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    paddingTop: theme.spacing(),
    justifyContent: 'flex-end',
  },
}));

const inputs = ['input', 'select', 'textarea'];

const SearchInput = ({ value, onChange }) => {
  const inputRef = useRef();
  const timer = useRef();
  const barcode = useRef('');
  const classes = useStyles();

  const onScan = (e) => {
    const { activeElement } = document;
    if (activeElement && inputs.indexOf(activeElement.tagName.toLowerCase())) {
      clearTimeout(timer.current);
      barcode.current = `${barcode.current}${String.fromCharCode(e.which)}`;

      if (barcode.current !== '') {
        timer.current = setTimeout(() => {
          if (barcode.current.length < 5) {
            barcode.current = '';
            return;
          }

          onChange(barcode.current);
          barcode.current = '';
        }, 20);
      }
    }
  };

  const onClear = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }

    onChange('');
  };

  useEffect(() => {
    document.addEventListener('keyup', onScan);
    return () => {
      document.removeEventListener('keyup', onScan);
    };
  }, []);

  return (
    <Container className={classes.container}>
      <Card className={classes.searchBar}>
        <BareText
          ref={inputRef}
          startAdornment={
            <Search className={classes.icon} />
          }
          endAdornment={(
            <ButtonClear
              className={classes.clear}
              onPress={onClear}
            />
          )}
          placeholder="Search by ID or name"
          onChange={onChange}
          value={value}
          disableUnderline
          fullWidth
        />
      </Card>
      {/* {!!groups && groups.map(({ */}
      {/*  name, filters, selectedFilters, onFiltersChange, */}
      {/* }) => ( */}
      {/*  <Container key={name} className={classes.filters}> */}
      {/*    <SearchFiltersMulti */}
      {/*      name={name} */}
      {/*      selectedFilters={selectedFilters} */}
      {/*      filters={filters} */}
      {/*      onFiltersChange={onFiltersChange} */}
      {/*    /> */}
      {/*  </Container> */}
      {/* ))} */}
    </Container>
  );
};

SearchInput.defaultProps = {
  // groups: null,
};

SearchInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  // groups: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     name: PropTypes.string.isRequired,
  //     filters: PropTypes.array.isRequired,
  //     onFiltersChange: PropTypes.func.isRequired,
  //     selectedFilters: PropTypes.array.isRequired,
  //   }),
  // ),
};

export default SearchInput;
