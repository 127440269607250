import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ProductStoreDetailsRow from './Product/ProductStoreDetailsRow';
import Container from './Base/Container';
// import PriceInput from './Base/Input/PriceInput';

const useStyles = makeStyles(theme => ({
  card: {
    padding: theme.spacing(),
  },
  standardPrice: {
    marginTop: theme.spacing(2),
  },
}));

const Prices = ({
  className,
  // onEnter,
  // onStandardPriceChange,
  standardPrice,
  // standardPriceErrors,
  onChange,
  prices,
  // disabled,
}) => {
  const classes = useStyles();

  const reset = id => onChange(id, standardPrice);

  return (
    <Container className={classNames(classes.card, className)}>
      <Typography variant="h5">Prices</Typography>
      {/* <Container className="input"> */}
      {/*  <PriceInput */}
      {/*    className={classes.standardPrice} */}
      {/*    onChange={onStandardPriceChange} */}
      {/*    value={standardPrice} */}
      {/*    errors={standardPriceErrors} */}
      {/*    onEnter={onEnter} */}
      {/*    label="Standard price" */}
      {/*    disabled={disabled} */}
      {/*    helperText="This is the price that will be used for all the stores
       that do not have their own pricing." */}
      {/*  /> */}
      {/* </Container> */}
      {prices.map(
        ({ id, name, price }) => (
          <ProductStoreDetailsRow
            key={id}
            id={id}
            reset={reset}
            onChange={onChange}
            name={name}
            price={price}
            isStandard={price === parseFloat(standardPrice)}
          />
        ),
      )}
    </Container>
  );
};

Prices.defaultProps = {
  className: null,
  // disabled: false,
  // standardPriceErrors: [],
};

Prices.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  prices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      price: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]).isRequired,
    }),
  ).isRequired,
  standardPrice: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  // onEnter: PropTypes.func.isRequired,
  // onStandardPriceChange: PropTypes.func.isRequired,
  // standardPriceErrors: PropTypes.array,
  // disabled: PropTypes.bool,
};

export default Prices;
