import React from 'react';
import classNames from 'classnames';
import { makeStyles, Typography } from '@material-ui/core';
import Container from '../Base/Container';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  name: {
    flex: 5,
  },
  quantity: {
    flex: 1,
    justifyContent: 'center',
  },
  total: {
    flex: 2,
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const DisplayItemHeader = () => {
  const classes = useStyles();
  return (
    <Container className={classes.container}>
      <Typography
        className={classNames([classes.item, classes.quantity])}
        variant="caption"
      >
        Q
      </Typography>
      <Typography
        className={classNames([classes.item, classes.name])}
        variant="caption"
      >
        Name
      </Typography>
      <Typography
        className={classNames([classes.item, classes.total])}
        variant="caption"
      >
        Total
      </Typography>
    </Container>
  );
};

export default DisplayItemHeader;
