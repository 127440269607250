import { useSelector } from 'react-redux';

const useModelInteractorView = reducer => useSelector(
  state => ({
    item: state[reducer].selected,
    errors: state[reducer].edit.errors,
    processing: state[reducer].edit.processing,
    success: state[reducer].edit.success,
  }),
);

export default useModelInteractorView;
