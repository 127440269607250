import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Avatar as MaterialAvatar, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(
  theme => ({
    container: {
      cursor: 'pointer',
    },
    text: {
      color: theme.palette.common.white,
    },
    round: {
      borderRadius: '50%',
    },
    square: {
      borderRadius: '10%',
    },
  }),
);

const Avatar = ({
  className, width, height, fontSize, initials, onClick, shape, image,
}) => {
  const classes = useStyles();
  return (
    <MaterialAvatar
      src={image}
      className={classNames(classes.container, classes[shape], className)}
      onClick={onClick}
      style={{ width, height, fontSize }}
    >
      {!!initials && (
        <Typography className={classes.text}>
          {initials}
        </Typography>
      )}
    </MaterialAvatar>
  );
};

Avatar.defaultProps = {
  className: null,
  onClick: null,
  initials: null,
  width: 100,
  height: 100,
  shape: 'square',
  image: null,
  fontSize: 17,
};

Avatar.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  initials: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  width: PropTypes.number,
  height: PropTypes.number,
  fontSize: PropTypes.number,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  shape: PropTypes.oneOf([
    'round', 'square',
  ]),
};

export default Avatar;
