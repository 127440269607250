import moment from 'moment';

const alertTransformer = (alert) => {
  const { id } = alert;
  const { level, text, created_at: createdAt } = alert.data();

  return {
    id,
    level,
    text,
    createdAt: moment(createdAt.seconds * 1000),
  };
};

export default alertTransformer;
