export const actions = {
  PRINT: {
    REQUEST: 'BARCODES_PRINT_REQUEST',
    SUCCESS: 'BARCODES_PRINT_SUCCESS',
    ERRORS: 'BARCODES_PRINT_ERRORS',
  },
};

export const barcodesPrintRequest = ({
  productId, storeId, quantity, printerId,
}) => ({
  type: actions.PRINT.REQUEST,
  payload: {
    productId,
    storeId,
    quantity,
    printerId,
  },
});

export const barcodesPrintSuccess = () => ({
  type: actions.PRINT.SUCCESS,
});

export const barcodesPrintErrors = ({ errors }) => ({
  type: actions.PRINT.ERRORS,
  payload: errors,
});
