export const actions = {
  GET: 'BRANDS_GET',
  PUT: 'BRANDS_PUT',
  UPDATE: 'BRANDS_UPDATE',
  DELETE: 'BRANDS_DELETE',
  RESET: 'BRANDS_RESET',
  CREATE: {
    REQUEST: 'BRANDS_CREATE_REQUEST',
    SUCCESS: 'BRANDS_CREATE_SUCCESS',
    PROCESSING: 'BRANDS_CREATE_PROCESSING',
    PROCESSED: 'BRANDS_CREATE_PROCESSED',
    ERRORS: 'BRANDS_CREATE_ERRORS',
  },
  EDIT: {
    REQUEST: 'BRANDS_EDIT_REQUEST',
    SUCCESS: 'BRANDS_EDIT_SUCCESS',
    PROCESSING: 'BRANDS_EDIT_PROCESSING',
    PROCESSED: 'BRANDS_EDIT_PROCESSED',
    ERRORS: 'BRANDS_EDIT_ERRORS',
  },
  ITEM: {
    SELECT: 'BRANDS_ITEM_SELECT',
    RESET: 'BRANDS_ITEM_RESET',
    PUT: 'BRANDS_ITEM_PUT',
  },
  AUTOCOMPLETE: {
    GET: 'BRANDS_AUTOCOMPLETE_GET',
    PUT: 'BRANDS_AUTOCOMPLETE_PUT',
    CLEAR: 'BRANDS_AUTOCOMPLETE_CLEAR',
  },
};

export const brandsGet = ({ search, filters, reset }) => ({
  type: actions.GET,
  payload: {
    search,
    filters,
    reset,
  },
});

export const brandsPut = ({
  page, timestamp, data, hasMore, search,
}) => ({
  type: actions.PUT,
  payload: {
    page,
    timestamp,
    data,
    hasMore,
    search,
  },
});

export const brandsPutSingle = ({
  brand,
}) => ({
  type: actions.UPDATE,
  payload: brand,
});

export const brandsDelete = ({ id }) => ({
  type: actions.DELETE,
  payload: id,
});

export const brandsReset = () => ({
  type: actions.RESET,
});

export const brandsEditRequest = ({
  id,
  name,
}) => ({
  type: actions.EDIT.REQUEST,
  payload: {
    id,
    name,
  },
});

export const brandsEditProcessing = () => ({
  type: actions.EDIT.PROCESSING,
});

export const brandsEditProcessed = () => ({
  type: actions.EDIT.PROCESSED,
});

export const brandsEditSuccess = () => ({
  type: actions.EDIT.SUCCESS,
});

export const brandsEditErrors = ({ errors }) => ({
  type: actions.EDIT.ERRORS,
  payload: errors,
});

export const brandsCreateRequest = ({
  name,
}) => ({
  type: actions.CREATE.REQUEST,
  payload: {
    name,
  },
});

export const brandsCreateProcessing = () => ({
  type: actions.CREATE.PROCESSING,
});

export const brandsCreateProcessed = () => ({
  type: actions.CREATE.PROCESSED,
});

export const brandsCreateSuccess = () => ({
  type: actions.CREATE.SUCCESS,
});

export const brandsCreateErrors = ({ errors }) => ({
  type: actions.CREATE.ERRORS,
  payload: errors,
});

export const brandsItemSelect = ({ id }) => ({
  type: actions.ITEM.SELECT,
  payload: id,
});

export const brandsItemReset = () => ({
  type: actions.ITEM.RESET,
});

export const brandsItemPut = ({ item }) => ({
  type: actions.ITEM.PUT,
  payload: item,
});

export const brandsAutocompleteGet = ({ text }) => ({
  type: actions.AUTOCOMPLETE.GET,
  payload: text,
});

export const brandsAutocompletePut = ({ data }) => ({
  type: actions.AUTOCOMPLETE.PUT,
  payload: data,
});

export const brandsAutocompleteClear = () => ({
  type: actions.AUTOCOMPLETE.CLEAR,
});
