import React from 'react';
import NumberFormat from 'react-number-format';
import Measurements from '../constants/measurements';

const DecimalFormatComponent = ({
  // eslint-disable-next-line react/prop-types
  inputRef, onChange, placeholder, ...other
}) => (
  <NumberFormat
    {...other}
    getInputRef={inputRef}
    placeholder={placeholder}
    onValueChange={(values) => {
      onChange({
        target: {
          value: values.value,
        },
      });
    }}
    decimalScale={2}
    fixedDecimalScale
    thousandSeparator
  />
);

const NumberFormatComponent = ({
  // eslint-disable-next-line react/prop-types
  inputRef, onChange, placeholder, ...other
}) => (
  <NumberFormat
    {...other}
    getInputRef={inputRef}
    placeholder={placeholder}
    onValueChange={(values) => {
      onChange({
        target: {
          value: values.value,
        },
      });
    }}
    thousandSeparator
  />
);

const QuantityFormat = (measurement) => {
  if (measurement) {
    const { type } = measurement;
    switch (type) {
      case Measurements.float:
        return DecimalFormatComponent;
      case Measurements.integer:
      default:
        return NumberFormatComponent;
    }
  }

  return NumberFormatComponent;
};

export default QuantityFormat;
