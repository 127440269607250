import camelCaseTransformer from './redux/transformers/CamelCaseTransformer';

const hasErrors = (e) => {
  if (e.response && e.response.data) {
    return camelCaseTransformer(e.response.data.errors);
  }

  return null;
};

export default hasErrors;
