import Typography from '@material-ui/core/Typography';
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as IconStore } from '../../assets/icons/ic-store.svg';
import usePermissions from '../../providers/hooks/usePermissions';
import Permissions from '../../providers/Permissions';
import Container from '../Base/Container';
import PlaceStatus from '../PlaceStatus';
import StatisticsValue from '../Statistics/StatisticsValue';
import MapStoreStatus from './MapStoreStatus';
import Constants from '../../constants';
import useNavigate from '../../services/useNavigate';

const useStyles = makeStyles(
  theme => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    icon: {
      width: 60,
      height: 60,
      marginBottom: theme.spacing(0.5),
    },
    infoBar: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      background: theme.palette.common.white,
      borderRadius: 4,
      paddingLeft: theme.spacing(),
      paddingRight: theme.spacing(),
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      boxShadow: '0 3px 6px rgba(0,0,0,.16)',
    },
    infoBarDark: {
      background: '#2E3A4B',
      color: '#EBEBEB',
    },
    revenue: {
      flex: 1,
      textAlign: 'right',
      marginRight: theme.spacing(),
    },
    transactionsCount: {
      marginRight: theme.spacing(),
    },
    stock: {
      flex: 1,
      marginLeft: theme.spacing(),
      marginRight: theme.spacing(),
    },
    revenueText: {
      textAlign: 'right',
      fontWeight: 'bold',
    },
  }),
);

const MapStoreMarker = ({
  id,
  transactionsCount,
  revenue,
  status,
  darkMode,
}) => {
  const userCan = usePermissions();
  const navigate = useNavigate();
  const classes = useStyles();

  const onPress = () => navigate(
    `${Constants.routes.Stores}/${id}`,
  );

  return (
    <Container className={classes.container} onClick={onPress}>
      <Container>
        <IconStore className={classes.icon} />
      </Container>
      <Container className={
        classNames([
          classes.infoBar,
          darkMode ? classes.infoBarDark : null,
        ])
      }
      >
        {userCan(Permissions.viewRevenue) && (
          <>
            <Container className={classes.transactionsCount}>
              <StatisticsValue
                value={transactionsCount}
                valueVariant="caption"
                type="number"
              />
            </Container>
            <Container className={classes.revenue}>
              <StatisticsValue
                valueClassName={classes.revenueText}
                value={revenue}
                valueVariant="caption"
                type="price"
              />
            </Container>
          </>
        )}
        <MapStoreStatus status={status} />
        {!userCan(Permissions.viewRevenue) && (
          <Typography className={classes.stock}>
            <PlaceStatus variant="caption" status={status} />
          </Typography>
        )}
      </Container>
    </Container>
  );
};

MapStoreMarker.defaultProps = {
  darkMode: false,
};

MapStoreMarker.propTypes = {
  id: PropTypes.string.isRequired,
  transactionsCount: PropTypes.number.isRequired,
  revenue: PropTypes.number.isRequired,
  status: PropTypes.number.isRequired,
  darkMode: PropTypes.bool,
};

export default MapStoreMarker;
