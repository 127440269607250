import React from 'react';
import {
  TableRow, TableCell, Tooltip, TableSortLabel, TableHead,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const TableHeader = ({ order, orderBy, columns }) => (
  <TableHead>
    <TableRow>
      {columns.map(
        row => (
          <TableCell
            key={row.dataKey}
            sortDirection={orderBy === row.dataKey ? order : false}
            align={row.align || 'inherit'}
          >
            {row.name && (
              <Tooltip
                title="Sort"
                placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                enterDelay={300}
              >
                <TableSortLabel
                  active={orderBy === row.dataKey}
                  direction={order}
                  onClick={null}
                >
                  {row.name}
                </TableSortLabel>
              </Tooltip>
            )}
          </TableCell>
        ),
      )}
    </TableRow>
  </TableHead>
);

TableHeader.defaultProps = {
  order: 'asc',
  orderBy: null,
};

TableHeader.propTypes = {
  order: PropTypes.string,
  orderBy: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      dataKey: PropTypes.string.isRequired,
      align: PropTypes.oneOf([
        'inherit', 'left', 'right', 'center', 'justify',
      ]),
    }),
  ).isRequired,

};

export default TableHeader;
