export const actions = {
  SYNC: 'DISPLAYS_SYNC',
  PUT: 'DISPLAYS_PUT',
  STORE: {
    PUT: 'DISPLAYS_STORE_PUT',
  },
};

export const displaysSync = storeId => ({
  type: actions.SYNC,
  payload: storeId,
});

export const displaysPutStore = data => ({
  type: actions.STORE.PUT,
  payload: data,
});

export const displaysPut = data => ({
  type: actions.PUT,
  payload: data,
});
