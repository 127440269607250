import { firestore } from 'firebase';
import {
  all,
  call,
  put,
  putResolve,
  fork,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import Constants from '../../../constants';
import { Instance, Resources } from '../../API';
import firebase from '../../firebase';
import Permissions from '../../Permissions';
import {
  actions,
  storesItemPut,
  storesItemReset,
  storesPut,
  storesEditSuccess,
  storesEditErrors,
  storesCreateErrors,
  storesCreateProcessed,
  storesCreateSuccess,
  storesCreateProcessing,
  storesAutocompleteClear,
  storesAutocompletePut,
  storesDestroySuccess, storesAlertsPut,
} from '../actions/Stores';
import {
  snackbarDefaultError,
  snackbarDefaultSuccess,
} from '../actions/Snackbar';
import hasErrors from '../../hasErrors';
import storeTransformer
  from '../transformers/firebase/dashboard/StoreTransformer';
import StoreTransformer from '../transformers/StoreTransformer';
import { dashboardSettlementsSync } from '../actions/Dashboard';
import alertTransformer from '../transformers/firebase/AlertTransformer';
import collectionTransformer
  from '../transformers/firebase/CollectionTransformer';

const resource = Resources.stores;

const storesPrintersCall = placeId => Instance.get(
  `${Resources.places}/${Resources.printers}`,
  {
    params: {
      place_id: placeId,
    },
  },
);

const storesCreateCall = ({
  organisationId,
  name,
  address,
  address2,
  postalCode,
}) => Instance.post(
  resource,
  {
    organisation_id: organisationId,
    name,
    address_1: address,
    address_2: address2,
    postal_code: postalCode,
  },
);

export const storesAutocompleteCall = ({
  organisationId, text,
}) => Instance.get(
  `${resource}/${Resources.autocomplete}`,
  {
    params: {
      organisation_id: organisationId,
      search: text !== '' ? text : null,
    },
  },
);

export const storesViewCall = ({ id }) => Instance.get(`${resource}/${id}`);

export const storesPutCall = ({
  id,
  name,
  address,
  address2,
  postalCode,
}) => Instance.put(
  `${resource}/${id}`,
  {
    name,
    address_1: address,
    address_2: address2,
    postal_code: postalCode,
  },
);

export const callStoresDestroy = id => Instance.delete(`${resource}/${id}`);

export const dataPermissions = state => state.authReducer.permissions;

const siteData = state => ({
  organisationId: state.organisationReducer.data.id,
});

const storesData = state => ({
  data: state.storesReducer.data,
});


function* sagaSyncAlerts(storeId) {
  try {
    const { organisationId } = yield select(siteData);
    yield fork(
      firebase.firestore.syncCollection,
      firestore().collection(Constants.firestore.collections.organisations)
        .doc(organisationId.toString())
        .collection(Constants.firestore.collections.stores)
        .doc(storeId.toString())
        .collection(Constants.firestore.collections.alerts),
      {
        successActionCreator: storesAlertsPut,
        transform: collectionTransformer(alertTransformer),
      },
    );
  } catch (e) {
    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

function* sagaStoreIndex() {
  try {
    const { organisationId } = yield select(siteData);
    yield fork(
      firebase.firestore.syncCollection,
      firestore().collection(Constants.firestore.collections.organisations)
        .doc(organisationId.toString())
        .collection(Constants.firestore.collections.stores),
      {
        successActionCreator: storesPut,
        transform: collectionTransformer(storeTransformer),
      },
    );

    yield put(
      dashboardSettlementsSync(organisationId),
    );
  } catch (e) {
    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

/**
 * createStoresSaga
 * @param store
 */
function* createStoresSaga({
  payload: {
    name,
    address,
    address2,
    postalCode,
  },
}) {
  try {
    yield put(storesCreateProcessing());
    const { organisationId } = yield select(siteData);
    const { data } = yield call(
      storesCreateCall,
      {
        organisationId,
        name,
        address,
        address2,
        postalCode,
      },
    );

    yield put(
      storesCreateSuccess(),
    );

    yield put(
      snackbarDefaultSuccess({ data }),
    );
  } catch (e) {
    const errors = hasErrors(e);
    if (errors) {
      yield put(
        storesCreateErrors({ errors }),
      );
    }
    yield put(
      snackbarDefaultError({ e }),
    );
  } finally {
    yield put(
      storesCreateProcessed(),
    );
  }
}

function* sagaStoresUpdate({
  payload: {
    id,
    name,
    address,
    address2,
    postalCode,
  },
}) {
  try {
    const { data } = yield call(
      storesPutCall,
      {
        id,
        name,
        address,
        address2,
        postalCode,
      },
    );

    yield put(
      storesItemPut({ item: StoreTransformer(data.data) }),
    );

    yield put(
      snackbarDefaultSuccess({ data }),
    );

    yield put(
      storesEditSuccess(),
    );
  } catch (e) {
    const errors = hasErrors(e);
    if (errors) {
      yield put(
        storesEditErrors({ errors }),
      );
    }
    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

function* sagaStoresDestroy({ payload: id }) {
  try {
    const response = yield call(callStoresDestroy, id);
    yield put(
      storesItemReset(),
    );

    yield put(
      storesDestroySuccess(),
    );

    yield put(
      snackbarDefaultSuccess({ data: response.data }),
    );
  } catch (e) {
    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

/**
 * selectStoreSaga
 * @param id
 */
function* selectStoreSaga({ payload: id }) {
  try {
    const {
      data: { data },
    } = yield call(
      storesViewCall,
      { id },
    );

    const store = StoreTransformer(data);

    let printers = [];
    const permissions = yield select(dataPermissions);
    if (permissions.indexOf(Permissions.editPrinter) !== -1) {
      const {
        data: { data: printerData },
      } = yield call(
        storesPrintersCall,
        store.placeId,
      );

      printers = printerData;
    }

    yield putResolve(
      storesItemPut({
        item: {
          ...store,
          printers,
          alerts: [],
        },
      }),
    );

    yield call(sagaSyncAlerts, id);
  } catch (e) {
    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

/**
 * updateStoresSaga
 * @param store
 */
function* updateStoresSaga({ payload: store }) {
  const { data } = yield select(storesData);
  const newData = [...data];
  const index = newData.findIndex(({ id }) => store.id === id);
  if (index !== -1) {
    newData[index] = { ...newData[index], ...store };
  } else {
    newData.unshift(store);
  }

  yield put(
    storesPut({ data: newData }),
  );
}

/**
 * @returns {IterableIterator<*>}
 */
function* storesAutocompleteGetSaga({ payload: text }) {
  try {
    if (text === '') {
      yield put(storesAutocompleteClear());
      return;
    }

    const { organisationId } = yield select(siteData);
    const { data } = yield call(
      storesAutocompleteCall,
      {
        organisationId,
        text,
      },
    );

    yield put(
      storesAutocompletePut({ data: data.data }),
    );
  } catch (e) {
    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

export default function* Stores() {
  yield all([
    takeLatest(actions.GET, sagaStoreIndex),
    takeLatest(actions.DESTROY.REQUEST, sagaStoresDestroy),
    takeLatest(actions.ITEM.SELECT, selectStoreSaga),
    takeEvery(actions.UPDATE, updateStoresSaga),
    takeLatest(actions.EDIT.REQUEST, sagaStoresUpdate),
    takeLatest(actions.CREATE.REQUEST, createStoresSaga),
    takeLatest(actions.AUTOCOMPLETE.GET, storesAutocompleteGetSaga),
  ]);
}
