import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(
  theme => ({
    maxWidth: {
      width: '100%',
    },
    innerWrapper: {
      display: 'flex',
      paddingBottom: theme.spacing(),
    },
  }),
);

const CardScroll = ({ innerWrapperClass, children }) => {
  const classes = useStyles();
  return (
    <ScrollMenu
      menuClass={classNames(classes.maxWidth, classes.menu)}
      wrapperClass={classes.maxWidth}
      innerWrapperClass={
        classNames([
          classes.innerWrapper,
          innerWrapperClass,
        ])
      }
      inertiaScrollingSlowdown={0.25}
      transition={0.4}
      data={children}
      alignCenter={false}
      wheel={false}
      alignOnResize
    />
  );
};

CardScroll.defaultProps = {
  innerWrapperClass: null,
};

CardScroll.propTypes = {
  children: PropTypes.array.isRequired,
  innerWrapperClass: PropTypes.string,
};

export default CardScroll;
