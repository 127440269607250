import {
  all,
  call,
  put,
  putResolve,
  select,
  takeLatest,
} from 'redux-saga/effects';
import { Instance, Resources } from '../../../API';
import {
  actions,
  supplierProductsIndexReset,
  supplierProductsIndexSuccess,
} from '../../actions/Supplier/Products';
import {
  snackbarDefaultError,
} from '../../actions/Snackbar';

const resource = Resources.products;

export const callSupplierProductIndex = (
  organisationId, supplierId, { search, timestamp, page },
) => Instance.get(
  resource,
  {
    params: {
      organisation_id: organisationId,
      supplier_id: supplierId,
      timestamp,
      page,
      search,
    },
  },
);

const dataSite = state => ({
  organisationId: state.organisationReducer.data.id,
});

const dataSupplier = state => ({
  selected: state.suppliersReducer.selected,
});

const dataMetadata = state => ({
  supplierId: state.supplierProductsReducer.index.supplierId,
  search: state.supplierProductsReducer.index.search,
  data: state.supplierProductsReducer.index.data,
  timestamp: state.supplierProductsReducer.index.timestamp,
  page: state.supplierProductsReducer.index.page,
});

/**
 * Sagas
 */
function* sagaSupplierProductsLoad({ payload: search }) {
  const { selected } = yield select(dataSupplier);
  if (selected !== null) {
    try {
      const { id: supplierId } = selected;
      const { organisationId } = yield select(dataSite);
      const metadata = yield select(dataMetadata);
      const {
        supplierId: originalSupplierId,
        search: originalSearch,
        page,
      } = metadata;
      let {
        timestamp,
        data: originalData,
      } = metadata;

      let nextPage = page + 1;
      if (supplierId !== originalSupplierId || search !== originalSearch) {
        yield putResolve(
          supplierProductsIndexReset(),
        );

        nextPage = 1;
        timestamp = null;
        originalData = [];
      }

      const { data } = yield call(
        callSupplierProductIndex,
        organisationId,
        supplierId,
        {
          timestamp,
          page: nextPage > 1 ? nextPage : null,
          search,
        },
      );

      const {
        data: {
          page: newPage,
          timestamp: newTimestamp,
          items,
        },
      } = data;

      yield putResolve(
        supplierProductsIndexSuccess(
          [...originalData, ...items],
          {
            supplierId,
            search,
            timestamp: newTimestamp,
            page: newPage,
            hasMore: items.length > 0,
          },
        ),
      );
    } catch (e) {
      yield put(
        snackbarDefaultError({ e }),
      );
    }
  }
}

export default function* SupplierProducts() {
  yield all([
    takeLatest(actions.INDEX.REQUEST, sagaSupplierProductsLoad),
  ]);
}
