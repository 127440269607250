export const actions = {
  GET: 'STORES_GET',
  PUT: 'STORES_PUT',
  UPDATE: 'STORES_UPDATE',
  DELETE: 'STORES_DELETE',
  RESET: 'STORES_RESET',
  CREATE: {
    REQUEST: 'STORES_CREATE_REQUEST',
    SUCCESS: 'STORES_CREATE_SUCCESS',
    PROCESSING: 'STORES_CREATE_PROCESSING',
    PROCESSED: 'STORES_CREATE_PROCESSED',
    ERRORS: 'STORES_CREATE_ERRORS',
    RESET: 'STORES_CREATE_RESET',
  },
  EDIT: {
    REQUEST: 'STORES_EDIT_REQUEST',
    SUCCESS: 'STORES_EDIT_SUCCESS',
    PROCESSING: 'STORES_EDIT_PROCESSING',
    PROCESSED: 'STORES_EDIT_PROCESSED',
    ERRORS: 'STORES_EDIT_ERRORS',
  },
  ITEM: {
    SELECT: 'STORES_ITEM_SELECT',
    RESET: 'STORES_ITEM_RESET',
    PUT: 'STORES_ITEM_PUT',
  },
  AUTOCOMPLETE: {
    GET: 'STORES_AUTOCOMPLETE_GET',
    PUT: 'STORES_AUTOCOMPLETE_PUT',
    CLEAR: 'STORES_AUTOCOMPLETE_CLEAR',
  },
  DESTROY: {
    REQUEST: 'STORES_DESTROY_REQUEST',
    SUCCESS: 'STORES_DESTROY_SUCCESS',
    ERRORS: 'STORES_DESTROY_ERRORS',
    RESET: 'STORES_DESTROY_RESET',
  },
  ALERTS: {
    PUT: 'STORES_ALERTS_PUT',
  },
};

export const storesGet = ({ search, filters, reset } = {}) => ({
  type: actions.GET,
  payload: {
    search,
    filters,
    reset,
  },
});

export const storesPut = data => ({
  type: actions.PUT,
  payload: data,
});

export const storesPutSingle = store => ({
  type: actions.UPDATE,
  payload: store,
});

export const storesEditRequest = ({
  id,
  name,
  address,
  address2,
  postalCode,
}) => ({
  type: actions.EDIT.REQUEST,
  payload: {
    id,
    name,
    address,
    address2,
    postalCode,
  },
});

export const storesEditSuccess = () => ({
  type: actions.EDIT.SUCCESS,
});

export const storesEditErrors = ({ errors }) => ({
  type: actions.EDIT.ERRORS,
  payload: errors,
});

export const storesCreateRequest = ({
  name,
  address,
  address2,
  postalCode,
}) => ({
  type: actions.CREATE.REQUEST,
  payload: {
    name,
    address,
    address2,
    postalCode,
  },
});

export const storesCreateProcessing = () => ({
  type: actions.CREATE.PROCESSING,
});

export const storesCreateProcessed = () => ({
  type: actions.CREATE.PROCESSED,
});

export const storesCreateSuccess = () => ({
  type: actions.CREATE.SUCCESS,
});

export const storesCreateErrors = ({ errors }) => ({
  type: actions.CREATE.ERRORS,
  payload: errors,
});

export const storesCreateReset = () => ({
  type: actions.CREATE.RESET,
});

export const storesItemSelect = ({ id }) => ({
  type: actions.ITEM.SELECT,
  payload: id,
});

export const storesItemReset = () => ({
  type: actions.ITEM.RESET,
});

export const storesItemPut = ({ item }) => ({
  type: actions.ITEM.PUT,
  payload: item,
});

export const storesAutocompleteGet = ({ text }) => ({
  type: actions.AUTOCOMPLETE.GET,
  payload: text,
});

export const storesAutocompletePut = ({ data }) => ({
  type: actions.AUTOCOMPLETE.PUT,
  payload: data,
});

export const storesAutocompleteClear = () => ({
  type: actions.AUTOCOMPLETE.CLEAR,
});

export const storesDestroyRequest = id => ({
  type: actions.DESTROY.REQUEST,
  payload: id,
});

export const storesDestroySuccess = () => ({
  type: actions.DESTROY.SUCCESS,
});

export const storesDestroyErrors = errors => ({
  type: actions.DESTROY.ERRORS,
  payload: errors,
});

export const storesDestroyReset = () => ({
  type: actions.DESTROY.RESET,
});

export const storesAlertsPut = data => ({
  type: actions.ALERTS.PUT,
  payload: data,
});
