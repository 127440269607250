import React from 'react';
import { makeStyles } from '@material-ui/core';
import Container from './Base/Container';
import { ReactComponent as ConstructionSite } from '../assets/images/construction/construction-site.svg';
import { ReactComponent as ConstructionVehicle } from '../assets/images/construction/construction-vehicle.svg';

const styles = makeStyles(theme => ({
  '@keyframes move': {
    '0%': {
      transform: 'translate(-500px,-300px)',
      opacity: 0,
    },
    '5%': {
      opacity: 1,
    },
    '48%': {
      transform: 'translate(5px,3px)',
    },
    '50%': {
      transform: 'translate(0,0)',
    },
    '56%': {
      transform: 'translate(0,0)',
    },
    '58%': {
      transform: 'translate(-5px,-3px)',
    },
    '60%': {
      transform: 'translate(0,0)',
    },
    '90%': {
      opacity: 1,
    },
    '100%': {
      transform: 'translate(500px,300px)',
      opacity: 0,
    },
  },
  title: {
    paddingBottom: theme.spacing(4),
  },
  site: {
    position: 'relative',
    paddingBottom: 70,
    zIndex: -2,
  },
  truck: {
    position: 'absolute',
    left: -10,
    bottom: 0,
    zIndex: -1,
    animation: '8s $move ease-in-out infinite',
  },
}));

const UnderConstruction = () => {
  const classes = styles();
  return (
    <Container>
      <ConstructionSite className={classes.site} />
      <ConstructionVehicle className={classes.truck} />
    </Container>
  );
};

export default UnderConstruction;
