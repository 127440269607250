import {
  all, fork, takeLatest, put, select, cancel, take,
} from 'redux-saga/effects';
import { firestore } from 'firebase/app';
import {
  actions,
  productsIndexSuccess,
} from '../../actions/Stores/Products';
import firebase from '../../../firebase';
import Constants from '../../../../constants';
import { snackbarDefaultError } from '../../actions/Snackbar';
import productTransformer from '../../transformers/firebase/ProductTransformer';
import collectionTransformer
  from '../../transformers/firebase/CollectionTransformer';

const perPage = 20;

const collectionStoreProducts = (organisationId, storeId) => firestore()
  .collection(Constants.firestore.collections.organisations)
  .doc(organisationId.toString())
  .collection(Constants.firestore.collections.stores)
  .doc(storeId)
  .collection(Constants.firestore.collections.products)
  .orderBy('alert', 'desc')
  .orderBy('updated_at', 'desc');

const dataSite = state => ({
  organisationId: state.organisationReducer.data.id,
});

const dataPage = state => ({
  page: state.storesProductsReducer.page,
});

function* sagaProductsIndex({ payload: storeId }) {
  try {
    const { organisationId } = yield select(dataSite);
    if (organisationId) {
      const { page } = yield select(dataPage);
      const task = yield fork(
        firebase.firestore.syncCollection,
        collectionStoreProducts(organisationId, storeId)
          .limit(page * perPage),
        {
          successActionCreator: productsIndexSuccess,
          transform: collectionTransformer(productTransformer),
        },
      );

      while (true) {
        yield take(actions.PAGE.NEXT);
        const { page: newPage } = yield select(dataPage);
        yield cancel(task);
        yield fork(
          firebase.firestore.syncCollection,
          collectionStoreProducts(organisationId, storeId)
            .limit(newPage * perPage),
          {
            successActionCreator: productsIndexSuccess,
            transform: collectionTransformer(productTransformer),
          },
        );
      }
    }
  } catch (e) {
    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

export default function* Products() {
  yield all([
    takeLatest(actions.INDEX.REQUEST, sagaProductsIndex),
  ]);
}
