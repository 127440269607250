import {
  all, fork, takeLatest, put, select, call,
} from 'redux-saga/effects';
import { firestore } from 'firebase/app';
import {
  dashboardStatisticsPut,
  dashboardStoresPut,
  actions, dashboardSettlementsPut,
} from '../actions/Dashboard';
import firebase from '../../firebase';
import Constants from '../../../constants';
import organisationTransformer
  from '../transformers/firebase/dashboard/OrganisationTransformer';
import storeTransformer
  from '../transformers/firebase/dashboard/StoreTransformer';
import { snackbarDefaultError } from '../actions/Snackbar';
import settlementTransformer
  from '../transformers/firebase/dashboard/SettlementTransformer';
import collectionTransformer
  from '../transformers/firebase/CollectionTransformer';

const siteData = state => ({
  organisationId: state.organisationReducer.data.id,
});

function* sagaSyncSettlement({ payload: organisationId }) {
  yield fork(
    firebase.firestore.syncCollection,
    firestore().collection(Constants.firestore.collections.organisations)
      .doc(organisationId.toString())
      .collection(Constants.firestore.collections.settlements),
    {
      successActionCreator: dashboardSettlementsPut,
      transform: collectionTransformer(settlementTransformer),
    },
  );
}

function* syncDashboardSaga() {
  try {
    const { organisationId } = yield select(siteData);
    if (organisationId) {
      yield all([
        fork(
          firebase.firestore.syncDocument,
          firestore().collection(Constants.firestore.collections.organisations)
            .doc(organisationId.toString()),
          {
            successActionCreator: dashboardStatisticsPut,
            transform: organisationTransformer,
          },
        ),
        fork(
          firebase.firestore.syncCollection,
          firestore().collection(Constants.firestore.collections.organisations)
            .doc(organisationId.toString())
            .collection(Constants.firestore.collections.stores),
          {
            successActionCreator: dashboardStoresPut,
            transform: collectionTransformer(storeTransformer),
          },
        ),
      ]);

      yield call(sagaSyncSettlement, { payload: organisationId });
    }
  } catch (e) {
    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

export default function* Dashboard() {
  yield all([
    takeLatest(actions.SYNC, syncDashboardSaga),
    takeLatest(actions.SETTLEMENTS.SYNC, sagaSyncSettlement),
  ]);
}
