import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { ReactComponent as IconLogo } from '../assets/logo.svg';

const styles = makeStyles(theme => ({
  logo: {
    fill: theme.palette.primary.main,
  },
  logoWhite: {
    fill: theme.palette.common.white,
  },
}));

const Logo = ({
  className, color, size,
}) => {
  const classes = styles();
  return (
    <IconLogo
      className={classNames([
        className,
        color ? classes.logo : classes.logoWhite,
      ])}
      width={size}
    />
  );
};

Logo.defaultProps = {
  className: null,
  color: false,
  size: 17,
};

Logo.propTypes = {
  className: PropTypes.string,
  color: PropTypes.bool,
  size: PropTypes.number,
};

export default Logo;
