import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import Container from '../../../../components/Base/Container';
import NameInput from './Inputs/NameInput';
import ProductsList from './ProductsList';
import useModelInteractorView
  from '../../../../providers/hooks/useModelInteractorView';
import fastCompare from '../../../../providers/fastCompare';
import {
  suppliersDelete,
  suppliersEditRequest,
} from '../../../../providers/redux/actions/Suppliers';
import ModelInteractorStandardView
  from '../../../../components/Base/ModelInteractor/ModelInteractorStandardView';

const useStyles = makeStyles(theme => ({
  parent: {
    width: '100%',
  },
  input: {
    padding: theme.spacing(8),
    paddingBottom: theme.spacing(2),
  },
}));

const SupplierView = () => {
  const dispatch = useDispatch();
  const {
    item, errors, processing, success,
  } = useModelInteractorView('suppliersReducer');
  const classes = useStyles();

  const {
    id,
    name: supplierName,
  } = item;

  const [name, setName] = useState(supplierName);

  useEffect(() => {
    if (success) {
      document.activeElement.blur();
    }
  }, [success]);

  useEffect(() => {
    const {
      name: newSupplierName,
    } = item;

    setName(newSupplierName);
  }, [item]);

  const previous = {
    name: supplierName,
  };

  const current = {
    name,
  };

  const isSame = fastCompare(previous, current);

  const edit = () => !isSame && dispatch(
    suppliersEditRequest({
      id,
      name,
    }),
  );

  const remove = () => dispatch(suppliersDelete({ id }));

  return (
    <ModelInteractorStandardView
      isSame={isSame}
      edit={edit}
      remove={remove}
      processing={processing}
      success={success}
    >
      <Container className={classes.parent} flex horizontal>
        <Container
          className={classes.input}
        >
          <NameInput
            onChange={setName}
            name={name}
            errors={errors.name}
            onEnter={edit}
          />
        </Container>
        <ProductsList supplierId={id} />
      </Container>
    </ModelInteractorStandardView>
  );
};

export default SupplierView;
