import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useDropzone } from 'react-dropzone';
import { CloseRounded, CloudUploadRounded } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import IconButton from '../Button/IconButton/IconButton';
import Container from '../Container';
import Dialog from '../Dialog';
import Avatar from '../../Avatar';
import Approval from '../Dialog/Approval';

const useStyles = makeStyles(
  theme => ({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    container: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: 200,
      borderRadius: 8,
      color: '#D2D2D2',
      background: 'transparent',
      overflow: 'hidden',
      border: '2px dashed #D2D2D2',
    },
    dragZone: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    dragging: {
      backgroundColor: 'rgba(0,255,255,.2)',
      border: '1px solid rgba(0,255,255, 1.0)',
    },
    icon: {
      fontSize: 56,
    },
    imagePreview: {
      background: 'center / contain no-repeat',
      width: '100%',
      height: 300,
    },
    close: {
      marginTop: theme.spacing(),
      background: 'rgba(0,0,0,.02)',
    },
  }),
);

const ImagePicker = ({
  width, height, fontSize, image, shape, onChange,
}) => {
  const classes = useStyles();
  const input = useRef();
  const zone = useRef();

  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [open, setOpen] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);

  const onOpen = () => setOpen(true);

  const onClose = () => {
    setFile(null);
    setPreview(null);
    setOpen(false);
  };

  const onCloseRemove = () => setIsRemoving(false);

  const onOpenRemove = () => setIsRemoving(true);

  const onCancel = () => {
    onClose();
    onChange(null);
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      const attachedFile = acceptedFiles[0];
      setFile(
        Object.assign(attachedFile, {
          preview: URL.createObjectURL(attachedFile),
        }),
      );
    },
    [setFile],
  );

  const onUpload = () => {
    onChange(file);
    setFile(null);
    onClose();
  };

  const onClick = () => input.current && input.current.click();

  const onRemove = () => onChange(null);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/*',
    onDrop,
  });

  useEffect(() => {
    if (image !== null) {
      if (image instanceof File) {
        setPreview(URL.createObjectURL(image));
        return;
      }

      setPreview(image);
    }
  }, [image]);

  return (
    <Container className={classes.wrapper}>
      <Approval
        triggerEvent={onRemove}
        open={isRemoving}
        closeDialog={onCloseRemove}
        title="Remove image"
        content="Do you want to remove this image?"
      />
      <Dialog
        title="Upload an image"
        open={open}
        close={onCancel}
        callback={onUpload}
        reject="Cancel"
        accept="Upload"
      >
        <div
          tabIndex={-1}
          role="button"
          className={classNames([
            classes.container,
            isDragActive ? classes.dragging : null,
          ])}
          onClick={onClick}
          onKeyDown={onClick}
          {...getRootProps()}
        >
          <input
            className={classes.input}
            type="file"
            ref={input}
            {...getInputProps()}
            accept="image/*"
            multiple
          />
          <div className={classes.dragZone} ref={zone} />
          {file === null
            ? (
              <>
                <CloudUploadRounded className={classes.icon} />
                <Typography variant="caption">Upload an image</Typography>
              </>
            )
            : (
              <Container
                className={classes.imagePreview}
                style={{ backgroundImage: `url('${file.preview}')` }}
              />
            )
        }
        </div>
      </Dialog>
      <Avatar
        shape={shape}
        onClick={onOpen}
        width={width}
        height={height}
        fontSize={fontSize}
        image={preview}
      />
      { image !== null && (
        <IconButton
          className={classes.close}
          color="default"
          onPress={onOpenRemove}
        >
          <CloseRounded />
        </IconButton>
      )}
    </Container>
  );
};

ImagePicker.defaultProps = {
  width: 100,
  height: 100,
  fontSize: null,
  image: null,
  shape: 'square',
};

ImagePicker.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  shape: PropTypes.oneOf([
    'round', 'square',
  ]),
  fontSize: PropTypes.number,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func.isRequired,
};

export default ImagePicker;
