import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  makeStyles,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Container from '../../../../../components/Base/Container';
import ProductsStoreInventoriesItem
  from '../../../../../components/Product/ProductsStoreInventoriesItem';
// import AlertQuantities from '../../../../../components/AlertQuantities';

const useStyles = makeStyles(theme => ({
  standard: {
    marginTop: theme.spacing(2),
  },
  list: {
    marginTop: theme.spacing(2),
  },
  paper: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  addButton: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    margin: theme.spacing(2.5),
  },
  loading: {
    padding: theme.spacing(3),
  },
  inline: {
    display: 'inline-block',
  },
  input: {
    display: 'inline-block',
    marginLeft: theme.spacing(),
  },
}));

const InventoriesList = ({
  className,
  productId,
  inventories,
  // fallBelowQuantity,
  // setFallBelowQuantity,
  // replenishQuantity,
  // setReplenishQuantity,
  // disabled,
}) => {
  const classes = useStyles();
  return (
    <Container className={classNames(classes.paper, className)}>
      <Typography variant="h5">Inventories</Typography>
      {/* <AlertQuantities */}
      {/*  setReplenishQuantity={setReplenishQuantity} */}
      {/*  setFallBelowQuantity={setFallBelowQuantity} */}
      {/*  fallBelowQuantity={fallBelowQuantity} */}
      {/*  replenishQuantity={replenishQuantity} */}
      {/* /> */}
      <Container className={classes.list}>
        {inventories.map(
          ({
            storeId,
            placeId,
            name,
            quantity,
            fallBelowQuantity,
            replenishQuantity,
          }) => (
            <ProductsStoreInventoriesItem
              key={`${placeId}${fallBelowQuantity}${replenishQuantity}`}
              storeId={storeId}
              placeId={placeId}
              fallBelowQuantity={fallBelowQuantity}
              replenishQuantity={replenishQuantity}
              productId={productId}
              name={name}
              quantity={quantity}
            />
          ),
        )}
      </Container>
    </Container>
  );
};

InventoriesList.defaultProps = {
  className: null,
  // disabled: false,
};

InventoriesList.propTypes = {
  className: PropTypes.string,
  productId: PropTypes.number.isRequired,
  inventories: PropTypes.array.isRequired,
  // fallBelowQuantity: PropTypes.number.isRequired,
  // replenishQuantity: PropTypes.number.isRequired,
  // setReplenishQuantity: PropTypes.func.isRequired,
  // setFallBelowQuantity: PropTypes.func.isRequired,
  // disabled: PropTypes.bool,
};

export default InventoriesList;
