import React, { forwardRef } from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import ContainerButtonWrapper from './ContainerButtonWrapper';

const Container = forwardRef(({
  onMouseDown,
  onMouseUp,
  style,
  flex,
  maxWidth,
  center,
  alignCenter,
  maxHeight,
  vertical,
  horizontal,
  wrap,
  className,
  onClick,
  scroll,
  children,
}, ref) => {
  const classesNames = [
    'container',
    flex ? 'flex' : null,
    maxWidth ? 'max-width' : null,
    center ? 'center' : null,
    alignCenter ? 'align-center' : null,
    maxHeight ? 'max-height' : null,
    vertical ? 'vertical' : null,
    horizontal ? 'horizontal' : null,
    wrap ? 'wrap' : null,
    className || null,
  ];

  if (onClick) {
    return (
      <ContainerButtonWrapper
        style={style}
        onKeyDown={onClick}
        ref={ref}
        className={classNames(classesNames)}
        onClick={onClick}
      >
        { children }
      </ContainerButtonWrapper>
    );
  }

  return (
    /**
     * @author  linde@aios.sg
     * @date    31 Dec 2019
     *
     * A false positive. Container is list when being used with mousedown.
     */
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      style={style}
      ref={ref}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      className={classNames(classesNames)}
    >
      {scroll
        ? <Scrollbars>{children}</Scrollbars>
        : children
      }
    </div>
  );
});

Container.defaultProps = {
  flex: false,
  maxWidth: false,
  maxHeight: false,
  center: false,
  alignCenter: false,
  vertical: false,
  horizontal: false,
  wrap: false,
  className: null,
  style: null,
  scroll: null,
  onClick: null,
  children: null,
  onMouseDown: null,
  onMouseUp: null,
};

Container.propTypes = {
  flex: PropTypes.bool,
  maxWidth: PropTypes.bool,
  maxHeight: PropTypes.bool,
  center: PropTypes.bool,
  alignCenter: PropTypes.bool,
  vertical: PropTypes.bool,
  horizontal: PropTypes.bool,
  wrap: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  scroll: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
};

export default Container;
