import React, { useEffect, useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { Fade } from 'react-reveal';
import { useDispatch, useSelector } from 'react-redux';
import HomeLayout from '../../components/Layout/HomeLayout';
import Container from '../../components/Base/Container';
import TextInput from '../../components/Base/Input/TextInput';
import ButtonGlow from '../../components/Buttons/ButtonGlow';
import { usersRegisterRequest } from '../../providers/redux/actions/Users';
import Loader from '../../components/Base/Loader';
import useRouter from '../../services/useRouter';
import Constants from '../../constants';

const styles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(8),
    maxWidth: 400,
    width: '100%',
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  title: {
    fontWeight: 'bold',
  },
  inputs: {
    marginTop: theme.spacing(8),
    maxWidth: 300,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  button: {
    marginTop: theme.spacing(8),
    width: '100%',
  },
}));

const ScreenRegister = () => {
  const classes = styles();
  const { history } = useRouter();
  const dispatch = useDispatch();

  const { processing, success, errors } = useSelector(state => ({
    processing: state.usersReducer.register.processing,
    success: state.usersReducer.register.success,
    errors: state.usersReducer.register.errors,
  }));

  useEffect(() => {
    if (success) {
      history.push(Constants.routes.Profile);
    }
  }, [success]);

  const {
    email: emailErrors,
    firstName: firstNameErrors,
    lastName: lastNameErrors,
    password: passwordErrors,
    phoneNo: phoneNoErrors,
  } = errors;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNo, setphoneNo] = useState('');

  const valid = email !== ''
    && password !== ''
    && confirmPassword !== ''
    && firstName !== ''
    && lastName !== ''
    && phoneNo !== '';

  const register = () => valid && dispatch(
    usersRegisterRequest({
      email,
      password,
      confirmPassword,
      firstName,
      lastName,
      phoneNo,
    }),
  );

  return (
    <HomeLayout>
      <Container className={classes.container}>
        <Fade bottom>
          <Typography className={classes.title} variant="h4">
            Register an account
          </Typography>
          <Container className={classes.inputs}>
            <TextInput
              label="E-mail address"
              onChange={setEmail}
              value={email}
              errors={emailErrors}
              shrink
              required
            />
            <TextInput
              type="password"
              label="Password"
              onChange={setPassword}
              value={password}
              errors={passwordErrors}
              shrink
              required
            />
            <TextInput
              type="password"
              label="Confirm password"
              onChange={setConfirmPassword}
              value={confirmPassword}
              shrink
              required
            />
            <TextInput
              label="First name"
              onChange={setFirstName}
              value={firstName}
              errors={firstNameErrors}
              shrink
              required
            />
            <TextInput
              label="Last name"
              onChange={setLastName}
              value={lastName}
              error={lastNameErrors}
              shrink
              required
            />
            <TextInput
              label="Phone number"
              onChange={setphoneNo}
              value={phoneNo}
              errors={phoneNoErrors}
              shrink
              required
            />
            {processing
              ? <Loader />
              : (
                <ButtonGlow
                  className={classes.button}
                  onPress={register}
                  disabled={!valid}
                >
              Register
                </ButtonGlow>
              )}
          </Container>
        </Fade>
      </Container>
    </HomeLayout>
  );
};

export default ScreenRegister;
