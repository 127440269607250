import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import TextInput from './TextInput';

const NumberFormatComponent = ({
  inputRef, onChange, placeholder, ...other
}) => (
  <NumberFormat
    {...other}
    prefix="$"
    getInputRef={inputRef}
    placeholder={placeholder}
    onValueChange={(values) => {
      onChange({
        target: {
          value: values.value,
        },
      });
    }}
    decimalScale={2}
    fixedDecimalScale
    thousandSeparator
  />
);

NumberFormatComponent.defaultProps = {
  placeholder: null,
};

NumberFormatComponent.propTypes = {
  inputRef: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

const PriceInput = ({
  className,
  label,
  value,
  onChange,
  errors,
  placeholder,
  onEnter,
  disabled,
  helperText,
}) => (
  <TextInput
    className={className}
    onEnter={onEnter}
    label={label || 'Price'}
    onChange={onChange}
    value={value}
    inputComponent={NumberFormatComponent}
    errors={errors}
    placeholder={placeholder}
    disabled={disabled}
    helperText={helperText}
    fullWidth
    shrink
  />
);

PriceInput.defaultProps = {
  className: null,
  label: null,
  errors: null,
  placeholder: null,
  onEnter: null,
  disabled: false,
  helperText: null,
};

PriceInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  errors: PropTypes.array,
  placeholder: PropTypes.string,
  onEnter: PropTypes.func,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
};

export default PriceInput;
