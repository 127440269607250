import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { Instance, Resources } from '../../API';
import { login } from '../actions/Auth';
import {
  actions,
  usersGet,
  usersItemPut,
  usersItemReset,
  usersItemSelect,
  usersEditProcessed,
  usersEditProcessing,
  usersPut,
  usersPutSingle,
  usersReset,
  usersEditSuccess,
  usersEditErrors,
  usersInviteErrors,
  usersInviteProcessed,
  usersInviteSuccess,
  usersInviteProcessing,
  usersAutocompleteClear,
  usersAutocompletePut, usersRegisterSuccess, usersRegisterErrors,
} from '../actions/Users';
import {
  snackbarDefaultError,
  snackbarDefaultSuccess,
} from '../actions/Snackbar';
import hasErrors from '../../hasErrors';

const resource = `${Resources.organisations}/${Resources.users}`;

const usersInviteCall = ({
  organisationId,
  name,
}) => Instance.post(
  resource,
  {
    organisation_id: organisationId,
    name,
  },
);

const usersRegisterCall = ({
  email,
  password,
  confirmPassword,
  firstName,
  lastName,
  phoneNo,
}) => Instance.post(
  Resources.register,
  {
    email,
    password,
    password_confirmation: confirmPassword,
    first_name: firstName,
    last_name: lastName,
    phone_no: phoneNo,
  },
);

export const usersAutocompleteCall = ({
  organisationId, text,
}) => Instance.get(
  `${resource}/${Resources.autocomplete}`,
  {
    params: {
      organisation_id: organisationId,
      search: text !== '' ? text : null,
    },
  },
);

export const usersIndexCall = ({
  organisationId,
}) => Instance.get(
  resource,
  {
    params: {
      organisation_id: organisationId,
    },
  },
);

export const usersViewCall = ({ id }) => Instance.get(`${resource}/${id}`);

export const usersPutCall = ({
  organisationId,
  id,
  roles,
  stores,
}) => Instance.put(
  `${resource}/${id}`,
  {
    organisation_id: organisationId,
    id,
    roles,
    stores,
  },
);

export const usersDeleteCall = ({ id }) => Instance.delete(`${resource}/${id}`);

const siteData = state => ({
  organisationId: state.organisationReducer.data.id,
});

const usersData = state => ({
  data: state.usersReducer.data,
});

const usersMetaData = state => ({
  search: state.usersReducer.search,
});

/**
 * loadUsersSaga
 * @returns {IterableIterator<*>}
 */
function* loadUsersSaga({
  payload: {
    search,
  },
}) {
  try {
    const { organisationId } = yield select(siteData);
    const {
      search: originalSearch,
    } = yield select(usersMetaData);

    if (search !== originalSearch) {
      yield put(usersReset());
    }

    const { data: { data } } = yield call(
      usersIndexCall,
      {
        organisationId,
        search: search === '' ? null : search,
      },
    );

    yield put(
      usersPut({
        data,
        search,
      }),
    );
  } catch (e) {
    yield put(snackbarDefaultError({ e }));
  }
}

/**
 * @param user
 */
function* inviteUserSaga({ payload: email }) {
  try {
    yield put(usersInviteProcessing());
    const { organisationId } = yield select(siteData);
    const { data } = yield call(
      usersInviteCall,
      {
        organisationId,
        email,
      },
    );
    yield put(usersPutSingle({ user: data.data }));
    yield put(usersGet({ reset: true }));
    yield put(usersInviteSuccess());
    yield put(usersItemSelect({ id: data.data.id }));
    yield put(snackbarDefaultSuccess({ data }));
  } catch (e) {
    const errors = hasErrors(e);
    if (errors) {
      yield put(
        usersInviteErrors({ errors }),
      );
    }
    yield put(snackbarDefaultError({ e }));
  } finally {
    yield put(usersInviteProcessed());
  }
}

/**
 * editUsersSaga
 * @param user
 */
function* editUsersSaga({
  payload: {
    id,
    roles,
    stores,
  },
}) {
  try {
    const { organisationId } = yield select(siteData);
    yield put(usersEditProcessing());
    const { data } = yield call(
      usersPutCall,
      {
        organisationId,
        id,
        roles,
        stores,
      },
    );
    yield put(usersItemPut({ item: data.data }));
    yield put(usersPutSingle({ user: data.data }));
    yield put(usersEditProcessed());
    yield put(snackbarDefaultSuccess({ data }));
    yield put(usersEditSuccess());
  } catch (e) {
    const errors = hasErrors(e);
    if (errors) {
      yield put(
        usersEditErrors({ errors }),
      );
    }
    yield put(snackbarDefaultError({ e }));
  } finally {
    yield put(usersEditProcessed());
  }
}

/**
 * deleteUsersSaga
 * @param id
 */
function* deleteUsersSaga({ payload: id }) {
  try {
    yield put(usersEditProcessing());
    const response = yield call(usersDeleteCall, { id });

    const { data } = yield select(usersData);
    const newData = [...data];
    yield put(
      usersPut({
        data: newData.filter(({ id: userId }) => userId !== id),
      }),
    );
    yield put(usersItemReset());
    yield put(usersEditSuccess());
    yield put(snackbarDefaultSuccess({ data: response.data }));
  } catch (e) {
    yield put(snackbarDefaultError({ e }));
  } finally {
    yield put(usersEditProcessed());
  }
}

/**
 * selectUserSaga
 * @param id
 */
function* selectUserSaga({ payload: id }) {
  try {
    const { data: { data } } = yield call(usersViewCall, { id });
    yield put(usersItemPut({ item: data }));
  } catch (e) {
    yield put(snackbarDefaultError({ e }));
  }
}

/**
 * updateUsersSaga
 * @param user
 */
function* updateUsersSaga({ payload: user }) {
  const { data } = yield select(usersData);
  const index = data.findIndex(({ id }) => user.id === id);
  if (index !== undefined) {
    data[index] = { ...data[index], ...user };
  } else {
    data.unshift(user);
  }

  yield put(usersPut({ data }));
}

/**
 * @returns {IterableIterator<*>}
 */
function* usersAutocompleteGetSaga({ payload: text }) {
  try {
    if (text === '') {
      yield put(usersAutocompleteClear());
      return;
    }

    const { organisationId } = yield select(siteData);
    const { data } = yield call(usersAutocompleteCall, {
      organisationId,
      text,
    });

    yield put(usersAutocompletePut({ data: data.data }));
  } catch (e) {
    yield put(snackbarDefaultError({ e }));
  }
}

/**
 * @returns {IterableIterator<*>}
 */
function* registerSaga({
  payload: {
    email,
    password,
    confirmPassword,
    firstName,
    lastName,
    phoneNo,
  },
}) {
  try {
    const { data } = yield call(
      usersRegisterCall,
      {
        email,
        password,
        confirmPassword,
        firstName,
        lastName,
        phoneNo,
      },
    );

    yield put(usersRegisterSuccess());
    yield put(
      login({
        username: email,
        password,
      }),
    );

    yield put(snackbarDefaultSuccess({ data }));
  } catch (e) {
    yield put(snackbarDefaultError({ e }));

    const errors = hasErrors(e);
    if (errors) {
      yield put(
        usersRegisterErrors({ errors }),
      );
    }
  }
}

export default function* Users() {
  yield all([
    takeLatest(actions.GET, loadUsersSaga),
    takeLatest(actions.DELETE, deleteUsersSaga),
    takeLatest(actions.ITEM.SELECT, selectUserSaga),
    takeEvery(actions.UPDATE, updateUsersSaga),
    takeLatest(actions.EDIT.REQUEST, editUsersSaga),
    takeLatest(actions.INVITE.REQUEST, inviteUserSaga),
    takeLatest(actions.AUTOCOMPLETE.GET, usersAutocompleteGetSaga),
    takeLatest(actions.REGISTER.REQUEST, registerSaga),
  ]);
}
