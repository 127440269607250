import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useHistory } from 'react-router';
import Layout from '../../../components/Layout';
import usePermissions from '../../../providers/hooks/usePermissions';
import Permissions from '../../../providers/Permissions';
import {
  storesItemSelect,
  storesItemReset,
  storesEditRequest, storesDestroyRequest,
} from '../../../providers/redux/actions/Stores';
import useRouter from '../../../services/useRouter';
import Loader from '../../../components/Base/Loader';
import ButtonBack from '../../../components/Base/Button/ButtonBack';
import Constants from '../../../constants';
import Container from '../../../components/Base/Container';
import PlaceStatus from '../../../components/PlaceStatus';
import PlaceActionBar from '../../../components/PlaceActionBar';
import Form from '../../../components/Form';
import TextInput from '../../../components/Base/Input/TextInput';
import Approval from '../../../components/Base/Dialog/Approval';
import CardScroll from '../../../components/CardScroll';
import Alert from '../../../components/Alert';
import PrintersList from '../../../components/Printers/PrintersList';
import {
  productsIndexRequest,
  productsPageNext,
} from '../../../providers/redux/actions/Stores/Products';
import StoreProductList from '../../../components/Store/StoreProductList';

const useStyles = makeStyles(
  theme => ({
    appContainer: {
      flex: 1,
    },
    container: {
      padding: theme.spacing(4),
      flex: 1,
      [theme.breakpoints.down('sm')]: {
        paddingTop: 0,
      },
    },
    info: {
      display: 'flex',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    back: {
      [theme.breakpoints.up('sm')]: {
        margin: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(2),
      },
    },
    name: {
      fontWeight: 'bold',
    },
    toolbar: {
      marginTop: theme.spacing(3),
    },
    alerts: {
      '& > *:first-child': {
        paddingLeft: theme.spacing(18),
      },
      '& > *:last-child': {
        paddingRight: theme.spacing(18),
      },
    },
    alertsContainer: {
      width: '100%',
    },
    printers: {
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(18),
        marginRight: theme.spacing(18),
      },
    },
    products: {
      marginTop: theme.spacing(4),
    },
  }),
);

const ScreenStore = () => {
  const userCan = usePermissions();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { match } = useRouter();
  const { goBack } = useHistory();
  const [edit, setEdit] = useState(false);
  const [showProducts, setShowProducts] = useState(false);

  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [address2, setAddress2] = useState('');
  const [postalCode, setPostalCode] = useState('');

  const [destroy, setDestroy] = useState(false);
  const { id } = match.params;
  const {
    productsLoading,
    products,
    store,
    updateSuccess,
    updateErrors,
    updateLoading,
    destroySuccess,
    destroyLoading,
  } = useSelector(
    state => ({
      // loading: state.storesReducer.show.loading,
      productsLoading: state.storesProductsReducer.index.loading,
      products: state.storesProductsReducer.index.data,
      store: state.storesReducer.selected,
      updateLoading: state.storesReducer.edit.processing,
      updateSuccess: state.storesReducer.edit.success,
      updateErrors: state.storesReducer.edit.errors,
      destroyLoading: state.storesReducer.destroy.loading,
      destroySuccess: state.storesReducer.destroy.success,
    }),
  );

  const onShowProducts = () => setShowProducts(true);

  const onHideProducts = () => setShowProducts(false);

  const onBack = () => {
    if (showProducts) {
      onHideProducts();
      return;
    }

    goBack();
  };

  const openEdit = () => setEdit(true);

  const closeEdit = () => setEdit(false);

  const openDestroy = () => setDestroy(true);

  const closeDestroy = () => setDestroy(false);

  const updateStore = () => dispatch(
    storesEditRequest({
      id,
      name,
      address,
      address2,
      postalCode,
    }),
  );

  const destroyStore = () => dispatch(
    storesDestroyRequest(id),
  );

  const onNext = () => dispatch(
    productsPageNext(),
  );

  const printers = store !== null && store.printers ? store.printers : [];
  const alerts = store !== null && store.alerts ? store.alerts : [];

  useEffect(() => {
    dispatch(
      storesItemSelect({ id }),
    );

    return () => dispatch(
      storesItemReset(),
    );
  }, []);

  useEffect(() => {
    if (store !== null) {
      const {
        name: storeName,
        address: storeAddress,
        address2: storeAddress2,
        postalCode: storePostalCode,
      } = store;

      setName(storeName);
      setAddress(storeAddress);
      setAddress2(storeAddress2);
      setPostalCode(storePostalCode);
    }
  }, [store]);

  useEffect(() => {
    if (showProducts) {
      dispatch(
        productsIndexRequest(id),
      );
    }
  }, [showProducts]);

  useEffect(() => {
    if (updateSuccess) {
      closeEdit();
    }
  }, [updateLoading]);

  useEffect(() => {
    if (destroySuccess) {
      closeDestroy();
      onBack();
    }
  }, [destroyLoading]);

  if (!store) {
    return (
      <Layout>
        <Container flex center alignCenter>
          <Loader />
        </Container>
      </Layout>
    );
  }

  const { name: storeName, placeId } = store;

  const alert = alerts.length === 0
    ? Constants.alerts.normal
    : Math.max(
      ...alerts.map(({ level }) => level),
    );

  const {
    name: nameErrors,
    address: addressErrors,
    address2: address2Errors,
    postalCode: postalCodeErrors,
  } = updateErrors;

  if (showProducts) {
    return (
      <Layout
        direction="bottom"
        horizontal
        scroll
      >
        <Container className={classes.info}>
          <Container className={classes.back}>
            <ButtonBack onPress={onBack} />
          </Container>
          <Container className={classes.container}>
            <Container className={classes.header}>
              <Container>
                <PlaceStatus status={alert} />
                <Typography className={classes.name} variant="h3">
                  {storeName}
                </Typography>
              </Container>
            </Container>
            <StoreProductList
              className={classes.products}
              placeId={placeId}
              onNext={onNext}
              data={products}
              loading={productsLoading}
            />
          </Container>
        </Container>
      </Layout>
    );
  }

  return (
    <Layout
      direction="bottom"
      horizontal
      scroll
    >
      <Approval
        open={destroy}
        title="Delete"
        content={`Are you sure you want to delete this store? We will reduce your subscription by SGD$${process.env.REACT_APP_STORE_PRICE} every month.`}
        closeDialog={closeDestroy}
        triggerEvent={destroyStore}
        processing={destroyLoading}
      />
      <Form
        label="Edit details"
        loading={updateLoading}
        onSubmit={updateStore}
        onClose={closeEdit}
        open={edit}
      >
        <TextInput
          label="Name"
          onChange={setName}
          onEnter={updateStore}
          value={name}
          errors={nameErrors}
          shrink
        />
        <TextInput
          label="Address"
          onChange={setAddress}
          value={address}
          errors={addressErrors}
          shrink
        />
        <TextInput
          label="Address line 2"
          onChange={setAddress2}
          value={address2}
          errors={address2Errors}
          shrink
        />
        <TextInput
          label="Postal code"
          onChange={setPostalCode}
          value={postalCode}
          errors={postalCodeErrors}
          shrink
        />
      </Form>
      <Container className={classes.appContainer}>
        <Container className={classes.info}>
          <Container className={classes.back}>
            <ButtonBack onPress={onBack} />
          </Container>
          <Container className={classes.container}>
            <Container className={classes.header}>
              <Container>
                <PlaceStatus status={alert} />
                <Typography className={classes.name} variant="h3">
                  {storeName}
                </Typography>
              </Container>
            </Container>
            <Container>
              <PlaceActionBar
                className={classes.toolbar}
                onEdit={openEdit}
                onProducts={onShowProducts}
                onSend={() => {}}
                onReceive={() => {}}
                onDestroy={openDestroy}
              />
            </Container>
          </Container>
        </Container>
        <Container className={classes.alertsContainer}>
          <CardScroll innerWrapperClass={classes.alerts}>
            {alerts.map(
              ({ id: alertId, level, text: message }) => (
                <Alert
                  key={alertId}
                  onPress={onShowProducts}
                  level={level}
                  message={message}
                />
              ),
            )}
          </CardScroll>
        </Container>
        {userCan(Permissions.editPrinters) && (
          <Container className={classes.printers}>
            <PrintersList data={printers} />
          </Container>
        )}
      </Container>
    </Layout>
  );
};

export default ScreenStore;
