import moment from 'moment';

const settlementTransformer = (settlement) => {
  const {
    Cash: cash,
    NETS: nets,
    Mastercard: mastercard,
    Visa: visa,
    created_at: createdAt,
  } = settlement.data();

  return {
    id: settlement.id,
    cash,
    nets,
    mastercard,
    visa,
    createdAt: moment(createdAt.seconds * 1000),
  };
};

export default settlementTransformer;
