import React, { useEffect } from 'react';
import qs from 'qs';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Typography } from '@material-ui/core';
import useRouter from '../../services/useRouter';
import HomeLayout from '../../components/Layout/HomeLayout';
import {
  authEmailResend,
  authEmailVerify,
} from '../../providers/redux/actions/Auth';
import Loader from '../../components/Base/Loader';
import Constants from '../../constants';
import Button from '../../components/Base/Button';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}));

const ScreenVerify = () => {
  const classes = useStyles();
  const { history, location } = useRouter();
  const {
    expires, signature, hash, id,
  } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const dispatch = useDispatch();
  const { verified, processing, success } = useSelector(state => ({
    verified: state.authReducer.user.verified,
    processing: state.authReducer.email.processing,
    success: state.authReducer.email.success,
  }));

  useEffect(() => {
    if (verified) {
      history.push(Constants.routes.Profile);
      return;
    }

    dispatch(
      authEmailVerify({
        expires,
        signature,
        hash,
        id,
      }),
    );
  }, []);


  useEffect(() => {
    if (success) {
      history.push(Constants.routes.Profile);
    }
  }, [success]);

  const resend = () => dispatch(authEmailResend());

  const successful = success
    ? (
      <>
        <Typography className={classes.title} variant="h5">
          We&apos;ve verified your e-mail address.
        </Typography>
      </>
    )
    : (
      <>
        <Typography className={classes.title} variant="h5">
          We couldn&apos;t verify you
        </Typography>
        <Button onClick={resend}>
          Resend verification e-mail
        </Button>
      </>
    );

  return (
    <HomeLayout className={classes.container} disableScroll>
      {processing ? <Loader /> : successful}
    </HomeLayout>
  );
};

export default ScreenVerify;
