import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import TextInput from '../../../components/Base/Input/TextInput';
import Container from '../../../components/Base/Container';
import Layout from '../../../components/Layout';
import ButtonBack from '../../../components/Base/Button/ButtonBack';
import Constants from '../../../constants';
import useNavigate from '../../../services/useNavigate';
import PageHeader from '../../../components/PageHeader';
import ButtonGlow from '../../../components/Buttons/ButtonGlow';
import {
  storesCreateRequest,
  storesCreateReset,
} from '../../../providers/redux/actions/Stores';
import Approval from '../../../components/Base/Dialog/Approval';
import InputPostalCode from '../../../components/Base/Input/InputPostalCode';

const useStyles = makeStyles(theme => ({
  inputs: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(6),
    maxWidth: 400,
    width: '100%',
  },
  backContainer: {
    padding: theme.spacing(6),
  },
  button: {
    marginTop: theme.spacing(7),
  },
  input: {
    marginBottom: theme.spacing(2),
  },
}));

const ScreenStoreAdd = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, success, errors } = useSelector(state => ({
    loading: state.storesReducer.create.processing,
    success: state.storesReducer.create.success,
    errors: state.storesReducer.create.errors,
  }));

  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [address2, setAddress2] = useState('');
  const [postalCode, setPostalCode] = useState('');

  const toStoresList = () => navigate(Constants.routes.Stores);

  const submit = () => dispatch(
    storesCreateRequest({
      name,
      address,
      address2,
      postalCode,
    }),
  );

  const onOpen = () => setOpen(true);

  const onClose = () => setOpen(false);

  useEffect(() => {
    if (!loading) {
      onClose();
    }
  }, [loading]);

  useEffect(() => {
    if (success) {
      toStoresList();
      dispatch(
        storesCreateReset(),
      );
    }
  }, [success]);

  return (
    <Layout flex>
      <Approval
        processing={loading}
        closeDialog={onClose}
        content={`Are you sure you want to create a new store? Adding a new store will increase the cost of your current plan by S$${process.env.REACT_APP_STORE_PRICE} per month. If you have not started your trial, it will begin as soon as you create your first store.`}
        open={open}
        title="Add a new store"
        triggerEvent={submit}
      />
      <Container className={classes.backContainer}>
        <ButtonBack onPress={toStoresList} />
      </Container>
      <Container>
        <PageHeader
          title="Add a new store"
          subtitle={`Adding a new store will increase the cost of your current plan by S$${process.env.REACT_APP_STORE_PRICE} per month`}
        />
        <Container className={classes.inputs}>
          <TextInput
            className={classes.input}
            label="Name"
            onChange={setName}
            value={name}
            errors={errors.name}
            shrink
          />
          <TextInput
            className={classes.input}
            label="Address"
            onChange={setAddress}
            value={address}
            errors={errors.address}
            shrink
          />
          <TextInput
            className={classes.input}
            label="Address line 2"
            onChange={setAddress2}
            value={address2}
            errors={errors.address2}
            shrink
          />
          <InputPostalCode
            className={classes.input}
            label="Postal code"
            onChange={setPostalCode}
            value={postalCode}
            errors={errors.postalCode}
          />
          <ButtonGlow className={classes.button} onPress={onOpen}>
            Make store
          </ButtonGlow>
        </Container>
      </Container>
    </Layout>
  );
};

export default ScreenStoreAdd;
