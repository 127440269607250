import React, { forwardRef } from 'react';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  container: {
    '&::focus': {
      outline: 0,
    },
  },
});

const ContainerButtonWrapper = forwardRef(({
  className, style, onClick, children,
}, ref) => {
  const classes = useStyles();
  return (
    <div
      ref={ref}
      style={style}
      onClick={onClick}
      onKeyDown={onClick}
      className={classNames([className, classes.container])}
      role="button"
      tabIndex="0"
      aria-pressed="false"
    >
      { children }
    </div>
  );
});

ContainerButtonWrapper.defaultProps = {
  className: null,
  style: null,
};

ContainerButtonWrapper.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ContainerButtonWrapper;
