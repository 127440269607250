import React from 'react';
import PropTypes from 'prop-types';

import SelectInput from './SelectInput';
import Constants from '../../../constants';

const PrinterModeDropdown = ({ selected, errors, onChange }) => (
  <SelectInput
    selected={selected}
    suggestions={Constants.printers.modes}
    placeholder="Mode"
    label="Mode"
    errors={errors}
    onChange={onChange}
  />
);

PrinterModeDropdown.defaultProps = {
  selected: null,
  errors: [],
};

PrinterModeDropdown.propTypes = {
  selected: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
  }),
  errors: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

export default PrinterModeDropdown;
