const foodTransformer = ({
  id, name, price, category, stores, options, image,
}) => ({
  id,
  name,
  price,
  category,
  stores,
  options,
  image: image ? `${process.env.REACT_APP_IMG_BUCKET}/${image}` : null,
});

export default foodTransformer;
