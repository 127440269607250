import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core';
import Layout from '../../../components/Layout';
import Container from '../../../components/Base/Container';
import StoreCard from '../../../components/Store/StoreCard';
import Loader from '../../../components/Base/Loader';
import {
  storesGet,
} from '../../../providers/redux/actions/Stores';
import Constants from '../../../constants';
import useNavigate from '../../../services/useNavigate';
import PageHeader from '../../../components/PageHeader';
import CardScroll from '../../../components/CardScroll';

const useStyles = makeStyles(
  theme => ({
    header: {
      display: 'block',
    },
    stores: {
      '& > *:first-child': {
        [theme.breakpoints.down('lg')]: {
          paddingLeft: theme.spacing(6.5),
        },
        [theme.breakpoints.up('lg')]: {
          paddingLeft: theme.spacing(12.5),
        },
      },
      '& > *:last-child': {
        [theme.breakpoints.down('lg')]: {
          paddingRight: theme.spacing(6.5),
        },
        [theme.breakpoints.up('lg')]: {
          paddingRight: theme.spacing(12.5),
        },
      },
    },
  }),
);

const ScreenStores = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const { selected, data, loading } = useSelector(
    state => ({
      selected: state.storesReducer.selected,
      data: state.storesReducer.data,
      loading: state.storesReducer.loading,
    }),
  );

  useEffect(() => {
    dispatch(
      storesGet(),
    );
  }, []);

  const toAdd = () => navigate(
    `${Constants.routes.Stores}${Constants.routes.Add}`,
  );

  const onSelect = id => navigate(
    `${Constants.routes.Stores}/${id}`,
  );

  return (
    <Layout
      key={selected}
      direction="bottom"
      horizontal
      scroll
    >
      {(!loading && !!data) && (
        <Container flex horizontal maxHeight>
          <Container className={classes.header}>
            <PageHeader
              title="Your stores"
              action={toAdd}
              actionText="Add new"
            />
          </Container>
          <Container maxWidth>
            <CardScroll innerWrapperClass={classes.stores}>
              {
                data.map(
                  ({
                    id: storeId,
                    revenue: storeRevenue,
                    profit: storeProfit,
                    open: storeOpen,
                    gain: storeGain,
                    name: storeName,
                    transactionsCount: storeTransactionsCount,
                    openingTime: storeOpeningTime,
                    alert,
                  }) => (
                    <StoreCard
                      key={storeId}
                      onPress={() => onSelect(storeId)}
                      className={classes.store}
                      id={storeId}
                      revenue={storeRevenue}
                      profit={storeProfit}
                      open={storeOpen}
                      gain={storeGain}
                      name={storeName}
                      transactionsCount={storeTransactionsCount}
                      openingTime={storeOpeningTime}
                      alert={alert}
                    />
                  ),
                )
              }
            </CardScroll>
          </Container>
        </Container>
      )}
      {loading && (
        <Container flex center alignCenter>
          <Loader />
        </Container>
      )}
    </Layout>
  );
};

export default ScreenStores;
