import { SnackbarProvider } from 'notistack';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import QueueAnim from 'rc-queue-anim';
import { FormHelperText, TextField, makeStyles } from '@material-ui/core';
import Default from '../../defaults';

import Logo from '../Logo';
import Loader from '../Base/Loader';
import Container from '../Base/Container';
import Button from '../Base/Button';
import BackgroundImage2x from '../../assets/images/login/mood-board-header@2x.png';
import BackgroundImage from '../../assets/images/login/mood-board-header.png';
import { authReset, login } from '../../providers/redux/actions/Auth';
import SnackbarStack from '../SnackbarStack';


const styles = makeStyles(theme => ({
  background: {
    position: 'absolute',
    top: -8,
    right: -8,
    left: -8,
    bottom: -8,
    filter: 'blur(8px)',
    [theme.breakpoints.up('sm')]: {
      background: `url('${BackgroundImage2x}') ${theme.palette.primary.main} 50% 50% / cover no-repeat`,
    },
    [theme.breakpoints.down('sm')]: {
      background: `url('${BackgroundImage}') ${theme.palette.primary.main} 50% 50% / cover no-repeat`,
    },
  },
  container: {
    width: 400,
    padding: theme.spacing(8),
    borderRadius: 40,
    boxShadow: '0 2px 99px rgba(0,0,0,0.16)',
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(24),
    },
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'none',
      padding: 0,
      width: 'auto',
    },
  },
  logoContainer: {
    padding: theme.spacing(3),
  },
  inputContainer: {
    marginBottom: theme.spacing(2),
  },
  inputLabel: {
    color: theme.palette.common.white,
    fontSize: 17,
  },
  inputText: {
    color: theme.palette.common.white,
    fontSize: 17,
  },
  error: {
    textAlign: 'center',
    padding: theme.spacing(2),
    color: theme.palette.common.white,
    marginBottom: theme.spacing(),
  },
  underline: {
    '&::before': {
      borderBottom: '1px solid rgba(255,255,255,0.42)',
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: '2px solid #FFFFFF',
    },
    '&::after': {
      transition: 'transform 200ms cubic-bezier(1, 1, 1, 1) 0ms',
      borderBottom: '2px solid #FFFFFF',
    },
  },
  labelFocused: {
    color: '#FFFFFF !important',
  },
  focused: {
    '&::after': {
      transition: 'transform 200ms cubic-bezier(1, 1, 1, 1) 0ms',
      borderBottom: '1px solid #FFFFFF',
    },
  },
}));

const Login = () => {
  const classes = styles();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const { loading, error } = useSelector(state => ({
    loading: state.authReducer.loading,
    error: state.authReducer.error,
  }));

  const dispatch = useDispatch();

  const fireLogin = () => dispatch(login({ username, password }));

  const onKeyPress = (e) => {
    if (!loading && !(username === '' || password === '')) {
      if (e.key === 'Enter') {
        fireLogin();
      }
    }
  };

  const inputProps = {
    className: classes.inputText,
    classes: {
      underline: classes.underline,
      focused: classes.focused,
    },
  };
  const inputLabelProps = {
    className: classes.inputLabel,
    classes: {
      shrink: classes.labelFocused,
      focused: classes.labelFocused,
    },
  };

  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      maxSnack={Default.maxSnack}
      preventDuplicate
    >
      <Container
        flex
        center
        horizontal
        alignCenter
      >
        <Container className={classes.background} />
        <SnackbarStack />
        <Container>
          <QueueAnim type="bottom" duration={800} className="flex">
            <Container
              key={1}
              className={classes.container}
              flex
              center
              horizontal
              alignCenter
            >
              <Container className={classes.logoContainer} alignCenter>
                <Logo size={180} />
              </Container>
              <Container className={classes.inputContainer} maxWidth>
                <TextField
                  label="Username | E-mail address"
                  onChange={({ target: { value } }) => {
                    setUsername(value);
                    dispatch(authReset());
                  }}
                  onKeyPress={onKeyPress}
                  InputLabelProps={inputLabelProps}
                  InputProps={inputProps}
                  value={username}
                  spellCheck={false}
                  fullWidth
                />
              </Container>
              <Container className={classes.inputContainer} maxWidth>
                <TextField
                  label="Password"
                  type="password"
                  onChange={({ target: { value } }) => {
                    setPassword(value);
                    dispatch(authReset());
                  }}
                  onKeyPress={onKeyPress}
                  InputLabelProps={inputLabelProps}
                  InputProps={inputProps}
                  value={password}
                  spellCheck={false}
                  fullWidth
                />
              </Container>
              <FormHelperText className={classes.error}>{error}</FormHelperText>
              {loading && <Container><Loader size={30} /></Container>}
              {!loading && (
                <Button
                  onClick={fireLogin}
                  disabled={username === '' || password === ''}
                  fullWidth
                >
                  Login
                </Button>
              )}
            </Container>
          </QueueAnim>
        </Container>
      </Container>
    </SnackbarProvider>
  );
};

export default Login;
