import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Layout from '../../../components/Layout';

import ModelInteractor from '../../../components/Base/ModelInteractor';
import BrandItem from './components/Item';
import BrandsCreate from './components/Create';
import BrandsView from './components/View';
import Container from '../../../components/Base/Container';
import {
  brandsGet,
  brandsItemReset,
  brandsItemSelect,
} from '../../../providers/redux/actions/Brands';
import { Resources } from '../../../providers/API';

const ScreenBrands = () => {
  const dispatch = useDispatch();
  const loadMore = (page, search) => dispatch(brandsGet({ search }));
  const {
    data,
    hasMore,
    processing,
    loading,
    selected,
    success,
  } = useSelector((state) => ({
    timestamp: state.brandsReducer.timestamp,
    hasMore: state.brandsReducer.hasMore,
    data: state.brandsReducer.data,
    loading: state.brandsReducer.loading,
    processing: state.brandsReducer.edit.processing,
    success: state.brandsReducer.edit.success,
    selected: state.brandsReducer.selected,
  }));

  return (
    <Layout direction="right" flex>
      <ModelInteractor
        create={BrandsCreate}
        view={BrandsView}
        list={data}
        resource={Resources.brands}
        itemElement={BrandItem}
        nextCallback={loadMore}
        hasMore={hasMore}
        loading={loading}
        reset={() => dispatch(brandsItemReset())}
        selected={selected}
        empty={<Container>Know what you&#39;re carrying.</Container>}
        viewAction={id => dispatch(brandsItemSelect({ id }))}
        processing={processing}
        success={success}
      />
    </Layout>
  );
};

ScreenBrands.defaultProps = {
  data: null,
  hasMore: false,
  loading: false,
  selected: null,
};

ScreenBrands.propTypes = {
  data: PropTypes.array,
  hasMore: PropTypes.bool,
  loading: PropTypes.bool,
  processing: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  selected: PropTypes.number,
  brandsGet: PropTypes.func.isRequired,
  brandsItemReset: PropTypes.func.isRequired,
  brandsItemSelect: PropTypes.func.isRequired,
};

export default ScreenBrands;
