import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, makeStyles } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';

import Container from '../../../../../components/Base/Container';
import BarcodeInput from '../Inputs/BarcodeInput';

const styles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(),
  },
  button: {
    padding: theme.spacing(),
  },
}));

const BarcodeItem = ({
  barcode, index, onChange, deleteItem,
}) => {
  const classes = styles();
  return (
    <Container
      className={classes.container}
      center
      alignCenter
      flex
    >
      <BarcodeInput
        value={barcode}
        onChange={text => onChange(text, index)}
        disabled={!deleteItem}
      />
      {deleteItem && (
        <IconButton className={classes.button} onClick={deleteItem}>
          <DeleteIcon />
        </IconButton>
      )}
    </Container>
  );
};

BarcodeItem.defaultProps = {
  deleteItem: null,
};

BarcodeItem.propTypes = {
  barcode: PropTypes.string.isRequired,
  deleteItem: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

export default BarcodeItem;
