export const actions = {
  GET: 'MOVEMENTS_GET',
  PUT: 'MOVEMENTS_PUT',
  UPDATE: 'MOVEMENTS_UPDATE',
  RESET: 'MOVEMENTS_RESET',
  REJECT: {
    REQUEST: 'MOVEMENTS_REJECT_REQUEST',
    SUCCESS: 'MOVEMENTS_REJECT_SUCCESS',
    ERRORS: 'MOVEMENTS_REJECT_ERRORS',
  },
  CREATE: {
    REQUEST: 'MOVEMENTS_CREATE_REQUEST',
    SUCCESS: 'MOVEMENTS_CREATE_SUCCESS',
    ERRORS: 'MOVEMENTS_CREATE_ERRORS',
  },
  APPROVE: {
    REQUEST: 'MOVEMENTS_APPROVE_REQUEST',
    SUCCESS: 'MOVEMENTS_APPROVE_SUCCESS',
    ERRORS: 'MOVEMENTS_APPROVE_ERRORS',
  },
};

export const movementsGet = ({
  storeId, search, filters, reset,
}) => ({
  type: actions.GET,
  payload: {
    storeId,
    search,
    filters,
    reset,
  },
});

export const movementsPut = ({
  page, timestamp, data, hasMore, search,
}) => ({
  type: actions.PUT,
  payload: {
    page,
    timestamp,
    data,
    hasMore,
    search,
  },
});

export const movementsRejectRequest = id => ({
  type: actions.REJECT.REQUEST,
  payload: id,
});

export const movementsReset = () => ({
  type: actions.RESET,
});

export const movementsApproveRequest = ({
  id,
  printerId,
}) => ({
  type: actions.APPROVE.REQUEST,
  payload: {
    id,
    printerId,
  },
});

export const movementsApproveSuccess = () => ({
  type: actions.APPROVE.SUCCESS,
});

export const movementsApproveErrors = ({ errors }) => ({
  type: actions.APPROVE.ERRORS,
  payload: errors,
});

export const movementsCreateRequest = ({
  fromStoreId,
  toStoreId,
  quantity,
}) => ({
  type: actions.CREATE.REQUEST,
  payload: {
    fromStoreId,
    toStoreId,
    quantity,
  },
});

export const movementsCreateSuccess = () => ({
  type: actions.CREATE.SUCCESS,
});

export const movementsCreateErrors = ({ errors }) => ({
  type: actions.CREATE.ERRORS,
  payload: errors,
});
