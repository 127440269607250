const Permissions = {
  all: '*',
  manageUsers: 'manage-users',
  viewDashboard: 'view-dashboard',
  editQuotes: 'edit:quote',
  viewQuotes: 'view:quote',
  editBrands: 'edit:brand',
  viewBrands: 'view:brand',
  editSuppliers: 'edit:supplier',
  viewSuppliers: 'view:supplier',
  editUsers: 'edit:user',
  viewUsers: 'view:user',
  editProducts: 'edit:product',
  viewProducts: 'view:product',
  editInventories: 'edit:inventory',
  viewInventories: 'view:inventory',
  editStores: 'edit:store',
  viewStores: 'view:store',
  editOrganisations: 'edit:organisation',
  viewOrganisations: 'view:organisation',
  editCategories: 'edit:category',
  viewCategories: 'view:category',
  viewTransactions: 'view:transaction',
  editTransactions: 'edit:transaction',
  viewWarehouses: 'view:warehouse',
  editWarehouse: 'edit:warehouse',
  editFoods: 'edit:food',
  viewFoods: 'view:food',
  viewRevenue: 'view:revenue',
  editPrinter: 'edit:printer',
};

export default Permissions;
