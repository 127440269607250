import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import Container from '../../../Base/Container';
import Row from './Row';

const Invited = ({ invited }) => {
  if (invited.length > 0) {
    return (
      <Container flex>
        {invited.map(({ email, status }) => <Row key={email} email={email} status={status} />)}
      </Container>
    );
  }
  return (
    <Container className="invitations-invited-none" flex center>
      <Typography>You haven&apos;t invited any users!</Typography>
    </Container>
  );
};

Invited.defaultProps = {
  invited: [],
};

Invited.propTypes = {
  invited: PropTypes.array,
};

export default Invited;
