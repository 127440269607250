import moment from 'moment';
import Constants from '../../../../../constants';

const storeTransformer = (store) => {
  const {
    name,
    revenue,
    expenses,
    percentage_gain: gain,
    profit,
    open,
    transaction_count: transactionsCount,
    opening_time: openingTime,
    alert,
    latitude,
    longitude,
  } = store.data();

  return {
    id: store.id,
    name,
    revenue,
    expenses,
    gain,
    profit,
    transactionsCount,
    open,
    openingTime: moment(openingTime.seconds * 1000),
    alert: alert || Constants.alerts.normal,
    latitude,
    longitude,
  };
};

export default storeTransformer;
