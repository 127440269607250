import moment from 'moment';
import Constants from '../constants';

const checkTimeOfDay = (times = null) => {
  const now = moment();
  if (times !== null) {
    if (now.isSameOrAfter(times.nauticalDusk) && now.isBefore(times.dawn)) {
      return Constants.periods.Night;
    }

    if (now.isSameOrAfter(times.sunrise) && now.isBefore(times.sunriseEnd)) {
      return Constants.periods.Sunrise;
    }

    if (now.isSameOrAfter(times.sunriseEnd) && now.isBefore(times.solarNoon)) {
      return Constants.periods.Morning;
    }

    if (now.isSameOrAfter(times.solarNoon) && now.isBefore(times.goldenHour)) {
      return Constants.periods.Noon;
    }

    if (now.isSameOrAfter(times.goldenHour) && now.isBefore(times.nauticalDusk)) {
      return Constants.periods.Sunset;
    }
  }

  if (now.hours() >= 19 && now.hours() <= 5) {
    return Constants.periods.Night;
  }

  if (now.hours() >= 6 && now.hours() <= 8) {
    return Constants.periods.Sunrise;
  }

  if (now.hours() >= 9 && now.hours() <= 11) {
    return Constants.periods.Morning;
  }

  if (now.hours() >= 12 && now.hours() <= 17) {
    return Constants.periods.Noon;
  }

  if (now.hours() === 18) {
    return Constants.periods.Sunset;
  }

  return null;
};

export default checkTimeOfDay;
