export const actions = {
  INDEX: {
    REQUEST: 'WAREHOUSES_INDEX_REQUEST',
    SUCCESS: 'WAREHOUSES_INDEX_SUCCESS',
    ERRORS: 'WAREHOUSES_INDEX_ERRORS',
    RESET: 'WAREHOUSES_INDEX_RESET',
  },
  STORE: {
    REQUEST: 'WAREHOUSES_STORE_REQUEST',
    SUCCESS: 'WAREHOUSES_STORE_SUCCESS',
    ERRORS: 'WAREHOUSES_STORE_ERRORS',
    RESET: 'WAREHOUSES_STORE_RESET',
  },
  UPDATE: {
    REQUEST: 'WAREHOUSES_UPDATE_REQUEST',
    SUCCESS: 'WAREHOUSES_UPDATE_SUCCESS',
    ERRORS: 'WAREHOUSES_UPDATE_ERRORS',
    RESET: 'WAREHOUSES_UPDATE_RESET',
  },
  SHOW: {
    REQUEST: 'WAREHOUSES_SHOW_REQUEST',
    SUCCESS: 'WAREHOUSES_SHOW_SUCCESS',
    ERRORS: 'WAREHOUSES_SHOW_ERRORS',
    RESET: 'WAREHOUSES_SHOW_RESET',
  },
  DESTROY: {
    REQUEST: 'WAREHOUSES_DESTROY_REQUEST',
    SUCCESS: 'WAREHOUSES_DESTROY_SUCCESS',
    ERRORS: 'WAREHOUSES_DESTROY_ERRORS',
    RESET: 'WAREHOUSES_DESTROY_RESET',
  },
};

export const warehousesIndexRequest = ({ search } = {}) => ({
  type: actions.INDEX.REQUEST,
  payload: {
    search,
  },
});

export const warehousesIndexSuccess = (data, { search } = {}) => ({
  type: actions.INDEX.SUCCESS,
  payload: {
    search,
    data,
  },
});

export const warehousesIndexErrors = errors => ({
  type: actions.INDEX.ERRORS,
  payload: errors,
});

export const warehousesIndexReset = () => ({
  type: actions.INDEX.RESET,
});

export const warehousesStoreRequest = ({
  name, latitude, longitude, openingTime, closingTime,
}) => ({
  type: actions.STORE.REQUEST,
  payload: {
    name,
    latitude,
    longitude,
    openingTime,
    closingTime,
  },
});

export const warehousesStoreSuccess = () => ({
  type: actions.STORE.SUCCESS,
});

export const warehousesStoreErrors = errors => ({
  type: actions.STORE.ERRORS,
  payload: errors,
});

export const warehousesStoreReset = () => ({
  type: actions.STORE.RESET,
});

export const warehousesUpdateRequest = (
  id,
  {
    name, latitude, longitude, openingTime, closingTime,
  },
) => ({
  type: actions.UPDATE.REQUEST,
  payload: {
    id,
    name,
    latitude,
    longitude,
    openingTime,
    closingTime,
  },
});

export const warehousesUpdateSuccess = () => ({
  type: actions.UPDATE.SUCCESS,
});

export const warehousesUpdateErrors = errors => ({
  type: actions.UPDATE.ERRORS,
  payload: errors,
});

export const warehousesUpdateReset = () => ({
  type: actions.UPDATE.RESET,
});

export const warehousesShowRequest = id => ({
  type: actions.SHOW.REQUEST,
  payload: id,
});

export const warehousesShowSuccess = data => ({
  type: actions.SHOW.SUCCESS,
  payload: data,
});

export const warehousesShowErrors = errors => ({
  type: actions.SHOW.ERRORS,
  payload: errors,
});

export const warehousesShowReset = () => ({
  type: actions.SHOW.RESET,
});

export const warehousesDestroyRequest = id => ({
  type: actions.DESTROY.REQUEST,
  payload: id,
});

export const warehousesDestroySuccess = () => ({
  type: actions.DESTROY.SUCCESS,
});

export const warehousesDestroyErrors = errors => ({
  type: actions.DESTROY.ERRORS,
  payload: errors,
});

export const warehousesDestroyReset = () => ({
  type: actions.DESTROY.RESET,
});
