import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import CloseIconButton from '../Base/Button/CloseIcon';
import { snackbarMessageClose } from '../../providers/redux/actions/Snackbar';

const SnackbarCloseButton = ({
  messageKey,
  snackbarMessageClose: snackbarMessageCloseAction,
}) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <CloseIconButton
      onClick={() => {
        closeSnackbar(messageKey);
        snackbarMessageCloseAction({ key: messageKey });
      }}
    />
  );
};

SnackbarCloseButton.propTypes = {
  messageKey: PropTypes.oneOfType([
    PropTypes.string, PropTypes.number,
  ]).isRequired,
  snackbarMessageClose: PropTypes.func.isRequired,
};

export default connect(null, { snackbarMessageClose })(SnackbarCloseButton);
