import { actions } from '../actions/Displays';

const initialState = {
  store: {
    voucher: 0,
    discount: 0,
    total: 0,
  },
  data: [],
};

const displaysReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.PUT:
      return {
        ...state,
        data: action.payload,
      };
    case actions.STORE.PUT:
      return {
        ...state,
        store: action.payload,
      };
    default:
      return state;
  }
};

export default displaysReducer;
