export const actions = {
  INDEX: {
    REQUEST: 'PRINTERS_INDEX_REQUEST',
    SUCCESS: 'PRINTERS_INDEX_SUCCESS',
    ERRORS: 'PRINTERS_INDEX_ERRORS',
    RESET: 'PRINTERS_INDEX_RESET',
  },
  CREATE: {
    REQUEST: 'PRINTER_DELETE_REQUEST',
    SUCCESS: 'PRINTER_DELETE_SUCCESS',
    ERRORS: 'PRINTER_DELETE_ERRORS',
  },
  REMOVE: {
    REQUEST: 'PRINTER_REMOVE_REQUEST',
    SUCCESS: 'PRINTER_REMOVE_SUCCESS',
    ERRORS: 'PRINTER_REMOVE_ERRORS',
  },
};

export const printersCreateRequest = ({
  name,
  location,
  mode,
}) => ({
  type: actions.CREATE.REQUEST,
  payload: {
    name,
    location,
    mode,
  },
});

export const printersCreateSuccess = () => ({
  type: actions.CREATE.SUCCESS,
});

export const printersCreateErrors = ({ errors }) => ({
  type: actions.CREATE.ERRORS,
  payload: errors,
});

export const printersRemoveRequest = ({ id }) => ({
  type: actions.REMOVE.REQUEST,
  payload: id,
});

export const printersRemoveSuccess = () => ({
  type: actions.REMOVE.SUCCESS,
});

export const printersRemoveErrors = ({ errors }) => ({
  type: actions.REMOVE.ERRORS,
  payload: errors,
});

export const printersIndexRequest = placeId => ({
  type: actions.INDEX.REQUEST,
  payload: placeId,
});

export const printersIndexSuccess = data => ({
  type: actions.INDEX.SUCCESS,
  payload: data,
});

export const printersIndexErrors = errors => ({
  type: actions.INDEX.ERRORS,
  payload: errors,
});

export const printersIndexReset = () => ({
  type: actions.INDEX.RESET,
});
