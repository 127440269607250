import React from 'react';
import { makeStyles } from '@material-ui/core';
import Container from './Base/Container';
import IconLoader from './Base/IconLoader';

const styles = makeStyles(() => ({
  container: {
    // backgroundColor: theme.palette.primary.main
  },
}));

const LoadingScreen = () => {
  const classes = styles();
  return (
    <Container className={classes.container} flex center horizontal alignCenter>
      <IconLoader color />
    </Container>
  );
};

export default LoadingScreen;
