import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Layout from '../../../components/Layout';
import ModelInteractor from '../../../components/Base/ModelInteractor';
import SupplierItem from './components/Item';
import SuppliersCreate from './components/Create';
import SuppliersView from './components/View';
import { Resources } from '../../../providers/API';
import Container from '../../../components/Base/Container';
import {
  suppliersDelete,
  suppliersGet,
  suppliersItemReset,
  suppliersItemSelect,
} from '../../../providers/redux/actions/Suppliers';

const ScreenSuppliers = ({
  suppliers,
  hasMore,
  loading,
  selected,
  processing,
  success,
  suppliersItemSelect: suppliersItemSelectAction,
  suppliersItemReset: suppliersItemResetAction,
  suppliersGet: suppliersGetAction,
}) => {
  const loadMore = (page, search) => {
    suppliersGetAction({ search });
  };

  return (
    <Layout direction="right" flex>
      <ModelInteractor
        create={SuppliersCreate}
        view={SuppliersView}
        resource={Resources.suppliers}
        list={suppliers}
        itemElement={SupplierItem}
        nextCallback={loadMore}
        hasMore={hasMore}
        loading={loading}
        selected={selected}
        viewAction={id => suppliersItemSelectAction({ id })}
        reset={suppliersItemResetAction}
        empty={<Container>Art of organising.</Container>}
        processing={processing}
        success={success}
      />
    </Layout>
  );
};

ScreenSuppliers.defaultProps = {
  suppliers: null,
  selected: null,
};

ScreenSuppliers.propTypes = {
  suppliers: PropTypes.array,
  hasMore: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  selected: PropTypes.object,
  suppliersItemSelect: PropTypes.func.isRequired,
  suppliersItemReset: PropTypes.func.isRequired,
  suppliersGet: PropTypes.func.isRequired,
  processing: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  timestamp: state.suppliersReducer.timestamp,
  hasMore: state.suppliersReducer.hasMore,
  suppliers: state.suppliersReducer.data,
  loading: state.suppliersReducer.loading,
  processing: state.suppliersReducer.edit.processing,
  success: state.suppliersReducer.edit.success,
  selected: state.suppliersReducer.selected,
});

export default connect(mapStateToProps, {
  suppliersGet,
  suppliersDelete,
  suppliersItemSelect,
  suppliersItemReset,
})(ScreenSuppliers);
