import { createMuiTheme } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';

const fontFace = {
  fontFamily: [
    '"RNS Sanz"',
    'Roboto',
  ],
};

const theme = createMuiTheme({
  status: {
    success: green,
  },
  palette: {
    primary: {
      light: '#ff9b8f',
      main: '#FF6961',
      dark: '#c63637',
      contrastText: '#fafafa',
    },
    secondary: {
      light: '#ffea91',
      main: '#ffb861',
      dark: '#c88833',
      contrastText: '#fafafa',
    },
    error: {
      main: '#DE2015',
    },
    header: {
      main: '#97374c',
      contrastText: '#fafafa',
    },
    icon: {
      main: '#818181',
    },
    dark: {
      main: '#333333',
    },
    text: {
      primary: '#414040',
    },
  },
  border: {
    light: '#e2e2e2',
  },
  images: {
    background: '#d7d7d7',
  },
  typography: {
    fontSize: 17,
    h1: { ...fontFace, fontWeight: 800 },
    h2: { ...fontFace, fontWeight: 700 },
    h3: { ...fontFace, fontWeight: 600 },
    h4: { ...fontFace, fontWeight: 500 },
    h5: { ...fontFace, fontWeight: 400 },
    h6: { ...fontFace, fontWeight: 300 },
    body1: { ...fontFace, fontWeight: 400 },
    body2: { ...fontFace, fontWeight: 400 },
  },
  icon: {
    width: '1em',
    height: '1em',
    display: 'inline-block',
    fontSize: 24,
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    userSelect: 'none',
    flexShrink: 0,
  },
  card: {
    position: 'relative',
    boxShadow: 'none',
    borderRadius: 40,
    overflow: 'visible',
    '&::before': {
      content: "''",
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: -1,
      boxShadow: '0 3px 6px rgba(0,0,0,.10)',
      borderRadius: 40,
    },
  },
  overrides: {
    MuiInput: {
      root: {
        fontFamily: '"RNS Sanz"',
      },
    },
    MuiInputLabel: {
      root: {
        fontFamily: '"RNS Sanz"',
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1440,
      xl: 1920,
    },
  },
});

export default theme;
