import {
  all,
  call,
  put,
  select,
  putResolve,
  takeLatest,
} from 'redux-saga/effects';
import { Instance, Resources } from '../../API';
import {
  actions,
  warehousesDestroyErrors, warehousesDestroyReset,
  warehousesDestroySuccess,
  warehousesIndexReset,
  warehousesIndexSuccess,
  warehousesShowErrors, warehousesShowReset,
  warehousesShowSuccess,
  warehousesStoreErrors,
  warehousesStoreReset,
  warehousesStoreSuccess,
  warehousesUpdateErrors,
  warehousesUpdateReset,
  warehousesUpdateSuccess,
} from '../actions/Warehouses';
import {
  snackbarDefaultError,
  snackbarDefaultSuccess,
} from '../actions/Snackbar';
import hasErrors from '../../hasErrors';
import warehouseTransformer from '../transformers/Warehouse';

const resource = Resources.warehouses;

const callWarehouseStore = (
  organisationId,
  name,
  {
    latitude,
    longitude,
    openingTime,
    closingTime,
  },
) => Instance.post(
  resource,
  {
    organisation_id: organisationId,
    name,
    latitude,
    longitude,
    opening_time: openingTime,
    closing_time: closingTime,
  },
);

export const callWarehouseIndex = (
  organisationId,
  {
    search,
  },
) => Instance.get(
  resource,
  {
    params: {
      organisation_id: organisationId,
      search,
    },
  },
);

export const callWarehouseShow = id => Instance.get(`${resource}/${id}`);

export const callWarehouseUpdate = (
  id,
  {
    name,
  },
) => Instance.put(
  `${resource}/${id}`,
  {
    name,
  },
);

export const callWarehouseDestroy = id => Instance.delete(
  `${resource}/${id}`,
);

export const callWarehouseProducts = (
  organisationId,
  placeId,
  {
    timestamp,
    page,
    search,
  } = {},
) => Instance.get(
  Resources.products,
  {
    params: {
      organisation_id: organisationId,
      place_id: placeId,
      timestamp,
      page,
      search,
    },
  },
);

const dataSite = state => ({
  organisationId: state.organisationReducer.data.id,
});

const dataMetadata = state => ({
  search: state.warehousesReducer.search,
});

const dataIndex = state => ({
  data: state.warehousesReducer.index.data,
});

/**
 * Sagas
 */
function* sagaUpdateIndexData(warehouse) {
  const { data } = yield select(dataIndex);
  const index = data.findIndex(({ id }) => warehouse.id === id);
  if (index !== undefined) {
    data[index] = { ...data[index], ...warehouse };
  } else {
    data.unshift(warehouse);
  }

  yield put(
    warehousesIndexSuccess(data),
  );
}

function* sagaGetProductsAtWarehouse(warehouse) {
  const { organisationId } = yield select(dataSite);
  const { data } = yield call(
    callWarehouseProducts,
    organisationId,
    warehouse.placeId,
  );

  const { data: products } = data;

  return {
    ...warehouse,
    products,
  };
}

function* sagaWarehousesLoad({
  payload: {
    search,
  },
}) {
  try {
    const { organisationId } = yield select(dataSite);
    const {
      search: originalSearch,
    } = yield select(dataMetadata);

    if (search !== originalSearch) {
      yield put(
        warehousesIndexReset(),
      );
    }

    const { data } = yield call(
      callWarehouseIndex,
      organisationId,
      {
        search,
      },
    );

    const { data: items } = data;

    yield put(
      warehousesIndexSuccess(
        items,
        {
          search,
        },
      ),
    );
  } catch (e) {
    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

function* sagaWarehouseStore({
  payload: {
    name,
    latitude,
    longitude,
    openingTime,
    closingTime,
  },
}) {
  try {
    const { organisationId } = yield select(dataSite);
    const { data } = yield call(
      callWarehouseStore,
      organisationId,
      name,
      {
        latitude,
        longitude,
        openingTime,
        closingTime,
      },
    );

    const { data: warehouse } = data;
    yield call(sagaUpdateIndexData, warehouse);

    yield putResolve(
      warehousesStoreSuccess(),
    );

    yield put(
      snackbarDefaultSuccess({ data }),
    );

    yield put(
      warehousesStoreReset(),
    );
  } catch (e) {
    const errors = hasErrors(e);
    if (errors) {
      yield put(
        warehousesStoreErrors(errors),
      );
    }
    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

function* sagaWarehouseUpdate({
  payload: {
    id,
    name,
    latitude,
    longitude,
    openingTime,
    closingTime,
  },
}) {
  try {
    const { data } = yield call(
      callWarehouseUpdate,
      id,
      {
        name,
        latitude,
        longitude,
        openingTime,
        closingTime,
      },
    );

    const { data: warehouseData } = data;
    const warehouse = warehouseTransformer(warehouseData);

    yield call(sagaUpdateIndexData, warehouse);

    yield putResolve(
      warehousesUpdateSuccess(warehouse),
    );

    yield putResolve(
      warehousesShowSuccess(warehouse),
    );

    yield put(
      snackbarDefaultSuccess({ data }),
    );

    yield put(
      warehousesUpdateReset(),
    );
  } catch (e) {
    const errors = hasErrors(e);
    if (errors) {
      yield put(
        warehousesUpdateErrors(errors),
      );
    }
    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

function* sagaWarehouseDestroy({ payload: id }) {
  try {
    const { data } = yield call(callWarehouseDestroy, id);

    yield put(
      warehousesDestroySuccess(),
    );

    yield put(
      snackbarDefaultSuccess({ data }),
    );

    yield putResolve(
      warehousesShowReset(),
    );

    const { data: indexData } = yield select(dataIndex);
    const newData = [
      ...indexData.filter(({ id: warehouseId }) => id !== warehouseId),
    ];

    yield put(
      warehousesIndexSuccess(newData),
    );

    yield putResolve(
      warehousesDestroyReset(),
    );
  } catch (e) {
    const errors = hasErrors(e);
    if (errors) {
      yield put(
        warehousesDestroyErrors(errors),
      );
    }
    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

function* sagaWarehouseShow({ payload: id }) {
  try {
    const { data } = yield call(
      callWarehouseShow,
      id,
    );

    const { data: warehouseData } = data;

    const warehouse = warehouseTransformer(warehouseData);

    const warehouseWithProducts = yield call(
      sagaGetProductsAtWarehouse,
      warehouse,
    );

    yield putResolve(
      warehousesShowSuccess(warehouseWithProducts),
    );
  } catch (e) {
    const errors = hasErrors(e);
    if (errors) {
      yield put(
        warehousesShowErrors(errors),
      );
    }

    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

export default function* Suppliers() {
  yield all([
    takeLatest(actions.INDEX.REQUEST, sagaWarehousesLoad),
    takeLatest(actions.STORE.REQUEST, sagaWarehouseStore),
    takeLatest(actions.UPDATE.REQUEST, sagaWarehouseUpdate),
    takeLatest(actions.DESTROY.REQUEST, sagaWarehouseDestroy),
    takeLatest(actions.SHOW.REQUEST, sagaWarehouseShow),
  ]);
}
