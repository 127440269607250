export const actions = {
  UPDATE: {
    REQUEST: 'ALERTS_UPDATE_REQUEST',
    SUCCESS: 'ALERTS_UPDATE_SUCCESS',
    ERRORS: 'ALERTS_UPDATE_ERRORS',
    RESET: 'ALERTS_UPDATE_RESET',
  },
  DESTROY: {
    REQUEST: 'ALERTS_DESTROY_REQUEST',
    SUCCESS: 'ALERTS_DESTROY_SUCCESS',
    ERRORS: 'ALERTS_DESTROY_ERRORS',
    RESET: 'ALERTS_DESTROY_RESET',
  },
};

export const alertsUpdateRequest = (
  storeId,
  productId,
  {
    fallBelowQuantity,
    replenishQuantity,
  },
) => ({
  type: actions.UPDATE.REQUEST,
  payload: {
    storeId,
    productId,
    fallBelowQuantity,
    replenishQuantity,
  },
});

export const alertsUpdateSuccess = () => ({
  type: actions.UPDATE.SUCCESS,
});

export const alertsUpdateErrors = errors => ({
  type: actions.UPDATE.ERRORS,
  payload: errors,
});

export const alertsUpdateReset = () => ({
  type: actions.UPDATE.RESET,
});

export const alertsDestroyRequest = (placeId, productId) => ({
  type: actions.DESTROY.REQUEST,
  payload: {
    placeId,
    productId,
  },
});

export const alertsDestroySuccess = () => ({
  type: actions.DESTROY.SUCCESS,
});

export const alertsDestroyErrors = errors => ({
  type: actions.DESTROY.ERRORS,
  payload: errors,
});

export const alertsDestroyReset = () => ({
  type: actions.DESTROY.RESET,
});
