import {
  all,
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import { Instance, Resources } from '../../API';
import {
  actions,
  goodsIndexSuccess,
} from '../actions/Goods';
import {
  snackbarDefaultError,
} from '../actions/Snackbar';
import goodTransformer from '../transformers/GoodTransformer';

const resource = Resources.goods;

export const callGoodIndex = organisationId => Instance.get(
  resource,
  {
    params: {
      organisation_id: organisationId,
    },
  },
);

const dataSite = state => ({
  organisationId: state.organisationReducer.data.id,
});

function* sagaGoodsIndex() {
  try {
    const { organisationId } = yield select(dataSite);
    const {
      data: {
        data,
      },
    } = yield call(callGoodIndex, organisationId);

    yield put(
      goodsIndexSuccess(
        data.map(goodTransformer),
      ),
    );
  } catch (e) {
    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

export default function* Suppliers() {
  yield all([
    takeLatest(actions.INDEX.REQUEST, sagaGoodsIndex),
  ]);
}
