import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import SelectInput from './SelectInput';
import Container from '../Container';
import Loader from '../Loader';
import { printersIndexRequest } from '../../../providers/redux/actions/Printers';

const PrinterDropdown = ({
  selected, errors, onChange, onEnter, placeId,
}) => {
  const dispatch = useDispatch();
  const { data, loading } = useSelector(
    state => ({
      data: state.printersReducer.index.data,
      loading: state.printersReducer.index.loading,
    }),
  );

  useEffect(() => {
    dispatch(
      printersIndexRequest(placeId),
    );
  }, [placeId]);

  useEffect(() => {
    if (data && data.length === 1) {
      onChange(data[0]);
    }
  }, [data]);

  if (data === null && loading === false) {
    return (
      <Container>
        Snap, we couldn&apos;t load your printers.
      </Container>
    );
  }

  if (loading === true) {
    return (
      <Container flex center alignCenter>
        <Loader />
      </Container>
    );
  }

  if (data.length === 1) {
    return null;
  }

  if (data.length > 0) {
    return (
      <SelectInput
        selected={selected}
        suggestions={data}
        placeholder="Backend"
        label="Printer"
        errors={errors}
        onChange={onChange}
        onEnter={onEnter}
      />
    );
  }

  return null;
};

PrinterDropdown.defaultProps = {
  selected: null,
  errors: null,
  onEnter: null,
};

PrinterDropdown.propTypes = {
  placeId: PropTypes.number.isRequired,
  selected: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
  }),
  errors: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onEnter: PropTypes.func,
};

export default PrinterDropdown;
