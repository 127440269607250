import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../../components/Layout';
import ModelInteractor from '../../../components/Base/ModelInteractor';
import ProductItem from './components/Item';
import ProductsCreate from './components/Create';
import ProductsView from './components/View';
import { Resources } from '../../../providers/API';
import Container from '../../../components/Base/Container';
import {
  productsGet,
  productsItemReset,
  productsItemSelect,
} from '../../../providers/redux/actions/Products';

const ScreenProducts = () => {
  const dispatch = useDispatch();
  const {
    hasMore,
    products,
    loading,
    selected,
    processing,
    success,
    stores,
  } = useSelector(
    state => ({
      timestamp: state.productsReducer.timestamp,
      hasMore: state.productsReducer.hasMore,
      products: state.productsReducer.data,
      loading: state.productsReducer.loading,
      selected: state.productsReducer.selected,
      processing: state.productsReducer.edit.processing,
      success: state.productsReducer.edit.success,
      stores: state.authReducer.user && state.authReducer.user.stores
        ? state.authReducer.user.stores.map(store => store.name)
        : [],
    }),
  );
  const [filters, setFilters] = useState([]);
  const loadMore = (page, search) => dispatch(
    productsGet({
      search,
      filters: filters.length === stores.length ? null : filters,
    }),
  );

  const reset = () => dispatch(
    productsItemReset(),
  );

  const view = id => dispatch(
    productsItemSelect({ id }),
  );

  useEffect(() => {
    loadMore({ page: 1 });
  }, [filters]);

  return (
    <Layout direction="right" flex>
      <ModelInteractor
        create={ProductsCreate}
        view={ProductsView}
        resource={Resources.products}
        list={products}
        itemElement={ProductItem}
        nextCallback={loadMore}
        hasMore={hasMore}
        loading={loading}
        reset={reset}
        empty={<Container>The philosophy of product.</Container>}
        groups={stores.length > 1 ? stores && [
          {
            name: 'Stores',
            filters: stores,
            selectedFilters: filters,
            onFiltersChange: setFilters,
          },
        ] : null}
        selected={selected}
        processing={processing}
        success={success}
        viewAction={view}
      />
    </Layout>
  );
};

export default ScreenProducts;
