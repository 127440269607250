import {
  IconButton,
  makeStyles,
  Paper,
  TableCell,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { DeleteRounded, EditRounded } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Container from '../../../../../components/Base/Container';
import Approval from '../../../../../components/Base/Dialog/Approval';
import PriceInput from '../../../../../components/Base/Input/PriceInput';
import Loader from '../../../../../components/Base/Loader';
import BaseTable from '../../../../../components/Base/Table/BaseTable';
import TableToolbar from '../../../../../components/Base/Table/TableToolbar';
import Form from '../../../../../components/Form';
import FormatPrice from '../../../../../components/Format/FormatPrice';
import usePermissions from '../../../../../providers/hooks/usePermissions';
import Permissions from '../../../../../providers/Permissions';
import {
  quotesDestroyRequest, quotesDestroyReset,
  quotesGet, quotesUpdateRequest, quotesUpdateReset,
} from '../../../../../providers/redux/actions/Quotes';
import QuotesCreate from './QuotesCreate';

const useStyles = makeStyles(
  theme => ({
    paper: {
      display: 'relative',
      width: '100%',
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(3),
    },
    addButton: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      margin: theme.spacing(2.5),
    },
    loading: {
      padding: theme.spacing(3),
    },
  }),
);

const QuotesList = ({ className }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [isDestroying, setIsDestroying] = useState(null);
  const [isUpdating, setIsUpdating] = useState(null);
  // const [name, setName] = useState('');
  const [cost, setCost] = useState('');
  const dispatch = useDispatch();
  const userCan = usePermissions();
  const {
    loading, data, total, update, destroy,
  } = useSelector(
    state => ({
      permissions: state.authReducer.permissions,
      update: state.quotesReducer.update,
      destroy: state.quotesReducer.destroy,
      loading: state.quotesReducer.loading,
      data: state.quotesReducer.data,
      total: state.quotesReducer.total,
    }),
  );

  const canViewQuotes = userCan(Permissions.viewQuotes);
  const canEditQuotes = userCan(Permissions.editQuotes);

  const load = () => (canViewQuotes ? dispatch(
    quotesGet(),
  ) : null);

  const reset = () => (canViewQuotes ? dispatch(
    quotesGet({ reset: true }),
  ) : null);

  const onClose = () => setOpen(false);

  const onOpen = (e) => {
    e.currentTarget.blur();
    setOpen(true);
  };

  const onDestroyAttempt = id => setIsDestroying(id);

  const onDestroyCancel = () => setIsDestroying(null);

  const onOpenUpdate = id => setIsUpdating(id);

  const onCloseUpdate = () => setIsUpdating(null);

  const onUpdate = () => dispatch(
    quotesUpdateRequest(isUpdating, { cost }),
  );

  const onDestroy = () => dispatch(
    quotesDestroyRequest(isDestroying),
  );

  useEffect(() => {
    if (destroy.success) {
      onDestroyCancel();
      dispatch(
        quotesDestroyReset(),
      );
    }
  }, [destroy.success]);

  useEffect(() => {
    if (update.success) {
      onCloseUpdate();
      dispatch(
        quotesUpdateReset(),
      );
    }
  }, [update.success]);

  useEffect(() => {
    if (isUpdating !== null) {
      const quote = data.find(({ id }) => id === isUpdating);
      const {
        // name: quoteName,
        cost: quoteCost,
      } = quote;
      // setName(quoteName);
      setCost(quoteCost);
    }
  }, [isUpdating]);

  return (
    <Paper className={classNames(classes.paper, className)}>
      <Approval
        closeDialog={onDestroyCancel}
        content="Are you sure you want to delete this quote? This will affect the prices of inventories that were created before the next available quote. We encourage you to do this with caution, as the accuracy of your reports will be affected."
        open={isDestroying !== null}
        title="Delete quote"
        triggerEvent={onDestroy}
        processing={destroy.loading}
      />
      <Form
        label="Modify quote"
        loading={update.loading}
        onClose={onCloseUpdate}
        open={isUpdating !== null}
        onSubmit={onUpdate}
      >
        {/* <TextInput label="Name" onChange={setName} value={name} shrink /> */}
        <PriceInput label="Cost" onChange={setCost} value={cost} shrink />
      </Form>
      <QuotesCreate
        open={open}
        close={onClose}
        callback={reset}
      />
      <TableToolbar
        title="Quotes"
        actions={canEditQuotes && (
          <Tooltip title="Add new">
            <IconButton
              color="secondary"
              onClick={onOpen}
              aria-label="Add new"
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        )}
      />
      {loading
        ? (
          <Container className={classes.loading} center alignCenter flex>
            <Loader />
          </Container>
        )
        : (
          <BaseTable
            key={data ? data.length : null}
            page={page}
            setPage={setPage}
            total={total}
            loadMore={load}
            data={data || []}
            columns={[
              { name: 'Supplier', dataKey: 'supplier' },
              {
                name: 'Cost', dataKey: 'cost', align: 'right', numeric: true,
              },
              { name: 'Added', dataKey: 'added' },
              { name: '', dataKey: 'actions' },
            ]}
            TableItem={({
              id, supplier, cost: quoteCost, added,
            }) => (
              <TableRow key={id}>
                <TableCell component="th" scope="row">
                  {supplier.name}
                </TableCell>
                <TableCell align="right">
                  <FormatPrice>{quoteCost}</FormatPrice>
                </TableCell>
                <TableCell>{added}</TableCell>
                {canEditQuotes && (
                  <>
                    <TableCell align="right">
                      <IconButton onClick={() => onOpenUpdate(id)}>
                        <EditRounded />
                      </IconButton>
                      <IconButton onClick={() => onDestroyAttempt(id)}>
                        <DeleteRounded />
                      </IconButton>
                    </TableCell>
                  </>
                )}
              </TableRow>
            )}
          />
        )}
    </Paper>
  );
};

QuotesList.defaultProps = {
  className: null,
};

QuotesList.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default QuotesList;
