import { actions } from '../actions/Site';

const initialState = {
  initialised: false,
  title: null,
  sidebar: false,
};

const siteReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.TITLE.SET:
      return {
        ...state,
        title: action.payload,
      };
    case actions.SIDEBAR.TOGGLE:
      return {
        ...state,
        sidebar: !state.sidebar,
      };
    case actions.SIDEBAR.CLOSE:
      return {
        ...state,
        sidebar: false,
      };
    case actions.SIDEBAR.OPEN:
      return {
        ...state,
        sidebar: true,
      };
    case actions.INITIALISED:
      return {
        ...state,
        initialised: true,
      };
    default:
      return state;
  }
};

export default siteReducer;
