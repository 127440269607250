import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl, FormHelperText, TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const SelectInput = ({
  selected,
  suggestions,
  placeholder,
  label,
  errors,
  onChange,
  isClearable,
  onChangeText,
  onEnter,
  autoFocus,
  disabled,
  renderOption,
  getOptionLabel,
}) => {
  const timer = useRef();
  const [text, setText] = useState('');

  const onValueChange = (e, val) => onChange(val);

  const onInputChange = (e, val) => {
    setText(val);
    if (onChangeText !== null) {
      if (timer.current !== null) {
        clearTimeout(timer.current);
      }

      timer.current = setTimeout(() => {
        onChangeText(val);
      }, 600);
    }
  };

  const onRenderOption = renderOption || (({ name }) => (<>{name}</>));

  const onGetOptionLabel = getOptionLabel || (({ name }) => name);

  return (
    <FormControl
      disabled={disabled}
      fullWidth
    >
      <Autocomplete
        value={selected}
        options={suggestions}
        getOptionLabel={onGetOptionLabel}
        onChange={onValueChange}
        onInputChange={onInputChange}
        onKeyDown={(e) => {
          if (onEnter && e.key === 'Enter') {
            onEnter();
          }
        }}
        renderOption={onRenderOption}
        renderInput={params => (
          <TextField
            {...params}
            InputLabelProps={{
              shrink: true,
            }}
            label={label}
            placeholder={placeholder}
            margin="normal"
            autoFocus={autoFocus}
            value={text}
          />
        )}
        noOptionsText={text === '' ? 'Start typing for options' : 'No options available'}
        disableClearable={!isClearable}
      />
      <FormHelperText error={!!errors}>{errors}</FormHelperText>
    </FormControl>
  );
};

SelectInput.defaultProps = {
  isClearable: true,
  errors: null,
  selected: null,
  placeholder: null,
  label: null,
  onEnter: null,
  onChangeText: null,
  autoFocus: false,
  disabled: false,
  renderOption: null,
  getOptionLabel: null,
};

SelectInput.propTypes = {
  selected: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.any,
  }),
  onChange: PropTypes.func.isRequired,
  suggestions: PropTypes.array.isRequired,
  errors: PropTypes.array,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  isClearable: PropTypes.bool,
  onEnter: PropTypes.func,
  onChangeText: PropTypes.func,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  renderOption: PropTypes.func,
  getOptionLabel: PropTypes.func,
};

export default SelectInput;
