import { firestore } from 'firebase';
import {
  take,
  fork,
  all,
  call,
  put,
  putResolve,
  select,
  takeLatest,
  cancel,
} from 'redux-saga/effects';
import Constants from '../../../constants';
import { Instance, Resources, toFormData } from '../../API';
import firebase from '../../firebase';
import hasErrors from '../../hasErrors';
import {
  actions,
  productsGet,
  productsItemPut,
  productsItemReset,
  productsItemSelect,
  productsEditProcessed,
  productsEditProcessing,
  productsPut,
  productsReset,
  productsEditSuccess,
  productsEditErrors,
  productsCreateErrors,
  productsCreateProcessed,
  productsCreateSuccess,
  productsCreateProcessing,
  productsMovementsPut, productsEditReset, productsHistoryIndexSuccess,
} from '../actions/Products';
import {
  snackbarDefaultError,
  snackbarDefaultSuccess,
} from '../actions/Snackbar';
import { quotesGet } from '../actions/Quotes';
import { actions as translationActions, translationsIndexRequest } from '../actions/Translations';
import collectionProductTransformer
  from '../transformers/collection/ProductTransformer';
import actionTransformer from '../transformers/firebase/ActionTransformer';
import collectionTransformer
  from '../transformers/firebase/CollectionTransformer';
import movementTransformer from '../transformers/Movement';
import productTransformer from '../transformers/ProductTransformer';
import UserCan from '../../UserCan';
import Permissions from '../../Permissions';

const resource = Resources.products;

const firestoreHistoryCollection = (organisationId, id) => firestore()
  .collection(Constants.firestore.collections.organisations)
  .doc(organisationId.toString())
  .collection(Constants.firestore.collections.products)
  .doc(id.toString())
  .collection(Constants.firestore.collections.actions);

const productsStoreCall = ({
  organisationId,
  name,
  alias,
  brandId,
  categoryId,
  barcodes,
  price,
  quantity,
  measurement,
  prices,
  fallBelowQuantity,
  replenishQuantity,
  image,
}) => {
  const payload = {
    organisation_id: organisationId,
    name,
    alias,
    brand_id: brandId,
    category_id: categoryId,
    barcodes,
    price,
    quantity,
    measurement,
    prices,
    fall_below_quantity: fallBelowQuantity,
    replenish_quantity: replenishQuantity,
    image,
  };

  return Instance.post(
    resource,
    toFormData(payload),
  );
};

export const productsIndexCall = ({
  organisationId, page, timestamp, search, filters,
}) => Instance.get(
  resource,
  {
    params: {
      organisation_id: organisationId,
      page: page !== 1 ? page : null,
      search: search !== '' ? search : null,
      timestamp: timestamp || null,
      filters: filters || null,
      paginate: 1,
    },
  },
);

export const productsViewCall = ({ id }) => Instance.get(`${resource}/${id}`);

export const productsPutCall = ({
  id,
  name,
  alias,
  brandId,
  categoryId,
  barcodes,
  price,
  quantity,
  measurement,
  prices,
  fallBelowQuantity,
  replenishQuantity,
  image,
}) => {
  const payload = {
    id,
    name,
    alias,
    brand_id: brandId,
    category_id: categoryId,
    barcodes,
    price,
    quantity,
    measurement,
    prices,
    fall_below_quantity: fallBelowQuantity,
    replenish_quantity: replenishQuantity,
    image,
  };

  const data = toFormData(payload);
  data.append('_method', 'PUT');
  return Instance.post(`${resource}/${id}`, data);
};

export const productsDeleteCall = ({ id }) => Instance.delete(`${resource}/${id}`);

const getMovementsCall = async ({
  productId, page, timestamp, search, filters,
}) => Instance.get(
  `${resource}/${Resources.movements}`,
  {
    params: {
      product_id: productId,
      page: page !== 1 ? page : null,
      search: search !== '' ? search : null,
      timestamp: timestamp || null,
      filters: filters || null,
      paginate: 1,
    },
  },
);

const siteData = state => ({
  organisationId: state.organisationReducer.data.id,
});

const getTranslations = state => state.translationsReducer.index.data;

const productsData = state => ({
  data: state.productsReducer.data,
});

const productsMetaData = state => ({
  page: state.productsReducer.page,
  timestamp: state.productsReducer.timestamp,
  search: state.productsReducer.search,
});

const getSelected = state => ({
  selected: state.productsReducer.selected,
});

const dataGetPermissions = state => state.authReducer.permissions;

const dataGetUser = state => state.authReducer.user;

const dataGetHistory = state => ({
  data: state.productsReducer.history.data,
  page: state.productsReducer.history.page,
  maxPage: state.productsReducer.history.maxPage,
});

function* sagaProductHistoryIndex({ payload: page }) {
  try {
    const newLimit = page * Constants.table.perPage;
    const {
      data: history,
      maxPage,
      page: currentPage,
    } = yield select(dataGetHistory);
    if (maxPage !== null && page > maxPage) {
      yield put(
        productsHistoryIndexSuccess(history, maxPage),
      );
      return;
    }

    if (newLimit <= history.length) {
      yield put(
        productsHistoryIndexSuccess(history, page),
      );
      return;
    }

    const { organisationId } = yield select(siteData);
    const { selected } = yield select(getSelected);
    if (selected && organisationId) {
      const { id } = selected;
      let translations = yield select(getTranslations);
      if (translations === null) {
        yield put(
          translationsIndexRequest(),
        );

        yield take([
          translationActions.INDEX.SUCCESS,
          translationActions.INDEX.ERRORS,
        ]);

        translations = yield select(getTranslations);
      }

      if (translations !== null) {
        const task = yield fork(
          firebase.firestore.syncCollection,
          firestoreHistoryCollection(organisationId, id)
            .limit(page * Constants.table.perPage)
            .orderBy('timestamp', 'desc'),
          {
            successActionCreator: (data) => {
              const newMaxPage = data.length === history.length
                ? currentPage
                : null;
              return productsHistoryIndexSuccess(
                data,
                (newMaxPage !== null && page > newMaxPage)
                  ? newMaxPage
                  : page,
                {
                  maxPage: newMaxPage,
                },
              );
            },
            transform: collectionTransformer(
              actionItem => actionTransformer(
                actionItem,
                translations,
              ),
            ),
          },
        );

        yield take(
          [
            actions.HISTORY.INDEX.REQUEST,
            actions.HISTORY.INDEX.NEXT_PAGE,
            actions.HISTORY.INDEX.PREVIOUS_PAGE,
          ],
        );

        yield cancel(task);
      }
    }
  } catch (e) {
    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

function* updateProductsSaga(product) {
  const user = yield select(dataGetUser);
  const { data } = yield select(productsData);
  let productData = { ...product };
  if (user.stores.length === 1) {
    const { stores } = user;
    const { prices } = product;
    const { id: storeId } = [...stores].pop();
    const { price: storePrice } = prices.find(
      ({ id: priceStoreId }) => priceStoreId === storeId,
    );
    productData = { ...product, price: storePrice };
  }

  const newData = data ? [...data] : [];
  const index = newData.findIndex(({ id }) => product.id === id);
  if (index !== undefined) {
    newData[index] = { ...newData[index], ...productData };
  } else {
    newData.unshift(productData);
  }

  const { page, timestamp, search } = yield select(productsMetaData);

  yield put(
    productsPut({
      data: newData, page, timestamp, search,
    }),
  );
}

/**
 * selectProductSaga
 * @param id
 */
function* selectProductSaga({ payload: id }) {
  try {
    const permissions = yield select(dataGetPermissions);
    const userCan = UserCan(permissions);
    const { data: { data } } = yield call(
      productsViewCall,
      { id },
    );

    const product = productTransformer(data);

    yield putResolve(
      productsItemPut({
        item: product,
      }),
    );

    if (userCan(Permissions.viewQuotes)) {
      yield putResolve(
        quotesGet({ reset: true }),
      );
    }

    yield call(updateProductsSaga, product);
  } catch (e) {
    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

/**
 * loadProductsSaga
 * @returns {IterableIterator<*>}
 */
function* loadProductsSaga({
  payload: {
    search, filters, reset,
  },
}) {
  try {
    const { organisationId } = yield select(siteData);
    const {
      search: originalSearch,
      page,
      timestamp,
    } = yield select(productsMetaData);

    if (search !== originalSearch) {
      yield putResolve(
        productsReset(),
      );
    }

    const { data } = yield call(
      productsIndexCall,
      {
        organisationId,
        page: reset || !page || search !== originalSearch ? 1 : page + 1,
        timestamp,
        search: search === '' ? null : search,
        filters,
      },
    );

    const {
      data: {
        page: newPage,
        timestamp: newTimestamp,
        items,
      },
    } = data;

    const newItems = items.map(collectionProductTransformer);

    let newData;
    if (newPage === 1) {
      newData = [...newItems];
    } else {
      const { data: originalData } = yield select(productsData);
      newData = originalData ? [...originalData, ...newItems] : [...newItems];
    }

    yield putResolve(
      productsPut({
        page: newPage,
        timestamp: newTimestamp,
        data: newData,
        hasMore: items.length > 0,
        search,
      }),
    );

    if (newPage === 1 && items.length === 1) {
      yield put(
        productsItemSelect({ id: items[0].id }),
      );
    }
  } catch (e) {
    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

/**
 * createProductsSaga
 * @param product
 */
function* createProductsSaga({
  payload: {
    name,
    alias,
    brand,
    category,
    barcodes,
    price,
    quantity,
    measurement,
    fallBelowQuantity,
    replenishQuantity,
    image,
  },
}) {
  try {
    yield put(productsCreateProcessing());
    const { organisationId } = yield select(siteData);
    const { data } = yield call(
      productsStoreCall,
      {
        organisationId,
        name,
        alias,
        brandId: brand ? brand.id : null,
        categoryId: category ? category.id : null,
        barcodes,
        price,
        quantity,
        measurement: measurement ? measurement.unit : null,
        fallBelowQuantity,
        replenishQuantity,
        image,
      },
    );

    yield call(
      updateProductsSaga,
      productTransformer(data.data),
    );

    yield put(
      productsGet({ reset: true }),
    );

    yield put(
      productsCreateSuccess(),
    );

    yield put(
      productsItemSelect({ id: data.data.id }),
    );

    yield put(
      snackbarDefaultSuccess({ data }),
    );
  } catch (e) {
    const errors = hasErrors(e);
    yield put(
      snackbarDefaultError({ e }),
    );

    if (errors) {
      yield put(
        productsCreateErrors({ errors }),
      );
    }
  } finally {
    yield put(
      productsCreateProcessed(),
    );
  }
}

/**
 * editProductsSaga
 * @param product
 */
function* editProductsSaga({
  payload: {
    id,
    name,
    alias,
    brand,
    category,
    barcodes,
    price,
    quantity,
    measurement,
    fallBelowQuantity,
    replenishQuantity,
    prices,
    image,
  },
}) {
  try {
    yield put(
      productsEditProcessing(),
    );

    const { data } = yield call(
      productsPutCall,
      {
        id,
        name,
        alias,
        brandId: brand ? brand.id : null,
        categoryId: category ? category.id : null,
        barcodes,
        price,
        quantity,
        fallBelowQuantity,
        replenishQuantity,
        measurement: measurement ? measurement.unit : null,
        prices: prices.map(
          ({ id: storeId, price: storePrice }) => ({
            store_id: storeId,
            amount: storePrice,
          }),
        ),
        image,
      },
    );

    const { selected } = yield select(getSelected);
    const product = {
      ...(selected || {}),
      ...productTransformer(data.data),
    };

    yield put(
      productsItemPut({ item: product }),
    );

    yield call(updateProductsSaga, product);

    yield put(
      productsEditProcessed(),
    );

    yield put(
      snackbarDefaultSuccess({ data }),
    );

    yield putResolve(
      productsEditSuccess(),
    );

    yield put(
      productsEditReset(),
    );
  } catch (e) {
    const errors = hasErrors(e);
    if (errors) {
      yield put(
        productsEditErrors({ errors }),
      );
    }

    yield put(
      snackbarDefaultError({ e }),
    );
  } finally {
    yield put(
      productsEditProcessed(),
    );
  }
}

/**
 * deleteProductsSaga
 * @param id
 */
function* deleteProductsSaga({ payload: id }) {
  try {
    yield put(productsEditProcessing());
    const response = yield call(productsDeleteCall, { id });

    const { data } = yield select(productsData);
    const newData = [...data];
    yield put(
      productsPut({
        data: newData.filter(({ id: productId }) => productId !== id),
      }),
    );

    yield put(
      productsItemReset(),
    );

    yield put(
      productsEditSuccess(),
    );
    yield put(
      snackbarDefaultSuccess({ data: response.data }),
    );
  } catch (e) {
    yield put(snackbarDefaultError({ e }));
  } finally {
    yield put(productsEditProcessed());
  }
}

function* getMovementsSaga() {
  while (true) {
    const { payload: { id } } = yield take(actions.ITEM.PUT);
    const { data: { data } } = yield call(
      getMovementsCall,
      {
        productId: id,
      },
    );

    yield put(
      productsMovementsPut(
        data.map(movementTransformer),
      ),
    );
  }
}

export default function* Products() {
  yield all([
    takeLatest(actions.GET, loadProductsSaga),
    takeLatest(actions.DELETE, deleteProductsSaga),
    takeLatest(actions.ITEM.SELECT, selectProductSaga),
    takeLatest(actions.EDIT.REQUEST, editProductsSaga),
    takeLatest(actions.CREATE.REQUEST, createProductsSaga),
    takeLatest(
      [
        actions.HISTORY.INDEX.REQUEST,
        actions.HISTORY.INDEX.NEXT_PAGE,
        actions.HISTORY.INDEX.PREVIOUS_PAGE,
      ],
      sagaProductHistoryIndex,
    ),
    fork(getMovementsSaga),
  ]);
}
