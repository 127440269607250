import React from 'react';
import PropTypes from 'prop-types';
import { ListItemText, ListItem } from '@material-ui/core';

import Container from '../../../../components/Base/Container';
import StylesModelInteractor from '../../../../styles/components/StylesModelInteractor';

const BrandItem = ({
  onClick, item, selected,
}) => {
  const classes = StylesModelInteractor();
  return (
    <ListItem
      component="div"
      className={classes.container}
      classes={{
        selected: classes.selected,
      }}
      onClick={() => onClick(item.id)}
      selected={selected}
      button
    >
      <ListItemText className={classes.content}>
        <Container>
          Brand
          {`#${item.id}`}
        </Container>
        <Container className={classes.name}>{item.name.toUpperCase()}</Container>
      </ListItemText>
    </ListItem>
  );
};

BrandItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default BrandItem;
