const mapStore = ({
  id,
  store_id: storeId,
  place_id: placeId,
  name,
  price: storePrice,
  quantity,
  fall_below_quantity: fallBelowQuantity,
  replenish_quantity: replenishQuantity,
}) => ({
  id,
  storeId,
  placeId,
  name,
  price: storePrice,
  quantity,
  fallBelowQuantity,
  replenishQuantity,
});

const productTransformer = ({
  id,
  name,
  alias,
  brand,
  category,
  measurement,
  quantity,
  price,
  barcodes,
  stores,
  fall_below_quantity: fallBelowQuantity,
  replenish_quantity: replenishQuantity,
  image,
}) => ({
  id,
  name,
  alias,
  brand,
  category,
  measurement,
  fallBelowQuantity,
  replenishQuantity,
  barcodes: barcodes
    ? barcodes.map(barcode => barcode.number)
    : [],
  quantity: parseInt(quantity, 10).toString(),
  price: parseFloat(price).toFixed(2).toString(),
  prices: stores.map(mapStore),
  stores: stores.map(mapStore),
  image: image ? `${process.env.REACT_APP_IMG_BUCKET}/${image}` : null,
});

export default productTransformer;
