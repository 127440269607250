import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { useSelector } from 'react-redux';
import Container from '../components/Base/Container';
import SiteIndexScreen from './site';
import ScreenRegister from './site/register';
import ScreenLogin from './site/login';
import Default from '../defaults';
import Constants from '../constants';
import ScreenProfile from './site/app/profile';
import ScreenVerify from './site/verify';
import SnackbarStack from '../components/SnackbarStack';
import ScreenOrganisations from './site/app/organisations';
import ScreenOrganisationAdd from './site/app/organisation/add';
import ScreenOrganisationView from './site/app/organisation/view';

const ScreenHome = () => {
  const { authenticated } = useSelector(state => ({
    authenticated: state.authReducer.authenticated,
  }));

  return (
    <Container flex>
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        maxSnack={Default.maxSnack}
      >
        <SnackbarStack />
        { authenticated
          ? (
            <Switch>
              <Route
                path={`${Constants.routes.Organisations}${Constants.routes.Add}`}
                component={ScreenOrganisationAdd}
              />
              <Route
                path={`${Constants.routes.Organisations}/:id`}
                component={ScreenOrganisationView}
              />
              <Route
                path={Constants.routes.Organisations}
                component={ScreenOrganisations}
              />
              <Route
                path={Constants.routes.Profile}
                component={ScreenProfile}
              />
              <Route
                path={Constants.routes.Verify}
                component={ScreenVerify}
              />
              <Route path={Constants.routes.Home} component={SiteIndexScreen} />
            </Switch>
          )
          : (
            <Switch>
              <Route
                path={Constants.routes.Register}
                component={ScreenRegister}
              />
              <Route
                path={Constants.routes.Login}
                component={ScreenLogin}
              />
              <Route
                path={Constants.routes.Home}
                component={SiteIndexScreen}
              />
            </Switch>
          )}
      </SnackbarProvider>
    </Container>
  );
};

export default ScreenHome;
