import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { Instance, Resources } from '../../API';
import { actions, putMeasurements } from '../actions/Measurements';
import { snackbarDefaultError } from '../actions/Snackbar';

const measurementsIndexCall = async () => Instance.get(Resources.measurements);

/**
 * loadMeasurementsSaga
 * @returns {IterableIterator<*>}
 */
function* loadMeasurementsSaga() {
  try {
    const { data } = yield call(measurementsIndexCall);
    yield put(
      putMeasurements({
        data: data.data,
      }),
    );
  } catch (e) {
    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

export default function* Measurements() {
  yield all([
    takeLatest(actions.GET, loadMeasurementsSaga),
  ]);
}
