import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';
import { RestoreRounded } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import PriceInput from '../Base/Input/PriceInput';
import IconButton from '../Base/Button/IconButton/IconButton';
import Container from '../Base/Container';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
  },
  name: {
    flex: 1,
    fontWeight: 'bold',
  },
  price: {
    flex: 1,
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  },
}));

const ProductStoreDetailsRow = ({
  id, name, onChange, price, reset,
}) => {
  const classes = useStyles();

  const onChangePrice = amount => onChange(id, amount);

  const onReset = () => reset(id);

  return (
    <div className={classes.container}>
      <Typography className={classes.name}>{name}</Typography>
      <PriceInput
        className={classes.price}
        onChange={onChangePrice}
        value={price}
      />
      <Container>
        <Tooltip title="Reset to original price">
          <IconButton
            onPress={onReset}
            aria-label="reset"
            color="default"
          >
            <RestoreRounded />
          </IconButton>
        </Tooltip>
      </Container>
    </div>
  );
};

ProductStoreDetailsRow.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  price: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

export default ProductStoreDetailsRow;
