import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarMessageRemove } from '../providers/redux/actions/Snackbar';
import SnackbarCloseButton from './Snackbar/SnackbarCloseButton';

const SnackbarStack = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { messages } = useSelector(state => ({
    messages: state.snackbarReducer.messages,
  }));
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState([]);
  useEffect(() => {
    messages.forEach(({ key, message, options = {} }) => {
      if (notifications.includes(key)) return;
      enqueueSnackbar(message, {
        ...options,
        onClose: (event, reason, messageKey) => {
          if (options.onClose) {
            options.onClose(event, reason, key);
          }

          dispatch(snackbarMessageRemove({ key: messageKey }));
        },
        action: messageKey => <SnackbarCloseButton messageKey={messageKey} />,
      });

      setNotifications([...notifications, key]);
    });
  }, [messages]);

  return null;
};
export default SnackbarStack;
