const collectionProductTransformer = ({
  id,
  name,
  quantity,
  price,
  image,
}) => ({
  id,
  name,
  quantity: parseInt(quantity, 10).toString(),
  price: parseFloat(price).toFixed(2).toString(),
  image: image ? `${process.env.REACT_APP_IMG_BUCKET}/${image}` : null,
});

export default collectionProductTransformer;
