export const actions = {
  INITIALISED: 'INITIALISED',
  TITLE: {
    SET: 'SITE_TITLE_SET',
  },
  SIDEBAR: {
    TOGGLE: 'SITE_SIDEBAR_TOGGLE',
    OPEN: 'SITE_SIDEBAR_OPEN',
    CLOSE: 'SITE_SIDEBAR_CLOSE',
  },
};

export const siteInitialised = () => ({
  type: actions.INITIALISED,
});

export const siteSetTitle = ({ title }) => ({
  type: actions.TITLE.SET,
  payload: title,
});

export const siteToggleSidebar = () => ({ type: actions.SIDEBAR.TOGGLE });

export const siteCloseSidebar = () => ({ type: actions.SIDEBAR.CLOSE });

export const siteOpenSidebar = () => ({ type: actions.SIDEBAR.OPEN });
