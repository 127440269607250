import React from 'react';
import { Add } from '@material-ui/icons';
import PropTypes from 'prop-types';

import FAB from '../FAB';

const AddButton = ({ onClick, className, size }) => (
  <FAB
    onClick={onClick}
    color="secondary"
    label="Add"
    size={size || 'large'}
    className={className}
  >
    <Add />
  </FAB>
);

AddButton.defaultProps = {
  className: null,
  size: null,
};

AddButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  size: PropTypes.string,
};

export default AddButton;
