import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import Container from '../../../../components/Base/Container';
import NameInput from './Inputs/NameInput';
import useModelInteractorView
  from '../../../../providers/hooks/useModelInteractorView';
import ModelInteractorStyles
  from '../../../../components/Base/ModelInteractor/ModelInteractorStyles';
import fastCompare from '../../../../providers/fastCompare';
import {
  brandsDelete,
  brandsEditRequest,
} from '../../../../providers/redux/actions/Brands';
import ModelInteractorStandardView
  from '../../../../components/Base/ModelInteractor/ModelInteractorStandardView';

const BrandsView = () => {
  const dispatch = useDispatch();
  const {
    item, errors, processing, success,
  } = useModelInteractorView('brandsReducer');
  const classes = ModelInteractorStyles();

  const {
    id,
    name: brandName,
  } = item;

  const [name, setName] = useState(brandName);

  useEffect(() => {
    if (success) {
      document.activeElement.blur();
    }
  }, [success]);

  useEffect(() => {
    const {
      name: newBrandName,
    } = item;

    setName(newBrandName);
  }, [item]);

  const previous = {
    name: brandName,
  };

  const current = {
    name,
  };

  const isSame = fastCompare(previous, current);

  const edit = () => !isSame && dispatch(
    brandsEditRequest({
      id,
      name,
    }),
  );

  const remove = () => dispatch(brandsDelete({ id }));

  return (
    <ModelInteractorStandardView
      isSame={isSame}
      edit={edit}
      remove={remove}
      processing={processing}
      success={success}
    >
      <Container className={classes.container} flex horizontal center alignCenter>
        <NameInput
          onChange={setName}
          name={name}
          errors={errors.name}
          onEnter={edit}
        />
      </Container>
    </ModelInteractorStandardView>
  );
};

export default BrandsView;
