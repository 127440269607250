import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Dialog from '@material-ui/core/Dialog';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Container from '../../../components/Base/Container';
import MenuItem from '../../../components/Menu/MenuItem';
import PageHeader from '../../../components/PageHeader';
import CardScroll from '../../../components/CardScroll';
import Layout from '../../../components/Layout';
import Loader from '../../../components/Base/Loader';
import useNavigate from '../../../services/useNavigate';
import Constants from '../../../constants';
import { menuIndexRequest } from '../../../providers/redux/actions/Menu';

const useStyles = makeStyles(
  theme => ({
    container: {
      display: 'block',
    },
    content: {
      '& > *:first-child': {
        [theme.breakpoints.down('lg')]: {
          paddingLeft: theme.spacing(6.5),
        },
        [theme.breakpoints.up('lg')]: {
          paddingLeft: theme.spacing(12.5),
        },
      },
      '& > *:last-child': {
        [theme.breakpoints.down('lg')]: {
          paddingRight: theme.spacing(6.5),
        },
        [theme.breakpoints.up('lg')]: {
          paddingRight: theme.spacing(12.5),
        },
      },
    },
    item: {
      marginRight: theme.spacing(4),
    },
    category: {
      flex: 1,
    },
    categoryTitle: {
      marginTop: theme.spacing(2),
      paddingLeft: theme.spacing(12.5),
      paddingRight: theme.spacing(12.5),
      marginBottom: theme.spacing(),
    },
  }),
);

const ScreenMenu = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [menu, setMenu] = useState(null);
  const [open, setOpen] = useState(false);

  const { loading, data } = useSelector(
    state => ({
      loading: state.menuReducer.index.loading,
      data: state.menuReducer.index.data,
    }),
  );

  useEffect(() => {
    dispatch(
      menuIndexRequest(),
    );
  }, []);

  useEffect(() => {
    if (data) {
      const categories = {};
      data.forEach(
        (item) => {
          const key = item.category ? item.category.name : 'Uncategorised';
          if (!categories[key]) {
            categories[key] = [];
          }

          categories[key].push(item);
        },
      );

      setMenu(categories);
    }
  }, [data]);

  if (loading) {
    return (
      <Layout>
        <Loader />
      </Layout>
    );
  }

  if (!loading && (!menu || menu.length === 0)) {
    return (
      <Layout>
        No items found
      </Layout>
    );
  }

  const onPress = (id, resource) => navigate(`${resource}/${id}`);

  const onAdd = resource => navigate(`${resource}${Constants.routes.Add}`);

  const onOpen = () => setOpen(true);

  const onClose = () => setOpen(false);

  return (
    <Layout scroll>
      <Container className={classes.container} maxWidth>
        <PageHeader title="Your menu" action={onOpen} actionText="Add new" />
        <Dialog
          onClose={onClose}
          open={open}
        >
          <List>
            <ListItem button onClick={() => onAdd(Constants.routes.Sets)}>
              <ListItemText primary="Add a new set" />
            </ListItem>
            <ListItem button onClick={() => onAdd(Constants.routes.Foods)}>
              <ListItemText primary="Add a new menu item" />
            </ListItem>
          </List>
        </Dialog>
        <Container maxWidth>
          {Object.entries(menu).map(
            category => (
              <Container key={category[0]} className={classes.category} maxWidth>
                <Container className={classes.categoryTitle}>
                  <Typography variant="caption">
                    {category[0]}
                  </Typography>
                </Container>
                <Container maxWidth>
                  <CardScroll innerWrapperClass={classes.content}>
                    {category[1].map(
                      ({
                        id, name, resource, image,
                      }) => (
                        <MenuItem
                          key={id}
                          className={classes.item}
                          name={name}
                          onPress={() => onPress(id, resource)}
                          image={image}
                        />
                      ),
                    )}
                  </CardScroll>
                </Container>
              </Container>
            ),
          )}
        </Container>
      </Container>
    </Layout>
  );
};

export default ScreenMenu;
