const setTransformer = ({
  id, name, price, stores, options, image,
}) => ({
  id,
  name,
  price,
  stores,
  options: options.map(
    ({ choices, ...others }) => ({
      ...others,
      choices: choices.map(
        ({ food, ...choice }) => ({
          ...choice,
          foodId: food ? food.id : null,
          food,
        }),
      ),
    }),
  ),
  image: image ? `${process.env.REACT_APP_IMG_BUCKET}/${image}` : null,
});

export default setTransformer;
