import React, { forwardRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Input, InputLabel, FormControl, FormHelperText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
    container: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(),
    },
    counter: {
      textAlign: 'right',
    },
  }),
);

const TextInput = forwardRef(({
  inputStyle,
  className,
  color,
  shrink,
  errors,
  label,
  type,
  inputComponent,
  placeholder,
  value,
  onChange,
  onEnter,
  required,
  helperText,
  autoFocus,
  disabled,
  inputClassName,
  disableUnderline,
  maxLength,
}, ref) => {
  const classes = useStyles();
  return (
    <FormControl
      className={classNames(className, classes.container)}
      variant={inputStyle || 'standard'}
      required={required}
      fullWidth
    >
      {label && (
        <InputLabel
          style={{ color }}
          shrink={shrink || false}
          variant={inputStyle || 'standard'}
          error={!!errors}
        >
          {label}
        </InputLabel>
      )}
      <Input
        className={inputClassName}
        inputRef={ref}
        type={type}
        style={{ color }}
        onKeyPress={(e) => {
          if (onEnter && e.key === 'Enter') {
            onEnter();
          }
        }}
        inputComponent={inputComponent}
        placeholder={placeholder}
        onChange={event => onChange(event.target.value)}
        error={!!errors}
        variant={inputStyle || 'standard'}
        value={value}
        autoFocus={autoFocus}
        disabled={disabled}
        disableUnderline={disableUnderline}
      />
      {helperText && (<FormHelperText>{helperText}</FormHelperText>)}
      {maxLength !== 0 && (
        <FormHelperText
          className={classes.counter}
          error={value.length >= maxLength}
        >
          {`${value.length}/${maxLength}`}
        </FormHelperText>
      )}
      <FormHelperText error={!!errors}>{errors}</FormHelperText>
    </FormControl>
  );
});

TextInput.defaultProps = {
  label: null,
  className: undefined,
  placeholder: null,
  errors: null,
  shrink: null,
  inputComponent: undefined,
  inputStyle: undefined,
  color: undefined,
  type: undefined,
  required: false,
  onEnter: null,
  helperText: null,
  autoFocus: null,
  disabled: false,
  inputClassName: null,
  disableUnderline: false,
  maxLength: 0,
};

TextInput.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onEnter: PropTypes.func,
  errors: PropTypes.array,
  shrink: PropTypes.bool,
  inputComponent: PropTypes.func,
  inputStyle: PropTypes.string,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  inputClassName: PropTypes.string,
  disableUnderline: PropTypes.bool,
  maxLength: PropTypes.number,
};

export default TextInput;
