import {
  all,
  call,
  put,
  select,
  putResolve,
  takeLatest,
} from 'redux-saga/effects';
import { Instance, Resources, toFormData } from '../../API';
import {
  actions,
  setsDestroyErrors, setsDestroyReset,
  setsDestroySuccess,
  setsShowErrors, setsShowReset,
  setsShowSuccess,
  setsStoreErrors,
  setsStoreReset,
  setsStoreSuccess,
  setsUpdateErrors,
  setsUpdateReset,
  setsUpdateSuccess,
} from '../actions/Sets';
import {
  snackbarDefaultError,
  snackbarDefaultSuccess,
} from '../actions/Snackbar';
import hasErrors from '../../hasErrors';
import setTransformer from '../transformers/SetTransformer';

const resource = Resources.sets;

const callSetStore = (
  organisationId,
  name,
  price,
  {
    image,
    prices,
    options,
  },
) => {
  const payload = {
    organisation_id: organisationId,
    name,
    image,
    price,
    prices: prices.map(
      ({ storeId, price: storePrice }) => ({
        store_id: storeId,
        amount: storePrice,
      }),
    ),
    options: options.map(
      ({ choices, ...others }) => ({
        ...others,
        choices: choices.map(
          ({ foodId, ...choice }) => ({
            ...choice,
            food_id: foodId,
          }),
        ),
      }),
    ),
  };

  return Instance.post(
    resource,
    toFormData(payload),
  );
};

export const callSetShow = id => Instance.get(`${resource}/${id}`);

export const callSetUpdate = (
  id,
  {
    name,
    image,
    price,
    prices,
    options,
  },
) => {
  const payload = {
    name,
    image,
    price,
    prices: prices.map(
      ({ storeId, price: storePrice }) => ({
        store_id: storeId,
        amount: storePrice,
      }),
    ),
    options: options.map(
      ({ choices, ...others }) => ({
        ...others,
        choices: choices.map(
          ({ foodId, ...choice }) => ({
            ...choice,
            food_id: foodId,
          }),
        ),
      }),
    ),
  };

  const data = toFormData(payload);
  data.append('_method', 'PUT');
  return Instance.post(`${resource}/${id}`, data);
};

export const callSetDestroy = id => Instance.delete(
  `${resource}/${id}`,
);

const dataSite = state => ({
  organisationId: state.organisationReducer.data.id,
});

function* sagaSetStore({
  payload: {
    name,
    image,
    price,
    prices,
    options,
    categoryId,
  },
}) {
  try {
    const filteredOptions = options.filter(
      ({ name: optionName }) => optionName !== '',
    ).map(
      ({ choices, ...others }) => ({
        ...others,
        choices: choices.filter(
          ({ name: choiceName, foodId }) => choiceName !== '' || foodId !== null,
        ),
      }),
    );

    const { organisationId } = yield select(dataSite);
    const { data } = yield call(
      callSetStore,
      organisationId,
      name,
      image,
      price,
      {
        prices,
        options: filteredOptions.filter(
          ({ name: optionName }) => optionName !== '',
        ),
        categoryId,
      },
    );

    yield putResolve(
      setsStoreSuccess(),
    );

    yield put(
      snackbarDefaultSuccess({ data }),
    );

    yield put(
      setsStoreReset(),
    );
  } catch (e) {
    const errors = hasErrors(e);
    if (errors) {
      yield put(
        setsStoreErrors(errors),
      );
    }
    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

function* sagaSetUpdate({
  payload: {
    id,
    name,
    image,
    categoryId,
    prices,
    price,
    options,
  },
}) {
  try {
    const { data } = yield call(
      callSetUpdate,
      id,
      {
        name,
        image,
        categoryId,
        price,
        prices,
        options,
      },
    );

    const { data: setData } = data;
    const set = setTransformer(setData);

    yield putResolve(
      setsShowSuccess(set),
    );

    yield putResolve(
      setsUpdateSuccess(),
    );

    yield put(
      snackbarDefaultSuccess({ data }),
    );

    yield put(
      setsUpdateReset(),
    );
  } catch (e) {
    const errors = hasErrors(e);
    if (errors) {
      yield put(
        setsUpdateErrors(errors),
      );
    }
    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

function* sagaSetDestroy({ payload: id }) {
  try {
    const { data } = yield call(callSetDestroy, id);

    yield put(
      setsDestroySuccess(),
    );

    yield put(
      snackbarDefaultSuccess({ data }),
    );

    yield putResolve(
      setsShowReset(),
    );

    yield putResolve(
      setsDestroyReset(),
    );
  } catch (e) {
    const errors = hasErrors(e);
    if (errors) {
      yield put(
        setsDestroyErrors(errors),
      );
    }
    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

function* sagaSetShow({ payload: id }) {
  try {
    const { data } = yield call(
      callSetShow,
      id,
    );

    const { data: setData } = data;
    yield putResolve(
      setsShowSuccess(
        setTransformer(setData),
      ),
    );
  } catch (e) {
    const errors = hasErrors(e);
    if (errors) {
      yield put(
        setsShowErrors(errors),
      );
    }

    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

export default function* Sets() {
  yield all([
    takeLatest(actions.STORE.REQUEST, sagaSetStore),
    takeLatest(actions.UPDATE.REQUEST, sagaSetUpdate),
    takeLatest(actions.DESTROY.REQUEST, sagaSetDestroy),
    takeLatest(actions.SHOW.REQUEST, sagaSetShow),
  ]);
}
