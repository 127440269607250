import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dialog as MaterialDialog, makeStyles } from '@material-ui/core';

import Container from '../../../../components/Base/Container';
import NameInput from './Inputs/NameInput';
import CloseIconButton from '../../../../components/Base/Button/CloseIcon';
import { suppliersCreateRequest } from '../../../../providers/redux/actions/Suppliers';
import ModelInteractorCreateActions
  from '../../../../components/Base/ModelInteractor/Components/ModelInteractorCreateActions';

const styles = makeStyles(theme => ({
  dialogPaper: {
    overflow: 'visible',
  },
  content: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

const SuppliersCreate = ({
  open,
  close,
  processing,
  success,
  errors,
  suppliersCreateRequest: suppliersCreateRequestAction,
}) => {
  const classes = styles();
  const [name, setName] = useState('');

  const reset = () => {
    setName('');
  };

  useEffect(() => {
    if (open && success) {
      close();
      reset();
    }
  }, [success]);

  const submit = () => suppliersCreateRequestAction({ name });

  return (
    <MaterialDialog
      maxWidth="sm"
      className="dialog"
      PaperProps={{ className: classes.dialogPaper }}
      open={open}
      aria-labelledby="form-dialog-title"
      scroll="body"
      onClose={close}
      fullWidth
    >
      <Container className="dialog-title" flex alignCenter>
        <Container className="dialog-title-close">
          <CloseIconButton onClick={close} />
        </Container>
        <Container>Add a new supplier</Container>
      </Container>
      <Container className={classes.content}>
        <NameInput
          onChange={setName}
          errors={errors.name}
          name={name}
          onEnter={submit}
        />
      </Container>
      <ModelInteractorCreateActions
        onClick={submit}
        processing={processing}
      />
    </MaterialDialog>
  );
};

SuppliersCreate.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  processing: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  suppliersCreateRequest: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  success: state.suppliersReducer.create.success,
  processing: state.suppliersReducer.create.processing,
  errors: state.suppliersReducer.create.errors,
});

export default connect(mapStateToProps, { suppliersCreateRequest })(SuppliersCreate);
