import React from 'react';
import Container from '../../../Base/Container';

const Row = props => (
  <Container flex>
    <Container flex>{props.email}</Container>
    <Container flex>{props.status}</Container>
    <Container className="invitation-cancel" flex />
  </Container>
);

export default Row;
