import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from '@material-ui/core';

import Loader from '../../Loader';
import Container from '../../Container';

const Approval = ({
  open, closeDialog, title, triggerEvent, content, processing,
}) => (
  <Dialog
    open={open}
    onClose={closeDialog}
    maxWidth="xs"
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    fullWidth
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {content}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      {
        !processing
          ? (
            <React.Fragment>
              <Button href={null} onClick={closeDialog} color="primary">
                Cancel
              </Button>
              <Button
                href={null}
                variant="contained"
                onClick={triggerEvent}
                color="primary"
                autoFocus
              >
                Go ahead
              </Button>
            </React.Fragment>
          )
          : (
            <Container>
              <Loader />
            </Container>
          )
      }
    </DialogActions>
  </Dialog>
);

Approval.defaultProps = {
  processing: false,
};

Approval.propTypes = {
  open: PropTypes.bool.isRequired,
  processing: PropTypes.bool,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  closeDialog: PropTypes.func.isRequired,
  triggerEvent: PropTypes.func.isRequired,
};

export default Approval;
