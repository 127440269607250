import { actions } from '../../actions/Stores/Products';

const initialState = {
  page: 1,
  index: {
    loading: false,
    data: [],
    search: '',
    errors: {},
  },
};

const storesProductsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.INDEX.REQUEST:
      return {
        ...state,
        index: {
          ...state.index,
          loading: true,
        },
      };
    case actions.INDEX.SUCCESS:
      return {
        ...state,
        index: {
          ...state.index,
          loading: false,
          data: action.payload,
        },
      };
    case actions.INDEX.ERRORS:
      return {
        ...state,
        index: {
          ...state.index,
          loading: false,
          errors: action.payload,
        },
      };
    case actions.INDEX.RESET:
      return {
        ...state,
        index: {
          ...initialState.index,
        },
      };
    case actions.PAGE.NEXT:
      return {
        ...state,
        page: state.page + 1,
      };
    default:
      return state;
  }
};

export default storesProductsReducer;
