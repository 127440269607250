import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 'bold',
  },
}));

const Title = ({ className, children }) => {
  const classes = useStyles();
  return (
    <Typography
      className={classNames([classes.title, className])}
      variant="h4"
    >
      { children }
    </Typography>
  );
};

Title.defaultProps = {
  className: null,
};

Title.propTypes = {
  className: PropTypes.string,
  children: PropTypes.string.isRequired,
};

export default Title;
