import React from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import MapStoreMarker from './MapStoreMarker';
import Constants from '../../constants';

const MapStore = ({ stores, darkMode }) => {
  const createMapOptions = () => ({
    styles: darkMode ? Constants.maps.styles.night : Constants.maps.styles.day,
    mapTypeControl: false,
    disableDefaultUI: true,
    clickableIcons: false,
    restriction: {
      latLngBounds: {
        west: 103.580024,
        east: 104.074085,
        north: 1.555950,
        south: 1.064916,
      },
      strictBounds: true,
    },
  });

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: process.env.REACT_APP_MAPS_API_KEY }}
      defaultCenter={{
        lat: 1.3376173,
        lng: 103.8039242,
      }}
      defaultZoom={12}
      options={createMapOptions}
      resetBoundsOnResize
    >
      {stores.map(
        ({
          id,
          name,
          revenue,
          transactionsCount,
          alert,
          latitude: storeLatitude,
          longitude: storeLongitude,
        }) => (
          <MapStoreMarker
            key={id}
            lat={storeLatitude}
            lng={storeLongitude}
            revenue={revenue}
            transactionsCount={transactionsCount}
            id={id}
            name={name}
            status={alert}
            darkMode={darkMode}
          />
        ),
      )}
    </GoogleMapReact>
  );
};

MapStore.defaultProps = {
  darkMode: false,
  stores: [],
};

MapStore.propTypes = {
  darkMode: PropTypes.bool,
  stores: PropTypes.array,
};

export default MapStore;
