import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import Container from '../../components/Base/Container';
import { displaysSync } from '../../providers/redux/actions/Displays';
import DisplayItem from '../../components/Display/DisplayItem';
import DisplayFooter from '../../components/Display/DisplayFooter';
import { siteCloseSidebar } from '../../providers/redux/actions/Site';
import DropdownStore from '../../components/Dropdown/DropdownStore';
import DisplayItemHeader from '../../components/Display/DisplayItemHeader';

const useStyles = makeStyles(theme => ({
  items: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  },
  select: {
    display: 'inline-flex',
    margin: 'auto',
    marginTop: theme.spacing(2),
  },
  footer: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  itemsContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
}));

const ScreenDisplay = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { stores, store: storeTotal, data } = useSelector(state => ({
    stores: state.authReducer.user.stores,
    store: state.displaysReducer.store,
    data: state.displaysReducer.data,
  }));

  const [store, setStore] = useState(stores && stores.length > 0
    ? stores[0].id
    : null);

  const {
    voucher, discount, total, change,
  } = storeTotal;

  const onMount = () => {
    dispatch(
      displaysSync(store),
    );

    dispatch(
      siteCloseSidebar(),
    );
  };

  useEffect(() => {
    onMount();
  }, [store]);

  return (
    <Container flex horizontal>
      { stores && stores.length > 1 && (
        <Container className={classes.select}>
          <DropdownStore onChange={setStore} value={store} />
        </Container>
      )}
      <Container className={classes.items} flex horizontal>
        <DisplayItemHeader />
        <Container className={classes.itemsContent} scroll>
          {data.map(({
            id, name, quantity, total: itemTotal,
          }) => (
            <DisplayItem
              key={id}
              total={itemTotal}
              name={name}
              quantity={quantity}
            />
          ))}
        </Container>
      </Container>
      <DisplayFooter
        className={classes.footer}
        voucher={voucher}
        total={total}
        discount={discount}
        change={change}
      />
    </Container>
  );
};

export default ScreenDisplay;
