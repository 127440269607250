const paymentTransformer = ({
  token,
  brand,
  last_four: lastFour,
  expiry_year: expiryYear,
  expiry_month: expiryMonth,
  default: isDefault,
}) => ({
  token,
  brand,
  lastFour,
  expiryMonth,
  expiryYear,
  isDefault,
});

export default paymentTransformer;
