import moment from 'moment';

const movementTransformer = ({
  id,
  product,
  from_store: fromStore,
  to_store: toStore,
  from_user: fromUser,
  to_user: toUser,
  quantity,
  created_at: createdAt,
  updated_at: updatedAt,
}) => ({
  id,
  product: product ? product.name : null,
  fromStore: fromStore.name,
  toStore: toStore ? toStore.name : null,
  fromUser: `${fromUser.first_name} ${fromUser.last_name}`,
  toUser: toUser ? toUser.name : null,
  quantity,
  createdAt: moment(createdAt),
  updatedAt: moment(updatedAt),
});

export default movementTransformer;
