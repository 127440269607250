import React from 'react';
import PropTypes from 'prop-types';
import { ArrowBack } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

const ButtonBack = ({ onPress }) => (
  <IconButton onClick={onPress}>
    <ArrowBack />
  </IconButton>
);

ButtonBack.propTypes = {
  onPress: PropTypes.func.isRequired,
};

export default ButtonBack;
