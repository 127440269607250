import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import Container from '../Base/Container';
import UserDropdown from './UserDropdown';
import Logo from '../Logo';
import MenuIconButton from '../Base/Button/IconButton/MenuIconButton';
import {
  siteToggleSidebar,
} from '../../providers/redux/actions/Site';

const styles = makeStyles(theme => ({
  header: {
    ...theme.mixins.fab,
    boxShadow: '0 2px 60px rgba(223,223,223,0.25)',
    height: 78,
  },
  headerContainer: {
    paddingLeft: theme.spacing(3),
  },
  titleText: {
    fontSize: 20,
    paddingLeft: theme.spacing(2),
  },
  logo: {
    marginLeft: theme.spacing(),
  },
  user: {
    margin: 0,
    justifyContent: 'flex-end',
  },
}));

const Header = () => {
  const classes = styles();
  const dispatch = useDispatch();
  const { sidebar } = useSelector(state => ({
    sidebar: state.siteReducer.sidebar,
  }));
  const toggle = () => dispatch(siteToggleSidebar());
  return (
    <Container className={classes.header}>
      <Container className={classes.headerContainer} flex alignCenter maxHeight>
        <Container alignCenter flex>
          {!sidebar && (
            <MenuIconButton
              className={classes.menu}
              onClick={toggle}
            />
          )}
          {sidebar && (<Logo className={classes.logo} size={48} color />)}
        </Container>
        <Container className={classes.user} flex>
          <UserDropdown />
        </Container>
      </Container>
    </Container>
  );
};

export default Header;
