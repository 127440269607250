import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { Typography } from '@material-ui/core';

const FormatPrice = ({
  className, variant, children, decimalScale,
}) => (
  <NumberFormat
    prefix="$"
    displayType="text"
    decimalScale={decimalScale}
    renderText={value => (
      <Typography className={className} variant={variant}>{value}</Typography>
    )}
    value={children}
    fixedDecimalScale
    thousandSeparator
  />
);

FormatPrice.defaultProps = {
  className: null,
  variant: null,
  decimalScale: 2,
};

FormatPrice.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  variant: PropTypes.string,
  className: PropTypes.string,
  decimalScale: PropTypes.number,
};

export default FormatPrice;
