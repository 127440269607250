import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme, Zoom } from '@material-ui/core';
import PropTypes from 'prop-types';

import Loader from '../../Loader';
import Approval from '../../Dialog/Approval';
import DoneButton from '../../Button/Done';
import DeleteButton from '../../Button/Delete';

const useStyles = makeStyles(theme => ({
  fab: {
    bottom: 0,
    right: 0,
    margin: theme.spacing(3),
  },
  toolbarContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  success: {
    color: theme.palette.common.white,
    backgroundColor: theme.status.success[500],
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.status.success[600],
    },
  },
}));

const Toolbar = ({
  edit, fixed, fireEdit, fireDelete, processing, destroyContent,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open && !processing) {
      setOpen(false);
    }
  }, [processing]);

  const editFunction = async () => {
    setLoading(true);
    await fireEdit();
    setLoading(false);
  };

  const deleteFunction = async () => {
    setLoading(true);
    await fireDelete();
    setLoading(false);
  };

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const style = {
    transitionDelay: `${!edit ? transitionDuration.exit : 0}ms`,
  };

  return (
    <React.Fragment>
      <Approval
        open={open}
        title="Delete"
        content={destroyContent}
        closeDialog={() => setOpen(false)}
        triggerEvent={deleteFunction}
        processing={processing}
      />
      <Zoom
        in={edit && !processing}
        timeout={transitionDuration}
        style={style}
        unmountOnExit
      >
        <DoneButton
          key="done"
          className={classes.fab}
          style={{ position: fixed ? 'fixed' : 'absolute' }}
          size="large"
          onClick={editFunction}
          label="Edit"
          disabled={loading}
        />
      </Zoom>
      <Zoom
        in={!edit && !processing}
        timeout={transitionDuration}
        style={style}
        unmountOnExit
      >
        <DeleteButton
          key="delete"
          className={classes.fab}
          style={{ position: fixed ? 'fixed' : 'absolute' }}
          onClick={() => setOpen(true)}
          size="large"
          disabled={loading}
          label="Delete"
        />
      </Zoom>
      <Zoom
        in={processing}
        timeout={transitionDuration}
        style={style}
        unmountOnExit
      >
        <Loader
          className={classes.fab}
          style={{ position: fixed ? 'fixed' : 'absolute' }}
        />
      </Zoom>
    </React.Fragment>
  );
};

Toolbar.defaultProps = {
  fixed: false,
  destroyContent: 'Are you sure you want to delete this?',
  fireDelete: null,
};

Toolbar.propTypes = {
  edit: PropTypes.bool.isRequired,
  fireEdit: PropTypes.func.isRequired,
  fireDelete: PropTypes.func,
  fixed: PropTypes.bool,
  processing: PropTypes.bool.isRequired,
  destroyContent: PropTypes.string,
};

export default Toolbar;
