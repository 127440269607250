import {
  all,
  call,
  fork,
  put,
  putResolve,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import firebase from 'firebase';
import {
  actions,
  authEditErrors,
  authEditSuccess,
  authEmailVerificationFailed,
  authEmailVerified,
  authLoading,
  authSetPermissions,
  authUserPut,
  unverified,
  verified,
} from '../actions/Auth';
import {
  forgetTokens, Instance, Resources, setLoginTokens,
} from '../../API';
import Default from '../../../defaults';
import { getMeasurements } from '../actions/Measurements';
import {
  snackbarClear,
  snackbarDefaultError,
  snackbarDefaultSuccess,
} from '../actions/Snackbar';
import {
  actions as organisationActions,
} from '../actions/Organisation';
import hasErrors from '../../hasErrors';
import { goodsIndexRequest } from '../actions/Goods';

const loginCall = async ({
  username,
  password,
}) => Instance.post(Resources.login, {
  grant_type: 'password',
  username,
  password,
  client_id: Resources.clientId,
  client_secret: Resources.clientSecret,
});

const logoutCall = async () => Instance.delete(Resources.logout);

const resendCall = async () => Instance.get(Resources.resend);

const userCall = async () => Instance.get(Resources.user);

const userUpdateCall = async ({
  email,
  password,
  confirmPassword,
  firstName,
  lastName,
  phoneNo,
}) => Instance.put(
  Resources.user,
  {
    email,
    password,
    password_confirmation: confirmPassword,
    first_name: firstName,
    last_name: lastName,
    phone_no: phoneNo,
  },
);

const permissionsCall = async ({ organisationId }) => Instance.get(
  Resources.permissions,
  { params: { organisation_id: organisationId } },
);

const verifyEmailCall = async ({
  expires,
  hash,
  id,
  signature,
}) => Instance.get(
  Resources.verify,
  {
    params: {
      expires,
      hash,
      id,
      signature,
    },
  },
);

export const authGetFirebaseTokenCall = () => Instance.get(
  Resources.firebaseToken,
);

const firebaseSignIn = token => firebase.auth().signInWithCustomToken(token);

const authData = state => ({
  authenticated: state.authReducer.authenticated,
});

const siteData = state => ({
  organisation: state.organisationReducer.data,
});

function* firebaseSignInSaga(firebaseToken) {
  yield call(firebaseSignIn, firebaseToken);
}

/**
 * unverifiedSaga
 * @returns {IterableIterator<*>}
 */
function* unverifiedSaga() {
  forgetTokens();
  yield put(unverified());
}

/**
 * verifyUserSaga
 * @returns {IterableIterator<*>}
 */
function* verifyUserSaga() {
  const token = localStorage.getItem(Default.tokenKey);
  if (!token) {
    yield call(unverifiedSaga);
    return;
  }

  try {
    const user = yield call(userCall);
    if (!user.data.data) {
      yield call(unverifiedSaga);
      return;
    }

    const {
      data: {
        data: {
          token: firebaseToken,
        },
      },
    } = yield call(authGetFirebaseTokenCall);

    yield call(firebaseSignInSaga, firebaseToken);

    yield putResolve(
      goodsIndexRequest(),
    );

    yield put(
      snackbarClear(),
    );

    const { organisation } = yield select(siteData);
    if (organisation && organisation.id) {
      const {
        data: { data: permissions },
      } = yield call(
        permissionsCall,
        { organisationId: organisation.id },
      );

      yield put(
        authSetPermissions({ permissions }),
      );
    }

    const {
      id,
      email,
      first_name: firstName,
      last_name: lastName,
      phone_no: phoneNo,
      stores,
      warehouses,
      places,
      roles,
      email_verified_at: emailVerifiedAt,
    } = user.data.data;

    const userVerified = emailVerifiedAt !== undefined
      && emailVerifiedAt !== null;

    yield put(
      verified({
        authenticated: user.status === 200,
        user: {
          id,
          email,
          firstName,
          lastName,
          phoneNo,
          stores,
          warehouses,
          places,
          roles,
          verified: userVerified,
        },
        token,
      }),
    );

    if (userVerified) {
      yield put(
        getMeasurements(),
      );
    }
  } catch (error) {
    yield call(unverifiedSaga);
  }
}

/**
 * loginSaga
 * @param username
 * @param password
 * @returns {IterableIterator<*>}
 */
function* loginSaga({ payload: { username, password } }) {
  yield putResolve(
    authLoading(),
  );

  try {
    const response = yield call(
      loginCall,
      {
        username,
        password,
      },
    );

    setLoginTokens(response);
  } catch (error) {
    yield put(
      snackbarDefaultError({ e: error }),
    );
  }

  yield call(verifyUserSaga);
}

/**
 * logoutSaga
 * @returns {IterableIterator<*>}
 */
function* logoutSaga() {
  yield put(snackbarClear());
  yield call(logoutCall);
  yield call(unverifiedSaga);
}

function* updateProfileSaga({
  payload: {
    email,
    password,
    confirmPassword,
    firstName,
    lastName,
    phoneNo,
  },
}) {
  try {
    const { data } = yield call(
      userUpdateCall,
      {
        email,
        password,
        confirmPassword,
        firstName,
        lastName,
        phoneNo,
      },
    );

    const {
      email: userEmail,
      first_name: userFirstName,
      last_name: userLastName,
      phone_no: userPhoneNo,
    } = data.data;

    yield put(
      authUserPut({
        user: {
          email: userEmail,
          firstName: userFirstName,
          lastName: userLastName,
          phoneNo: userPhoneNo,
        },
      }),
    );

    yield put(snackbarDefaultSuccess({ data }));
    yield put(authEditSuccess());
  } catch (e) {
    const errors = hasErrors(e);
    if (errors) {
      yield put(
        authEditErrors({ errors }),
      );
    }
    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

function* verifyEmailSaga({
  payload: {
    expires,
    hash,
    id,
    signature,
  },
}) {
  try {
    const { authenticated } = yield select(authData);

    if (authenticated) {
      const { data } = yield call(
        verifyEmailCall,
        {
          expires,
          hash,
          id,
          signature,
        },
      );

      yield put(snackbarDefaultSuccess({ data }));
      yield put(
        authUserPut({
          user: {
            verified: true,
          },
        }),
      );
      yield put(
        authEmailVerified(),
      );
    }
  } catch (e) {
    yield put(authEmailVerificationFailed());
    yield put(snackbarDefaultError({ e }));
  }
}

function* resendSaga() {
  try {
    const { data } = yield call(resendCall);
    yield put(snackbarDefaultSuccess({ data }));
  } catch (e) {
    yield put(snackbarDefaultError({ e }));
  }
}

function* verifyOrganisationLoadSaga() {
  while (true) {
    yield take(organisationActions.LOADED);
    yield call(verifyUserSaga);
  }
}

export default function* Auth() {
  yield all([
    takeLatest(actions.LOGIN, loginSaga),
    takeLatest(actions.LOGOUT, logoutSaga),
    takeLatest(actions.VERIFY, verifyUserSaga),
    takeLatest(actions.EDIT.REQUEST, updateProfileSaga),
    takeLatest(actions.EMAIL.VERIFY, verifyEmailSaga),
    takeLatest(actions.EMAIL.RESEND, resendSaga),
    fork(verifyOrganisationLoadSaga),
  ]);
}
