import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';
import Container from './Base/Container';
import Title from './Title';
import ButtonGlow from './Buttons/ButtonGlow';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down('lg')]: {
      paddingLeft: theme.spacing(6.5),
      paddingRight: theme.spacing(6.5),
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(12.5),
      paddingRight: theme.spacing(12.5),
    },
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    display: 'inline-block',
  },
  subtitle: {
    marginTop: theme.spacing(),
    maxWidth: 400,
    width: '100%',
  },
  subtitleText: {
    color: 'rgba(0,0,0,.54)',
  },
  button: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(6),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
}));

const PageHeader = ({
  title, subtitle, action, actionText,
}) => {
  const classes = useStyles();
  return (
    <Container className={classes.container}>
      <Container className={classes.header}>
        <Title className={classes.title}>{ title }</Title>
        {subtitle && (
          <Container className={classes.subtitle}>
            <Typography className={classes.subtitleText} variant="caption">
              { subtitle }
            </Typography>
          </Container>
        )}
      </Container>
      { action && (
        <ButtonGlow
          className={classes.button}
          onPress={action}
          color="secondary"
        >
          { actionText }
        </ButtonGlow>
      )}
    </Container>
  );
};

PageHeader.defaultProps = {
  action: null,
  actionText: null,
  subtitle: null,
};

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  action: PropTypes.func,
  actionText: PropTypes.string,
  subtitle: PropTypes.string,
};

export default PageHeader;
