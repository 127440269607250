const displayItemTransformer = (item) => {
  const { name, quantity, total } = item.data();

  return {
    id: item.id,
    name,
    quantity,
    total,
  };
};

export default displayItemTransformer;
