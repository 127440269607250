import React from 'react';
import PropTypes from 'prop-types';
import {
  InputAdornment,
  InputLabel,
  FormControl,
  Input,
  Select,
  MenuItem,
  makeStyles,
} from '@material-ui/core';

const styles = makeStyles(theme => ({
  container: {
    width: '100%',
    maxWidth: 250,
    paddingRight: theme.spacing(4),
  },
}));

const SearchFiltersSelect = ({
  name, startAdornment, filters, onFiltersChange, selected,
}) => {
  const classes = styles();
  return (
    <FormControl className={classes.container}>
      {name && <InputLabel htmlFor="select-single">{name}</InputLabel>}
      <Select
        value={selected}
        onChange={event => onFiltersChange(event.target.value)}
        input={(
          <Input
            startAdornment={(
              <InputAdornment position="start">
                {startAdornment}
              </InputAdornment>
            )}
            id="select-single"
          />
        )}
        displayEmpty
      >
        {filters.map((filter, index) => (
          <MenuItem key={filter} value={index}>
            {filter}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

SearchFiltersSelect.defaultProps = {
  name: null,
  startAdornment: null,
  selected: '',
};

SearchFiltersSelect.propTypes = {
  name: PropTypes.string,
  startAdornment: PropTypes.element,
  filters: PropTypes.array.isRequired,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onFiltersChange: PropTypes.func.isRequired,
};

export default SearchFiltersSelect;
