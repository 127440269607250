const warehouseTransformer = ({
  name,
  latitude,
  longitude,
  opening_time: openingTime,
  closing_time: closingTime,
  place,
}) => ({
  name,
  placeId: place ? place.id : null,
  latitude,
  longitude,
  openingTime,
  closingTime,
});

export default warehouseTransformer;
