import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

import { ReactComponent as IconMenu } from '../../../../assets/icons/ic-menu.svg';
import IconButton from './IconButton';

const useStyles = makeStyles(theme => ({
  icon: {
    ...theme.icon,
    fill: '#555555',
  },
}));

const MenuIconButton = ({ onClick, className }) => {
  const classes = useStyles();
  return (
    <IconButton
      className={className}
      onPress={onClick}
    >
      <IconMenu className={classes.icon} />
    </IconButton>
  );
};

MenuIconButton.defaultProps = {
  className: null,
};

MenuIconButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default MenuIconButton;
