import { actions } from '../../../actions/Places/Products/Inventories';

const initialState = {
  index: {
    placeId: null,
    loading: false,
    data: [],
    search: '',
    errors: {},
  },
  destroy: {
    loading: false,
    success: false,
    errors: {},
  },
};

const placeProductInventoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.INDEX.REQUEST:
      return {
        ...state,
        index: {
          ...state.index,
          loading: true,
        },
      };
    case actions.INDEX.SUCCESS:
      return {
        ...state,
        index: {
          ...state.index,
          loading: false,
          placeId: action.payload.placeId,
          data: action.payload.data,
        },
      };
    case actions.INDEX.ERRORS:
      return {
        ...state,
        index: {
          ...state.index,
          loading: false,
          errors: action.payload,
        },
      };
    case actions.INDEX.RESET:
      return {
        ...state,
        index: {
          ...initialState.index,
        },
      };
    case actions.DESTROY.REQUEST:
      return {
        ...state,
        destroy: {
          ...state.destroy,
          loading: true,
        },
      };
    case actions.DESTROY.SUCCESS:
      return {
        ...state,
        destroy: {
          ...state.destroy,
          loading: false,
          success: true,
        },
      };
    case actions.DESTROY.ERRORS:
      return {
        ...state,
        destroy: {
          ...state.destroy,
          loading: false,
          success: false,
          errors: action.payload,
        },
      };
    case actions.DESTROY.RESET:
      return {
        ...state,
        destroy: {
          ...initialState.destroy,
        },
      };
    default:
      return state;
  }
};

export default placeProductInventoriesReducer;
