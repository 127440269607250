import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import Container from '../../Base/Container';
import StatisticsValue from '../../Statistics/StatisticsValue';

const StoreCardValue = ({
  classes, isClosed, isOpening, transactionsCount, settlement, openingTime,
}) => {
  if (isClosed && isOpening) {
    return (
      <Container className={classes.valueClosed}>
        <Typography variant="h6">
          {moment().isSameOrAfter(openingTime)
            ? 'Opening soon'
            : `Opening in ${openingTime.fromNow()}`
          }
        </Typography>
      </Container>
    );
  }

  if (isClosed && !isOpening) {
    return (
      <StatisticsValue
        className={classes.valueClosed}
        valueClassName={classes.cash}
        valueVariant="h6"
        value={settlement.cash}
        label="Cash tally"
      />
    );
  }

  return (
    <StatisticsValue
      className={classes.value}
      valueClassName={classes.amount}
      valueVariant="h6"
      type="number"
      value={transactionsCount}
      label="Transactions"
    />
  );
};

StoreCardValue.defaultProps = {
  transactionsCount: null,
  settlement: null,
  openingTime: null,
};

StoreCardValue.propTypes = {
  classes: PropTypes.object.isRequired,
  isClosed: PropTypes.bool.isRequired,
  isOpening: PropTypes.bool.isRequired,
  transactionsCount: PropTypes.number,
  settlement: PropTypes.object,
  openingTime: PropTypes.object,
};

export default StoreCardValue;
