import * as React from 'react';
import { BrokenImageRounded } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(
  theme => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    brokenIcon: {
      fontSize: 100,
      color: theme.palette.error.main,
    },
    text: {
      margin: theme.spacing(2),
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
      textAlign: 'center',
    },
  }),
);

const ServerUnreachable = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <BrokenImageRounded className={classes.brokenIcon} />
      <Typography className={classes.text}>
        Something went terribly wrong. Try refreshing the page again.
        If the error persists, please contact our administrators.
      </Typography>
    </div>
  );
};

export default ServerUnreachable;
