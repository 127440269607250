import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog as MaterialDialog, makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';

import Container from '../../../../components/Base/Container';
import NameInput from './NameInput';
import BrandInput from './Inputs/BrandInput';
import PriceInput from '../../../../components/Base/Input/PriceInput';
import CloseIconButton from '../../../../components/Base/Button/CloseIcon';
import CategoryInput from '../../../../components/CategoryInput';
import MeasurementDropdown from '../../../../components/Base/Input/MeasurementDropdown';
import AliasInput from './AliasInput';
import { productsCreateRequest } from '../../../../providers/redux/actions/Products';
import ModelInteractorCreateActions
  from '../../../../components/Base/ModelInteractor/Components/ModelInteractorCreateActions';
import ImagePicker from '../../../../components/Base/Image/ImagePicker';

const styles = makeStyles(theme => ({
  dialogPaper: {
    overflow: 'visible',
  },
  content: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
  },
  inputs: {
    paddingLeft: theme.spacing(2),
  },
}));

const ProductsCreate = ({
  open,
  close,
  processing,
  success,
  errors,
  productsCreate: productsCreateAction,
}) => {
  const classes = styles();

  const [name, setName] = useState('');
  const [image, setImage] = useState(null);
  const [alias, setAlias] = useState('');
  const [price, setPrice] = useState('');
  const [brand, setBrand] = useState(null);
  const [category, setCategory] = useState(null);
  const [barcodes, setBarcodes] = useState(['']);
  const [measurement, setMeasurement] = useState(null);

  const reset = () => {
    setName('');
    setAlias('');
    setPrice('');
    setBrand(null);
    setCategory(null);
    setBarcodes(['']);
    setMeasurement(null);
  };

  useEffect(() => {
    if (success) {
      document.activeElement.blur();
      close();
      reset();
    }
  }, [success]);

  const create = () => productsCreateAction({
    name,
    alias,
    price,
    brand,
    category,
    barcodes,
    measurement,
    image,
  });

  return (
    <MaterialDialog
      maxWidth="sm"
      className="dialog"
      PaperProps={{
        className: classes.dialogPaper,
      }}
      open={open}
      aria-labelledby="form-dialog-title"
      scroll="body"
      onClose={close}
      fullWidth
    >
      <Container className="dialog-title" flex alignCenter>
        <Container className="dialog-title-close">
          <CloseIconButton onClick={close} />
        </Container>
        <Container>Add a new product</Container>
      </Container>
      <Container className={classes.content}>
        <ImagePicker
          shape="square"
          onChange={setImage}
          width={100}
          height={100}
          fontSize={32}
          image={image}
        />
        <Container className={classes.inputs}>
          <NameInput
            onEnter={create}
            onChange={(newName) => {
              if (alias === '' || alias === name) {
                setAlias(newName);
              }

              setName(newName);
            }}
            errors={errors.name}
            name={name}
            autoFocus
          />
          <AliasInput
            onEnter={create}
            onChange={setAlias}
            errors={errors.alias}
            name={alias}
          />
          <Container className="input">
            <BrandInput
              onEnter={create}
              onChange={setBrand}
              errors={errors.brand_id}
              selected={brand}
            />
          </Container>
          <Container className="input">
            <CategoryInput
              onEnter={create}
              onChange={setCategory}
              errors={errors.category_id}
              selected={category}
            />
          </Container>
          <Container className="input">
            <PriceInput
              onEnter={create}
              onChange={setPrice}
              errors={errors.price}
              placeholder="$12.35"
              value={price}
            />
          </Container>
          <Container className="input">
            <MeasurementDropdown
              selected={measurement}
              onChange={setMeasurement}
              errors={errors.measurement}
            />
          </Container>
        </Container>
      </Container>
      <ModelInteractorCreateActions onClick={create} processing={processing} />
    </MaterialDialog>
  );
};

ProductsCreate.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  productsCreate: PropTypes.func.isRequired,
  processing: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  processing: state.productsReducer.create.processing,
  success: state.productsReducer.create.success,
  errors: state.productsReducer.create.errors,
});

export default connect(mapStateToProps, { productsCreate: productsCreateRequest })(ProductsCreate);
