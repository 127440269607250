import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { Fade } from 'react-reveal';
import { useDispatch, useSelector } from 'react-redux';
import HomeLayout from '../../components/Layout/HomeLayout';
import Container from '../../components/Base/Container';
import TextInput from '../../components/Base/Input/TextInput';
import ButtonGlow from '../../components/Buttons/ButtonGlow';
import { login } from '../../providers/redux/actions/Auth';
import useRouter from '../../services/useRouter';
import Constants from '../../constants';
import Loader from '../../components/Base/Loader';

const styles = makeStyles(theme => ({
  container: {
    maxWidth: 400,
    width: '100%',
    flexDirection: 'column',
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  title: {
    fontWeight: 'bold',
  },
  inputs: {
    display: 'flex',
    flex: 1,
    maxWidth: 300,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  button: {
    marginTop: theme.spacing(8),
    width: '100%',
  },
  loaderContainer: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(2),
    width: '100%',
  },
}));

const ScreenLogin = () => {
  const classes = styles();
  const dispatch = useDispatch();
  const { history } = useRouter();
  const { authenticated, loading } = useSelector(state => ({
    loading: state.authReducer.loading,
    authenticated: state.authReducer.authenticated,
  }));
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (authenticated) {
      history.push(Constants.routes.Home);
    }
  }, [authenticated]);

  const request = () => dispatch(login({ username: email, password }));

  const onEnter = () => {
    if (!loading && !(email === '' || password === '')) {
      request();
    }
  };

  return (
    <HomeLayout disableScroll>
      <Fade bottom>
        <div className={classes.inputs}>
          <Container className={classes.container} flex alignCenter center>
            <TextInput
              label="E-mail address"
              onChange={setEmail}
              onEnter={onEnter}
              value={email}
              shrink
            />
            <TextInput
              type="password"
              label="Password"
              onChange={setPassword}
              onEnter={onEnter}
              value={password}
              shrink
            />
            {loading
              ? (
                <Container className={classes.loaderContainer}>
                  <Loader />
                </Container>
              )
              : (
                <ButtonGlow
                  className={classes.button}
                  onPress={request}
                  disabled={email === '' || password === ''}
                >
                  Login
                </ButtonGlow>
              )}
          </Container>
        </div>
      </Fade>
    </HomeLayout>
  );
};

export default ScreenLogin;
