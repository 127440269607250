import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import CountUp from 'react-countup';
import Typography from '@material-ui/core/Typography';
import StatisticsValueChange from './StatisticsValueChange';
import Loader from '../Base/Loader';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  value: {
    position: 'relative',
  },
}));

const duration = 0.8;

const StatisticsValue = ({
  className, valueClassName, type, label, value, valueVariant,
}) => {
  const currentChanges = useRef([]);
  const timers = useRef([]);
  const [previousValue, setPreviousValue] = useState(null);
  const [currentValue, setCurrentValue] = useState(null);
  const [changes, setChanges] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    const change = value - currentValue;
    const newChanges = [...changes];
    const index = changes.length;
    newChanges[index] = change;
    setChanges(newChanges);

    timers.current.push(
      setTimeout(() => {
        currentChanges.current.splice(index, 1);
        setChanges(currentChanges.current);
      }, 800),
    );

    setPreviousValue(currentValue);
    setCurrentValue(value);

    return () => {
      timers.current.forEach(timer => clearTimeout(timer));
    };
  }, [value]);

  useEffect(() => {
    changes.current = [...changes];
  }, [changes]);

  return (
    <div className={classNames([className, classes.container])}>
      <div className={classes.value}>
        {changes.map(
          (changeValue, index) => (
            <StatisticsValueChange
              key={index.toString()}
              type={type}
              amount={changeValue}
            />
          ),
        )}
        {value !== null
          ? (
            <Typography className={valueClassName} variant={valueVariant}>
              {type === 'price' && (
                <CountUp
                  duration={duration}
                  prefix="$"
                  decimals={2}
                  separator=","
                  start={previousValue}
                  end={currentValue}
                />
              )}
              {type === 'number' && (
                <CountUp
                  duration={duration}
                  decimals={0}
                  separator=","
                  start={previousValue}
                  end={currentValue}
                />
              )}
            </Typography>
          )
          : (
            <Loader />
          )}
      </div>
      {label && (
        <div className={classes.label}>
          <Typography variant="body1">{ label }</Typography>
        </div>
      )}
    </div>
  );
};

StatisticsValue.defaultProps = {
  className: null,
  valueClassName: null,
  valueVariant: 'h4',
  label: null,
  type: 'price',
  value: null,
};

StatisticsValue.propTypes = {
  className: PropTypes.string,
  valueClassName: PropTypes.string,
  valueVariant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'body1',
    'body2',
    'caption',
  ]),
  type: PropTypes.oneOf([
    'price',
    'number',
  ]),
  value: PropTypes.number,
  label: PropTypes.string,
};

export default StatisticsValue;
