const productTransformer = (product) => {
  const {
    name,
    price,
    quantity,
    alert,
    fall_below_quantity: fallBelowQuantity,
    replenish_quantity: replenishQuantity,
  } = product.data();

  const replenish = replenishQuantity
    ? `it will be restocked to ${replenishQuantity}`
    : 'an alert will be set';

  const alertMessage = fallBelowQuantity === null
    ? 'No alerts set.'
    : `If quantity falls below ${fallBelowQuantity}, ${replenish}`;

  return {
    productId: product.id,
    name,
    price,
    quantity,
    alert,
    fallBelowQuantity,
    replenishQuantity,
    alertMessage,
  };
};

export default productTransformer;
