import {
  all,
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import { Instance, Resources } from '../../API';
import {
  actions,
  menuIndexReset,
  menuIndexSuccess,
} from '../actions/Menu';
import {
  snackbarDefaultError,
} from '../actions/Snackbar';
import foodTransformer from '../transformers/FoodTransformer';
import setTransformer from '../transformers/SetTransformer';

export const callFoodsIndex = (
  organisationId,
  {
    search,
  },
) => Instance.get(
  Resources.foods,
  {
    params: {
      organisation_id: organisationId,
      search,
    },
  },
);

export const callSetsIndex = (
  organisationId,
  {
    search,
  },
) => Instance.get(
  Resources.sets,
  {
    params: {
      organisation_id: organisationId,
      search,
    },
  },
);

const dataSite = state => ({
  organisationId: state.organisationReducer.data.id,
});

const dataMetadata = state => ({
  search: state.menuReducer.index.search,
});

function* sagaMenuIndex({ payload: search }) {
  try {
    const { organisationId } = yield select(dataSite);
    const {
      search: originalSearch,
    } = yield select(dataMetadata);

    if (search !== originalSearch) {
      yield put(
        menuIndexReset(),
      );
    }

    const { data: { data: foods } } = yield call(
      callFoodsIndex,
      organisationId,
      {
        search,
      },
    );

    const { data: { data: sets } } = yield call(
      callSetsIndex,
      organisationId,
      {
        search,
      },
    );

    const items = [
      ...foods.map(
        food => ({ ...foodTransformer(food), resource: Resources.foods }),
      ),
      ...sets.map(
        set => ({
          ...setTransformer(set),
          resource: Resources.sets,
          category: {
            name: 'Sets',
          },
        }),
      ),
    ];

    yield put(
      menuIndexSuccess(
        items,
        {
          search,
        },
      ),
    );
  } catch (e) {
    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

export default function* Menu() {
  yield all([
    takeLatest(actions.INDEX.REQUEST, sagaMenuIndex),
  ]);
}
