const organisationTransformer = (organisation) => {
  const {
    revenue,
    expenses,
    percentage_gain: gain,
    profit,
    transaction_count: transactionsCount,
  } = organisation.data();

  return {
    revenue,
    expenses,
    gain,
    profit,
    transactionsCount,
  };
};

export default organisationTransformer;
