import { actions } from '../actions/Quotes';

const initialState = {
  total: 0,
  processing: false,
  loading: false,
  timestamp: null,
  page: null,
  data: null,
  hasMore: true,
  selected: null,
  search: '',
  create: {
    processing: false,
    success: false,
    errors: {},
  },
  update: {
    loading: false,
    success: false,
    errors: {},
  },
  autocomplete: {
    loading: false,
    data: [],
  },
  destroy: {
    loading: false,
    success: false,
    errors: {},
  },
};

const quotesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET:
      return {
        ...state,
        loading: true,
      };
    case actions.PUT:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actions.PUT_ITEMS:
      return {
        ...state,
        data: [...action.payload],
        loading: false,
      };
    case actions.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actions.ITEM.RESET:
      return {
        ...state,
        edit: {
          ...state.edit,
          errors: {},
        },
        selected: null,
      };
    case actions.ITEM.PUT:
      return {
        ...state,
        edit: {
          ...state.edit,
          errors: {},
        },
        selected: action.payload,
      };

    case actions.UPDATE.REQUEST:
      return {
        ...state,
        update: {
          ...state.update,
          loading: true,
        },
      };
    case actions.UPDATE.SUCCESS:
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
          success: true,
        },
      };
    case actions.UPDATE.ERRORS:
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
          success: false,
          errors: action.payload,
        },
      };
    case actions.UPDATE.RESET:
      return {
        ...state,
        update: {
          ...initialState.update,
        },
      };
    case actions.CREATE.PROCESSING:
      return {
        ...state,
        create: {
          ...state.create,
          success: false,
          processing: true,
        },
      };
    case actions.CREATE.PROCESSED:
      return {
        ...state,
        create: {
          ...state.create,
          processing: false,
        },
      };
    case actions.CREATE.ERRORS:
      return {
        ...state,
        create: {
          ...state.create,
          errors: action.payload,
        },
      };
    case actions.CREATE.SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          errors: {},
          success: true,
        },
      };
    case actions.RESET:
      return {
        ...state,
        data: null,
        page: null,
        hasMore: true,
        timestamp: null,
      };
    case actions.AUTOCOMPLETE.GET:
      return {
        ...state,
        autocomplete: {
          ...state.autocomplete,
          loading: true,
        },
      };
    case actions.AUTOCOMPLETE.PUT:
      return {
        ...state,
        autocomplete: {
          loading: false,
          data: action.payload,
        },
      };
    case actions.AUTOCOMPLETE.CLEAR:
      return {
        ...state,
        autocomplete: {
          loading: false,
          data: [],
        },
      };
    case actions.DESTROY.REQUEST:
      return {
        ...state,
        destroy: {
          ...state.destroy,
          loading: true,
        },
      };
    case actions.DESTROY.SUCCESS:
      return {
        ...state,
        destroy: {
          ...state.destroy,
          loading: false,
          success: true,
        },
      };
    case actions.DESTROY.ERRORS:
      return {
        ...state,
        destroy: {
          ...state.destroy,
          loading: false,
          success: false,
          errors: action.payload,
        },
      };
    case actions.DESTROY.RESET:
      return {
        ...state,
        destroy: {
          ...initialState.destroy,
        },
      };
    default:
      return state;
  }
};

export default quotesReducer;
