export const actions = {
  INDEX: {
    REQUEST: 'SUPPLIER_PRODUCTS_INDEX_REQUEST',
    SUCCESS: 'SUPPLIER_PRODUCTS_INDEX_SUCCESS',
    ERRORS: 'SUPPLIER_PRODUCTS_INDEX_ERRORS',
    RESET: 'SUPPLIER_PRODUCTS_INDEX_RESET',
  },
};

export const supplierProductsIndexRequest = (search = null) => ({
  type: actions.INDEX.REQUEST,
  payload: search,
});

export const supplierProductsIndexSuccess = (data, {
  search,
  timestamp,
  page,
  hasMore,
  supplierId,
} = {}) => ({
  type: actions.INDEX.SUCCESS,
  payload: {
    search,
    data,
    timestamp,
    page,
    hasMore,
    supplierId,
  },
});

export const supplierProductsIndexErrors = errors => ({
  type: actions.INDEX.ERRORS,
  payload: errors,
});

export const supplierProductsIndexReset = () => ({
  type: actions.INDEX.RESET,
});
