import { makeStyles } from '@material-ui/core';

const StylesModelInteractor = makeStyles(theme => ({
  container: {
    display: 'flex',
    cursor: 'pointer',
  },
  content: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  selected: {
    backgroundColor: `${theme.palette.secondary.light} !important`,
  },
  name: {
    fontWeight: 600,
  },
}));

export default StylesModelInteractor;
