import React from 'react';
import PropTypes from 'prop-types';
import TextInput from '../../../../components/Base/Input/TextInput';

const AliasInput = ({
  onEnter, onChange, name, errors, disabled,
}) => (
  <TextInput
    onEnter={onEnter}
    label="Alias"
    className="input"
    onChange={onChange}
    value={name}
    errors={errors}
    placeholder="10MM DRILL BIT"
    disabled={disabled}
    fullWidth
    shrink
  />
);

AliasInput.defaultProps = {
  onEnter: null,
  errors: null,
  disabled: false,
};

AliasInput.propTypes = {
  onEnter: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  errors: PropTypes.string,
  disabled: PropTypes.bool,
};

export default AliasInput;
