import React, { useEffect, useState } from 'react';
import { Fade } from 'react-reveal';
import {
  Card,
  CardContent,
  CardHeader,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Add } from '@material-ui/icons';
import HomeLayout from '../../../../components/Layout/HomeLayout';
import Container from '../../../../components/Base/Container';
import SiteAppNavigation from '../../../../components/Site/SiteAppNavigation';
import SiteOrganisationInputs
  from '../../../../components/Site/SiteOrganisation/SiteOrganisationInputs';
import {
  organisationDestroyRequest,
  organisationDestroyReset,
  organisationItemSelect, organisationPaymentsStoreRequest,
  organisationUpdateRequest,
} from '../../../../providers/redux/actions/Organisation';
import ButtonBack from '../../../../components/Base/Button/ButtonBack';
import useNavigate from '../../../../services/useNavigate';
import Constants from '../../../../constants';
import useRouter from '../../../../services/useRouter';
import Loader from '../../../../components/Base/Loader';
import ButtonGlow from '../../../../components/Buttons/ButtonGlow';
import Approval from '../../../../components/Base/Dialog/Approval';
import SubscriptionDetails
  from '../../../../components/Subscription/SubscriptionDetails';
import IconButton
  from '../../../../components/Base/Button/IconButton/IconButton';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(8),
    maxWidth: 1200,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  title: {
    fontWeight: 'bold',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
  },
  data: {
    maxWidth: 400,
    width: '100%',
  },
  back: {
    marginRight: theme.spacing(2),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  destroy: {
    marginLeft: theme.spacing(6),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  card: {
    marginTop: theme.spacing(6),
  },
}));

const ScreenOrganisationView = () => {
  const { match } = useRouter();
  const { id } = match.params;

  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    updating, destroying, destroyed, loading, item, errors,
  } = useSelector(state => ({
    destroying: state.organisationReducer.destroy.loading,
    destroyed: state.organisationReducer.destroy.success,
    updating: state.organisationReducer.destroy.updating,
    loading: state.organisationReducer.selected.loading,
    item: state.organisationReducer.selected.data,
    errors: state.organisationReducer.update.errors,
  }));

  const [open, setOpen] = useState(false);
  const [openDestroy, setOpenDestroy] = useState(false);

  useEffect(() => {
    dispatch(
      organisationItemSelect(id),
    );
  }, [id]);

  const {
    name,
    domain,
    registrationNo,
    taxRegistrationNo,
    tax,
    paymentMethods,
    goods,
    terms,
  } = item;

  const updatePayments = (token, isDefault) => {
    dispatch(
      organisationPaymentsStoreRequest({
        token,
        isDefault,
      }),
    );
  };

  const onDestroy = () => setOpenDestroy(true);

  const onCloseDestroy = () => setOpenDestroy(false);

  const onClose = () => setOpen(false);

  const onOpen = () => setOpen(true);

  const submit = payload => dispatch(
    organisationUpdateRequest(id, payload),
  );

  const destroy = () => dispatch(
    organisationDestroyRequest(id),
  );

  const backToList = () => navigate(Constants.routes.Organisations);

  useEffect(() => {
    if (destroyed) {
      onClose();
      backToList();
      dispatch(organisationDestroyReset());
    }
  }, [destroyed]);

  return (
    <HomeLayout>
      <Container className={classes.container}>
        <SiteAppNavigation />
        <Container className={classes.content}>
          <Container className={classes.back}>
            <ButtonBack onPress={backToList} />
          </Container>
          <Container className={classes.form} flex>
            <Approval
              open={openDestroy}
              title="Delete"
              content="Are you sure you want to delete this organisation? It will be gone for good."
              closeDialog={onCloseDestroy}
              triggerEvent={destroy}
              processing={destroying}
            />
            {loading ? <Loader /> : (
              <Fade bottom>
                <Container className={classes.header}>
                  <Typography className={classes.title} variant="h4">
                    {name}
                  </Typography>
                  <ButtonGlow
                    className={classes.destroy}
                    onPress={onDestroy}
                    color="error"
                  >
                    Delete this organisation
                  </ButtonGlow>
                </Container>
                <SiteOrganisationInputs
                  loading={updating || false}
                  className={classes.data}
                  onPress={submit}
                  errors={errors}
                  name={name}
                  domain={domain}
                  registrationNo={registrationNo}
                  tax={tax}
                  taxRegistrationNo={taxRegistrationNo}
                  goods={goods}
                  terms={terms}
                  buttonText="Update details"
                />
                <Card className={classes.card}>
                  <CardHeader
                    title="Your subscription details"
                    action={(
                      <IconButton onPress={onOpen}>
                        <Add />
                      </IconButton>
                    )}
                  />
                  <CardContent>
                    <SubscriptionDetails
                      methods={paymentMethods || []}
                      onUpdate={updatePayments}
                      onClose={onClose}
                      open={open}
                    />
                  </CardContent>
                </Card>
              </Fade>
            )}
          </Container>
        </Container>
      </Container>
    </HomeLayout>
  );
};

export default ScreenOrganisationView;
