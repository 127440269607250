import React from 'react';
import { IconButton, makeStyles, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import { KeyboardArrowRight, KeyboardArrowLeft } from '@material-ui/icons';

const styles = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
}));

const TablePaginationActions = ({
  onChangePage, page, count, rowsPerPage, hasMore, nextPage, previousPage,
}) => {
  const handleBackButtonClick = event => (
    previousPage
      ? previousPage()
      : onChangePage(event, page - 1)
  );
  const handleNextButtonClick = event => (
    nextPage
      ? nextPage()
      : onChangePage(event, page + 1)
  );

  const classes = styles();
  const theme = useTheme();

  return (
    <div className={classes.root}>
      <IconButton
        href={null}
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Previous Page"
      >
        {theme.direction === 'rtl'
          ? <KeyboardArrowRight />
          : <KeyboardArrowLeft />
        }
      </IconButton>
      <IconButton
        href={null}
        onClick={handleNextButtonClick}
        disabled={
          !hasMore
          || (count !== -1 && page >= Math.ceil(count / rowsPerPage) - 1)
        }
        aria-label="Next Page"
      >
        {theme.direction === 'rtl'
          ? <KeyboardArrowLeft />
          : <KeyboardArrowRight />
        }
      </IconButton>
    </div>
  );
};

TablePaginationActions.defaultProps = {
  hasMore: true,
  nextPage: null,
  previousPage: null,
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  hasMore: PropTypes.bool,
  nextPage: PropTypes.func,
  previousPage: PropTypes.func,
};

export default TablePaginationActions;
