import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import TextInput from '../../../../../components/Base/Input/TextInput';

const NameInput = ({
  className, name, errors, onChange, onEnter,
}) => (
  <TextInput
    label="Name"
    className={classNames([className, 'input'])}
    onChange={onChange}
    value={name}
    shrink
    errors={errors}
    placeholder="Aios (maybe, you never know.)"
    onEnter={onEnter}
    fullWidth
  />
);

NameInput.defaultProps = {
  errors: null,
  className: null,
  onEnter: null,
};

NameInput.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  errors: PropTypes.string,
  onEnter: PropTypes.func,
};

export default NameInput;
