import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Layout from '../../../components/Layout';
import ModelInteractor from '../../../components/Base/ModelInteractor';
import CategoryItem from './components/Item';
import CategoriesCreate from './components/Create';
import CategoriesView from './components/View';
import { Resources } from '../../../providers/API';
import Container from '../../../components/Base/Container';
import {
  categoriesDelete,
  categoriesGet,
  categoriesItemReset,
  categoriesItemSelect,
} from '../../../providers/redux/actions/Categories';

const ScreenCategories = ({
  categories,
  hasMore,
  loading,
  selected,
  processing,
  success,
  categoriesItemSelect: categoriesItemSelectAction,
  categoriesItemReset: categoriesItemResetAction,
  categoriesGet: categoriesGetAction,
}) => {
  const loadMore = (page, search) => {
    categoriesGetAction({ search });
  };

  return (
    <Layout direction="right" flex>
      <ModelInteractor
        create={CategoriesCreate}
        view={CategoriesView}
        resource={Resources.categories}
        list={categories}
        itemElement={CategoryItem}
        nextCallback={loadMore}
        hasMore={hasMore}
        loading={loading}
        selected={selected}
        viewAction={id => categoriesItemSelectAction({ id })}
        reset={categoriesItemResetAction}
        empty={<Container>Art of organising.</Container>}
        processing={processing}
        success={success}
      />
    </Layout>
  );
};

ScreenCategories.defaultProps = {
  categories: null,
  selected: null,
};

ScreenCategories.propTypes = {
  categories: PropTypes.array,
  hasMore: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  selected: PropTypes.number,
  categoriesItemSelect: PropTypes.func.isRequired,
  categoriesItemReset: PropTypes.func.isRequired,
  categoriesGet: PropTypes.func.isRequired,
  processing: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  timestamp: state.categoriesReducer.timestamp,
  hasMore: state.categoriesReducer.hasMore,
  categories: state.categoriesReducer.data,
  loading: state.categoriesReducer.loading,
  processing: state.categoriesReducer.edit.processing,
  success: state.categoriesReducer.edit.success,
  selected: state.categoriesReducer.selected,
});

export default connect(mapStateToProps, {
  categoriesGet,
  categoriesDelete,
  categoriesItemSelect,
  categoriesItemReset,
})(ScreenCategories);
