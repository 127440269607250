import createSagaMiddleware from 'redux-saga';
import * as Sentry from '@sentry/browser';

const sagaMiddleware = createSagaMiddleware({
  onError: (error, errorInfo) => {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  },
});

export default sagaMiddleware;
