export const actions = {
  LOADING: 'AUTH_LOADING',
  LOGIN: 'AUTH_LOGIN',
  LOGOUT: 'AUTH_LOGOUT',
  VERIFY: 'AUTH_VERIFY',
  VERIFIED: 'AUTH_VERIFIED',
  UNVERIFIED: 'AUTH_UNVERIFIED',
  RESET: 'AUTH_RESET',
  ERROR_STATE: 'AUTH_ERROR_STATE',
  USER: {
    PUT: 'AUTH_USER_PUT',
  },
  EMAIL: {
    VERIFY: 'AUTH_EMAIL_VERIFY',
    VERIFIED: 'AUTH_EMAIL_VERIFIED',
    VERIFICATION_FAILED: 'AUTH_EMAIL_VERIFICATION_FAILED',
    RESEND: 'AUTH_EMAIL_RESEND',
  },
  PERMISSIONS: {
    SET: 'AUTH_PERMISSIONS_SET',
  },
  EDIT: {
    REQUEST: 'AUTH_EDIT_REQUEST',
    SUCCESS: 'AUTH_EDIT_SUCCESS',
    ERRORS: 'AUTH_EDIT_ERRORS',
  },
};

export const verify = () => ({
  type: actions.VERIFY,
});

export const verified = ({
  authenticated, user, token,
}) => ({
  type: actions.VERIFIED,
  payload: {
    authenticated,
    user,
    token,
  },
});

export const authSetPermissions = ({ permissions }) => ({
  type: actions.PERMISSIONS.SET,
  payload: permissions,
});

export const login = ({ username, password }) => ({
  type: actions.LOGIN,
  payload: {
    username,
    password,
  },
});

export const logout = () => ({
  type: actions.LOGOUT,
});


export const unverified = () => ({
  type: actions.UNVERIFIED,
});

export const setErrorState = ({ error }) => ({
  type: actions.ERROR_STATE,
  payload: error,
});

export const authLoading = () => ({
  type: actions.LOADING,
});

export const authReset = () => ({
  type: actions.RESET,
});

export const authEditRequest = ({
  email,
  password,
  confirmPassword,
  firstName,
  lastName,
  phoneNo,
}) => ({
  type: actions.EDIT.REQUEST,
  payload: {
    email,
    password,
    confirmPassword,
    firstName,
    lastName,
    phoneNo,
  },
});

export const authEditSuccess = () => ({
  type: actions.EDIT.SUCCESS,
});

export const authEditErrors = ({ errors }) => ({
  type: actions.EDIT.ERRORS,
  payload: errors,
});

export const authEmailVerify = ({
  expires,
  hash,
  id,
  signature,
}) => ({
  type: actions.EMAIL.VERIFY,
  payload: {
    expires,
    hash,
    id,
    signature,
  },
});

export const authUserPut = ({ user }) => ({
  type: actions.USER.PUT,
  payload: user,
});

export const authEmailResend = () => ({
  type: actions.EMAIL.RESEND,
});

export const authEmailVerified = () => ({
  type: actions.EMAIL.VERIFIED,
});

export const authEmailVerificationFailed = () => ({
  type: actions.EMAIL.VERIFICATION_FAILED,
});
