import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '../../../../components/Base/Container';
import NameInput from './Inputs/NameInput';
import SwitchInput from '../../../../components/Base/Input/SwitchInput';
import useModelInteractorView
  from '../../../../providers/hooks/useModelInteractorView';
import ModelInteractorStandardView
  from '../../../../components/Base/ModelInteractor/ModelInteractorStandardView';
import fastCompare from '../../../../providers/fastCompare';
import {
  categoriesDelete,
  categoriesEditRequest,
} from '../../../../providers/redux/actions/Categories';
import ModelInteractorStyles
  from '../../../../components/Base/ModelInteractor/ModelInteractorStyles';
import ImagePicker from '../../../../components/Base/Image/ImagePicker';

const useStyles = makeStyles(
  theme => ({
    container: {
      display: 'flex',
      flexDirection: 'row',
    },
    inputs: {
      marginLeft: theme.spacing(4),
      flex: 1,
    },
  }),
);

const CategoriesView = () => {
  const dispatch = useDispatch();
  const {
    item, errors, processing, success,
  } = useModelInteractorView('categoriesReducer');
  const classes = ModelInteractorStyles();
  const viewStyles = useStyles();

  const {
    id,
    name: categoryName,
    otf: categoryOtf,
    isMenu: categoryIsMenu,
    image: categoryImage,
  } = item;

  const [name, setName] = useState(categoryName);
  const [otf, setOtf] = useState(categoryOtf);
  const [isMenu, setIsMenu] = useState(categoryIsMenu);
  const [image, setImage] = useState(categoryImage);

  useEffect(() => {
    if (success) {
      document.activeElement.blur();
    }
  }, [success]);

  useEffect(() => {
    const {
      name: newCategoryName,
      otf: newCategoryOtf,
      isMenu: newCategoryIsMenu,
      image: newImage,
    } = item;

    setName(newCategoryName);
    setOtf(newCategoryOtf);
    setIsMenu(newCategoryIsMenu);
    setImage(newImage);
  }, [item]);

  const previous = {
    name: categoryName,
    otf: categoryOtf,
    isMenu: categoryIsMenu,
    image: categoryImage,
  };

  const current = {
    name,
    otf,
    isMenu,
    image,
  };

  const isSame = fastCompare(previous, current);

  const edit = () => !isSame && dispatch(
    categoriesEditRequest({
      id,
      name,
      otf,
      isMenu,
      image,
    }),
  );

  const remove = () => dispatch(categoriesDelete({ id }));

  return (
    <ModelInteractorStandardView
      isSame={isSame}
      edit={edit}
      remove={remove}
      processing={processing}
      success={success}
    >
      <Container
        key={1}
        className={classes.container}
        flex
        horizontal
        center
      >
        <Container className={viewStyles.container}>
          <ImagePicker
            key={image}
            onChange={setImage}
            width={100}
            height={100}
            shape="square"
            image={image}
          />
          <Container className={viewStyles.inputs}>
            <NameInput
              onChange={setName}
              name={name}
              errors={errors.name}
              onEnter={edit}
            />
            <SwitchInput
              title="Enable this category for on the fly?"
              onChange={newOtf => setOtf(newOtf ? 1 : 0)}
              checked={otf === 1 || otf === true}
            />
            <SwitchInput
              title="Display this category in menu?"
              onChange={setIsMenu}
              checked={isMenu === true}
            />
          </Container>
        </Container>
      </Container>
    </ModelInteractorStandardView>
  );
};

export default CategoriesView;
