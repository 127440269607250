import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import Button from '../Base/Button';
import useRouter from '../../services/useRouter';
import Constants from '../../constants';

const styles = makeStyles(() => ({
  button: {
    borderRadius: 4,
    boxShadow: 'none',
  },
  text: {
    fontWeight: 'bold',
  },
}));

const ButtonRegister = ({ className, textClassName }) => {
  const { history } = useRouter();
  const classes = styles();
  return (
    <Button
      className={classNames([classes.button, className])}
      textClassName={classNames([classes.text, textClassName])}
      variant="text"
      color="default"
      onClick={() => history.push(Constants.routes.Register)}
    >
      Register
    </Button>
  );
};

ButtonRegister.defaultProps = {
  className: null,
  textClassName: null,
};

ButtonRegister.propTypes = {
  className: PropTypes.string,
  textClassName: PropTypes.string,
};

export default ButtonRegister;
