import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Dialog as MaterialDialog,
  makeStyles,
} from '@material-ui/core';

import Container from '../../../../components/Base/Container';
import NameInput from './Inputs/NameInput';
import CloseIconButton from '../../../../components/Base/Button/CloseIcon';
import ModelInteractorCreateActions
  from '../../../../components/Base/ModelInteractor/Components/ModelInteractorCreateActions';
import { brandsCreateRequest } from '../../../../providers/redux/actions/Brands';

const styles = makeStyles(theme => ({
  dialogPaper: {
    overflow: 'visible',
  },
  content: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

const BrandsCreate = ({
  open,
  close,
  processing,
  success,
  errors,
  brandsCreateRequest: brandsCreateRequestAction,
}) => {
  const [name, setName] = useState('');
  const classes = styles();

  useEffect(() => {
    if (open && success) {
      close();
      setName('');
    }
  }, [success]);

  const submit = () => brandsCreateRequestAction({ name });

  return (
    <MaterialDialog
      maxWidth="sm"
      PaperProps={{ className: classes.dialogPaper }}
      className="dialog"
      open={open}
      aria-labelledby="form-dialog-title"
      scroll="body"
      onClose={close}
      fullWidth
    >
      <Container className="dialog-title" flex alignCenter>
        <Container className="dialog-title-close">
          <CloseIconButton onClick={close} />
        </Container>
        <Container>Add a new brand</Container>
      </Container>
      <Container className={classes.content}>
        <NameInput
          onChange={setName}
          errors={errors.name}
          name={name}
          onEnter={submit}
        />
      </Container>
      <ModelInteractorCreateActions
        onClick={submit}
        processing={processing}
      />
    </MaterialDialog>
  );
};

BrandsCreate.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  processing: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  brandsCreateRequest: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  success: state.brandsReducer.create.success,
  errors: state.brandsReducer.create.errors,
  processing: state.brandsReducer.create.processing,
});

export default (connect(mapStateToProps, { brandsCreateRequest })(BrandsCreate));
