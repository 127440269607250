import {
  all, select, put, fork, takeEvery,
} from 'redux-saga/effects';
import { siteInitialised } from '../actions/Site';
import {
  actions as organisationActions, organisationLoad,
} from '../actions/Organisation';
import {
  actions as authActions, verify,
} from '../actions/Auth';

const dataLoaders = state => ({
  authInitialised: state.authReducer.init,
  organisationInitialised: state.organisationReducer.initialLoad,
  organisationLoading: state.organisationReducer.loading,
});

function* sagaSiteTenantVerify() {
  const {
    authInitialised,
    organisationInitialised,
    organisationLoading,
  } = yield select(dataLoaders);

  if (!authInitialised && !organisationLoading && !organisationInitialised) {
    yield put(
      siteInitialised(),
    );
  }
}

function* sagaSiteTenant(name) {
  yield put(
    organisationLoad({ name }),
  );

  yield takeEvery([
    organisationActions.LOADED,
    authActions.VERIFIED,
    authActions.UNVERIFIED,
  ], sagaSiteTenantVerify);
}

function* sagaSiteIndexVerify() {
  const { authInitialised } = yield select(dataLoaders);
  if (!authInitialised) {
    yield put(
      siteInitialised(),
    );
  }
}

function* sagaSiteIndex() {
  yield put(
    verify(),
  );

  yield takeEvery([
    authActions.VERIFIED,
    authActions.UNVERIFIED,
  ], sagaSiteIndexVerify);
}

function* sagaSiteInit() {
  const name = (window.location.host !== process.env.REACT_APP_HOST)
    ? window.location.host.replace(
      `.${process.env.REACT_APP_HOST}`,
      '',
    )
    : null;

  if (name) {
    yield fork(sagaSiteTenant, name);
    return;
  }

  yield fork(sagaSiteIndex);
}

export default function* Site() {
  yield all([
    sagaSiteInit(),
  ]);
}
