import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core';
import ModelInteractorList from './List';
import Container from '../Container';
import AddButton from '../Button/AddButton';
import ModelInteractorEmptyView from './Components/EmptyView';
import useRouter from '../../../services/useRouter';

const styles = makeStyles(theme => ({
  viewContainer: {
    display: 'flex',
    flex: 3,
  },
  listContainer: {
    flex: 2,
  },
  createFab: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    margin: theme.spacing(3),
  },
}));

const ModelInteractor = ({
  create: CreateComponent,
  view: ViewComponent,
  list,
  nextCallback,
  itemElement,
  hasMore,
  loading,
  groups,
  selected,
  resource,
  viewAction,
  reset,
  empty,
}) => {
  const classes = styles();
  const { history, match } = useRouter();
  const [open, setOpen] = useState(false);
  const { id } = match.params;

  useEffect(() => {
    if (id) {
      viewAction(id);
    }
  }, []);

  useEffect(() => {
    if (selected !== null && selected.id) {
      history.push(`/${resource}/${selected.id.toString()}`);
    }
  }, [selected]);

  return (
    <Container flex>
      <CreateComponent open={open} close={() => setOpen(false)} />
      <Container className={classes.viewContainer}>
        {selected && selected.id
          ? <ViewComponent />
          : (
            <ModelInteractorEmptyView>
              {empty}
            </ModelInteractorEmptyView>
          )
        }
      </Container>
      <Container className={classes.listContainer} flex>
        <ModelInteractorList
          selected={selected ? selected.id : null}
          reset={reset}
          loadNext={(page, search) => nextCallback(page, search)}
          hasMore={hasMore}
          itemElement={itemElement}
          items={list}
          loading={loading}
          groups={groups}
          onClick={itemId => viewAction(itemId)}
        />
        <AddButton
          onClick={() => setOpen(true)}
          className={classes.createFab}
        />
      </Container>
    </Container>
  );
};

ModelInteractor.defaultProps = {
  list: null,
  groups: null,
  selected: null,
  viewAction: null,
  reset: null,
  hasMore: false,
};

ModelInteractor.propTypes = {
  create: PropTypes.object.isRequired,
  view: PropTypes.func.isRequired,
  resource: PropTypes.string.isRequired,
  list: PropTypes.array,
  itemElement: PropTypes.func.isRequired,
  nextCallback: PropTypes.func.isRequired,
  hasMore: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  groups: PropTypes.array,
  selected: PropTypes.object,
  viewAction: PropTypes.func,
  reset: PropTypes.func,
  empty: PropTypes.node.isRequired,
};

export default ModelInteractor;
