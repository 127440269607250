import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {
  AddRounded,
  MoreVertRounded,
  RemoveRedEyeRounded,
  NotificationsActiveRounded,
  NotificationsOffRounded,
  NotificationsPausedRounded, PrintRounded,
} from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Menu from '@material-ui/core/Menu';
import Dialog from '@material-ui/core/Dialog';
import ListItem from '@material-ui/core/ListItem';
import Container from '../Base/Container';
import Form from '../Form';
import Approval from '../Base/Dialog/Approval';
import IconButton from '../Base/Button/IconButton/IconButton';
import {
  alertsDestroyRequest,
  alertsUpdateRequest,
} from '../../providers/redux/actions/Places/Products/Alerts';
import InventoriesCreate
  from '../../routes/app/products/components/Inventories/InventoriesCreate';
import PlaceInventoriesList from '../PlaceInventoriesList';
import BarcodePrintModal from '../BarcodePrintModal';
import AlertQuantities from '../AlertQuantities';

const useStyles = makeStyles(
  theme => ({
    name: {
      fontWeight: 'bold',
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      flex: 1,
      flexDirection: 'row',
    },
    autoWidth: {
      flex: 1,
    },
    inline: {
      display: 'inline-block',
    },
    input: {
      display: 'inline-block',
      marginLeft: theme.spacing(),
    },
  }),
);

const ProductsStoreInventoriesItem = ({
  name,
  quantity,
  replenishQuantity: originalReplenishQuantity,
  fallBelowQuantity: originalFallBelowQuantity,
  storeId,
  placeId,
  productId,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    updateLoading,
    updateSuccess,
    destroyLoading,
    destroySuccess,
  } = useSelector(
    state => ({
      updateLoading: state.placesProductsAlertsReducer.update.loading,
      updateSuccess: state.placesProductsAlertsReducer.update.success,
      destroyLoading: state.placesProductsAlertsReducer.destroy.loading,
      destroySuccess: state.placesProductsAlertsReducer.destroy.success,
    }),
  );

  const [open, setOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(null);
  const [
    isCreateInventoryOpen, setIsCreateInventoryOpen,
  ] = useState(false);
  const [viewingInventories, setViewingInventories] = useState(false);
  const [printing, setPrinting] = useState(false);
  const [replenishQuantity, setReplenishQuantity] = useState(
    originalReplenishQuantity
      ? originalReplenishQuantity.toString()
      : '',
  );

  const [fallBelowQuantity, setFallBelowQuantity] = useState(
    originalFallBelowQuantity
      ? originalFallBelowQuantity.toString()
      : '',
  );

  const [pause, setPause] = useState(false);
  const [remove, setRemove] = useState(false);

  const onOpen = () => setOpen(true);

  const onClose = () => setOpen(false);

  const openMenu = e => setIsMenuOpen(e.currentTarget);

  const closeMenu = () => setIsMenuOpen(null);

  const onPause = () => setPause(true);

  const onClosePause = () => setPause(false);

  const onRemove = () => setRemove(true);

  const onCloseRemove = () => setRemove(false);

  const onOpenCreateInventory = () => setIsCreateInventoryOpen(true);

  const onCloseCreateInventory = () => setIsCreateInventoryOpen(false);

  const onViewInventories = () => setViewingInventories(true);

  const onCloseInventories = () => setViewingInventories(false);

  const onOpenPrinting = () => setPrinting(true);

  const onClosePrinting = () => setPrinting(false);

  const setAlerts = () => dispatch(
    alertsUpdateRequest(
      placeId,
      productId,
      {
        fallBelowQuantity,
        replenishQuantity,
      },
    ),
  );

  const pauseAlerts = () => dispatch(
    alertsUpdateRequest(
      placeId,
      productId,
      {
        fallBelowQuantity: null,
        replenishQuantity: null,
      },
    ),
  );

  const removeAlerts = () => dispatch(
    alertsDestroyRequest(
      placeId,
      productId,
    ),
  );

  useEffect(() => {
    if (updateSuccess) {
      onClosePause();
      onClose();
    }
  }, [updateLoading]);

  useEffect(() => {
    if (destroySuccess) {
      onCloseRemove();
    }
  }, [destroyLoading]);

  useEffect(() => {
    if (destroySuccess || updateSuccess) {
      closeMenu();
    }
  }, [destroySuccess, updateSuccess]);

  return (
    <Container className={classes.container}>
      <>
        <BarcodePrintModal
          productId={productId}
          placeId={placeId}
          storeId={storeId}
          onClose={onClosePrinting}
          isOpen={printing}
        />
        <Dialog
          maxWidth="md"
          open={viewingInventories}
          onClose={onCloseInventories}
          fullWidth
        >
          <PlaceInventoriesList
            storeName={name}
            productId={productId}
            placeId={placeId}
            quantity={quantity}
          />
        </Dialog>
        <InventoriesCreate
          open={isCreateInventoryOpen}
          productId={productId}
          placeId={placeId}
          setClose={onCloseCreateInventory}
        />
        <Form
          label="Add alert"
          loading={updateLoading}
          onClose={onClose}
          onSubmit={setAlerts}
          open={open}
        >
          <AlertQuantities
            key={`${replenishQuantity}${fallBelowQuantity}`}
            setReplenishQuantity={setReplenishQuantity}
            setFallBelowQuantity={setFallBelowQuantity}
            fallBelowQuantity={fallBelowQuantity}
            replenishQuantity={replenishQuantity}
          />
        </Form>
        <Approval
          processing={updateLoading}
          closeDialog={onClosePause}
          content="Pause alerts for this product? You will no longer see any alerts for this product for this store."
          open={pause}
          title="Pause alerts"
          triggerEvent={pauseAlerts}
        />
        <Approval
          processing={destroyLoading}
          closeDialog={onCloseRemove}
          content="Remove alerts for this store? We will default to the global alert set for this product."
          open={remove}
          title="Remove alerts"
          triggerEvent={removeAlerts}
        />
      </>
      <Typography className={classNames(classes.autoWidth, classes.name)}>
        {name}
      </Typography>
      <Typography className={classes.autoWidth}>
        {`${quantity} left in stock`}
      </Typography>
      <Container className={classes.actions}>
        <IconButton onPress={onOpenCreateInventory}>
          <AddRounded />
        </IconButton>
        <IconButton onPress={onOpenPrinting}>
          <PrintRounded />
        </IconButton>
        <IconButton onPress={onViewInventories}>
          <RemoveRedEyeRounded />
        </IconButton>
        <IconButton
          onPress={openMenu}
          color="default"
        >
          <MoreVertRounded />
        </IconButton>
        <Menu
          anchorOrigin={
            {
              vertical: 'center',
              horizontal: 'right',
            }
          }
          anchorEl={isMenuOpen}
          open={Boolean(isMenuOpen)}
          onClose={closeMenu}
        >
          <ListItem>
            <IconButton onPress={onOpen} color="default">
              <NotificationsActiveRounded />
            </IconButton>
          </ListItem>
          <ListItem>
            <IconButton onPress={onPause} color="default">
              <NotificationsPausedRounded />
            </IconButton>
          </ListItem>
          <ListItem>
            <IconButton onPress={onRemove} color="default">
              <NotificationsOffRounded />
            </IconButton>
          </ListItem>
        </Menu>
      </Container>
    </Container>
  );
};

ProductsStoreInventoriesItem.defaultProps = {
  replenishQuantity: null,
  fallBelowQuantity: null,
};

ProductsStoreInventoriesItem.propTypes = {
  name: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  replenishQuantity: PropTypes.number,
  fallBelowQuantity: PropTypes.number,
  placeId: PropTypes.number.isRequired,
  productId: PropTypes.number.isRequired,
  storeId: PropTypes.number.isRequired,
};

export default ProductsStoreInventoriesItem;
