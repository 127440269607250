import { actions } from '../actions/Products';

const initialState = {
  processing: false,
  loading: false,
  timestamp: null,
  page: null,
  data: null,
  hasMore: true,
  selected: null,
  search: '',
  create: {
    processing: false,
    success: false,
    errors: {},
  },
  edit: {
    processing: false,
    success: false,
    errors: {},
  },
  history: {
    page: 1,
    data: [],
    loading: false,
    maxPage: null,
  },
};

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET:
      return {
        ...state,
        loading: true,
      };
    case actions.PUT:
      return {
        ...state,
        ...action.payload,
        hasMore: action.payload.hasMore !== undefined
          ? action.payload.hasMore
          : state.hasMore,
        loading: false,
      };
    case actions.ITEM.RESET:
      return {
        ...state,
        edit: {
          ...state.edit,
          errors: {},
        },
        selected: null,
      };
    case actions.ITEM.PUT:
      return {
        ...state,
        edit: {
          ...state.edit,
          errors: {},
        },
        selected: action.payload,
      };
    case actions.MOVEMENTS.PUT:
      return {
        ...state,
        selected: {
          ...state.selected,
          movements: action.payload,
        },
      };
    case actions.EDIT.PROCESSING:
      return {
        ...state,
        edit: {
          ...state.edit,
          success: false,
          processing: true,
        },
      };
    case actions.EDIT.PROCESSED:
      return {
        ...state,
        edit: {
          ...state.edit,
          processing: false,
        },
      };
    case actions.EDIT.ERRORS:
      return {
        ...state,
        edit: {
          ...state.edit,
          errors: action.payload,
        },
      };
    case actions.EDIT.SUCCESS:
      return {
        ...state,
        edit: {
          ...state.edit,
          errors: {},
          success: true,
        },
      };
    case actions.CREATE.PROCESSING:
      return {
        ...state,
        create: {
          ...state.create,
          success: false,
          processing: true,
        },
      };
    case actions.CREATE.PROCESSED:
      return {
        ...state,
        create: {
          ...state.create,
          processing: false,
        },
      };
    case actions.CREATE.ERRORS:
      return {
        ...state,
        create: {
          ...state.create,
          errors: action.payload,
        },
      };
    case actions.CREATE.SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          errors: {},
          success: true,
        },
      };
    case actions.RESET:
      return {
        ...state,
        data: null,
        page: null,
        hasMore: true,
        timestamp: null,
      };
    case actions.HISTORY.INDEX.REQUEST:
      return {
        ...state,
        history: {
          ...state.history,
          loading: true,
        },
      };
    case actions.HISTORY.INDEX.SUCCESS:
      return {
        ...state,
        history: {
          loading: false,
          data: action.payload.data,
          page: action.payload.page,
          maxPage: action.payload.maxPage
            ? action.payload.maxPage
            : state.history.maxPage,
        },
      };
    default:
      return state;
  }
};

export default productsReducer;
