import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormHelperText, makeStyles } from '@material-ui/core';
import {
  injectStripe,
} from 'react-stripe-elements';
import { useSelector } from 'react-redux';
import Dialog from '../Base/Dialog';
import SubscriptionCardDetails from './SubscriptionCardDetails';
import Container from '../Base/Container';
import SwitchInput from '../Base/Input/SwitchInput';

const useStyles = makeStyles(theme => ({
  details: {
    paddingBottom: theme.spacing(3),
  },
}));

const SubscriptionDialog = ({
  onUpdate, stripe, open, onClose, error,
}) => {
  const { loading, success } = useSelector(state => ({
    loading: state.organisationReducer.payments.store.loading,
    success: state.organisationReducer.payments.store.success,
  }));

  const [isDefault, setIsDefault] = useState(true);
  const classes = useStyles();

  const submit = async () => {
    const { paymentMethod } = await stripe.createPaymentMethod('card');
    if (paymentMethod) {
      const { id } = paymentMethod;
      onUpdate(id, isDefault);
    }
  };

  useEffect(() => {
    if (success) {
      onClose();
    }
  }, [success]);


  return (
    <Dialog
      title="Add a payment method"
      open={open}
      close={onClose}
      callback={submit}
      loading={loading}
      reject="Cancel"
      accept="Save"
    >
      <Container className={classes.details}>
        <SubscriptionCardDetails />
        <FormHelperText error={error !== null}>
          {error}
        </FormHelperText>
        <SwitchInput
          checked={isDefault}
          onChange={setIsDefault}
          title="Do you want to set this as default?"
        />
      </Container>
    </Dialog>
  );
};

SubscriptionDialog.defaultProps = {
  error: null,
};

SubscriptionDialog.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  stripe: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  error: PropTypes.string,
};

export default injectStripe(SubscriptionDialog);
