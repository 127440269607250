import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import StoreProductListItemQuantityInput
  from './Store/StoreProductListItemQuantityInput';

const useStyles = makeStyles(
  theme => ({
    inline: {
      display: 'inline-block',
    },
    input: {
      display: 'inline-block',
      marginLeft: theme.spacing(),
    },
  }),
);

const AlertQuantities = ({
  setFallBelowQuantity,
  fallBelowQuantity,
  setReplenishQuantity,
  replenishQuantity,
}) => {
  const classes = useStyles();
  return (
    <>
      <Typography className={classes.inline} variant="body1">
        If quantity falls below
      </Typography>
      <StoreProductListItemQuantityInput
        className={classes.input}
        onChange={setFallBelowQuantity}
        value={fallBelowQuantity}
      />
      <Typography className={classes.inline} variant="body1">
        , replenish to
      </Typography>
      <StoreProductListItemQuantityInput
        className={classes.input}
        onChange={setReplenishQuantity}
        value={replenishQuantity}
      />
    </>
  );
};

AlertQuantities.defaultProps = {
  replenishQuantity: null,
  fallBelowQuantity: null,
};

AlertQuantities.propTypes = {
  setReplenishQuantity: PropTypes.func.isRequired,
  setFallBelowQuantity: PropTypes.func.isRequired,
  replenishQuantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fallBelowQuantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default AlertQuantities;
