import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import { ClearRounded } from '@material-ui/icons';

const ButtonClear = ({ className, onPress }) => (
  <IconButton onClick={onPress}>
    <ClearRounded className={className} />
  </IconButton>
);

ButtonClear.defaultProps = {
  className: null,
};

ButtonClear.propTypes = {
  className: PropTypes.string,
  onPress: PropTypes.func.isRequired,
};

export default ButtonClear;
