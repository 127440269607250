import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Add } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import Container from '../../Base/Container';
import Dialog from '../../Base/Dialog';
import TextInput from '../../Base/Input/TextInput';
import Tooltip from '../../Base/Dialog/Tooltip';
import Invited from './Invited';
import IconButton from '../../Base/Button/IconButton/IconButton';

const Invitations = ({ count, invited }) => {
  const [email, setEmail] = useState('');
  const [open, setOpen] = useState(false);

  const openInvitationDialog = () => setOpen(true);

  const closeInvitationDialog = () => setOpen(false);

  const invite = () => {};

  return (
    <Container horizontal flex>
      <Container flex>
        <Container flex horizontal>
          <Typography variant="h5">Invite your team</Typography>
          <Typography>
            {`${count} remaining invites.`}
          </Typography>
        </Container>
        <Container style={{ flex: 0 }} flex center horizontal>
          <IconButton
            onPress={openInvitationDialog}
          >
            <Add />
          </IconButton>
          <Dialog
            title="Invite new user"
            open={open}
            close={closeInvitationDialog}
            callback={invite}
            loading={false}
            reject="Cancel"
            accept="Invite"
          >
            <Tooltip>
                We&apos;ll send an invitation to the user at this e-mail
                address, after which the user can join this organisation at any
                time.
            </Tooltip>
            <TextInput
              label="E-mail Address"
              onChange={setEmail}
              value={email}
              fullWidth
              shrink
            />
          </Dialog>
        </Container>
      </Container>
      <Container>
        <Invited invited={invited} />
      </Container>
    </Container>
  );
};

Invitations.propTypes = {
  count: PropTypes.number.isRequired,
  invited: PropTypes.array.isRequired,
};

export default Invitations;
