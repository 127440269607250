export const actions = {
  STORE: {
    REQUEST: 'SETS_STORE_REQUEST',
    SUCCESS: 'SETS_STORE_SUCCESS',
    ERRORS: 'SETS_STORE_ERRORS',
    RESET: 'SETS_STORE_RESET',
  },
  UPDATE: {
    REQUEST: 'SETS_UPDATE_REQUEST',
    SUCCESS: 'SETS_UPDATE_SUCCESS',
    ERRORS: 'SETS_UPDATE_ERRORS',
    RESET: 'SETS_UPDATE_RESET',
  },
  SHOW: {
    REQUEST: 'SETS_SHOW_REQUEST',
    SUCCESS: 'SETS_SHOW_SUCCESS',
    ERRORS: 'SETS_SHOW_ERRORS',
    RESET: 'SETS_SHOW_RESET',
  },
  DESTROY: {
    REQUEST: 'SETS_DESTROY_REQUEST',
    SUCCESS: 'SETS_DESTROY_SUCCESS',
    ERRORS: 'SETS_DESTROY_ERRORS',
    RESET: 'SETS_DESTROY_RESET',
  },
};

export const setsStoreRequest = ({
  name, image, price, prices, options, categoryId,
}) => ({
  type: actions.STORE.REQUEST,
  payload: {
    name,
    image,
    price,
    prices,
    options,
    categoryId,
  },
});

export const setsStoreSuccess = () => ({
  type: actions.STORE.SUCCESS,
});

export const setsStoreErrors = errors => ({
  type: actions.STORE.ERRORS,
  payload: errors,
});

export const setsStoreReset = () => ({
  type: actions.STORE.RESET,
});

export const setsUpdateRequest = (
  id,
  {
    name, image, price, prices, options, categoryId,
  },
) => ({
  type: actions.UPDATE.REQUEST,
  payload: {
    id,
    name,
    image,
    price,
    prices,
    options,
    categoryId,
  },
});

export const setsUpdateSuccess = () => ({
  type: actions.UPDATE.SUCCESS,
});

export const setsUpdateErrors = errors => ({
  type: actions.UPDATE.ERRORS,
  payload: errors,
});

export const setsUpdateReset = () => ({
  type: actions.UPDATE.RESET,
});

export const setsShowRequest = id => ({
  type: actions.SHOW.REQUEST,
  payload: id,
});

export const setsShowSuccess = data => ({
  type: actions.SHOW.SUCCESS,
  payload: data,
});

export const setsShowErrors = errors => ({
  type: actions.SHOW.ERRORS,
  payload: errors,
});

export const setsShowReset = () => ({
  type: actions.SHOW.RESET,
});

export const setsDestroyRequest = id => ({
  type: actions.DESTROY.REQUEST,
  payload: id,
});

export const setsDestroySuccess = () => ({
  type: actions.DESTROY.SUCCESS,
});

export const setsDestroyErrors = errors => ({
  type: actions.DESTROY.ERRORS,
  payload: errors,
});

export const setsDestroyReset = () => ({
  type: actions.DESTROY.RESET,
});
