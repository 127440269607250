import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import SelectInput from './Base/Input/SelectInput';
import { categoriesAutocompleteGet } from '../providers/redux/actions/Categories';

const CategoryInput = ({
  onChange,
  selected,
  errors,
  onEnter,
}) => {
  const dispatch = useDispatch();
  const { data } = useSelector(
    state => ({
      data: state.categoriesReducer.autocomplete.data,
      loading: state.categoriesReducer.autocomplete.loading,
    }),
  );

  const onChangeText = text => dispatch(
    categoriesAutocompleteGet({ text }),
  );

  return (
    <SelectInput
      selected={selected}
      onEnter={onEnter}
      suggestions={data}
      placeholder="Category"
      label="Category"
      errors={errors}
      onChange={onChange}
      onChangeText={onChangeText}
      isClearable
    />
  );
};

CategoryInput.defaultProps = {
  selected: null,
  errors: [],
  onEnter: null,
};

CategoryInput.propTypes = {
  selected: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.array,
  onEnter: PropTypes.func,
};

export default CategoryInput;
