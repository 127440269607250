import {
  all,
  call,
  put,
  select,
  takeLatest,
  take,
  fork,
} from 'redux-saga/effects';
import { Instance, Resources } from '../../API';
import {
  actions,
  rolesPut,
} from '../actions/Roles';
import {
  actions as authActions,
} from '../actions/Auth';
import {
  snackbarDefaultError,
} from '../actions/Snackbar';
import UserCan from '../../UserCan';
import Permissions from '../../Permissions';

const resource = Resources.roles;

export const rolesIndexCall = ({ organisationId }) => Instance.get(
  resource,
  {
    params: {
      organisation_id: organisationId,
    },
  },
);

const siteData = state => ({
  organisationId: state.organisationReducer.data
    ? state.organisationReducer.data.id
    : null,
});

const dataGetPermissions = state => ({
  permissions: state.authReducer.permissions,
});

/**
 * loadRolesSaga
 * @returns {IterableIterator<*>}
 */
function* loadRolesSaga() {
  try {
    const { organisationId } = yield select(siteData);
    const { data: { data } } = yield call(
      rolesIndexCall,
      {
        organisationId,
      },
    );

    yield put(
      rolesPut({
        data,
      }),
    );
  } catch (e) {
    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

function* verifyRolesSaga() {
  while (true) {
    yield take(authActions.VERIFIED);
    try {
      const { organisationId } = yield select(siteData);
      const { permissions } = yield select(dataGetPermissions);

      const userCan = UserCan(permissions);
      if (organisationId && userCan(Permissions.editUsers)) {
        const { data: { data } } = yield call(
          rolesIndexCall,
          {
            organisationId,
          },
        );

        yield put(
          rolesPut({
            data,
          }),
        );
      } else {
        yield put(
          rolesPut({ data: {} }),
        );
      }
    } catch (e) {
      yield put(
        snackbarDefaultError({ e }),
      );
    }
  }
}

export default function* Roles() {
  yield all([
    takeLatest(actions.GET, loadRolesSaga),
    fork(verifyRolesSaga),
  ]);
}
