import { actions } from '../actions/Printers';

const initialState = {
  index: {
    loading: false,
    data: null,
    search: '',
    errors: {},
  },
  create: {
    success: false,
    processing: false,
    errors: {},
  },
  remove: {
    success: false,
    processing: false,
    errors: {},
  },
};

const printersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.INDEX.REQUEST:
      return {
        ...state,
        index: {
          ...state.index,
          loading: true,
        },
      };
    case actions.INDEX.SUCCESS:
      return {
        ...state,
        index: {
          ...state.index,
          loading: false,
          data: action.payload,
        },
      };
    case actions.INDEX.ERRORS:
      return {
        ...state,
        index: {
          ...state.index,
          loading: false,
          errors: action.payload,
        },
      };
    case actions.INDEX.RESET:
      return {
        ...state,
        index: {
          ...initialState.index,
        },
      };
    case actions.CREATE.REQUEST:
      return {
        ...state,
        create: {
          ...state.create,
          processing: true,
          success: false,
          errors: {},
        },
      };
    case actions.CREATE.SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          processing: false,
          success: true,
        },
      };
    case actions.CREATE.ERRORS:
      return {
        ...state,
        create: {
          ...state.create,
          processing: false,
          success: false,
          errors: action.payload,
        },
      };
    case actions.REMOVE.REQUEST:
      return {
        ...state,
        remove: {
          ...state.remove,
          processing: true,
          success: false,
        },
      };
    case actions.REMOVE.SUCCESS:
      return {
        ...state,
        remove: {
          ...state.remove,
          processing: false,
          success: true,
        },
      };
    case actions.REMOVE.ERRORS:
      return {
        ...state,
        remove: {
          ...state.remove,
          processing: false,
          success: false,
          errors: action.payload,
        },
      };
    default:
      return state;
  }
};

export default printersReducer;
