import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import Dialog from '../Base/Dialog';

const PrintersDelete = ({ setClose, open, onDelete }) => {
  const { success, processing } = useSelector(state => ({
    success: state.printersReducer.remove.success,
    processing: state.printersReducer.remove.processing,
    errors: state.printersReducer.remove.errors,
  }));

  useEffect(() => {
    if (success) {
      setClose();
    }
  }, [success, setClose]);

  return (
    <Dialog
      title="Delete this printer"
      open={open}
      close={setClose}
      callback={onDelete}
      loading={processing}
      reject="Please no"
      accept="Do it"
    >
      Are you sure you want to delete this printer?
    </Dialog>
  );
};


PrintersDelete.propTypes = {
  open: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  setClose: PropTypes.func.isRequired,
};

export default PrintersDelete;
