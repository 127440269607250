import {
  all,
  call,
  put,
  select,
  putResolve,
  takeLatest,
} from 'redux-saga/effects';
import { Instance, Resources, toFormData } from '../../API';
import {
  actions,
  foodsDestroyErrors, foodsDestroyReset,
  foodsDestroySuccess,
  foodsIndexReset,
  foodsIndexSuccess,
  foodsShowErrors, foodsShowReset,
  foodsShowSuccess,
  foodsStoreErrors,
  foodsStoreReset,
  foodsStoreSuccess,
  foodsUpdateErrors,
  foodsUpdateReset,
  foodsUpdateSuccess,
} from '../actions/Foods';
import {
  snackbarDefaultError,
  snackbarDefaultSuccess,
} from '../actions/Snackbar';
import hasErrors from '../../hasErrors';
import foodTransformer from '../transformers/FoodTransformer';

const resource = Resources.foods;

const callFoodStore = (
  organisationId,
  name,
  price,
  {
    image,
    prices,
    options,
    categoryId,
  },
) => {
  const payload = {
    organisation_id: organisationId,
    name,
    image,
    price,
    prices: prices.map(
      ({ storeId, price: storePrice }) => ({
        store_id: storeId,
        amount: storePrice,
      }),
    ),
    options,
    category_id: categoryId,
  };

  return Instance.post(
    resource,
    toFormData(payload),
  );
};

export const callFoodIndex = (
  organisationId,
  {
    search,
  },
) => Instance.get(
  resource,
  {
    params: {
      organisation_id: organisationId,
      search,
    },
  },
);

export const callFoodShow = id => Instance.get(`${resource}/${id}`);

export const callFoodUpdate = (
  id,
  {
    name,
    image,
    price,
    prices,
    options,
    categoryId,
  },
) => {
  const payload = {
    name,
    image,
    price,
    prices: prices.map(
      ({ storeId, price: storePrice }) => ({
        store_id: storeId,
        amount: storePrice,
      }),
    ),
    options,
    category_id: categoryId,
  };

  const data = toFormData(payload);
  data.append('_method', 'PUT');
  return Instance.post(`${resource}/${id}`, data);
};

export const callFoodDestroy = id => Instance.delete(
  `${resource}/${id}`,
);

const dataSite = state => ({
  organisationId: state.organisationReducer.data.id,
});

const dataMetadata = state => ({
  search: state.foodsReducer.search,
});

const dataIndex = state => ({
  data: state.foodsReducer.index.data,
});

/**
 * Sagas
 */
function* sagaUpdateIndexData(food) {
  const { data } = yield select(dataIndex);
  if (data) {
    const index = data.findIndex(({ id }) => food.id === id);
    if (index !== undefined) {
      data[index] = { ...data[index], ...food };
    } else {
      data.unshift(food);
    }

    yield put(
      foodsIndexSuccess(data),
    );
  }
}

function* sagaFoodsIndex({ payload: search }) {
  try {
    const { organisationId } = yield select(dataSite);
    const {
      search: originalSearch,
    } = yield select(dataMetadata);

    if (search !== originalSearch) {
      yield put(
        foodsIndexReset(),
      );
    }

    const { data } = yield call(
      callFoodIndex,
      organisationId,
      {
        search,
      },
    );

    const { data: items } = data;
    yield put(
      foodsIndexSuccess(
        items,
        {
          search,
        },
      ),
    );
  } catch (e) {
    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

function* sagaFoodStore({
  payload: {
    name,
    image,
    price,
    prices,
    options,
    categoryId,
  },
}) {
  try {
    const filteredOptions = options.filter(
      ({ name: optionName }) => optionName !== '',
    ).map(
      ({ choices, ...others }) => ({
        ...others,
        choices: choices.filter(
          ({ name: choiceName }) => choiceName !== '',
        ),
      }),
    );

    const { organisationId } = yield select(dataSite);
    const { data } = yield call(
      callFoodStore,
      organisationId,
      name,
      price,
      {
        image,
        prices,
        options: filteredOptions.filter(
          ({ name: optionName }) => optionName !== '',
        ),
        categoryId,
      },
    );

    const { data: food } = data;
    yield call(sagaUpdateIndexData, food);

    yield putResolve(
      foodsStoreSuccess(),
    );

    yield put(
      snackbarDefaultSuccess({ data }),
    );

    yield put(
      foodsStoreReset(),
    );
  } catch (e) {
    const errors = hasErrors(e);
    if (errors) {
      yield put(
        foodsStoreErrors(errors),
      );
    }
    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

function* sagaFoodUpdate({
  payload: {
    id,
    name,
    image,
    categoryId,
    prices,
    price,
    options,
  },
}) {
  try {
    const { data } = yield call(
      callFoodUpdate,
      id,
      {
        name,
        image,
        categoryId,
        price,
        prices,
        options,
      },
    );

    const { data: foodData } = data;
    const food = foodTransformer(foodData);

    yield call(sagaUpdateIndexData, food);

    yield putResolve(
      foodsShowSuccess(food),
    );

    yield putResolve(
      foodsUpdateSuccess(),
    );

    yield put(
      snackbarDefaultSuccess({ data }),
    );

    yield put(
      foodsUpdateReset(),
    );
  } catch (e) {
    const errors = hasErrors(e);
    if (errors) {
      yield put(
        foodsUpdateErrors(errors),
      );
    }
    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

function* sagaFoodDestroy({ payload: id }) {
  try {
    const { data } = yield call(callFoodDestroy, id);

    yield put(
      foodsDestroySuccess(),
    );

    yield put(
      snackbarDefaultSuccess({ data }),
    );

    yield putResolve(
      foodsShowReset(),
    );

    const { data: indexData } = yield select(dataIndex);
    const newData = [
      ...indexData.filter(({ id: foodId }) => id !== foodId),
    ];

    yield put(
      foodsIndexSuccess(newData),
    );

    yield putResolve(
      foodsDestroyReset(),
    );
  } catch (e) {
    const errors = hasErrors(e);
    if (errors) {
      yield put(
        foodsDestroyErrors(errors),
      );
    }
    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

function* sagaFoodShow({ payload: id }) {
  try {
    const { data } = yield call(
      callFoodShow,
      id,
    );

    const { data: foodData } = data;

    const food = foodTransformer(foodData);

    yield putResolve(
      foodsShowSuccess(food),
    );
  } catch (e) {
    const errors = hasErrors(e);
    if (errors) {
      yield put(
        foodsShowErrors(errors),
      );
    }

    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

export default function* Suppliers() {
  yield all([
    takeLatest(actions.INDEX.REQUEST, sagaFoodsIndex),
    takeLatest(actions.STORE.REQUEST, sagaFoodStore),
    takeLatest(actions.UPDATE.REQUEST, sagaFoodUpdate),
    takeLatest(actions.DESTROY.REQUEST, sagaFoodDestroy),
    takeLatest(actions.SHOW.REQUEST, sagaFoodShow),
  ]);
}
