import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Menu, Typography, makeStyles, ButtonBase, MenuList, MenuItem,
} from '@material-ui/core';
import Container from '../Base/Container';
import { logout } from '../../providers/redux/actions/Auth';
import useRouter from '../../services/useRouter';
import Constants from '../../constants';
import Avatar from '../Avatar';

const styles = makeStyles(theme => ({
  image: {
    width: 68,
    height: 68,
  },
  userButtonBase: {
    padding: theme.spacing(),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  user: {
    fontWeight: 'bold',
    marginRight: theme.spacing(4),
  },
  logout: {
    padding: theme.spacing(2),
  },
}));

const UserDropdown = ({ className, nameClassName }) => {
  const classes = styles();
  const dispatch = useDispatch();
  const { history } = useRouter();
  const [anchor, setAnchor] = useState(null);
  const { user } = useSelector(state => ({
    user: state.authReducer.user,
  }));

  const name = `${user.firstName} ${user.lastName}`;

  const close = () => setAnchor(null);
  const initials = name.split(' ')
    .map(word => word.substring(0, 1))
    .concat();

  const navigate = path => history.push(path);

  const signOut = () => dispatch(logout());

  return (
    <Container>
      <Container center alignCenter flex>
        <ButtonBase
          onClick={({ currentTarget }) => setAnchor(currentTarget)}
          className={
            classNames([
              classes.userButtonBase,
              className,
            ])
          }
        >
          <Typography
            className={
              classNames([
                classes.user,
                nameClassName,
              ])
            }
          >
            {name}
          </Typography>
          <Avatar
            fontSize={24}
            className={classes.image}
            initials={initials}
            shape="round"
            width={48}
            height={48}
          />
        </ButtonBase>
      </Container>
      <Menu
        anchorEl={anchor}
        open={anchor !== null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        getContentAnchorEl={null}
        onClose={close}
      >
        <MenuList>
          <MenuItem onClick={() => navigate(Constants.routes.Profile)}>
            Profile
          </MenuItem>
          <MenuItem onClick={() => navigate(Constants.routes.Organisations)}>
            Organisations
          </MenuItem>
          <MenuItem onClick={signOut}>
            Logout
          </MenuItem>
        </MenuList>
      </Menu>
    </Container>
  );
};

UserDropdown.defaultProps = {
  className: null,
  nameClassName: null,
};

UserDropdown.propTypes = {
  className: PropTypes.string,
  nameClassName: PropTypes.string,
};

export default UserDropdown;
