import { actions } from '../actions/Snackbar';

const initialState = {
  messages: [],
};

const snackbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET:
      return {
        ...state,
        messages: action.payload,
      };
    case actions.CLEAR:
      return initialState;
    default:
      return state;
  }
};

export default snackbarReducer;
