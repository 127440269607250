import {
  all, put, select, takeEvery, takeLatest,
} from 'redux-saga/effects';
import React from 'react';
import * as Sentry from '@sentry/browser';
import Default from '../../../defaults';
import SnackbarDefaults from '../../../defaults/Snackbar';
import { actions, snackbarMessagePush, snackbarSet } from '../actions/Snackbar';
import SnackbarCloseButton
  from '../../../components/Snackbar/SnackbarCloseButton';

const getMessages = state => ({
  messages: state.snackbarReducer.messages,
});

/**
 * @param message
 * @param variant
 * @returns {IterableIterator<*>}
 */
function* messagePushSaga({ payload: { message, variant } }) {
  const { messages } = yield select(getMessages);
  const newMessages = [...messages];
  const key = new Date().getTime() + Math.random();
  newMessages.push({
    key,
    message,
    options: {
      variant: variant || SnackbarDefaults.variants.success,
      autoHideDuration: Default.autoHideDuration,
      action: <SnackbarCloseButton messageKey={key} />,
    },
  });

  yield put(snackbarSet({ messages: newMessages }));
}

/**
 * @param key
 * @returns {IterableIterator<*>}
 */
function* messageCloseSaga({ payload: key }) {
  const { messages } = yield select(getMessages);
  const newMessages = [...messages];
  yield put(
    snackbarSet({
      messages: newMessages.map(message => (message.key === key
        ? {
          ...message,
          dismissed: true,
        }
        : { ...message })),
    }),
  );
}

/**
 * @param key
 * @returns {IterableIterator<*>}
 */
function* messageRemoveSaga({ payload: key }) {
  const { messages } = yield select(getMessages);
  const newMessages = [...messages];
  yield put(snackbarSet({
    messages: newMessages.filter(message => message.key !== key),
  }));
}

/**
 * @param e
 */
function* defaultFailureHandlerSaga({ payload: e }) {
  if (e.response && e.response.data) {
    yield put(
      snackbarMessagePush({
        message: e.response.data ? e.response.data.message : e.response.message,
        variant: SnackbarDefaults.variants.warning,
      }),
    );

    return;
  }

  if (e.message === 'Network Error') {
    yield put(
      snackbarMessagePush({
        message: 'We could not process this request. You may not have internet connection at the moment.',
        variant: SnackbarDefaults.variants.warning,
      }),
    );

    return;
  }

  Sentry.captureException(e);
  yield put(
    snackbarMessagePush({
      message: 'We could not process this request. Please refresh the browser and try again.',
      variant: SnackbarDefaults.variants.warning,
    }),
  );
}

/**
 * @param e
 */
function* defaultSuccessHandlerSaga({ payload: data }) {
  yield put(snackbarMessagePush({ message: data.message }));
}

export default function* Snackbar() {
  yield all([
    takeEvery(actions.MESSAGE.PUSH, messagePushSaga),
    takeLatest(actions.MESSAGE.CLOSE, messageCloseSaga),
    takeLatest(actions.MESSAGE.REMOVE, messageRemoveSaga),
    takeLatest(actions.DEFAULT.SUCCESS, defaultSuccessHandlerSaga),
    takeLatest(actions.DEFAULT.ERROR, defaultFailureHandlerSaga),
  ]);
}
