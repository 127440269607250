import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import {
  Typography, makeStyles, ListItem, ListItemText,
} from '@material-ui/core';

import { useSelector } from 'react-redux';
import Container from '../../../../components/Base/Container';
import StylesModelInteractor from '../../../../styles/components/StylesModelInteractor';
import Avatar from '../../../../components/Avatar';

const useStyles = makeStyles(
  theme => ({
    content: {
      display: 'flex',
      flexDirection: 'row',
    },
    quantity: {
      fontWeight: 400,
    },
    price: {
      display: 'flex',
      fontSize: 24,
      justifyContent: 'flex-end',
      alignItems: 'center',
      paddingRight: theme.spacing(4),
    },
  }),
);

const ProductItem = ({
  item, onClick, selected,
}) => {
  const { user } = useSelector(
    state => ({
      user: state.authReducer.user,
    }),
  );
  const modelInteractorClasses = StylesModelInteractor();
  const classes = useStyles();

  const {
    id, name, price, quantity, image,
  } = item;
  return (
    <ListItem
      component="div"
      role="button"
      tabIndex="0"
      className={modelInteractorClasses.container}
      classes={{
        selected: modelInteractorClasses.selected,
      }}
      onClick={() => onClick(id)}
      onKeyDown={() => onClick(id)}
      aria-pressed="false"
      selected={selected}
      button
    >
      <Avatar image={image} width={60} height={60} />
      <ListItemText className={modelInteractorClasses.content}>
        <Container>
          <Typography className={modelInteractorClasses.name} variant="body1">
            {name}
          </Typography>
          {(user.stores.length <= 1
            && quantity !== undefined
            && quantity !== 'NaN') && (
            <Typography className={classes.quantity} variant="body1">
              {`${quantity} left in stock`}
            </Typography>
          )}
        </Container>
      </ListItemText>
      { user.stores.length <= 1 && (
        <div className={classes.price}>
          <NumberFormat
            prefix="$"
            decimalScale={2}
            fixedDecimalScale
            value={price}
            displayType="text"
            thousandSeparator
          />
        </div>
      )}
    </ListItem>
  );
};

ProductItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default ProductItem;
