export const actions = {
  LOAD: 'ORGANISATION_LOAD',
  LOADED: 'ORGANISATION_LOADED',
  ERROR: 'ORGANISATION_ERROR',
  LIST: {
    LOAD: 'ORGANISATION_LIST_LOAD',
    PUT: 'ORGANISATION_LIST_PUT',
  },
  ITEM: {
    SELECT: 'ORGANISATION_ITEM_SELECT',
    PUT: 'ORGANISATION_ITEM_PUT',
  },
  CREATE: {
    REQUEST: 'ORGANISATION_CREATE_REQUEST',
    SUCCESS: 'ORGANISATION_CREATE_SUCCESS',
    FAILED: 'ORGANISATION_CREATE_FAILED',
    RESET: 'ORGANISATION_CREATE_RESET',
  },
  UPDATE: {
    REQUEST: 'ORGANISATION_UPDATE_REQUEST',
    SUCCESS: 'ORGANISATION_UPDATE_SUCCESS',
    FAILED: 'ORGANISATION_UPDATE_FAILED',
  },
  DESTROY: {
    REQUEST: 'ORGANISATION_DESTROY_REQUEST',
    SUCCESS: 'ORGANISATION_DESTROY_SUCCESS',
    FAILED: 'ORGANISATION_DESTROY_FAILED',
    RESET: 'ORGANISATION_DESTROY_RESET',
  },
  PAYMENTS: {
    STORE: {
      REQUEST: 'ORGANISATION_PAYMENTS_STORE_REQUEST',
      SUCCESS: 'ORGANISATION_PAYMENTS_STORE_SUCCESS',
      FAILED: 'ORGANISATION_PAYMENTS_STORE_FAILED',
      RESET: 'ORGANISATION_PAYMENTS_STORE_RESET',
    },
    UPDATE: {
      REQUEST: 'ORGANISATION_PAYMENTS_UPDATE_REQUEST',
      SUCCESS: 'ORGANISATION_PAYMENTS_UPDATE_SUCCESS',
      FAILED: 'ORGANISATION_PAYMENTS_UPDATE_FAILED',
      RESET: 'ORGANISATION_PAYMENTS_UPDATE_RESET',
    },
    DESTROY: {
      REQUEST: 'ORGANISATION_PAYMENTS_DESTROY_REQUEST',
      SUCCESS: 'ORGANISATION_PAYMENTS_DESTROY_SUCCESS',
      FAILED: 'ORGANISATION_PAYMENTS_DESTROY_FAILED',
      RESET: 'ORGANISATION_PAYMENTS_DESTROY_RESET',
    },
  },
};

export const organisationLoad = ({ name }) => ({
  type: actions.LOAD,
  payload: {
    name,
  },
});

export const organisationLoaded = ({ data }) => ({
  type: actions.LOADED,
  payload: {
    data,
  },
});

export const organisationError = () => ({
  type: actions.ERROR,
});

export const organisationListLoad = () => ({
  type: actions.LIST.LOAD,
});

export const organisationListPut = data => ({
  type: actions.LIST.PUT,
  payload: data,
});

export const organisationCreateRequest = ({
  name,
  domain,
  taxRegistrationNo,
  tax,
  registrationNo,
  terms,
}) => ({
  type: actions.CREATE.REQUEST,
  payload: {
    name,
    domain,
    taxRegistrationNo,
    tax,
    registrationNo,
    terms,
  },
});

export const organisationCreateSuccess = () => ({
  type: actions.CREATE.SUCCESS,
});

export const organisationCreateFailed = errors => ({
  type: actions.CREATE.FAILED,
  payload: errors,
});

export const organisationCreateReset = () => ({
  type: actions.CREATE.RESET,
});

export const organisationUpdateRequest = (id, {
  name,
  domain,
  taxRegistrationNo,
  tax,
  registrationNo,
  goods,
  terms,
}) => ({
  type: actions.UPDATE.REQUEST,
  payload: {
    id,
    name,
    domain,
    taxRegistrationNo,
    tax,
    registrationNo,
    goods,
    terms,
  },
});

export const organisationUpdateSuccess = () => ({
  type: actions.UPDATE.SUCCESS,
});

export const organisationUpdateFailed = errors => ({
  type: actions.UPDATE.FAILED,
  payload: errors,
});

export const organisationDestroyRequest = id => ({
  type: actions.DESTROY.REQUEST,
  payload: id,
});

export const organisationDestroySuccess = () => ({
  type: actions.DESTROY.SUCCESS,
});

export const organisationDestroyFailed = errors => ({
  type: actions.DESTROY.FAILED,
  payload: errors,
});

export const organisationDestroyReset = () => ({
  type: actions.DESTROY.RESET,
});

export const organisationItemSelect = id => ({
  type: actions.ITEM.SELECT,
  payload: id,
});

export const organisationItemPut = data => ({
  type: actions.ITEM.PUT,
  payload: data,
});

export const organisationPaymentsStoreRequest = ({
  token,
  isDefault,
}) => ({
  type: actions.PAYMENTS.STORE.REQUEST,
  payload: {
    token,
    isDefault,
  },
});

export const organisationPaymentsStoreSuccess = () => ({
  type: actions.PAYMENTS.STORE.SUCCESS,
});

export const organisationPaymentsStoreFailed = errors => ({
  type: actions.PAYMENTS.STORE.FAILED,
  payload: errors,
});

export const organisationPaymentsStoreReset = () => ({
  type: actions.PAYMENTS.STORE.RESET,
});

export const organisationPaymentsUpdateRequest = ({
  token,
  isDefault,
}) => ({
  type: actions.PAYMENTS.UPDATE.REQUEST,
  payload: {
    token,
    isDefault,
  },
});

export const organisationPaymentsUpdateSuccess = () => ({
  type: actions.PAYMENTS.UPDATE.SUCCESS,
});

export const organisationPaymentsUpdateFailed = errors => ({
  type: actions.PAYMENTS.UPDATE.FAILED,
  payload: errors,
});

export const organisationPaymentsDestroyRequest = token => ({
  type: actions.PAYMENTS.DESTROY.REQUEST,
  payload: token,
});

export const organisationPaymentsDestroySuccess = () => ({
  type: actions.PAYMENTS.DESTROY.SUCCESS,
});

export const organisationPaymentsDestroyFailed = errors => ({
  type: actions.PAYMENTS.DESTROY.FAILED,
  payload: errors,
});

export const organisationPaymentsDestroyReset = () => ({
  type: actions.PAYMENTS.DESTROY.RESET,
});
