import {
  Paper,
  TableCell,
  TableRow, Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Container from '../Base/Container';
import Loader from '../Base/Loader';
import BaseTable from '../Base/Table/BaseTable';
import TableToolbar from '../Base/Table/TableToolbar';

const useStyles = makeStyles(
  theme => ({
    paper: {
      display: 'relative',
      width: '100%',
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(3),
    },
    loading: {
      padding: theme.spacing(3),
    },
  }),
);

const ChangeHistory = ({
  className, events, onChangePage, loading, container, page, hasMore,
}) => {
  const classes = useStyles();
  const onChange = newPage => onChangePage(newPage + 1);

  useEffect(() => {
    onChangePage(page);
  }, []);

  if (loading) {
    return (
      <Paper
        className={
          classNames([
            container === 'paper' && classes.paper,
            className,
          ])
        }
      >
        <TableToolbar title="Change history" />
        <Container className={classes.loading} center alignCenter flex>
          <Loader />
        </Container>
      </Paper>
    );
  }

  return (
    <Paper
      className={
        classNames([
          container === 'paper' && classes.paper,
          className,
        ])
      }
    >
      <TableToolbar title="Change history" />
      <BaseTable
        key={events.length}
        page={page - 1}
        setPage={onChange}
        data={events}
        columns={[
          { name: 'Action', dataKey: 'action' },
          { name: 'Timestamp', dataKey: 'timestamp' },
        ]}
        TableItem={({
          id, action, timestamp,
        }) => (
          <TableRow key={id}>
            <TableCell component="th" scope="row">
              <Typography variant="body1">
                {action}
              </Typography>
            </TableCell>
            <TableCell>
              <Tooltip title={timestamp.format('LLLL')} placement="right-end">
                <Typography variant="body1">
                  { timestamp.fromNow() }
                </Typography>
              </Tooltip>
            </TableCell>
          </TableRow>
        )}
        hasMore={hasMore}
      />
    </Paper>
  );
};

ChangeHistory.defaultProps = {
  className: null,
  events: [],
  container: 'paper',
  hasMore: true,
};

ChangeHistory.propTypes = {
  className: PropTypes.string,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.string.isRequired,
      timestamp: PropTypes.object.isRequired,
    }),
  ),
  onChangePage: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  container: PropTypes.oneOf(['paper', 'div']),
  page: PropTypes.number.isRequired,
  hasMore: PropTypes.bool,
};

export default ChangeHistory;
