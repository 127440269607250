import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import SnackbarDefaults from '../../defaults/Snackbar';
import { snackbarMessagePush } from '../../providers/redux/actions/Snackbar';

class ErrorBoundary extends React.PureComponent {
  componentDidCatch(error, errorInfo) {
    const { snackbarMessagePush: snackbarMessagePushAction } = this.props;
    snackbarMessagePushAction({
      message: "It looks like we messed up. That's on us. Our developers have been notified and we're working to fix this.",
      variant: SnackbarDefaults.variants.error,
    });
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

ErrorBoundary.defaultProps = {
  children: null,
};

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  snackbarMessagePush: PropTypes.func.isRequired,
};

export default connect(null, { snackbarMessagePush })(ErrorBoundary);
