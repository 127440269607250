import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import SunCalc from 'suncalc';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Layout from '../../../components/Layout';
import Container from '../../../components/Base/Container';
import StatisticsValue from '../../../components/Statistics/StatisticsValue';
import Constants from '../../../constants';
import useRoles from '../../../providers/hooks/useRoles';
import PageHeader from '../../../components/PageHeader';
import UnderConstruction from '../../../components/UnderConstruction';
import MapStore from '../../../components/MapStore/MapStore';
import { dashboardSync } from '../../../providers/redux/actions/Dashboard';
import usePosition from '../../../providers/hooks/usePosition';
import checkTimeOfDay from '../../../providers/checkTimeOfDay';

const useStyles = makeStyles(theme => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgba(0,0,0,.25)',
  },
  welcome: {
    flex: 1,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(2),
  },
  containerBackground: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    background: 'center / cover no-repeat',
    transition: 'background-image 2s ease-in-out',
  },
  background: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  dashboard: {
    flex: 1,
  },
  stores: {
    '& > *:first-child': {
      [theme.breakpoints.down('lg')]: {
        paddingLeft: theme.spacing(6.5),
      },
      [theme.breakpoints.up('lg')]: {
        paddingLeft: theme.spacing(12.5),
      },
    },
    '& > *:last-child': {
      [theme.breakpoints.down('lg')]: {
        paddingRight: theme.spacing(6.5),
      },
      [theme.breakpoints.up('lg')]: {
        paddingRight: theme.spacing(12.5),
      },
    },
  },
  statistics: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(2),
    background: 'linear-gradient(0deg, rgba(0,0,0,0.4) 0%, transparent 95%)',
  },
  value: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    padding: theme.spacing(4),
    color: theme.palette.common.white,
  },
  store: {
    marginLeft: 0,
    marginRight: theme.spacing(6.5),
  },
}));

const ScreenDashboard = () => {
  const hasRole = useRoles();
  const dispatch = useDispatch();
  const timer = useRef(null);
  const { latitude, longitude } = usePosition();
  const [lastKnownLatitude, setLastKnownLatitude] = useState(null);
  const [lastKnownLongitude, setLastKnownLongitude] = useState(null);
  const [greeting, setGreeting] = useState('Good morning');
  const [darkMode, setDarkMode] = useState(false);
  const classes = useStyles();

  const {
    revenue, expenses, stores, user,
  } = useSelector(
    state => ({
      revenue: state.dashboardReducer.revenue,
      expenses: state.dashboardReducer.expenses,
      stores: state.dashboardReducer.stores,
      user: state.authReducer.user,
    }),
  );

  const changeBackground = (period) => {
    switch (period) {
      case Constants.periods.Sunrise:
      case Constants.periods.Morning:
      case Constants.periods.Noon:
        setDarkMode(false);
        return;
      case Constants.periods.Sunset:
      case Constants.periods.Night:
      default:
        setDarkMode(true);
    }
  };

  const changeGreeting = (period) => {
    switch (period) {
      case Constants.periods.Sunrise:
      case Constants.periods.Morning:
        setGreeting('Good morning');
        return;
      case Constants.periods.Noon:
        setGreeting('Good afternoon');
        return;
      case Constants.periods.Sunset:
        setGreeting('Good evening');
        return;
      case Constants.periods.Night:
      default:
        setGreeting('Good night');
    }
  };

  const checkTimeNow = () => {
    if (lastKnownLatitude && lastKnownLongitude) {
      const times = SunCalc.getTimes(
        new Date(),
        lastKnownLatitude,
        lastKnownLongitude,
      );

      const period = checkTimeOfDay(times);
      changeBackground(period);
      changeGreeting(period);
      return;
    }

    const period = checkTimeOfDay();
    changeBackground(period);
    changeGreeting(period);
  };

  const onTimeout = () => {
    const nextDate = moment();
    checkTimeNow();

    nextDate.set({
      m: nextDate.minutes() + 1,
      s: 0,
    });

    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(
      onTimeout,
      nextDate.diff(moment()),
    );
  };

  useEffect(() => {
    onTimeout();

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  useEffect(() => {
    setLastKnownLatitude(latitude);
    setLastKnownLongitude(longitude);
  }, [latitude, longitude]);

  useEffect(() => {
    onTimeout();
  }, [lastKnownLatitude, lastKnownLongitude]);

  useEffect(() => {
    dispatch(
      dashboardSync(),
    );
  }, []);

  const name = `${user.firstName} ${user.lastName}`;

  if (hasRole(Constants.roles.manager) || hasRole(Constants.roles.admin)) {
    return (
      <Layout title="Dashboard" scroll>
        <Container
          className={classes.background}
          maxWidth
        >
          <Container
            className={classes.containerBackground}
            maxWidth
            flex
          >
            <Container
              className={classes.container}
              maxWidth
              flex
            >
              <Container className={classes.dashboard}>
                <MapStore stores={stores} darkMode={darkMode} />
              </Container>
              <Container className={classes.statistics}>
                <Container className={classes.welcome}>
                  <Typography variant="h4">
                    {`${greeting}, ${name}`}
                  </Typography>
                  <Typography variant="body1">
                    {moment().format('Do MMMM YYYY')}
                  </Typography>
                </Container>
                <StatisticsValue
                  className={classes.value}
                  value={expenses}
                  label="Expenses"
                />
                <StatisticsValue
                  className={classes.value}
                  value={revenue}
                  label="Revenue"
                />
              </Container>
            </Container>
          </Container>
        </Container>
      </Layout>
    );
  }

  if (hasRole(Constants.roles.storeman)
    || hasRole(Constants.roles.cashier)
    || hasRole(Constants.roles.admin)) {
    return (
      <Layout title="Dashboard" scroll>
        <Container
          className={classes.background}
          maxWidth
        >
          <Container
            className={classes.containerBackground}
            maxWidth
            flex
          >
            <Container
              className={classes.container}
              maxWidth
              flex
            >
              <Container className={classes.dashboard}>
                <MapStore stores={stores} darkMode={darkMode} />
              </Container>
              <Container className={classes.statistics}>
                <Container className={classes.welcome}>
                  <Typography variant="h4">
                    {`${greeting}, ${name}`}
                  </Typography>
                  <Typography variant="body1">
                    {moment().format('Do MMMM YYYY')}
                  </Typography>
                </Container>
              </Container>
            </Container>
          </Container>
        </Container>
      </Layout>
    );
  }

  return (
    <Layout title="Dashboard" scroll>
      <Container maxWidth>
        <PageHeader
          title="Your personalised dashboard"
          subtitle="Under construction"
        />
        <Container maxWidth flex center alignCenter>
          <UnderConstruction />
        </Container>
      </Container>
    </Layout>
  );
};

export default ScreenDashboard;
