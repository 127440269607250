import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import SupplierDropdown from '../../../../../components/Base/Input/SupplierDropdown';
import QuantityInput from '../../../../../components/Base/Input/QuantityInput';
import ModalForm from '../../../../../components/ModalForm';
import LabelledSwitch from '../../../../../components/Base/Switch/LabelledSwitch';
import PrinterDropdown from '../../../../../components/Base/Input/PrinterDropdown';
import { inventoriesCreateRequest } from '../../../../../providers/redux/actions/Inventories';

const InventoriesCreate = ({
  open,
  setClose,
  productId,
  measurement,
  placeId,
}) => {
  const dispatch = useDispatch();
  const { success, errors, processing } = useSelector(
    state => ({
      processing: state.inventoriesReducer.create.processing,
      success: state.inventoriesReducer.create.success,
      errors: state.inventoriesReducer.create.errors,
    }),
  );

  const [supplier, setSupplier] = useState(null);
  const [quantity, setQuantity] = useState('');
  const [printer, setPrinter] = useState(null);
  const [print, setPrint] = useState(true);

  const reset = () => {
    setSupplier(null);
    setQuantity('');
    setPrinter(null);
    setPrint(true);
  };

  const close = () => setClose();

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open]);

  useEffect(() => {
    if (success) {
      close();
      document.activeElement.blur();
    }
  }, [success]);

  const submit = () => {
    dispatch(
      inventoriesCreateRequest({
        productId,
        placeId,
        supplierId: supplier && supplier.id,
        printerId: printer && printer.id,
        quantity,
        print,
      }),
    );
  };

  return (
    <ModalForm
      title="Add inventories"
      open={open}
      close={setClose}
      confirm={submit}
      loading={processing}
    >
      <SupplierDropdown
        productId={productId}
        selected={supplier}
        errors={errors.supplierId}
        onChange={setSupplier}
        onEnter={submit}
      />
      <QuantityInput
        label="Quantity"
        onChange={setQuantity}
        errors={errors.quantity}
        placeholder="1"
        value={quantity}
        measurement={measurement}
        onEnter={submit}
        autoFocus
      />
      {placeId && (
        <LabelledSwitch
          onChange={() => setPrint(!print)}
          checked={print}
          label="Print labels for the new inventories"
        />
      )}
      {(placeId && print) && (
        <PrinterDropdown
          placeId={placeId}
          selected={printer}
          onChange={setPrinter}
          errors={errors.printerId}
        />
      )}
    </ModalForm>
  );
};

InventoriesCreate.defaultProps = {
  placeId: null,
  measurement: null,
};

InventoriesCreate.propTypes = {
  productId: PropTypes.number.isRequired,
  measurement: PropTypes.string,
  open: PropTypes.bool.isRequired,
  setClose: PropTypes.func.isRequired,
  placeId: PropTypes.number,
};

export default InventoriesCreate;
