import {
  all, takeLatest, call, put, select,
} from 'redux-saga/effects';
import {
  actions,
  organisationCreateFailed,
  organisationCreateSuccess,
  organisationDestroyFailed,
  organisationDestroySuccess,
  organisationError,
  organisationItemPut,
  organisationListPut,
  organisationLoaded,
  organisationPaymentsDestroyFailed,
  organisationPaymentsDestroySuccess,
  organisationPaymentsStoreFailed,
  organisationPaymentsStoreSuccess,
  organisationPaymentsUpdateFailed,
  organisationPaymentsUpdateSuccess,
  organisationUpdateFailed,
  organisationUpdateSuccess,
} from '../actions/Organisation';
import { Instance, Resources } from '../../API';
import {
  snackbarDefaultError,
  snackbarDefaultSuccess,
} from '../actions/Snackbar';
import OrganisationTransformer from '../transformers/OrganisationTransformer';
import hasErrors from '../../hasErrors';
import paymentTransformer from '../transformers/PaymentTransformer';

const organisationCall = async name => Instance.get(
  `${Resources.organisations}/site/${name}`,
);

const organisationIndexCall = async () => Instance.get(
  Resources.organisations,
);

const organisationCreateCall = async ({
  name,
  domain,
  taxRegistrationNo,
  tax,
  registrationNo,
  goods,
  terms,
}) => Instance.post(
  Resources.organisations,
  {
    name,
    username: domain,
    tax_registration: taxRegistrationNo,
    tax,
    registration_no: registrationNo,
    goods,
    terms,
  },
);

const organisationShowCall = async id => Instance.get(
  `${Resources.organisations}/${id}`,
);

const organisationDestroyCall = async id => Instance.delete(
  `${Resources.organisations}/${id}`,
);

const organisationUpdateCall = async (id, {
  name,
  domain,
  taxRegistrationNo,
  tax,
  registrationNo,
  goods,
  terms,
}) => Instance.put(
  `${Resources.organisations}/${id}`,
  {
    name,
    username: domain,
    tax_registration: taxRegistrationNo,
    tax,
    registration_no: registrationNo,
    goods,
    terms,
  },
);

const organisationPaymentsIndexCall = async id => Instance.get(
  `${Resources.organisations}/${Resources.payments}`,
  {
    params: {
      organisation_id: id,
    },
  },
);

const organisationPaymentsStoreCall = async ({ id, isDefault, organisationId }) => Instance.post(
  `${Resources.organisations}/${Resources.payments}`,
  {
    organisation_id: organisationId,
    payment_method_id: id,
    default: isDefault,
  },
);

const organisationPaymentsUpdateCall = async (id, {
  organisationId, isDefault,
}) => Instance.put(
  `${Resources.organisations}/${Resources.payments}/${id}`,
  {
    organisation_id: organisationId,
    default: isDefault,
  },
);

const organisationPaymentsDestroyCall = async (id, {
  organisationId,
}) => Instance.delete(
  `${Resources.organisations}/${Resources.payments}/${id}`,
  {
    params: {
      organisation_id: organisationId,
    },
  },
);

const siteOrganisationIdData = state => ({
  organisationId: state.organisationReducer.data
    ? state.organisationReducer.data.id
    : null,
});

const selectedOrganisationIdData = state => ({
  organisationId: state.organisationReducer.selected.data
    ? state.organisationReducer.selected.data.id
    : null,
});

const siteOrganisationData = state => ({
  data: state.organisationReducer.data,
});

const selectedOrganisationData = state => ({
  data: state.organisationReducer.selected.data,
});

function* getPaymentsSaga(id) {
  const { data } = yield call(organisationPaymentsIndexCall, id);
  return data.data.map(paymentTransformer);
}

/**
 * loadOrganisationSaga
 * @param name
 * @returns {IterableIterator<*>}
 */
function* loadOrganisationSaga({ payload: { name } }) {
  try {
    const { data } = yield call(organisationCall, name);
    yield put(
      organisationLoaded({
        data: data.data,
      }),
    );
  } catch (e) {
    yield put(
      organisationError(),
    );

    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

function* loadListSaga() {
  try {
    const { data } = yield call(organisationIndexCall);
    yield put(
      organisationListPut(data.data),
    );
  } catch (e) {
    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

function* createOrganisationSaga({
  payload: {
    name,
    domain,
    taxRegistrationNo,
    tax,
    registrationNo,
    goods,
    terms,
  },
}) {
  try {
    const { data } = yield call(
      organisationCreateCall,
      {
        name,
        domain,
        taxRegistrationNo,
        tax,
        registrationNo,
        goods,
        terms,
      },
    );

    yield put(
      organisationCreateSuccess(),
    );

    yield put(
      snackbarDefaultSuccess({ data }),
    );
  } catch (e) {
    const errors = hasErrors(e);
    if (errors) {
      yield put(
        organisationCreateFailed(
          OrganisationTransformer(errors),
        ),
      );
    }

    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

function* updateOrganisationSaga({
  payload: {
    id,
    name,
    domain,
    taxRegistrationNo,
    tax,
    registrationNo,
    goods,
    terms,
  },
}) {
  try {
    const { data } = yield call(
      organisationUpdateCall,
      id,
      {
        name,
        domain,
        taxRegistrationNo,
        tax,
        registrationNo,
        goods,
        terms,
      },
    );

    yield put(
      organisationUpdateSuccess(),
    );

    yield put(
      snackbarDefaultSuccess({ data }),
    );
  } catch (e) {
    const errors = hasErrors(e);
    if (errors) {
      yield put(
        organisationUpdateFailed(
          OrganisationTransformer(errors),
        ),
      );
    }

    yield put(snackbarDefaultError({ e }));
  }
}

function* destroyOrganisationSaga({ payload: id }) {
  try {
    const { data } = yield call(
      organisationDestroyCall,
      id,
    );

    yield put(organisationDestroySuccess());
    yield put(snackbarDefaultSuccess({ data }));
  } catch (e) {
    const errors = hasErrors(e);
    if (errors) {
      yield put(
        organisationDestroyFailed(
          OrganisationTransformer(errors),
        ),
      );
    }

    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

function* selectOrganisationSaga({ payload: id }) {
  try {
    const { data } = yield call(
      organisationShowCall,
      id,
    );

    const paymentMethods = yield call(getPaymentsSaga, id);

    yield put(
      organisationItemPut(
        OrganisationTransformer({
          ...data.data,
          paymentMethods,
        }),
      ),
    );
  } catch (e) {
    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

function* reloadPaymentSaga(organisationId, selectedOrganisationId) {
  const paymentMethods = yield call(
    getPaymentsSaga,
    organisationId || selectedOrganisationId,
  );

  if (organisationId) {
    const { data: organisationData } = yield select(siteOrganisationData);

    yield put(
      organisationLoaded({
        data: {
          ...organisationData,
          paymentMethods,
        },
      }),
    );
  }

  if (selectedOrganisationId) {
    const { data: organisationData } = yield select(selectedOrganisationData);

    yield put(
      organisationItemPut({
        ...organisationData,
        paymentMethods,
      }),
    );
  }
}

function* storePaymentSaga({
  payload: {
    token,
    isDefault,
  },
}) {
  try {
    const { organisationId } = yield select(siteOrganisationIdData);
    const {
      organisationId: selectedOrganisationId,
    } = yield select(selectedOrganisationIdData);

    const { data } = yield call(
      organisationPaymentsStoreCall,
      {
        id: token,
        organisationId: organisationId || selectedOrganisationId,
        isDefault,
      },
    );

    yield call(reloadPaymentSaga, organisationId, selectedOrganisationId);

    yield put(
      organisationPaymentsStoreSuccess(),
    );

    yield put(
      snackbarDefaultSuccess({ data }),
    );
  } catch (e) {
    const errors = hasErrors(e);
    if (errors) {
      yield put(
        organisationPaymentsStoreFailed(errors),
      );
    }

    yield put(
      snackbarDefaultError(e),
    );
  }
}

function* updatePaymentSaga({
  payload: {
    token,
    isDefault,
  },
}) {
  try {
    const { organisationId } = yield select(siteOrganisationIdData);
    const {
      organisationId: selectedOrganisationId,
    } = yield select(selectedOrganisationIdData);

    const { data } = yield call(
      organisationPaymentsUpdateCall,
      token,
      {
        organisationId: organisationId || selectedOrganisationId,
        isDefault,
      },
    );

    yield call(reloadPaymentSaga, organisationId, selectedOrganisationId);

    yield put(
      snackbarDefaultSuccess({ data }),
    );

    yield put(
      organisationPaymentsUpdateSuccess(),
    );
  } catch (e) {
    const errors = hasErrors(e);
    if (errors) {
      yield put(
        organisationPaymentsUpdateFailed(errors),
      );
    }

    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

function* destroyPaymentSaga({ payload: token }) {
  try {
    const { organisationId } = yield select(siteOrganisationIdData);
    const {
      organisationId: selectedOrganisationId,
    } = yield select(selectedOrganisationIdData);

    const { data } = yield call(
      organisationPaymentsDestroyCall,
      token,
      {
        organisationId: organisationId || selectedOrganisationId,
      },
    );

    yield call(reloadPaymentSaga, organisationId, selectedOrganisationId);

    yield put(
      snackbarDefaultSuccess({ data }),
    );

    yield put(
      organisationPaymentsDestroySuccess(),
    );
  } catch (e) {
    const errors = hasErrors(e);
    if (errors) {
      yield put(
        organisationPaymentsDestroyFailed(errors),
      );
    }

    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

export default function* Organisation() {
  yield all([
    takeLatest(actions.LOAD, loadOrganisationSaga),
    takeLatest(actions.LIST.LOAD, loadListSaga),
    takeLatest(actions.CREATE.REQUEST, createOrganisationSaga),
    takeLatest(actions.UPDATE.REQUEST, updateOrganisationSaga),
    takeLatest(actions.DESTROY.REQUEST, destroyOrganisationSaga),
    takeLatest(actions.ITEM.SELECT, selectOrganisationSaga),
    takeLatest(actions.PAYMENTS.STORE.REQUEST, storePaymentSaga),
    takeLatest(actions.PAYMENTS.UPDATE.REQUEST, updatePaymentSaga),
    takeLatest(actions.PAYMENTS.DESTROY.REQUEST, destroyPaymentSaga),
  ]);
}
