import PropTypes from 'prop-types';
import React from 'react';
import ListView from '../ListView';

const ModelInteractorList = ({
  scrollParent,
  selected,
  onClick,
  items,
  hasMore,
  loadNext,
  itemElement,
  loading,
  groups,
  reset,
}) => (
  <ListView
    startPage={0}
    scrollParent={scrollParent}
    loadNext={(page, search) => loadNext(page, search)}
    hasMore={hasMore}
    loading={loading}
    groups={groups}
    reset={reset}
    maxHeight
  >
    {
      items && items.map(
        (item, i) => React.createElement(itemElement, {
          key: JSON.stringify(item),
          onClick,
          index: i,
          item,
          selected: selected === item.id,
        }),
      )
    }
  </ListView>
);

ModelInteractorList.defaultProps = {
  selected: null,
  items: null,
  groups: null,
  scrollParent: null,
};

ModelInteractorList.propTypes = {
  selected: PropTypes.number,
  items: PropTypes.array,
  itemElement: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  loadNext: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool.isRequired,
  groups: PropTypes.array,
  scrollParent: PropTypes.object,
  reset: PropTypes.func.isRequired,
};

export default ModelInteractorList;
