const StoreTransformer = ({
  id,
  name,
  address_1: address,
  address_2: address2,
  postal_code: postalCode,
  latitude,
  longitude,
  place,
}) => ({
  id,
  name,
  address,
  address2,
  postalCode,
  latitude,
  longitude,
  placeId: place.id,
});

export default StoreTransformer;
