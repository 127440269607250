import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { Instance, Resources, toFormData } from '../../API';
import {
  actions,
  categoriesGet,
  categoriesItemPut,
  categoriesItemReset,
  categoriesItemSelect,
  categoriesEditProcessed,
  categoriesEditProcessing,
  categoriesPut,
  categoriesPutSingle,
  categoriesReset,
  categoriesEditSuccess,
  categoriesEditErrors,
  categoriesCreateErrors,
  categoriesCreateProcessed,
  categoriesCreateSuccess,
  categoriesCreateProcessing,
  categoriesAutocompleteClear, categoriesAutocompletePut,
} from '../actions/Categories';
import {
  snackbarDefaultError,
  snackbarDefaultSuccess,
} from '../actions/Snackbar';
import hasErrors from '../../hasErrors';
import categoryTransformer from '../transformers/CategoryTransformer';

const resource = Resources.categories;

const categoriesCreateCall = ({
  organisationId,
  name,
  otf,
  image,
  isMenu,
}) => {
  const payload = {
    organisation_id: organisationId,
    name,
    otf,
    is_menu: isMenu,
    image,
  };

  return Instance.post(
    resource,
    toFormData(payload),
  );
};

export const categoriesAutocompleteCall = ({
  organisationId, text,
}) => Instance.get(
  `${resource}/${Resources.autocomplete}`,
  {
    params: {
      organisation_id: organisationId,
      search: text !== '' ? text : null,
    },
  },
);

export const categoriesIndexCall = ({
  organisationId, page, timestamp, search, filters,
}) => Instance.get(
  resource,
  {
    params: {
      organisation_id: organisationId,
      page: page !== 1 ? page : null,
      search: search !== '' ? search : null,
      timestamp: timestamp || null,
      filters: filters || null,
      paginate: 1,
    },
  },
);

export const categoriesViewCall = ({ id }) => Instance.get(`${resource}/${id}`);

export const categoriesPutCall = ({
  id,
  name,
  otf,
  isMenu,
  image,
}) => {
  const payload = {
    name,
    otf,
    is_menu: isMenu,
    image,
  };

  const data = toFormData(payload);
  data.append('_method', 'PUT');
  return Instance.post(`${resource}/${id}`, data);
};

export const categoriesDeleteCall = ({ id }) => Instance.delete(`${resource}/${id}`);

const siteData = state => ({
  organisationId: state.organisationReducer.data.id,
});

const categoriesData = state => ({
  data: state.categoriesReducer.data,
});

const categoriesMetaData = state => ({
  page: state.categoriesReducer.page,
  timestamp: state.categoriesReducer.timestamp,
  search: state.categoriesReducer.search,
});

/**
 * loadCategoriesSaga
 * @returns {IterableIterator<*>}
 */
function* loadCategoriesSaga({
  payload: {
    search, filters, reset,
  },
}) {
  try {
    const { organisationId } = yield select(siteData);
    const {
      search: originalSearch,
      page,
      timestamp,
    } = yield select(categoriesMetaData);

    if (search !== originalSearch) {
      yield put(categoriesReset());
    }

    const { data } = yield call(
      categoriesIndexCall,
      {
        organisationId,
        page: reset || !page || search !== originalSearch ? 1 : page + 1,
        timestamp,
        search: search === '' ? null : search,
        filters,
      },
    );

    const {
      data: {
        page: newPage,
        timestamp: newTimestamp,
        items,
      },
    } = data;

    let newData;
    const newItems = [...items.map(categoryTransformer)];
    if (newPage === 1) {
      newData = [...newItems];
    } else {
      const { data: originalData } = yield select(categoriesData);
      newData = originalData ? [...originalData, ...newItems] : [...newItems];
    }

    yield put(
      categoriesPut({
        page: newPage,
        timestamp: newTimestamp,
        data: newData,
        hasMore: newItems.length > 0,
        search,
      }),
    );
  } catch (e) {
    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

/**
 * createCategoriesSaga
 * @param category
 */
function* createCategoriesSaga({
  payload: {
    name,
    otf,
    isMenu,
    image,
  },
}) {
  try {
    yield put(categoriesCreateProcessing());
    const { organisationId } = yield select(siteData);
    const { data } = yield call(
      categoriesCreateCall,
      {
        organisationId,
        name,
        otf,
        isMenu,
        image,
      },
    );

    const category = categoryTransformer(data.data);
    yield put(
      categoriesPutSingle({ category }),
    );
    yield put(categoriesGet({ reset: true }));
    yield put(categoriesCreateSuccess());
    yield put(categoriesItemSelect({ id: category.id }));
    yield put(snackbarDefaultSuccess({ data }));
  } catch (e) {
    const errors = hasErrors(e);
    if (errors) {
      yield put(
        categoriesCreateErrors({ errors }),
      );
    }
    yield put(snackbarDefaultError({ e }));
  } finally {
    yield put(categoriesCreateProcessed());
  }
}

/**
 * editCategoriesSaga
 * @param category
 */
function* editCategoriesSaga({
  payload: {
    id,
    name,
    otf,
    isMenu,
    image,
  },
}) {
  try {
    yield put(categoriesEditProcessing());
    const { data } = yield call(
      categoriesPutCall,
      {
        id,
        name,
        otf,
        isMenu,
        image,
      },
    );

    const category = categoryTransformer(data.data);
    yield put(
      categoriesItemPut({ item: category }),
    );
    yield put(
      categoriesPutSingle({ category }),
    );
    yield put(categoriesEditProcessed());
    yield put(snackbarDefaultSuccess({ data }));
    yield put(categoriesEditSuccess());
  } catch (e) {
    const errors = hasErrors(e);
    if (errors) {
      yield put(
        categoriesEditErrors({ errors }),
      );
    }
    yield put(snackbarDefaultError({ e }));
  } finally {
    yield put(categoriesEditProcessed());
  }
}

/**
 * deleteCategoriesSaga
 * @param id
 */
function* deleteCategoriesSaga({ payload: id }) {
  try {
    yield put(categoriesEditProcessing());
    const response = yield call(categoriesDeleteCall, { id });

    const { data } = yield select(categoriesData);
    const newData = [...data];
    yield put(
      categoriesPut({
        data: newData.filter(({ id: categoryId }) => categoryId !== id),
      }),
    );
    yield put(categoriesItemReset());
    yield put(categoriesEditSuccess());
    yield put(snackbarDefaultSuccess({ data: response.data }));
  } catch (e) {
    yield put(snackbarDefaultError({ e }));
  } finally {
    yield put(categoriesEditProcessed());
  }
}

/**
 * selectCategoriesaga
 * @param id
 */
function* selectCategoriesaga({ payload: id }) {
  try {
    const { data: { data } } = yield call(categoriesViewCall, { id });
    yield put(
      categoriesItemPut({ item: categoryTransformer(data) }),
    );
  } catch (e) {
    yield put(snackbarDefaultError({ e }));
  }
}

/**
 * updateCategoriesSaga
 * @param category
 */
function* updateCategoriesSaga({ payload: category }) {
  const { data } = yield select(categoriesData);
  const index = data.findIndex(({ id }) => category.id === id);
  if (index !== undefined) {
    data[index] = { ...data[index], ...category };
  } else {
    data.unshift(category);
  }

  yield put(
    categoriesPut({ data }),
  );
}

/**
 * @returns {IterableIterator<*>}
 */
function* categoriesAutocompleteGetSaga({ payload: text }) {
  try {
    if (text === '') {
      yield put(categoriesAutocompleteClear());
      return;
    }

    const { organisationId } = yield select(siteData);
    const { data } = yield call(categoriesAutocompleteCall, {
      organisationId,
      text,
    });

    yield put(categoriesAutocompletePut({ data: data.data }));
  } catch (e) {
    yield put(snackbarDefaultError({ e }));
  }
}

export default function* Categories() {
  yield all([
    takeLatest(actions.GET, loadCategoriesSaga),
    takeLatest(actions.DELETE, deleteCategoriesSaga),
    takeLatest(actions.ITEM.SELECT, selectCategoriesaga),
    takeEvery(actions.UPDATE, updateCategoriesSaga),
    takeLatest(actions.EDIT.REQUEST, editCategoriesSaga),
    takeLatest(actions.CREATE.REQUEST, createCategoriesSaga),
    takeLatest(actions.AUTOCOMPLETE.GET, categoriesAutocompleteGetSaga),
  ]);
}
