
const sort = (object) => {
  const ordered = {};
  Object.keys(object).sort().forEach((key) => {
    ordered[key] = object[key];
  });

  return ordered;
};

const fastCompare = (objA, objB) => JSON.stringify(sort(objA)) === JSON.stringify(sort(objB));

export default fastCompare;
