import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

import Button from '../Base/Button';

const styles = makeStyles(theme => ({
  button: {
    borderRadius: 100,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0px 3px 20px #FF69619E',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    boxShadow: '0px 3px 20px #FFC681',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  error: {
    backgroundColor: '#DE2015',
    boxShadow: '0px 3px 20px rgba(222,32,21,0.74)',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#a81a13',
    },
  },
  disabled: {
    boxShadow: 'none',
    backgroundColor: '#FAFAFA',
  },
}));

const ButtonGlow = ({
  className, textClassName, onPress, children, disabled, color,
}) => {
  const classes = styles();
  return (
    <Button
      textClassName={textClassName}
      className={
        classNames([
          classes.button,
          classes[color],
          disabled && classes.disabled,
          className,
        ])
      }
      variant="contained"
      onClick={onPress}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};

ButtonGlow.defaultProps = {
  className: null,
  textClassName: null,
  disabled: false,
  color: 'primary',
};

ButtonGlow.propTypes = {
  className: PropTypes.string,
  textClassName: PropTypes.string,
  onPress: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'error',
  ]),
  disabled: PropTypes.bool,
};

export default ButtonGlow;
