import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import useRouter from '../../services/useRouter';

const useStyles = makeStyles(() => ({
  selected: {
    fontWeight: 'bold',
  },
}));

const SiteAppNavigationItem = ({ link, text }) => {
  const classes = useStyles();
  const { history, location } = useRouter();
  const navigate = () => history.push(link);
  return (
    <MenuItem onClick={navigate}>
      <Typography
        className={
          location.pathname.indexOf(link) > -1 ? classes.selected : null
        }
        variant="body1"
      >
        {text}
      </Typography>
    </MenuItem>
  );
};

SiteAppNavigationItem.propTypes = {
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default SiteAppNavigationItem;
