import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableFooter,
  TablePagination,
  TableRow,
  TableCell,
  makeStyles,
} from '@material-ui/core';
import Constants from '../../../constants';

import TablePaginationActions from './TablePaginationActions';
import TableHeader from './TableHeader';

const rowHeight = 49;

const useStyles = makeStyles(theme => ({
  tableWrapper: {
    overflowX: 'auto',
  },
  table: {
    fontFamily: theme.typography.fontFamily,
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  tableRow: {
    cursor: 'pointer',
    height: rowHeight,
  },
  tableRowHover: {
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  tableCell: {
    flex: 1,
  },
  noClick: {
    cursor: 'initial',
  },
}));

const rowsPerPage = Constants.table.perPage;

const BaseTable = ({
  columns,
  data,
  total,
  loadMore,
  TableItem,
  page,
  setPage,
  hasMore,
  nextPage,
  previousPage,
}) => {
  const classes = useStyles();
  const startRowIndex = page * rowsPerPage;
  const tableData = data.slice(startRowIndex, startRowIndex + rowsPerPage);
  const empty = rowsPerPage - tableData.length;

  return (
    <div className={classes.tableWrapper}>
      <Table className={classes.table}>
        <TableHeader columns={columns} />
        <TableBody>
          {tableData.map(row => (
            <TableItem
              className={classes.tableRow}
              key={row.id}
              index={row.id}
              {...row}
            />
          ))}
          {empty > 0 && (
            <TableRow style={{ height: rowHeight * empty }}>
              <TableCell colSpan={columns.length} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={total}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                native: true,
              }}
              onChangePage={(event, newPage) => {
                setPage(newPage);
                if (!!loadMore && (startRowIndex + ((rowsPerPage * 2))
                  >= data.length)) {
                  loadMore();
                }
              }}
              rowsPerPageOptions={[rowsPerPage]}
              ActionsComponent={props => (
                <TablePaginationActions
                  {...props}
                  nextPage={nextPage}
                  previousPage={previousPage}
                  hasMore={hasMore}
                />
              )}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </div>
  );
};

BaseTable.defaultProps = {
  loadMore: null,
  hasMore: true,
  total: -1,
  nextPage: null,
  previousPage: null,
};

BaseTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      dataKey: PropTypes.string.isRequired,
      align: PropTypes.oneOf([
        'inherit', 'left', 'right', 'center', 'justify',
      ]),
    }),
  ).isRequired,
  data: PropTypes.array.isRequired,
  TableItem: PropTypes.func.isRequired,
  total: PropTypes.number,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  loadMore: PropTypes.func,
  nextPage: PropTypes.func,
  previousPage: PropTypes.func,
  hasMore: PropTypes.bool,
};

export default BaseTable;
