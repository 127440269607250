const firestore = {
  collections: {
    stores: 'stores',
    products: 'products',
    items: 'items',
    organisations: 'organisations',
    users: 'users',
    settlements: 'settlements',
    alerts: 'alerts',
    actions: 'actions',
  },
};

export default firestore;
