import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import classNames from 'classnames';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import CardActionArea from '@material-ui/core/CardActionArea';
import { ReactComponent as IconSleep } from '../../assets/icons/ic-sleep.svg';

const useStyles = makeStyles(theme => ({
  card: {
    position: 'relative',
    display: 'inline-flex',
    flexDirection: 'column',
    borderRadius: 40,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    color: '#181818',
    transition: 'background-color 0.8s ease-out',
    boxSizing: 'content-box',
    userSelect: 'none',
    whiteSpace: 'normal',
    [theme.breakpoints.down('lg')]: {
      width: 300,
      height: 350,
    },
    [theme.breakpoints.up('lg')]: {
      width: 400,
      height: 450,
    },
  },
  actionArea: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(2),
    height: '100%',
  },
  animation: {
    flex: 1,
  },
  stats: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(2),
    boxSizing: 'content-box',
  },
  nameContainer: {
    marginRight: 60,
  },
  name: {
    color: '#181818',
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: '2.8428571428571423rem',
    },
  },
  value: {
    flex: 1,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  amount: {
    fontWeight: 'bold',
  },
  closed: {
    backgroundColor: '#181818',
  },
  nameClosed: {
    color: '#343434',
  },
  amountClosed: {
    color: '#C7C7C7',
  },
  valueClosed: {
    flex: 1,
    color: '#8D8D8D',
  },
  cash: {
    color: '#88DF64',
  },
  icon: {
    position: 'absolute',
    top: theme.spacing(4),
    right: theme.spacing(4),
    width: 40,
  },
}));

const WarehouseCard = ({
  className, name, isClosed, disabled, onPress,
}) => {
  const classes = useStyles();
  return (
    <Card
      className={
        classNames([
          classes.card,
          className,
          isClosed && classes.closed,
        ])
      }
    >
      <CardActionArea
        className={classes.actionArea}
        onClick={!disabled ? onPress : null}
      >
        {isClosed && (<IconSleep className={classes.icon} />)}
        <CardContent className={classes.nameContainer}>
          <Typography
            className={
              classNames([
                classes.name,
                isClosed && classes.nameClosed,
              ])
            }
            variant="h3"
          >
            {name}
          </Typography>
        </CardContent>
        <CardContent className={classes.animation} />
      </CardActionArea>
    </Card>
  );
};

WarehouseCard.defaultProps = {
  className: null,
  isClosed: false,
  disabled: false,
};

WarehouseCard.propTypes = {
  className: PropTypes.string,
  isClosed: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default WarehouseCard;
