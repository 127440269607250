export const actions = {
  CREATE: {
    REQUEST: 'INVENTORIES_CREATE_REQUEST',
    SUCCESS: 'INVENTORIES_CREATE_SUCCESS',
    ERRORS: 'INVENTORIES_CREATE_ERRORS',
  },
  DELETE: {
    REQUEST: 'INVENTORIES_DELETE_REQUEST',
    SUCCESS: 'INVENTORIES_DELETE_SUCCESS',
    ERRORS: 'INVENTORIES_DELETE_ERRORS',
    RESET: 'INVENTORIES_DELETE_RESET',
  },
};

export const inventoriesCreateRequest = ({
  placeId,
  supplierId,
  quantity,
  productId,
  printerId,
  print,
}) => ({
  type: actions.CREATE.REQUEST,
  payload: {
    placeId,
    supplierId,
    quantity,
    productId,
    printerId,
    print,
  },
});

export const inventoriesCreateSuccess = () => ({
  type: actions.CREATE.SUCCESS,
});

export const inventoriesCreateErrors = ({ errors }) => ({
  type: actions.CREATE.ERRORS,
  payload: errors,
});
export const inventoriesDeleteRequest = ({ inventoryId }) => ({
  type: actions.DELETE.REQUEST,
  payload: inventoryId,
});

export const inventoriesDeleteSuccess = () => ({
  type: actions.DELETE.SUCCESS,
});

export const inventoriesDeleteErrors = ({ errors }) => ({
  type: actions.DELETE.ERRORS,
  payload: errors,
});

export const inventoriesDeleteReset = () => ({
  type: actions.DELETE.RESET,
});
