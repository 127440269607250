import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import PrinterDropdown from './Base/Input/PrinterDropdown';
import QuantityInput from './Base/Input/QuantityInput';
import ModalForm from './ModalForm';
import { barcodesPrintRequest } from '../providers/redux/actions/Barcodes';

const BarcodePrintModal = ({
  isOpen, onClose, productId, storeId, placeId,
}) => {
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState('');
  const [printer, setPrinter] = useState(null);

  const {
    processing, errors, success,
  } = useSelector(state => ({
    processing: state.barcodesReducer.print.processing,
    success: state.barcodesReducer.print.success,
    errors: state.barcodesReducer.print.errors,
    places: state.authReducer.user.places || [],
  }));

  const {
    printer: printerErrors,
    quantity: quantityErrors,
  } = errors;

  const reset = () => {
    setPrinter(null);
    setQuantity('');
  };

  const print = () => dispatch(
    barcodesPrintRequest({
      productId,
      printerId: printer ? printer.id : null,
      storeId,
      quantity,
    }),
  );

  useEffect(() => {
    if (success) {
      reset();
      onClose();
    }
  }, [success]);

  return (
    <ModalForm
      open={isOpen}
      close={onClose}
      title="Print labels"
      confirm={print}
      loading={processing}
    >
      <PrinterDropdown
        onChange={setPrinter}
        placeId={placeId}
        selected={printer}
        onEnter={print}
        errors={printerErrors}
      />
      <QuantityInput
        label="Quantity"
        onChange={setQuantity}
        errors={quantityErrors}
        placeholder="1"
        value={quantity}
        onEnter={print}
        autoFocus
      />
    </ModalForm>
  );
};

BarcodePrintModal.propTypes = {
  productId: PropTypes.number.isRequired,
  storeId: PropTypes.number.isRequired,
  placeId: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default BarcodePrintModal;
