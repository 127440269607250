import moment from 'moment';

const inventoryTransformer = ({
  id, supplier, quantity, created_at: createdAt,
}) => ({
  id,
  supplier: supplier.name,
  quantity,
  createdAt: moment(createdAt),
});

export default inventoryTransformer;
