export const actions = {
  GET: 'SUPPLIERS_GET',
  PUT: 'SUPPLIERS_PUT',
  UPDATE: 'SUPPLIERS_UPDATE',
  DELETE: 'SUPPLIERS_DELETE',
  RESET: 'SUPPLIERS_RESET',
  CREATE: {
    REQUEST: 'SUPPLIERS_CREATE_REQUEST',
    SUCCESS: 'SUPPLIERS_CREATE_SUCCESS',
    PROCESSING: 'SUPPLIERS_CREATE_PROCESSING',
    PROCESSED: 'SUPPLIERS_CREATE_PROCESSED',
    ERRORS: 'SUPPLIERS_CREATE_ERRORS',
  },
  EDIT: {
    REQUEST: 'SUPPLIERS_EDIT_REQUEST',
    SUCCESS: 'SUPPLIERS_EDIT_SUCCESS',
    PROCESSING: 'SUPPLIERS_EDIT_PROCESSING',
    PROCESSED: 'SUPPLIERS_EDIT_PROCESSED',
    ERRORS: 'SUPPLIERS_EDIT_ERRORS',
  },
  ITEM: {
    SELECT: 'SUPPLIERS_ITEM_SELECT',
    RESET: 'SUPPLIERS_ITEM_RESET',
    PUT: 'SUPPLIERS_ITEM_PUT',
  },
  AUTOCOMPLETE: {
    GET: 'SUPPLIERS_AUTOCOMPLETE_GET',
    PUT: 'SUPPLIERS_AUTOCOMPLETE_PUT',
    CLEAR: 'SUPPLIERS_AUTOCOMPLETE_CLEAR',
  },
};

export const suppliersGet = ({ search, filters, reset }) => ({
  type: actions.GET,
  payload: {
    search,
    filters,
    reset,
  },
});

export const suppliersPut = ({
  page, timestamp, data, hasMore, search,
}) => ({
  type: actions.PUT,
  payload: {
    page,
    timestamp,
    data,
    hasMore,
    search,
  },
});

export const suppliersPutSingle = ({
  supplier,
}) => ({
  type: actions.UPDATE,
  payload: supplier,
});

export const suppliersDelete = ({ id }) => ({
  type: actions.DELETE,
  payload: id,
});

export const suppliersReset = () => ({
  type: actions.RESET,
});

export const suppliersEditRequest = ({
  id,
  name,
}) => ({
  type: actions.EDIT.REQUEST,
  payload: {
    id,
    name,
  },
});

export const suppliersEditProcessing = () => ({
  type: actions.EDIT.PROCESSING,
});

export const suppliersEditProcessed = () => ({
  type: actions.EDIT.PROCESSED,
});

export const suppliersEditSuccess = () => ({
  type: actions.EDIT.SUCCESS,
});

export const suppliersEditErrors = ({ errors }) => ({
  type: actions.EDIT.ERRORS,
  payload: errors,
});

export const suppliersCreateRequest = ({
  name,
}) => ({
  type: actions.CREATE.REQUEST,
  payload: {
    name,
  },
});

export const suppliersCreateProcessing = () => ({
  type: actions.CREATE.PROCESSING,
});

export const suppliersCreateProcessed = () => ({
  type: actions.CREATE.PROCESSED,
});

export const suppliersCreateSuccess = () => ({
  type: actions.CREATE.SUCCESS,
});

export const suppliersCreateErrors = ({ errors }) => ({
  type: actions.CREATE.ERRORS,
  payload: errors,
});

export const suppliersItemSelect = ({ id }) => ({
  type: actions.ITEM.SELECT,
  payload: id,
});

export const suppliersItemReset = () => ({
  type: actions.ITEM.RESET,
});

export const suppliersItemPut = ({ item }) => ({
  type: actions.ITEM.PUT,
  payload: item,
});

export const suppliersAutocompleteGet = ({ text }) => ({
  type: actions.AUTOCOMPLETE.GET,
  payload: text,
});

export const suppliersAutocompletePut = ({ data }) => ({
  type: actions.AUTOCOMPLETE.PUT,
  payload: data,
});

export const suppliersAutocompleteClear = () => ({
  type: actions.AUTOCOMPLETE.CLEAR,
});
