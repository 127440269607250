import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ClickAwayListener,
  makeStyles, Slide,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {
  Category as CategoryIcon,
  Dashboard,
  Description,
  Label as LabelIcon,
  Person,
  SlideshowRounded,
  Store as StoreIcon,
  EmojiFoodBeverageRounded,
} from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconTransport from '../../assets/icons/ic-transport.svg';
import IconSupplier from '../../assets/icons/ic-supplier.svg';
import IconWarehouse from '../../assets/icons/ic-warehouse.svg';
import usePermissions from '../../providers/hooks/usePermissions';

import Container from '../Base/Container';
import Permissions from '../../providers/Permissions';
import SidebarItem from './SidebarItem';
import { siteCloseSidebar } from '../../providers/redux/actions/Site';
import Constants from '../../constants';

const styles = makeStyles(theme => ({
  sidebar: {
    width: 280,
    backgroundColor: theme.palette.primary.main,
    zIndex: 1000,
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      left: 0,
      top: 0,
      bottom: 0,
    },
  },
  title: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    color: theme.palette.common.white,
  },
  overlay: {
    display: 'none',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 100,
  },
  show: {
    display: 'block',
  },
  close: {
    [theme.breakpoints.down('md')]: {
      transform: 'translate(-100%)',
    },
  },
  open: {
    transform: 'translate(0)',
  },
  logo: {
    padding: theme.spacing(2),
  },
  linkText: {
    color: theme.palette.common.white,
  },
  icon: {
    width: 40,
  },
}));

const Sidebar = () => {
  const classes = styles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const { sidebar, goods } = useSelector(state => ({
    goods: state.goodsReducer.index.data,
    sidebar: state.siteReducer.sidebar,
  }));
  const dispatch = useDispatch();
  const userCan = usePermissions();

  const closeSidebar = () => matches && dispatch(
    siteCloseSidebar(),
  );

  const hasProducts = goods.indexOf(Constants.goods.product) !== -1;
  const hasFood = goods.indexOf(Constants.goods.food) !== -1;

  return (
    <ClickAwayListener onClickAway={closeSidebar}>
      <Slide direction="right" in={sidebar} mountOnEnter unmountOnExit>
        <Container
          className={classes.sidebar}
          scroll
        >
          <Container>
            <Container className={classes.title}>Navigation</Container>
            <SidebarItem
              icon={<Dashboard className={classes.icon} />}
              link="/dashboard"
              text="Dashboard"
            />
            {userCan(Permissions.editOrganisations) && (
              <SidebarItem
                icon={<Description className={classes.icon} />}
                link="/organisation"
                text="Manage organisation"
              />
            )}
            {userCan(Permissions.editUsers) && (
              <SidebarItem
                icon={<Person className={classes.icon} />}
                link="/users"
                text="Manage users"
              />
            )}
            {userCan(Permissions.editStores) && (
              <SidebarItem
                icon={<StoreIcon className={classes.icon} />}
                link="/stores"
                text="Your stores"
              />
            )}
            {(userCan(Permissions.viewWarehouses) && hasProducts) && (
              <SidebarItem
                icon={
                  <img src={IconWarehouse} className={classes.icon} alt="" />
                }
                link="/warehouses"
                text="Warehouses"
              />
            )}
            {(userCan(Permissions.viewProducts) && hasProducts) && (
              <SidebarItem
                icon={<FontAwesomeIcon className={classes.icon} icon="boxes" />}
                link="/products"
                text="Products"
              />
            )}
            {(userCan(Permissions.editInventories) && hasProducts) && (
              <SidebarItem
                icon={
                  <img src={IconTransport} className={classes.icon} alt="" />
                }
                link="/movements"
                text="Movement requests"
              />
            )}
            {(userCan(Permissions.editSuppliers) && hasProducts) && (
              <SidebarItem
                icon={
                  <img src={IconSupplier} className={classes.icon} alt="" />
                }
                link="/suppliers"
                text="Suppliers"
              />
            )}
            {(userCan(Permissions.editBrands) && hasProducts) && (
              <SidebarItem
                icon={<LabelIcon className={classes.icon} />}
                link="/brands"
                text="Brands"
              />
            )}
            {(userCan(Permissions.viewFoods) && hasFood) && (
              <SidebarItem
                icon={<EmojiFoodBeverageRounded className={classes.icon} />}
                link="/menu"
                text="Menu"
              />
            )}
            {userCan(Permissions.editCategories) && (
              <SidebarItem
                icon={<CategoryIcon className={classes.icon} />}
                link="/categories"
                text="Categories"
              />
            )}
            {userCan(Permissions.viewTransactions) && (
              <SidebarItem
                icon={<SlideshowRounded className={classes.icon} />}
                link="/display"
                text="Displays"
              />
            )}
          </Container>
        </Container>
      </Slide>
    </ClickAwayListener>
  );
};

export default Sidebar;
