import React from 'react';
import CloseIconButton from '../components/Base/Button/CloseIcon';
import Default from './index';

const SnackbarDefaults = {
  success: (queue, action, message) => queue(message, {
    variant: 'success',
    action: <CloseIconButton />,
    autoHideDuration: Default.autoHideDuration,
  }),
  warning: (queue, action, message) => queue(message, {
    variant: 'warning',
    action: <CloseIconButton />,
    autoHideDuration: Default.autoHideDuration,
  }),
  error: (queue, action, message) => queue(message, {
    variant: 'error',
    action: <CloseIconButton />,
    autoHideDuration: Default.autoHideDuration,
  }),
  variants: {
    success: 'success',
    warning: 'warning',
    error: 'error',
  },
};

export default SnackbarDefaults;
