import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import {
  Toolbar, Tooltip, IconButton, Typography, makeStyles,
} from '@material-ui/core';

const styles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(),
    paddingRight: theme.spacing(),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    color: theme.palette.text.secondary,
  },
  titleContainer: {
    flex: '1',
  },
}));

const TableToolbar = ({
  helpers, actions, numSelected, title,
}) => {
  const classes = styles();
  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.titleContainer}>
        <Typography variant="h5" className={classes.title} id="tableTitle">
          {title}
        </Typography>
      </div>
      <div className={classes.actions}>
        {helpers}
        {numSelected > 0
          ? (
            <Tooltip title="Delete">
              <IconButton href={null} aria-label="Delete">
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )
          : actions
        }
      </div>
    </Toolbar>
  );
};

TableToolbar.defaultProps = {
  actions: null,
  numSelected: null,
  helpers: null,
};

TableToolbar.propTypes = {
  actions: PropTypes.node,
  numSelected: PropTypes.number,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  helpers: PropTypes.element,
};

export default TableToolbar;
