import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import SelectInput from '../../../../../components/Base/Input/SelectInput';
import { brandsAutocompleteGet } from '../../../../../providers/redux/actions/Brands';

const BrandInput = ({
  onChange,
  selected,
  errors,
  onEnter,
  disabled,
}) => {
  const dispatch = useDispatch();
  const { data } = useSelector(
    state => ({
      data: state.brandsReducer.autocomplete.data,
      loading: state.brandsReducer.autocomplete.loading,
    }),
  );

  const onChangeText = text => dispatch(
    brandsAutocompleteGet({ text }),
  );

  return (
    <SelectInput
      selected={selected}
      suggestions={data}
      placeholder="Brand"
      label="Brand"
      errors={errors}
      onChange={onChange}
      onEnter={onEnter}
      onChangeText={onChangeText}
      disabled={disabled}
      isClearable
    />
  );
};

BrandInput.defaultProps = {
  selected: null,
  errors: [],
  onEnter: null,
  disabled: false,
};

BrandInput.propTypes = {
  selected: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.array,
  onEnter: PropTypes.func,
  disabled: PropTypes.bool,
};

export default BrandInput;
