import { actions } from '../actions/Movements';

const initialState = {
  processing: false,
  loading: false,
  timestamp: null,
  page: null,
  data: null,
  hasMore: true,
  selected: null,
  search: '',
  create: {
    processing: false,
    success: false,
    errors: {},
  },
  approve: {
    processing: false,
    success: false,
    errors: {},
  },
  reject: {
    processing: false,
    success: false,
    errors: {},
  },
};

const movementsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET:
      return {
        ...state,
        loading: true,
      };
    case actions.PUT:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actions.APPROVE.REQUEST:
      return {
        ...state,
        approve: {
          ...state.approve,
          processing: true,
          success: false,
          errors: {},
        },
      };
    case actions.APPROVE.ERRORS:
      return {
        ...state,
        approve: {
          ...state.approve,
          errors: action.payload,
        },
      };
    case actions.APPROVE.SUCCESS:
      return {
        ...state,
        approve: {
          ...state.approve,
          processing: false,
          errors: {},
          success: true,
        },
      };
    case actions.REJECT.REQUEST:
      return {
        ...state,
        reject: {
          ...state.reject,
          processing: true,
          success: false,
          errors: {},
        },
      };
    case actions.REJECT.ERRORS:
      return {
        ...state,
        reject: {
          ...state.reject,
          errors: action.payload,
        },
      };
    case actions.REJECT.SUCCESS:
      return {
        ...state,
        reject: {
          ...state.reject,
          processing: false,
          errors: {},
          success: true,
        },
      };
    case actions.CREATE.ERRORS:
      return {
        ...state,
        create: {
          ...state.create,
          errors: action.payload,
        },
      };
    case actions.CREATE.SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          errors: {},
          success: true,
        },
      };
    case actions.RESET:
      return {
        ...state,
        data: null,
        page: null,
        hasMore: true,
        timestamp: null,
      };
    default:
      return state;
  }
};

export default movementsReducer;
