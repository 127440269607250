import React from 'react';
import PropTypes from 'prop-types';

import TextInput from './TextInput';
import QuantityFormat from '../../QuantityFormat';

const QuantityInput = ({
  className,
  label,
  placeholder,
  value,
  onChange,
  errors,
  measurement,
  autoFocus,
  onEnter,
  disabled,
}) => (
  <TextInput
    className={className}
    label={label}
    errors={errors}
    value={value}
    onChange={onChange}
    inputComponent={QuantityFormat(measurement)}
    placeholder={placeholder}
    autoFocus={autoFocus}
    onEnter={onEnter}
    disabled={disabled}
    shrink
  />
);

QuantityInput.defaultProps = {
  className: null,
  label: null,
  errors: null,
  placeholder: null,
  measurement: null,
  onEnter: null,
  autoFocus: false,
  disabled: false,
};

QuantityInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onEnter: PropTypes.func,
  errors: PropTypes.array,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  measurement: PropTypes.object,
};

export default QuantityInput;
