import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles, Slide, Typography } from '@material-ui/core';
import Container from '../Base/Container';
import FormatPrice from '../Format/FormatPrice';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  name: {
    flex: 5,
  },
  quantity: {
    flex: 1,
    justifyContent: 'center',
  },
  total: {
    flex: 2,
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const DisplayItem = ({ name, quantity, total }) => {
  const classes = useStyles();
  return (
    <Slide direction="down" in unmountOnExit>
      <Container className={classes.container}>
        <Typography
          variant="h4"
          className={classNames([classes.item, classes.quantity])}
        >
          {quantity}
        </Typography>
        <Typography
          className={classNames([classes.item, classes.name])}
          variant="h5"
        >
          {name}
        </Typography>
        <Typography
          className={classNames([classes.item, classes.total])}
          variant="h4"
        >
          <FormatPrice>{total}</FormatPrice>
        </Typography>
      </Container>
    </Slide>
  );
};

DisplayItem.propTypes = {
  name: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

export default DisplayItem;
