import React from 'react';
import { useSelector } from 'react-redux';
import Login from '../../components/Login';
import App from './App';

const ScreenApp = () => {
  const { authenticated } = useSelector(state => ({
    authenticated: state.authReducer.authenticated,
  }));

  return authenticated ? <App /> : <Login />;
};

export default ScreenApp;
