import React from 'react';
import PropTypes from 'prop-types';
import QueueAnim from 'rc-queue-anim';
import Toolbar from './Components/Toolbar';

const ModelInteractorStandardView = ({
  isSame,
  edit,
  remove,
  processing,
  success,
  children,
  canEdit,
}) => (
  <QueueAnim
    className="flex relative center align-center"
    duration={800}
    type={['bottom']}
  >
    { children }
    { canEdit && (
      <Toolbar
        edit={!isSame}
        processing={processing}
        success={success}
        fireEdit={edit}
        fireDelete={remove}
      />
    )}
  </QueueAnim>
);

ModelInteractorStandardView.defaultProps = {
  edit: null,
  remove: null,
  canEdit: true,
};

ModelInteractorStandardView.propTypes = {
  isSame: PropTypes.bool.isRequired,
  edit: PropTypes.func,
  remove: PropTypes.func,
  processing: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  canEdit: PropTypes.bool,
};

export default ModelInteractorStandardView;
