import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';

const Loader = ({ size, className, style }) => (
  <CircularProgress
    color="secondary"
    className={className}
    style={style}
    size={size || 40}
  />
);

Loader.defaultProps = {
  className: null,
  size: null,
  style: null,
};

Loader.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
  style: PropTypes.object,
};

export default Loader;
