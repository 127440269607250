import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import MainHeader from './MainHeader';
import Container from '../Base/Container';

const styles = makeStyles(() => ({
  container: {
    flex: 1,
    flexDirection: 'column',
  },
}));

const HomeLayout = ({ className, children, disableScroll }) => {
  const classes = styles();
  return (
    <Container className={classes.container} flex>
      <MainHeader />
      <Container flex center scroll={!disableScroll}>
        <Container className={className} flex alignCenter>
          { children }
        </Container>
      </Container>
    </Container>
  );
};

HomeLayout.defaultProps = {
  className: null,
  disableScroll: false,
};

HomeLayout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  disableScroll: PropTypes.bool,
};

export default HomeLayout;
