import React from 'react';
import classNames from 'classnames';
import { Card, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const styles = makeStyles(({ card }) => ({
  card,
  container: {
    overflow: 'hidden',
    borderRadius: card.borderRadius,
  },
}));

const CustomCard = ({ children, className }) => {
  const classes = styles();
  return (
    <Card className={classNames([className, classes.card])}>
      <div className={classes.container}>
        {children}
      </div>
    </Card>
  );
};

CustomCard.defaultProps = {
  className: null,
};

CustomCard.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default CustomCard;
