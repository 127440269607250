import {
  takeLatest, all, call, put, putResolve, select,
} from 'redux-saga/effects';
import {
  actions,
  inventoriesDestroyErrors, inventoriesDestroyReset,
  inventoriesDestroySuccess,
  inventoriesIndexErrors,
  inventoriesIndexSuccess,
} from '../../../actions/Places/Products/Inventories';
import { Instance, Resources } from '../../../../API';
import {
  snackbarDefaultError, snackbarDefaultSuccess,
} from '../../../actions/Snackbar';
import hasErrors from '../../../../hasErrors';
import inventoryTransformer from '../../../transformers/InventoryTransformer';
import { productsItemSelect } from '../../../actions/Products';


const callInventoriesIndex = (productId, placeId) => Instance.get(
  `${Resources.places}/${Resources.products}/${Resources.inventories}`,
  {
    params: {
      product_id: productId,
      place_id: placeId,
    },
  },
);

const callInventoryDestroy = id => Instance.delete(
  `${Resources.inventories}/${id}`,
);

const dataCurrentProduct = state => ({
  placeId: state.placeProductInventoriesReducer.index.placeId,
  product: state.productsReducer.selected,
});

function* sagaInventoryIndex({
  payload: {
    placeId,
    productId,
  },
}) {
  try {
    const {
      data: {
        data: inventories,
      },
    } = yield call(
      callInventoriesIndex,
      productId,
      placeId,
    );

    yield put(
      inventoriesIndexSuccess({
        placeId,
        data: inventories.map(inventoryTransformer),
      }),
    );
  } catch (e) {
    const errors = hasErrors(e);
    if (errors) {
      yield put(
        inventoriesIndexErrors(errors),
      );
    }

    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

function* sagaInventoryDestroy({ payload: inventoryId }) {
  try {
    const { data } = yield call(
      callInventoryDestroy, inventoryId,
    );

    const { product, placeId } = yield select(dataCurrentProduct);
    yield putResolve(
      productsItemSelect({ id: product.id }),
    );

    yield put(
      snackbarDefaultSuccess({ data }),
    );

    yield putResolve(
      inventoriesDestroySuccess(),
    );

    yield put(
      inventoriesDestroyReset(),
    );

    yield call(
      sagaInventoryIndex,
      {
        payload: {
          placeId,
          productId: product.id,
        },
      },
    );
  } catch (e) {
    yield put(
      snackbarDefaultError({ e }),
    );

    const errors = hasErrors(e);
    if (errors) {
      yield put(
        inventoriesDestroyErrors(errors),
      );
    }
  }
}

export default function* PlaceProductInventories() {
  yield all([
    takeLatest(actions.INDEX.REQUEST, sagaInventoryIndex),
    takeLatest(actions.DESTROY.REQUEST, sagaInventoryDestroy),
  ]);
}
