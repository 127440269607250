import React from 'react';
import { DialogContentText } from '@material-ui/core';
import PropTypes from 'prop-types';

const Tooltip = ({ children }) => (
  <DialogContentText>
    {children}
  </DialogContentText>
);

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Tooltip;
