import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import BannerVerification from '../BannerVerification';
import Container from '../Base/Container';
import Logo from '../Logo';
import UserDropdown from './UserDropdown';
import ButtonRegister from '../Buttons/ButtonRegister';
import ButtonLogin from '../Buttons/ButtonLogin';
import useRouter from '../../services/useRouter';
import Button from '../Base/Button';
import Constants from '../../constants';

const styles = makeStyles(theme => ({
  header: {
    boxShadow: '0 0px 6px #00000029 ',
  },
  menu: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  logo: {
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  titleText: {
    fontSize: 20,
    paddingLeft: theme.spacing(2),
  },
  user: {
    justifyContent: 'flex-end',
  },
  headerAction: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginRight: theme.spacing(4),
  },
  headerContainer: {
    minHeight: 84,
  },
  links: {
    marginLeft: theme.spacing(4),
  },
  navLink: {
    boxShadow: 'none',
    borderRadius: 4,
  },
  navLinkText: {
    fontWeight: 'bold',
  },
}));

const MainHeader = () => {
  const classes = styles();
  const { history } = useRouter();
  const { authenticated } = useSelector(state => ({
    authenticated: state.authReducer.authenticated,
  }));

  return (
    <Container className={classes.header}>
      <BannerVerification />
      <Container className={classes.headerContainer} flex alignCenter>
        <Container alignCenter>
          <Logo className={classes.logo} size={48} color />
        </Container>
        <Container className={classes.links} flex>
          <Button
            className={classes.navLink}
            textClassName={classes.navLinkText}
            onClick={() => history.push(Constants.routes.Home)}
            variant="text"
            color="default"
          >
            Home
          </Button>
        </Container>
        <Container className={classes.user}>
          { authenticated
            ? <UserDropdown />
            : (
              <>
                <ButtonRegister className={classes.headerAction} />
                <ButtonLogin className={classes.headerAction} />
              </>
            )}
        </Container>
      </Container>
    </Container>
  );
};

MainHeader.propTypes = {

};

export default MainHeader;
