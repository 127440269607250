import { Dialog } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { HistoryRounded } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IconButton
  from '../../../../components/Base/Button/IconButton/IconButton';
import Container from '../../../../components/Base/Container';
import ChangeHistory from '../../../../components/ChangeHistory/ChangeHistory';
import usePrevious from '../../../../providers/hooks/usePrevious';
import NameInput from './NameInput';
import BrandInput from './Inputs/BrandInput';
import CategoryInput from '../../../../components/CategoryInput';
import QuotesList from './Quotes/QuotesList';
import BarcodesList from './Barcodes/BarcodesList';
import Permissions from '../../../../providers/Permissions';
import InventoriesList from './Inventories/InventoriesList';
import MeasurementDropdown from '../../../../components/Base/Input/MeasurementDropdown';
import AliasInput from './AliasInput';
import {
  productsDelete,
  productsEditRequest,
  productsHistoryIndexRequest,
} from '../../../../providers/redux/actions/Products';
import usePermissions from '../../../../providers/hooks/usePermissions';
import ModelInteractorStyles
  from '../../../../components/Base/ModelInteractor/ModelInteractorStyles';
import ModelInteractorStandardView
  from '../../../../components/Base/ModelInteractor/ModelInteractorStandardView';
import fastCompare from '../../../../providers/fastCompare';
import useModelInteractorView
  from '../../../../providers/hooks/useModelInteractorView';
import MovementsList from '../../../../components/Movements/MovementsList';
import Prices
  from '../../../../components/Prices';
import Loader from '../../../../components/Base/Loader';
import ImagePicker from '../../../../components/Base/Image/ImagePicker';

const useStyles = makeStyles(
  theme => ({
    basic: {
      display: 'flex',
      flexDirection: 'row',
    },
    header: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(4),
    },
    inputs: {
      flex: 1,
      marginLeft: theme.spacing(4),
    },
    barcodes: {
      marginTop: theme.spacing(2),
    },
    prices: {
      marginTop: theme.spacing(2),
    },
    actions: {
      marginBottom: theme.spacing(),
    },
  }),
);

const ProductsView = () => {
  const userCan = usePermissions();
  const {
    item, errors, processing, success,
  } = useModelInteractorView('productsReducer');
  const {
    history, loadingHistory, historyPage, maxPage,
  } = useSelector(
    state => ({
      history: state.productsReducer.history.data,
      historyPage: state.productsReducer.history.page,
      loadingHistory: state.productsReducer.history.loading,
      maxPage: state.productsReducer.history.maxPage,
    }),
  );

  const dispatch = useDispatch();
  const classes = useStyles();
  const interactorClasses = ModelInteractorStyles();

  const { id } = item;
  const canEditProducts = userCan(Permissions.editProducts);

  const {
    name: productName,
    alias: productAlias,
    price: productPrice,
    brand: productBrand,
    category: productCategory,
    measurement: productMeasurement,
    barcodes: productBarcodes,
    stores: productStores,
    prices: productPrices,
    movements: productMovements,
    fallBelowQuantity: productFallBelowQuantity,
    replenishQuantity: productReplenishQuantity,
    image: productImage,
  } = item;

  const [showingHistory, setShowingHistory] = useState(false);
  const [name, setName] = useState(productName || '');
  const [alias, setAlias] = useState(productAlias || '');
  const [price, setPrice] = useState(productPrice || '');
  const [prices, setPrices] = useState(productPrices || []);
  const [brand, setBrand] = useState(productBrand || null);
  const [category, setCategory] = useState(productCategory || null);
  const [measurement, setMeasurement] = useState(productMeasurement || null);
  const [barcodes, setBarcodes] = useState(
    productBarcodes && productBarcodes.length > 0
      ? productBarcodes
      : [''],
  );
  const [fallBelowQuantity, setFallBelowQuantity] = useState(productFallBelowQuantity || '');
  const [replenishQuantity, setReplenishQuantity] = useState(productReplenishQuantity || '');
  const [image, setImage] = useState(productImage || null);

  const previousPrice = usePrevious(price);


  const previous = {
    name: productName,
    alias: productAlias,
    price: productPrice,
    prices: productPrices,
    brand: productBrand,
    category: productCategory,
    measurement: productMeasurement,
    barcodes: productBarcodes,
    fallBelowQuantity: productFallBelowQuantity,
    replenishQuantity: productReplenishQuantity,
    image: productImage,
  };

  const current = {
    name,
    alias,
    price,
    prices,
    category,
    brand,
    measurement,
    barcodes,
    fallBelowQuantity,
    replenishQuantity,
    image,
  };

  const setStorePrice = (storeId, storePrice) => {
    const newPrices = [
      ...prices.filter(({ id: priceStoreId }) => priceStoreId !== storeId),
      {
        ...prices.find(({ id: priceStoreId }) => priceStoreId === storeId),
        price: storePrice,
      },
    ];

    setPrices(newPrices);
  };

  const remove = () => dispatch(productsDelete({ id }));

  const isSame = fastCompare(previous, current);

  const edit = () => {
    if (!isSame) {
      dispatch(
        productsEditRequest({
          id,
          name,
          alias,
          price,
          prices,
          category,
          brand,
          measurement,
          barcodes,
          replenishQuantity,
          fallBelowQuantity,
          image,
        }),
      );
    }
  };

  const onChangePage = page => dispatch(
    productsHistoryIndexRequest(page),
  );

  const onOpenHistory = () => setShowingHistory(true);
  const onCloseHistory = () => setShowingHistory(false);

  useEffect(() => {
    if (success) {
      document.activeElement.blur();
    }
  }, [success]);

  useEffect(() => {
    if (item.id) {
      const {
        name: newProductName,
        alias: newProductAlias,
        price: newProductPrice,
        brand: newProductBrand,
        category: newProductCategory,
        measurement: newProductMeasurement,
        barcodes: newProductBarcodes,
        prices: newProductPrices,
        fallBelowQuantity: newProductFallBelowQuantity,
        replenishQuantity: newProductReplenishQuantity,
        image: newProductImage,
      } = item;

      setName(newProductName);
      setAlias(newProductAlias);
      setPrice(newProductPrice);
      setPrices(newProductPrices);
      setBrand(newProductBrand);
      setCategory(newProductCategory);
      setMeasurement(newProductMeasurement);
      setBarcodes(newProductBarcodes);
      setFallBelowQuantity(newProductFallBelowQuantity);
      setReplenishQuantity(newProductReplenishQuantity);
      setImage(newProductImage);
    }
  }, [item]);

  useEffect(() => {
    const storePrices = [...prices];
    const formatPreviousPrice = parseFloat(previousPrice) || 0;

    setPrices(
      storePrices.map((original) => {
        const formatStorePrice = parseFloat(original.price) || 0;
        if (formatPreviousPrice === formatStorePrice) {
          return {
            ...original,
            price,
          };
        }

        return original;
      }),
    );
  }, [price]);

  if (!id) {
    return <Loader />;
  }

  return (
    <ModelInteractorStandardView
      isSame={isSame}
      edit={canEditProducts ? edit : null}
      remove={canEditProducts ? remove : null}
      processing={processing}
      success={success}
      canEdit={canEditProducts}
    >
      <Dialog
        maxWidth="md"
        open={showingHistory}
        onClose={onCloseHistory}
        fullWidth
      >
        <ChangeHistory
          loading={loadingHistory}
          onChangePage={onChangePage}
          page={historyPage}
          events={history}
          container="div"
          hasMore={!(maxPage !== null && historyPage === maxPage)}
        />
      </Dialog>
      <Container flex scroll>
        <Container
          className={interactorClasses.container}
          key={id}
          flex
          horizontal
        >
          <Container className={classes.basic}>
            <Container>
              <ImagePicker
                key={image}
                onChange={setImage}
                image={image}
                shape="square"
                width={100}
                height={100}
              />
            </Container>
            <Container className={classes.inputs}>
              <NameInput
                onChange={setName}
                name={name}
                errors={errors.name}
                onEnter={edit}
                disabled={!canEditProducts}
              />
              <AliasInput
                onChange={setAlias}
                name={alias}
                errors={errors.alias}
                onEnter={edit}
                disabled={!canEditProducts}
              />
              <Container horizontal flex>
                <Container className="input">
                  <BrandInput
                    onChange={setBrand}
                    errors={errors.brand_id}
                    onEnter={edit}
                    selected={brand}
                    disabled={!canEditProducts}
                  />
                </Container>
                <Container className="input">
                  <CategoryInput
                    onChange={setCategory}
                    errors={errors.category_id}
                    selected={category}
                    onEnter={edit}
                    disabled={!canEditProducts}
                  />
                </Container>
                <Container className="input">
                  <MeasurementDropdown
                    selected={measurement}
                    onChange={setMeasurement}
                    errors={errors.measurement}
                    onEnter={edit}
                    disabled={!canEditProducts}
                  />
                </Container>
                <Container className={classes.header}>
                  <Typography
                    className={classes.actions}
                    variant="caption"
                  >
                    Actions
                  </Typography>
                  <Container>
                    <IconButton
                      label="View change history"
                      color="default"
                      onPress={onOpenHistory}
                    >
                      <HistoryRounded />
                    </IconButton>
                  </Container>
                </Container>
              </Container>
            </Container>
          </Container>
          <Container>
            <InventoriesList
              productId={id}
              measurement={productMeasurement}
              inventories={productStores}
              fallBelowQuantity={fallBelowQuantity}
              replenishQuantity={replenishQuantity}
              setFallBelowQuantity={setFallBelowQuantity}
              setReplenishQuantity={setReplenishQuantity}
              disabled={!canEditProducts}
            />
          </Container>
          {userCan(Permissions.viewQuotes) && (
            <Container className={classes.divider}>
              <QuotesList
                productId={item.id}
              />
            </Container>
          )}
          {(prices && prices.length > 0) && (
            <Container className={classes.prices}>
              <Prices
                standardPrice={price}
                onStandardPriceChange={setPrice}
                standardPriceErrors={errors.price}
                prices={prices}
                onEnter={edit}
                onChange={setStorePrice}
                disabled={!canEditProducts}
              />
            </Container>
          )}
          {productMovements && (
            <Container className={classes.divider}>
              <MovementsList movements={productMovements} />
            </Container>
          )}
          <BarcodesList
            className={classes.barcodes}
            onEnter={edit}
            barcodes={barcodes}
            onChange={(barcode, index) => {
              const newBarcodes = [...barcodes];
              newBarcodes[index] = barcode;
              setBarcodes(newBarcodes);
            }}
            addItem={() => {
              if (barcodes) {
                const newBarcodes = [...barcodes];
                newBarcodes.push('');
                setBarcodes(newBarcodes);
                return;
              }

              setBarcodes(['']);
            }}
            deleteItem={canEditProducts ? (index) => {
              const newBarcodes = [...barcodes];
              newBarcodes.splice(index, 1);
              setBarcodes(newBarcodes);
            } : null}
          />
        </Container>
      </Container>
    </ModelInteractorStandardView>
  );
};

export default ProductsView;
