import { actions } from '../actions/Barcodes';

const initialState = {
  print: {
    processing: false,
    success: false,
    errors: {},
  },
};

const barcodesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.PRINT.REQUEST:
      return {
        ...state,
        print: {
          ...state.print,
          success: false,
          processing: true,
          errors: {},
        },
      };
    case actions.PRINT.SUCCESS:
      return {
        ...state,
        print: {
          ...state.print,
          success: true,
          processing: false,
          errors: {},
        },
      };
    case actions.PRINT.ERRORS:
      return {
        ...state,
        print: {
          ...state.print,
          success: false,
          processing: false,
          errors: action.payload,
        },
      };
    default:
      return state;
  }
};

export default barcodesReducer;
