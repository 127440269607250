import React from 'react';
import {
  CardElement,
} from 'react-stripe-elements';
import Container from '../Base/Container';

const SubscriptionCardDetails = () => (
  <Container>
    <CardElement />
  </Container>
);

export default SubscriptionCardDetails;
