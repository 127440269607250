import {
  all, call, put, select, takeLatest,
} from 'redux-saga/effects';
import { Instance, Resources } from '../../API';
import {
  actions,
  movementsApproveErrors,
  movementsApproveSuccess,
  movementsCreateErrors,
  movementsCreateSuccess,
  movementsPut,
  movementsReset,
} from '../actions/Movements';
import {
  snackbarDefaultError,
  snackbarDefaultSuccess,
} from '../actions/Snackbar';
import movementTransformer from '../transformers/Movement';
import hasErrors from '../../hasErrors';

const resource = `${Resources.movements}`;

const movementsCreateCall = ({
  productId,
  fromStoreId,
  toStoreId,
  quantity,
}) => Instance.post(
  `${Resources.products}/${resource}`,
  {
    product_id: productId,
    from_store_id: fromStoreId,
    to_store_id: toStoreId,
    quantity,
  },
);

export const movementsIndexCall = async ({
  storeId, page, timestamp, search, filters,
}) => Instance.get(
  `${Resources.stores}/${resource}`,
  {
    params: {
      store_id: storeId,
      page: page !== 1 ? page : null,
      search: search !== '' ? search : null,
      timestamp: timestamp || null,
      filters: filters || null,
      paginate: 1,
    },
  },
);

export const movementsPutCall = async ({
  id,
  printerId,
}) => Instance.put(
  `${Resources.products}/${resource}/${id}`,
  {
    id,
    printerId,
  },
);

export const movementsRejectCall = async ({ id }) => Instance.delete(
  `${Resources.products}/${resource}/${id}`,
);

const productData = state => ({
  productId: state.productsReducer.selected.id,
});

const movementsData = state => ({
  data: state.movementsReducer.data,
});

const movementsMetaData = state => ({
  page: state.movementsReducer.page,
  timestamp: state.movementsReducer.timestamp,
  search: state.movementsReducer.search,
});

/**
 * loadMovementsSaga
 * @returns {IterableIterator<*>}
 */
function* loadMovementsSaga({
  payload: {
    storeId, search, filters, reset,
  },
}) {
  try {
    const {
      search: originalSearch,
      page,
      timestamp,
    } = yield select(movementsMetaData);

    if (search !== originalSearch) {
      yield put(movementsReset());
    }

    const { data: responseData } = yield call(
      movementsIndexCall,
      {
        storeId,
        page: reset || !page || search !== originalSearch ? 1 : page + 1,
        timestamp,
        search: search === '' ? null : search,
        filters,
      },
    );

    const { data } = responseData;

    yield put(
      movementsPut({
        data: [...data.map(movementTransformer)],
        search,
      }),
    );
  } catch (e) {
    yield put(snackbarDefaultError({ e }));
  }
}

/**
 * createMovementsSaga
 * @param supplier
 */
function* createMovementsSaga({
  payload: {
    fromStoreId,
    toStoreId,
    quantity,
  },
}) {
  try {
    const { productId } = yield select(productData);
    const { data } = yield call(
      movementsCreateCall,
      {
        productId,
        fromStoreId,
        toStoreId,
        quantity,
      },
    );

    yield put(movementsCreateSuccess());
    yield put(snackbarDefaultSuccess({ data }));
  } catch (e) {
    const errors = hasErrors(e);
    if (errors) {
      yield put(
        movementsCreateErrors({ errors }),
      );
    }
    yield put(snackbarDefaultError({ e }));
  }
}

/**
 * approveMovementsSaga
 * @param supplier
 */
function* approveMovementsSaga({
  payload: {
    id,
    printerId,
  },
}) {
  try {
    const { data } = yield call(
      movementsPutCall,
      {
        id,
        printerId,
      },
    );

    const { data: currentData } = yield select(movementsData);
    const newData = [...currentData];
    yield put(
      movementsPut({
        data: newData.filter(({ id: movementId }) => movementId !== id),
      }),
    );

    yield put(snackbarDefaultSuccess({ data }));
    yield put(movementsApproveSuccess());
  } catch (e) {
    const errors = hasErrors(e);
    if (errors) {
      yield put(
        movementsApproveErrors({ errors }),
      );
    }
    yield put(snackbarDefaultError({ e }));
  }
}

/**
 * rejectMovementsSaga
 * @param id
 */
function* rejectMovementsSaga({ payload: id }) {
  try {
    const response = yield call(movementsRejectCall, { id });

    const { data } = yield select(movementsData);
    const newData = [...data];
    yield put(
      movementsPut({
        data: newData.filter(({ id: movementId }) => movementId !== id),
      }),
    );

    yield put(movementsApproveSuccess());
    yield put(snackbarDefaultSuccess({ data: response.data }));
  } catch (e) {
    yield put(snackbarDefaultError({ e }));
  }
}

export default function* Movements() {
  yield all([
    takeLatest(actions.GET, loadMovementsSaga),
    takeLatest(actions.REJECT.REQUEST, rejectMovementsSaga),
    takeLatest(actions.APPROVE.REQUEST, approveMovementsSaga),
    takeLatest(actions.CREATE.REQUEST, createMovementsSaga),
  ]);
}
