import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../Base/Button';
import Container from '../Base/Container';
import PaymentLogo from './PaymentLogo';
import {
  organisationPaymentsDestroyRequest, organisationPaymentsDestroyReset,
  organisationPaymentsUpdateRequest,
} from '../../providers/redux/actions/Organisation';
import Loader from '../Base/Loader';
import Approval from '../Base/Dialog/Approval';

const useStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(),
    padding: theme.spacing(4),
    borderRadius: theme.spacing(2),
    background: '#FAFAFA',
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  set: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: theme.spacing(2),
  },
  dot: {
    borderRadius: '50%',
    margin: theme.spacing(0.5),
    width: 8,
    height: 8,
    backgroundColor: theme.palette.common.black,
  },
  number: {
    display: 'flex',
    marginLeft: theme.spacing(2),
    justifyContent: 'flex-end',
  },
  expiry: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
  cardDetails: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    borderRadius: 4,
  },
  setDefault: {
    marginRight: theme.spacing(),
  },
  primary: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(4),
  },
}));

const PaymentCard = ({
  token, brand, lastFour, isDefault, expiryMonth, expiryYear,
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    loading, destroyLoading, destroySuccess,
  } = useSelector(state => ({
    loading: state.organisationReducer.payments.update.loading,
    destroyLoading: state.organisationReducer.payments.destroy.loading,
    destroySuccess: state.organisationReducer.payments.destroy.success,
  }));

  const reset = () => dispatch(
    organisationPaymentsDestroyReset(),
  );

  const update = () => dispatch(
    organisationPaymentsUpdateRequest({
      token,
      isDefault: true,
    }),
  );

  const remove = () => dispatch(
    organisationPaymentsDestroyRequest(token),
  );

  const onOpen = () => setOpen(true);

  const onClose = () => setOpen(false);

  useEffect(() => {
    if (destroySuccess) {
      onClose();
      reset();
    }
  }, [destroySuccess]);

  return (
    <Container className={classes.container}>
      <Approval
        closeDialog={onClose}
        content="Are you sure you want to remove this payment method?"
        open={open}
        title="Remove payment method"
        triggerEvent={remove}
        processing={destroyLoading}
      />
      <Container className={classes.details}>
        <PaymentLogo brand={brand} />
        <Container className={classes.cardDetails}>
          <Container className={classes.number}>
            <Container className={classes.set}>
              <Container className={classes.dot} />
              <Container className={classes.dot} />
              <Container className={classes.dot} />
              <Container className={classes.dot} />
            </Container>
            <Container className={classes.set}>
              <Container className={classes.dot} />
              <Container className={classes.dot} />
              <Container className={classes.dot} />
              <Container className={classes.dot} />
            </Container>
            <Container className={classes.set}>
              <Container className={classes.dot} />
              <Container className={classes.dot} />
              <Container className={classes.dot} />
              <Container className={classes.dot} />
            </Container>
            <Typography variant="subtitle1">
              {lastFour}
            </Typography>
          </Container>
          <Container className={classes.expiry}>
            <Typography variant="caption">
              {`Expires ${expiryMonth}/${expiryYear}`}
            </Typography>
          </Container>
        </Container>
      </Container>
      <Container className={classes.actions}>
        { !loading
          ? (
            <>
              {isDefault
                ? (
                  <Container className={classes.primary}>
                    <Typography variant="subtitle2">
                      Primary
                    </Typography>
                  </Container>
                )
                : (
                  <Button
                    className={classNames([classes.button, classes.setDefault])}
                    variant="default"
                    textVariant="caption"
                    onClick={update}
                  >
                      Set as default
                  </Button>
                )
              }
              <Button
                className={classes.button}
                onClick={onOpen}
                color="primary"
                textVariant="caption"
              >
                Remove
              </Button>
            </>
          )
          : (
            <Loader />
          )}
      </Container>
    </Container>
  );
};

PaymentCard.defaultProps = {
  isDefault: false,
};

PaymentCard.propTypes = {
  token: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  lastFour: PropTypes.string.isRequired,
  isDefault: PropTypes.bool,
  expiryMonth: PropTypes.number.isRequired,
  expiryYear: PropTypes.number.isRequired,
};

export default PaymentCard;
