import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import makeStyles from '@material-ui/core/styles/makeStyles';
import ListView from '../../../../../components/Base/ListView';
import ProductsListItem from './ProductsListItem';
import {
  supplierProductsIndexRequest,
  supplierProductsIndexReset,
} from '../../../../../providers/redux/actions/Supplier/Products';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
  },
}));

const ProductsList = ({ supplierId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading, data, hasMore } = useSelector(
    state => ({
      loading: state.supplierProductsReducer.index.loading,
      data: state.supplierProductsReducer.index.data,
      hasMore: state.supplierProductsReducer.index.hasMore,
    }),
  );

  useEffect(() => {
    dispatch(
      supplierProductsIndexRequest(),
    );
  }, [supplierId]);

  const reset = () => dispatch(
    supplierProductsIndexReset(),
  );

  const onMore = search => dispatch(
    supplierProductsIndexRequest(search),
  );

  return (
    <ListView
      className={classes.container}
      reset={reset}
      startPage={1}
      hasMore={hasMore}
      loadNext={(page, search) => onMore(search)}
      loading={loading}
    >
      { data !== null && data.map(product => (
        <ProductsListItem key={product.id} product={product} />
      ))}
    </ListView>
  );
};

ProductsList.propTypes = {
  supplierId: PropTypes.number.isRequired,
};

export default ProductsList;
