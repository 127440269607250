import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText, makeStyles } from '@material-ui/core';

import BarcodeIcon from '../../../../../components/Icons/Barcode';
import Container from '../../../../../components/Base/Container';
import BareText from '../../../../../components/Base/Input/BareText';

const useStyles = makeStyles(
  theme => ({
    icon: {
      width: 40,
      color: theme.palette.icon.main,
    },
  }),
);

const BarcodeInput = ({
  errors, onChange, value, disabled,
}) => {
  const classes = useStyles();
  return (
    <Container flex>
      <FormControl
        error={errors !== undefined}
        disabled={disabled}
        fullWidth
      >
        <BareText
          startAdornment={<BarcodeIcon className={classes.icon} />}
          placeholder="(Ex. 12318492333)"
          onChange={text => onChange(text)}
          value={value}
          disableUnderline
          fullWidth
        />
        {errors && <FormHelperText id="component-error-text">{errors}</FormHelperText>}
      </FormControl>
    </Container>
  );
};

BarcodeInput.defaultProps = {
  disabled: false,
  errors: [],
};

BarcodeInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.array,
  disabled: PropTypes.bool,
};

export default BarcodeInput;
