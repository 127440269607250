import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import Container from '../Base/Container';
import useNavigate from '../../services/useNavigate';

const useStyles = makeStyles(theme => ({
  container: {
    flex: 1,
    display: 'flex',
    textDecoration: 'none',
    color: theme.palette.common.white,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    alignItems: 'center',
  },
  iconContainer: {
    display: 'flex',
    width: 40,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(2),
  },
  linkText: {
    color: theme.palette.common.white,
  },
}));

const SidebarItem = ({
  icon, text, link,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <ListItem
      role="button"
      className={classes.container}
      onClick={() => navigate(link)}
      button
    >
      <Container className={classes.iconContainer}>
        { icon }
      </Container>
      <Typography className={classes.linkText}>
        {text}
      </Typography>
    </ListItem>
  );
};

SidebarItem.propTypes = {
  icon: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default SidebarItem;
