import { makeStyles } from '@material-ui/core';

const ModelInteractorStyles = makeStyles(theme => ({
  parent: {
    width: '100%',
  },
  container: {
    padding: theme.spacing(8),
    paddingBottom: theme.spacing(16),
  },
}));

export default ModelInteractorStyles;
