export const actions = {
  INDEX: {
    REQUEST: 'MENU_INDEX_REQUEST',
    SUCCESS: 'MENU_INDEX_SUCCESS',
    ERRORS: 'MENU_INDEX_ERRORS',
    RESET: 'MENU_INDEX_RESET',
  },
};

export const menuIndexRequest = (search = null) => ({
  type: actions.INDEX.REQUEST,
  payload: search,
});

export const menuIndexSuccess = (data, { search } = {}) => ({
  type: actions.INDEX.SUCCESS,
  payload: {
    search,
    data,
  },
});

export const menuIndexErrors = errors => ({
  type: actions.INDEX.ERRORS,
  payload: errors,
});

export const menuIndexReset = () => ({
  type: actions.INDEX.RESET,
});
