import React, { useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { DeleteRounded, DragHandleRounded } from '@material-ui/icons';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Slide from '@material-ui/core/Slide';
import { useDrag, useDrop } from 'react-dnd';
import FormControl from '@material-ui/core/FormControl';
import Container from '../Base/Container';
import TextInput from '../Base/Input/TextInput';
import IconButton from '../Base/Button/IconButton/IconButton';
import PriceInput from '../Base/Input/PriceInput';
import FoodsDropdown from '../Base/Input/FoodsDropdown';

const useStyles = makeStyles(
  theme => ({
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    dragHandle: {
      cursor: 'move',
      paddingLeft: theme.spacing(),
      paddingRight: theme.spacing(),
    },
    name: {
      flex: 1,
      marginRight: theme.spacing(2),
    },
    price: {
      flex: 1,
      marginRight: theme.spacing(2),
    },
    dragging: {
      opacity: 0.2,
    },
  }),
);

const OptionChoice = ({
  optionUuid,
  id,
  name,
  price,
  onNameChange,
  onPriceChange,
  onDestroy,
  onDrag,
  index,
  onEnter,
  isSetMenu,
}) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: optionUuid,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      onDrag(dragIndex, hoverIndex);
      /**
       * @author    linde@aios.sg
       * @date      24 Feb 2020
       *
       * From react-dnd package:
       * Note: we're mutating the monitor item here!
       * Generally it's better to avoid mutations,
       * but it's good here for the sake of performance
       * to avoid expensive index searches.
       */
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type: optionUuid, id, index },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  drag(
    drop(ref),
  );

  const classes = useStyles();
  return (
    <Slide direction="right" mountOnEnter unmountOnExit in>
      <Container
        ref={ref}
        className={
          classNames([
            classes.container,
            isDragging ? classes.dragging : null,
          ])
        }
      >
        <Container className={classes.dragHandle}>
          <DragHandleRounded />
        </Container>
        <Container className={classes.name}>
          {isSetMenu
            ? <FoodsDropdown onChange={onNameChange} value={name} />
            : (
              <FormControl margin="normal">
                <TextInput
                  label="Choice"
                  placeholder="Choice"
                  onChange={onNameChange}
                  value={name}
                  onEnter={onEnter}
                  shrink
                />
              </FormControl>
            )}
        </Container>
        <FormControl className={classes.price} margin="normal">
          <PriceInput
            onChange={onPriceChange}
            value={price}
            onEnter={onEnter}
          />
        </FormControl>
        <Container>
          <IconButton onPress={onDestroy}>
            <DeleteRounded />
          </IconButton>
        </Container>
      </Container>
    </Slide>
  );
};

OptionChoice.defaultProps = {
  isSetMenu: false,
};

OptionChoice.propTypes = {
  optionUuid: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onNameChange: PropTypes.func.isRequired,
  onPriceChange: PropTypes.func.isRequired,
  onDestroy: PropTypes.func.isRequired,
  onDrag: PropTypes.func.isRequired,
  onEnter: PropTypes.func.isRequired,
  isSetMenu: PropTypes.bool,
};

export default OptionChoice;
