import { actions } from '../actions/Inventories';

const initialState = {
  create: {
    processing: false,
    success: false,
    errors: {},
  },
  delete: {
    processing: false,
    success: false,
  },
};

const inventoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.CREATE.REQUEST:
      return {
        ...state,
        create: {
          ...state.create,
          success: false,
          processing: true,
          errors: {},
        },
      };
    case actions.CREATE.ERRORS:
      return {
        ...state,
        create: {
          ...state.create,
          errors: action.payload,
          processing: false,
        },
      };
    case actions.CREATE.SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          errors: {},
          success: true,
          processing: false,
        },
      };
    case actions.DELETE.REQUEST:
      return {
        ...state,
        delete: {
          ...state.delete,
          success: false,
          processing: true,
        },
      };
    case actions.DELETE.ERRORS:
      return {
        ...state,
        delete: {
          ...state.delete,
          errors: action.payload,
          processing: false,
        },
      };
    case actions.DELETE.SUCCESS:
      return {
        ...state,
        delete: {
          ...state.delete,
          success: true,
          processing: false,
        },
      };
    case actions.DELETE.RESET:
      return {
        ...state,
        delete: {
          ...initialState.delete,
        },
      };
    default:
      return state;
  }
};

export default inventoriesReducer;
