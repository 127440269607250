export const actions = {
  INDEX: {
    REQUEST: 'PLACE_PRODUCT_INVENTORIES_INDEX_REQUEST',
    SUCCESS: 'PLACE_PRODUCT_INVENTORIES_INDEX_SUCCESS',
    ERRORS: 'PLACE_PRODUCT_INVENTORIES_INDEX_ERRORS',
  },
  DESTROY: {
    REQUEST: 'PLACE_PRODUCT_INVENTORIES_DESTROY_REQUEST',
    SUCCESS: 'PLACE_PRODUCT_INVENTORIES_DESTROY_SUCCESS',
    ERRORS: 'PLACE_PRODUCT_INVENTORIES_DESTROY_ERRORS',
    RESET: 'PLACE_PRODUCT_INVENTORIES_DESTROY_RESET',
  },
};

export const inventoriesIndexRequest = (placeId, productId) => ({
  type: actions.INDEX.REQUEST,
  payload: {
    placeId,
    productId,
  },
});

export const inventoriesIndexErrors = errors => ({
  type: actions.INDEX.ERRORS,
  payload: errors,
});

export const inventoriesIndexSuccess = ({ placeId, data }) => ({
  type: actions.INDEX.SUCCESS,
  payload: {
    placeId,
    data,
  },
});

export const inventoriesDestroyRequest = id => ({
  type: actions.DESTROY.REQUEST,
  payload: id,
});

export const inventoriesDestroyErrors = errors => ({
  type: actions.DESTROY.ERRORS,
  payload: errors,
});

export const inventoriesDestroySuccess = () => ({
  type: actions.DESTROY.SUCCESS,
});

export const inventoriesDestroyReset = () => ({
  type: actions.DESTROY.RESET,
});
