import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import QueueAnim from 'rc-queue-anim';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Layout from '../../../components/Layout';
import Container from '../../../components/Base/Container';
import UserCard from '../../../components/UserCard';
import { usersGet } from '../../../providers/redux/actions/Users';
import Loader from '../../../components/Base/Loader';
import { storesGet } from '../../../providers/redux/actions/Stores';
import PageHeader from '../../../components/PageHeader';

const useStyles = makeStyles(() => ({
  container: {
    display: 'block',
    width: '100%',
  },
  list: {
    display: 'flex',
  },
}));

const ScreenUsers = ({
  users,
  loading,
  usersGet: usersGetAction,
  storesGet: storesGetAction,
}) => {
  const classes = useStyles();

  useEffect(() => {
    storesGetAction({});
    usersGetAction({});
  }, []);

  return (
    <Layout flex>
      {loading || !users
        ? (
          <Container
            flex
            center
            alignCenter
            maxHeight
            maxWidth
          >
            <Loader />
          </Container>
        )
        : (
          <QueueAnim type="bottom" duration={800} className={classes.container}>
            <PageHeader title="Manage users" />
            <Container
              key={1}
              className={classes.list}
              maxWidth
              maxHeight
              scroll
            >
              {users.map(user => (
                <UserCard
                  id={user.id}
                  key={user.id}
                  name={user.email}
                  roles={user.roles.map(({ id }) => id)}
                  stores={user.stores.map(({ id }) => id)}
                />
              ))}
            </Container>
          </QueueAnim>
        )
      }
    </Layout>
  );
};

ScreenUsers.defaultProps = {
  users: null,
};

ScreenUsers.propTypes = {
  users: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  usersGet: PropTypes.func.isRequired,
  storesGet: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  organisation: state.organisationReducer.data,
  users: state.usersReducer.data,
  loading: state.usersReducer.loading,
});

export default connect(
  mapStateToProps,
  { usersGet, storesGet },
)(ScreenUsers);
