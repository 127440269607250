import goodTransformer from './GoodTransformer';

const OrganisationTransformer = ({
  id,
  name,
  username,
  registration_no: registrationNo,
  tax_registration: taxRegistrationNo,
  tax,
  inventory_method: inventoryMethod,
  paymentMethods,
  goods,
  terms,
}) => ({
  id,
  name,
  domain: username,
  registrationNo,
  taxRegistrationNo,
  tax,
  inventoryMethod,
  paymentMethods,
  goods: goods.map(goodTransformer),
  terms,
});

export default OrganisationTransformer;
