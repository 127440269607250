import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import Approval from './Base/Dialog/Approval';
import IconButton from './Base/Button/IconButton/IconButton';
import { inventoriesDestroyRequest } from '../providers/redux/actions/Places/Products/Inventories';

const PlaceInventoryListItem = ({
  className, id, quantity, supplier, createdAt,
}) => {
  const dispatch = useDispatch();
  const [isDestroying, setIsDestroying] = useState(false);
  const {
    destroyLoading, destroySuccess,
  } = useSelector(
    state => ({
      destroyLoading: state.placeProductInventoriesReducer.destroy.loading,
      destroySuccess: state.placeProductInventoriesReducer.destroy.success,
    }),
  );

  const onDestroy = () => dispatch(
    inventoriesDestroyRequest(id),
  );

  const onOpenDestroy = () => setIsDestroying(true);

  const onCloseDestroy = () => setIsDestroying(false);

  useEffect(() => {
    if (!destroyLoading && destroySuccess) {
      onCloseDestroy();
    }
  }, [destroyLoading, destroySuccess]);

  return (
    <TableRow className={className} key={id}>
      <Approval
        processing={destroyLoading}
        closeDialog={onCloseDestroy}
        content="Are you sure you want to delete this?"
        open={isDestroying}
        title="Delete inventory"
        triggerEvent={() => onDestroy(id)}
      />
      <TableCell component="th" scope="row">
        {supplier}
      </TableCell>
      <TableCell align="right">{quantity}</TableCell>
      <TableCell>{createdAt.fromNow()}</TableCell>
      <TableCell>
        <IconButton
          color="default"
          onPress={onOpenDestroy}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

PlaceInventoryListItem.defaultProps = {
  className: null,
};

PlaceInventoryListItem.propTypes = {
  className: PropTypes.string,
  id: PropTypes.number.isRequired,
  supplier: PropTypes.string.isRequired,
  quantity: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  createdAt: PropTypes.object.isRequired,
};

export default PlaceInventoryListItem;
