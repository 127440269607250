import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Fade } from 'react-reveal';
import { makeStyles, Typography } from '@material-ui/core';
import HomeLayout from '../../../components/Layout/HomeLayout';
import Container from '../../../components/Base/Container';
import TextInput from '../../../components/Base/Input/TextInput';
import ButtonGlow from '../../../components/Buttons/ButtonGlow';
import { authEditRequest } from '../../../providers/redux/actions/Auth';
import Loader from '../../../components/Base/Loader';
import SiteAppNavigation from '../../../components/Site/SiteAppNavigation';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(8),
    maxWidth: 1200,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  title: {
    fontWeight: 'bold',
  },
  inputs: {
    maxWidth: 400,
    marginTop: theme.spacing(3),
  },
  button: {
    marginTop: theme.spacing(5),
    width: '100%',
  },
  action: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const ScreenProfile = () => {
  const classes = useStyles();

  const { processing, errors, user } = useSelector(state => ({
    user: state.authReducer.user,
    processing: state.authReducer.edit.processing,
    success: state.authReducer.edit.success,
    errors: state.authReducer.edit.errors,
  }));

  const {
    // email: userEmail,
    firstName: userFirstName,
    lastName: userLastName,
    phoneNo: userPhoneNo,
  } = user;

  const {
    firstName: firstNameErrors,
    lastName: lastNameErrors,
    phoneNo: phoneNoErrors,
  } = errors;

  // const [email, setEmail] = useState(userEmail);
  // const [password, setPassword] = useState('');
  // const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState(userFirstName);
  const [lastName, setLastName] = useState(userLastName);
  const [phoneNo, setPhoneNo] = useState(userPhoneNo);

  const dispatch = useDispatch();

  const update = () => dispatch(
    authEditRequest({
      // email,
      // password,
      // confirmPassword,
      firstName,
      lastName,
      phoneNo,
    }),
  );

  return (
    <HomeLayout>
      <Container className={classes.container}>
        <SiteAppNavigation />
        <Container>
          <Fade bottom>
            <Typography className={classes.title} variant="h4">
            Your profile
            </Typography>
            <Container className={classes.inputs}>
              {/* <TextInput */}
              {/*  label="E-mail address" */}
              {/*  onChange={setEmail} */}
              {/*  value={email} */}
              {/*  shrink */}
              {/*  required */}
              {/* /> */}
              {/* <TextInput */}
              {/*  type="password" */}
              {/*  label="Password" */}
              {/*  onChange={setPassword} */}
              {/*  value={password} */}
              {/*  shrink */}
              {/*  required */}
              {/* /> */}
              {/* <TextInput */}
              {/*  type="password" */}
              {/*  label="Confirm password" */}
              {/*  onChange={setConfirmPassword} */}
              {/*  value={confirmPassword} */}
              {/*  shrink */}
              {/*  required */}
              {/* /> */}
              <TextInput
                label="First name"
                onChange={setFirstName}
                value={firstName}
                errors={firstNameErrors}
                shrink
                required
              />
              <TextInput
                label="Last name"
                onChange={setLastName}
                value={lastName}
                errors={lastNameErrors}
                shrink
                required
              />
              <TextInput
                label="Phone number"
                onChange={setPhoneNo}
                value={phoneNo}
                errors={phoneNoErrors}
                shrink
                required
              />
              <div className={classes.action}>
                { processing ? <Loader /> : (
                  <ButtonGlow className={classes.button} onPress={update}>
                  Update
                  </ButtonGlow>
                )}
              </div>
            </Container>
          </Fade>
        </Container>
      </Container>
    </HomeLayout>
  );
};

export default ScreenProfile;
