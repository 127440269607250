import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { WarningRounded, ErrorRounded } from '@material-ui/icons';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Constants from '../constants';

const useStyles = makeStyles(
  () => ({
    icon: {
      width: 60,
      height: 60,
    },
    critical: {
      color: '#FF6961',
    },
    warning: {
      color: '#FFC681',
    },
    info: {
      color: '#009dff',
    },
  }),
);

const AlertIcon = ({ className, level }) => {
  const classes = useStyles();
  switch (level) {
    case Constants.alerts.critical:
      return (
        <ErrorRounded
          className={
            classNames([classes.icon, classes.critical, className])
          }
        />
      );
    case Constants.alerts.warning:
      return (
        <WarningRounded
          className={
            classNames([classes.icon, classes.warning, className])
          }
        />
      );
    case Constants.alerts.normal:
    default:
      return null;
  }
};

AlertIcon.defaultProps = {
  className: null,
};

AlertIcon.propTypes = {
  className: PropTypes.string,
  level: PropTypes.oneOf([
    Constants.alerts.critical,
    Constants.alerts.warning,
    Constants.alerts.normal,
  ]).isRequired,
};

export default AlertIcon;
