import _ from 'lodash';

const camelCaseTransformer = (obj) => {
  if (!_.isObject(obj)) {
    return obj;
  }

  if (_.isArray(obj)) {
    return obj.map(v => camelCaseTransformer(v));
  }

  return _.reduce(obj, (r, v, k) => ({
    ...r,
    [_.camelCase(k)]: camelCaseTransformer(v),
  }), {});
};

export default camelCaseTransformer;
