export const actions = {
  SYNC: 'DASHBOARD_SYNC',
  STATISTICS: {
    PUT: 'DASHBOARD_STATISTICS_PUT',
  },
  STORES: {
    PUT: 'DASHBOARD_STORES_PUT',
  },
  SETTLEMENTS: {
    SYNC: 'DASHBOARD_SETTLEMENTS_SYNC',
    PUT: 'DASHBOARD_SETTLEMENTS_PUT',
  },
};

export const dashboardSync = () => ({
  type: actions.SYNC,
});

export const dashboardStatisticsPut = stats => ({
  type: actions.STATISTICS.PUT,
  payload: stats,
});

export const dashboardStoresPut = stores => ({
  type: actions.STORES.PUT,
  payload: stores,
});

export const dashboardSettlementsSync = organisationId => ({
  type: actions.SETTLEMENTS.SYNC,
  payload: organisationId,
});

export const dashboardSettlementsPut = settlements => ({
  type: actions.SETTLEMENTS.PUT,
  payload: settlements,
});
