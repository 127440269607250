import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { Select } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';

const DropdownStore = ({
  value, errors, onChange, exclusions,
}) => {
  const { stores } = useSelector(state => ({
    stores: state.authReducer.user.stores
      .filter(({ id }) => exclusions.indexOf(id) === -1),
  }));

  useEffect(() => {
    if (stores.length === 1) {
      onChange(stores[0]);
    }
  }, []);

  return (
    <Select
      value={value}
      placeholder="Store"
      errors={errors}
      onChange={event => onChange(event.target.value)}
      disableUnderline
    >
      {stores.map(({ id, name }) => <MenuItem value={id}>{name}</MenuItem>)}
    </Select>
  );
};

DropdownStore.defaultProps = {
  errors: [],
  exclusions: [],
};

DropdownStore.propTypes = {
  value: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
  }).isRequired,
  errors: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  exclusions: PropTypes.array,
};

export default DropdownStore;
