import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Switch } from 'react-router-dom';
import ServerUnreachable from '../components/Errors/ServerUnreachable';
import ScreenApp from './app';
import ScreenHome from './index';
import LoadingScreen from '../components/LoadingScreen';

const Controller = () => {
  const { initialised, organisation, error } = useSelector(
    state => ({
      initialised: state.siteReducer.initialised,
      organisation: state.organisationReducer.data,
      error: state.organisationReducer.error,
    }),
  );

  if (error) {
    return <ServerUnreachable />;
  }

  if (!initialised) {
    return <LoadingScreen />;
  }

  return (
    <BrowserRouter>
      <Switch>
        {organisation
          ? <ScreenApp organisation={organisation} />
          : <ScreenHome />
        }
      </Switch>
    </BrowserRouter>
  );
};

export default Controller;
