import { actions } from '../../actions/Supplier/Products';

const initialState = {
  index: {
    supplierId: null,
    loading: false,
    timestamp: null,
    page: 1,
    data: [],
    search: '',
    errors: {},
    hasMore: false,
  },
};

const supplierProductsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.INDEX.REQUEST:
      return {
        ...state,
        index: {
          ...state.index,
          loading: true,
        },
      };
    case actions.INDEX.SUCCESS:
      return {
        ...state,
        index: {
          ...state.index,
          loading: false,
          search: action.payload.search,
          data: action.payload.data,
          page: action.payload.page,
          timestamp: action.payload.timestamp,
          hasMore: action.payload.hasMore,
          supplierId: action.payload.supplierId,
        },
      };
    case actions.INDEX.ERRORS:
      return {
        ...state,
        index: {
          ...state.index,
          loading: false,
          errors: action.payload,
        },
      };
    case actions.INDEX.RESET:
      return {
        ...state,
        index: {
          ...initialState.index,
        },
      };
    default:
      return state;
  }
};

export default supplierProductsReducer;
