import React from 'react';
import PropTypes from 'prop-types';
import StoreIcon from '@material-ui/icons/Store';
import SearchFiltersSelect from '../../../../../components/Base/ListView/Search/SearchFilters/SearchFiltersSelect';

const StoreFilters = ({ filters, onFiltersChange, selected }) => (
  <SearchFiltersSelect
    startAdornment={<StoreIcon />}
    selected={selected}
    filters={filters}
    onFiltersChange={onFiltersChange}
  />
);

StoreFilters.defaultProps = {
  selected: null,
};

StoreFilters.propTypes = {
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  filters: PropTypes.array.isRequired,
  onFiltersChange: PropTypes.func.isRequired,
};

export default StoreFilters;
