import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Fab as FabButton } from '@material-ui/core';

const FAB = ({
  className, style, onClick, color, label, size, disabled, classes, children,
}) => {
  const classesNames = [
    'fab',
  ];

  if (className) {
    classesNames.push(className);
  }

  const onButtonClick = (e) => {
    e.currentTarget.blur();
    onClick(e);
  };

  return (
    <FabButton
      className={classNames(classesNames)}
      style={style}
      onClick={onButtonClick}
      color={color}
      aria-label={label}
      size={size || 'medium'}
      disabled={disabled || false}
      classes={classes || null}
    >
      {children}
    </FabButton>
  );
};

FAB.defaultProps = {
  className: null,
  disabled: false,
  size: null,
  classes: null,
  style: null,
};

FAB.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  classes: PropTypes.object,
  style: PropTypes.object,
  children: PropTypes.node.isRequired,
};

export default FAB;
