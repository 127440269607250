import { combineReducers } from 'redux';

import authReducer from './Auth';
import categoriesReducer from './Categories';
import measurementsReducer from './Measurements';
import organisationReducer from './Organisation';
import productsReducer from './Products';
import siteReducer from './Site';
import snackbarReducer from './Snackbar';
import suppliersReducer from './Suppliers';
import brandsReducer from './Brands';
import storesReducer from './Stores';
import translationsReducer from './Translations';
import usersReducer from './Users';
import rolesReducer from './Roles';
import inventoriesReducer from './Inventories';
import barcodesReducer from './Barcodes';
import printersReducer from './Printers';
import quotesReducer from './Quotes';
import movementsReducer from './Movements';
import displaysReducer from './Displays';
import dashboardReducer from './Dashboard';
import warehousesReducer from './Warehouses';
import placesProductsAlertsReducer from './Places/Products/Alerts';
import storesProductsReducer from './Stores/Products';
import placeProductInventoriesReducer from './Places/Products/Inventories';
import goodsReducer from './Goods';
import foodsReducer from './Foods';
import supplierProductsReducer from './Supplier/Products';
import menuReducer from './Menu';
import setsReducer from './Sets';

export default combineReducers({
  authReducer,
  organisationReducer,
  measurementsReducer,
  siteReducer,
  productsReducer,
  categoriesReducer,
  snackbarReducer,
  suppliersReducer,
  brandsReducer,
  storesReducer,
  usersReducer,
  rolesReducer,
  inventoriesReducer,
  barcodesReducer,
  printersReducer,
  quotesReducer,
  movementsReducer,
  displaysReducer,
  dashboardReducer,
  warehousesReducer,
  placesProductsAlertsReducer,
  storesProductsReducer,
  placeProductInventoriesReducer,
  goodsReducer,
  foodsReducer,
  supplierProductsReducer,
  menuReducer,
  setsReducer,
  translationsReducer,
});
