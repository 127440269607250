import React from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell, IconButton } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';

const PrinterRow = ({
  className, id, name, location, mode, deleteRow,
}) => (
  <TableRow className={className} key={id}>
    <TableCell component="th" scope="row">
      {name}
    </TableCell>
    <TableCell>{location}</TableCell>
    <TableCell>{mode}</TableCell>
    <TableCell>
      <IconButton href={null} onClick={() => deleteRow(id)}>
        <DeleteIcon />
      </IconButton>
    </TableCell>
  </TableRow>
);

PrinterRow.defaultProps = {
  className: null,
};

PrinterRow.propTypes = {
  className: PropTypes.string,
  deleteRow: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  location: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default PrinterRow;
