export const actions = {
  GET: 'USERS_GET',
  PUT: 'USERS_PUT',
  UPDATE: 'USERS_UPDATE',
  DELETE: 'USERS_DELETE',
  RESET: 'USERS_RESET',
  INVITE: {
    REQUEST: 'USERS_INVITE_REQUEST',
    SUCCESS: 'USERS_INVITE_SUCCESS',
    PROCESSING: 'USERS_INVITE_PROCESSING',
    PROCESSED: 'USERS_INVITE_PROCESSED',
    ERRORS: 'USERS_INVITE_ERRORS',
  },
  EDIT: {
    REQUEST: 'USERS_EDIT_REQUEST',
    SUCCESS: 'USERS_EDIT_SUCCESS',
    PROCESSING: 'USERS_EDIT_PROCESSING',
    PROCESSED: 'USERS_EDIT_PROCESSED',
    ERRORS: 'USERS_EDIT_ERRORS',
  },
  ITEM: {
    SELECT: 'USERS_ITEM_SELECT',
    RESET: 'USERS_ITEM_RESET',
    PUT: 'USERS_ITEM_PUT',
  },
  AUTOCOMPLETE: {
    GET: 'USERS_AUTOCOMPLETE_GET',
    PUT: 'USERS_AUTOCOMPLETE_PUT',
    CLEAR: 'USERS_AUTOCOMPLETE_CLEAR',
  },
  REGISTER: {
    REQUEST: 'USERS_REGISTER_REQUEST',
    SUCCESS: 'USERS_REGISTER_SUCCESS',
    ERRORS: 'USERS_REGISTER_ERRORS',
  },
};

export const usersGet = ({ search, filters, reset }) => ({
  type: actions.GET,
  payload: {
    search,
    filters,
    reset,
  },
});

export const usersPut = ({
  page, timestamp, data, hasMore, search,
}) => ({
  type: actions.PUT,
  payload: {
    page,
    timestamp,
    data,
    hasMore,
    search,
  },
});

export const usersPutSingle = ({
  user,
}) => ({
  type: actions.UPDATE,
  payload: user,
});

export const usersDelete = ({ id }) => ({
  type: actions.DELETE,
  payload: id,
});

export const usersReset = () => ({
  type: actions.RESET,
});

export const usersEditRequest = ({
  id,
  stores,
  roles,
}) => ({
  type: actions.EDIT.REQUEST,
  payload: {
    id,
    stores,
    roles,
  },
});

export const usersEditProcessing = () => ({
  type: actions.EDIT.PROCESSING,
});

export const usersEditProcessed = () => ({
  type: actions.EDIT.PROCESSED,
});

export const usersEditSuccess = () => ({
  type: actions.EDIT.SUCCESS,
});

export const usersEditErrors = ({ errors }) => ({
  type: actions.EDIT.ERRORS,
  payload: errors,
});

export const usersInviteRequest = ({
  email,
}) => ({
  type: actions.INVITE.REQUEST,
  payload: email,
});

export const usersInviteProcessing = () => ({
  type: actions.INVITE.PROCESSING,
});

export const usersInviteProcessed = () => ({
  type: actions.INVITE.PROCESSED,
});

export const usersInviteSuccess = () => ({
  type: actions.INVITE.SUCCESS,
});

export const usersInviteErrors = ({ errors }) => ({
  type: actions.INVITE.ERRORS,
  payload: errors,
});

export const usersItemSelect = ({ id }) => ({
  type: actions.ITEM.SELECT,
  payload: id,
});

export const usersItemReset = () => ({
  type: actions.ITEM.RESET,
});

export const usersItemPut = ({ item }) => ({
  type: actions.ITEM.PUT,
  payload: item,
});

export const usersAutocompleteGet = ({ text }) => ({
  type: actions.AUTOCOMPLETE.GET,
  payload: text,
});

export const usersAutocompletePut = ({ data }) => ({
  type: actions.AUTOCOMPLETE.PUT,
  payload: data,
});

export const usersAutocompleteClear = () => ({
  type: actions.AUTOCOMPLETE.CLEAR,
});

export const usersRegisterRequest = ({
  email,
  password,
  confirmPassword,
  firstName,
  lastName,
  phoneNo,
}) => ({
  type: actions.REGISTER.REQUEST,
  payload: {
    email,
    password,
    confirmPassword,
    firstName,
    lastName,
    phoneNo,
  },
});

export const usersRegisterSuccess = () => ({
  type: actions.REGISTER.SUCCESS,
});

export const usersRegisterErrors = ({ errors }) => ({
  type: actions.REGISTER.ERRORS,
  payload: errors,
});
