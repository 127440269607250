import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import NumberFormat from 'react-number-format';
import Container from '../Base/Container';

const useStyles = makeStyles(
  () => ({
    '@keyframes exit': {
      '0%': {
        opacity: 1.0,
        transform: 'translateY(0px)',
      },
      '100%': {
        opacity: 0,
        transform: 'translateY(-80px)',
      },
    },
    container: {
      position: 'absolute',
      right: 0,
      bottom: '100%',
      animation: '$exit 0.8s ease-out',
    },
    positive: {
      color: '#88DF64',
    },
    negative: {
      color: '#FF6961',
    },
  }),
);

const StatisticsValueChange = ({ type, amount }) => {
  const classes = useStyles();
  return (
    <Container
      className={
        classNames([
          classes.container,
          amount >= 0 ? classes.positive : classes.negative,
        ])
      }
    >
      <NumberFormat
        prefix={amount >= 0 ? '+' : '-'}
        displayType="text"
        value={amount}
        decimalScale={type === 'price' ? 2 : 0}
        renderText={value => (
          <Typography variant="body1">
            {value}
          </Typography>
        )}
        fixedDecimalScale
        thousandSeparator
      />
    </Container>
  );
};

StatisticsValueChange.defaultProps = {
  type: 'price',
};

StatisticsValueChange.propTypes = {
  type: PropTypes.oneOf([
    'price',
    'number',
  ]),
  amount: PropTypes.number.isRequired,
};

export default StatisticsValueChange;
