export const actions = {
  INDEX: {
    REQUEST: 'FOODS_INDEX_REQUEST',
    SUCCESS: 'FOODS_INDEX_SUCCESS',
    ERRORS: 'FOODS_INDEX_ERRORS',
    RESET: 'FOODS_INDEX_RESET',
  },
  STORE: {
    REQUEST: 'FOODS_STORE_REQUEST',
    SUCCESS: 'FOODS_STORE_SUCCESS',
    ERRORS: 'FOODS_STORE_ERRORS',
    RESET: 'FOODS_STORE_RESET',
  },
  UPDATE: {
    REQUEST: 'FOODS_UPDATE_REQUEST',
    SUCCESS: 'FOODS_UPDATE_SUCCESS',
    ERRORS: 'FOODS_UPDATE_ERRORS',
    RESET: 'FOODS_UPDATE_RESET',
  },
  SHOW: {
    REQUEST: 'FOODS_SHOW_REQUEST',
    SUCCESS: 'FOODS_SHOW_SUCCESS',
    ERRORS: 'FOODS_SHOW_ERRORS',
    RESET: 'FOODS_SHOW_RESET',
  },
  DESTROY: {
    REQUEST: 'FOODS_DESTROY_REQUEST',
    SUCCESS: 'FOODS_DESTROY_SUCCESS',
    ERRORS: 'FOODS_DESTROY_ERRORS',
    RESET: 'FOODS_DESTROY_RESET',
  },
};

export const foodsIndexRequest = (search = null) => ({
  type: actions.INDEX.REQUEST,
  payload: search,
});

export const foodsIndexSuccess = (data, { search } = {}) => ({
  type: actions.INDEX.SUCCESS,
  payload: {
    search,
    data,
  },
});

export const foodsIndexErrors = errors => ({
  type: actions.INDEX.ERRORS,
  payload: errors,
});

export const foodsIndexReset = () => ({
  type: actions.INDEX.RESET,
});

export const foodsStoreRequest = ({
  name, image, price, prices, options, categoryId,
}) => ({
  type: actions.STORE.REQUEST,
  payload: {
    name,
    image,
    price,
    prices,
    options,
    categoryId,
  },
});

export const foodsStoreSuccess = () => ({
  type: actions.STORE.SUCCESS,
});

export const foodsStoreErrors = errors => ({
  type: actions.STORE.ERRORS,
  payload: errors,
});

export const foodsStoreReset = () => ({
  type: actions.STORE.RESET,
});

export const foodsUpdateRequest = (
  id,
  {
    name, image, price, prices, options, categoryId,
  },
) => ({
  type: actions.UPDATE.REQUEST,
  payload: {
    id,
    name,
    image,
    price,
    prices,
    options,
    categoryId,
  },
});

export const foodsUpdateSuccess = () => ({
  type: actions.UPDATE.SUCCESS,
});

export const foodsUpdateErrors = errors => ({
  type: actions.UPDATE.ERRORS,
  payload: errors,
});

export const foodsUpdateReset = () => ({
  type: actions.UPDATE.RESET,
});

export const foodsShowRequest = id => ({
  type: actions.SHOW.REQUEST,
  payload: id,
});

export const foodsShowSuccess = data => ({
  type: actions.SHOW.SUCCESS,
  payload: data,
});

export const foodsShowErrors = errors => ({
  type: actions.SHOW.ERRORS,
  payload: errors,
});

export const foodsShowReset = () => ({
  type: actions.SHOW.RESET,
});

export const foodsDestroyRequest = id => ({
  type: actions.DESTROY.REQUEST,
  payload: id,
});

export const foodsDestroySuccess = () => ({
  type: actions.DESTROY.SUCCESS,
});

export const foodsDestroyErrors = errors => ({
  type: actions.DESTROY.ERRORS,
  payload: errors,
});

export const foodsDestroyReset = () => ({
  type: actions.DESTROY.RESET,
});
