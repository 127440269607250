import { actions } from '../actions/Auth';

const initialState = {
  init: true,
  loading: true,
  authenticated: false,
  token: null,
  permissions: [],
  user: {},
  error: '',
  edit: {
    processing: false,
    success: false,
    errors: {},
  },
  email: {
    processing: false,
    success: false,
  },
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOADING:
      return {
        ...state,
        loading: true,
      };
    case actions.VERIFY:
      return {
        ...state,
        loading: false,
      };
    case actions.UNVERIFIED:
    case actions.LOGOUT:
      return {
        ...state,
        init: false,
        loading: false,
        authenticated: false,
        user: {},
      };
    case actions.VERIFIED:
      return {
        ...state,
        ...action.payload,
        init: false,
        loading: false,
      };
    case actions.ERROR_STATE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case actions.RESET:
      return {
        ...state,
        error: null,
      };
    case actions.PERMISSIONS.SET:
      return {
        ...state,
        permissions: action.payload,
      };
    case actions.EDIT.REQUEST:
      return {
        ...state,
        edit: {
          ...state.edit,
          processing: true,
          success: false,
          errors: {},
        },
      };
    case actions.EDIT.SUCCESS:
      return {
        ...state,
        edit: {
          ...state.edit,
          processing: false,
          success: true,
        },
      };
    case actions.EDIT.ERRORS:
      return {
        ...state,
        edit: {
          ...state.edit,
          processing: false,
          success: false,
          errors: action.payload,
        },
      };
    case actions.USER.PUT:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    case actions.EMAIL.VERIFY:
      return {
        ...state,
        email: {
          processing: true,
          success: false,
        },
      };
    case actions.EMAIL.VERIFIED:
      return {
        ...state,
        email: {
          processing: false,
          success: true,
        },
      };
    case actions.EMAIL.VERIFICATION_FAILED:
      return {
        ...state,
        email: {
          processing: false,
          success: false,
        },
      };
    default:
      return state;
  }
};

export default authReducer;
