import {
  all, takeLatest, call, put, putResolve,
} from 'redux-saga/effects';
import hasErrors from '../../hasErrors';
import { snackbarDefaultError } from '../actions/Snackbar';
import {
  actions, translationsIndexErrors, translationsIndexSuccess,
} from '../actions/Translations';
import { Instance, Resources } from '../../API';

const callTranslationsIndex = async () => Instance.get(Resources.translations);

function* sagaTranslationsIndex() {
  try {
    const { data: { data } } = yield call(callTranslationsIndex);
    yield putResolve(
      translationsIndexSuccess(data),
    );
  } catch (e) {
    const errors = hasErrors(e);
    if (errors) {
      yield put(
        translationsIndexErrors(errors),
      );
    }

    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

export default function* Translations() {
  yield all([
    takeLatest(actions.INDEX.REQUEST, sagaTranslationsIndex),
  ]);
}
