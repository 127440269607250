import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  InputLabel,
  FormControl,
  Select,
  Input,
  MenuItem,
  Checkbox,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';

const styles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(0.25),
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const renderValue = (selected, roles) => selected.map(id => (roles.find(role => id === role.id)
  ? roles.find(role => id === role.id).title
  : null))
  .filter(role => role !== null)
  .join(', ');

const RolesSelect = ({
  roles, selected, onChange, onOpen, onClose, icon, open, disabled,
}) => {
  const classes = styles();
  return (
    <FormControl component="div" className={classes.formControl} disabled={disabled}>
      <InputLabel htmlFor="select-multiple-checkbox">Roles</InputLabel>
      <Select
        value={selected}
        onChange={event => onChange(event.target.value)}
        onClose={onClose}
        onOpen={onOpen}
        open={open}
        input={<Input id="select-multiple-checkbox" />}
        IconComponent={icon || ArrowDropDown}
        renderValue={newSelected => renderValue(newSelected, roles)}
        multiple
      >
        {roles.map(role => (
          <MenuItem component="div" key={role.id} value={role.id}>
            <Checkbox checked={selected.indexOf(role.id) > -1} />
            <ListItemText primary={role.title} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

RolesSelect.defaultProps = {
  icon: null,
  disabled: false,
};

RolesSelect.propTypes = {
  roles: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  icon: PropTypes.func,
  disabled: PropTypes.bool,
};

const mapStateToProps = state => ({
  roles: state.rolesReducer.data,
  organisation: state.organisationReducer.data,
});

export default connect(mapStateToProps)(RolesSelect);
