import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import ButtonGlow from '../../Buttons/ButtonGlow';
import TextInput from '../../Base/Input/TextInput';
import Container from '../../Base/Container';
import SwitchInput from '../../Base/Input/SwitchInput';
import Loader from '../../Base/Loader';
import Constants from '../../../constants';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(4),
  },
  button: {
    marginTop: theme.spacing(6),
  },
  input: {
    marginBottom: theme.spacing(2),
  },
}));

const SiteOrganisationInputs = ({
  className,
  onPress,
  buttonText,
  name: defaultName,
  domain: defaultDomain,
  registrationNo: defaultRegistrationNo,
  taxRegistrationNo: defaultTaxRegistrationNo,
  tax: defaultTax,
  goods: defaultGoods,
  terms: defaultTerms,
  errors,
  loading,
}) => {
  const classes = useStyles();
  const [name, setName] = useState(defaultName || '');
  const [domain, setDomain] = useState(defaultDomain || '');
  const [registrationNo, setRegistrationNo] = useState(defaultRegistrationNo || '');
  const [
    taxRegistrationNo,
    setTaxRegistrationNo,
  ] = useState(defaultTaxRegistrationNo || '');
  const [tax, setTax] = useState(defaultTax);
  const [isSellingFood, setIsSellingFood] = useState(
    defaultGoods.indexOf(Constants.goods.food) !== -1,
  );
  const [isSellingProducts, setIsSellingProducts] = useState(
    defaultGoods.indexOf(Constants.goods.product) !== -1,
  );
  const [terms, setTerms] = useState(defaultTerms || '');

  const onSubmit = () => {
    const goods = [];

    if (isSellingFood) {
      goods.push(Constants.goods.food);
    }

    if (isSellingProducts) {
      goods.push(Constants.goods.product);
    }

    onPress({
      name,
      domain,
      registrationNo,
      taxRegistrationNo,
      tax,
      goods,
      terms,
    });
  };

  useEffect(() => {
    setDomain(name.replace(/[^\w-]/g, '').substring(0, 16).toLowerCase());
  }, [name]);

  return (
    <Container
      className={classNames([classes.container, className])}
      flex
      horizontal
    >
      <TextInput
        className={classes.input}
        label="Name"
        onChange={setName}
        value={name}
        errors={errors.name}
        shrink
      />
      <TextInput
        className={classes.input}
        label="Domain"
        onChange={setDomain}
        value={domain}
        helperText={`Your domain will be created at ${domain}.aios.sg`}
        errors={errors.domain}
        shrink
      />
      <TextInput
        className={classes.input}
        label="Registration No."
        onChange={setRegistrationNo}
        value={registrationNo}
        errors={errors.registrationNo}
        shrink
      />
      <TextInput
        className={classes.input}
        label="Terms & Conditions"
        onChange={setTerms}
        value={terms}
        errors={errors.terms}
        maxLength={255}
        shrink
      />
      <SwitchInput
        className={classes.input}
        title="Are you tax registered?"
        onChange={setTax}
        checked={tax}
      />
      <Collapse in={tax}>
        <Container flex>
          <TextInput
            label="Tax Registration No."
            onChange={setTaxRegistrationNo}
            value={taxRegistrationNo}
            shrink
          />
        </Container>
      </Collapse>
      <Container>
        <SwitchInput
          checked={isSellingFood}
          onChange={setIsSellingFood}
          title="Do you sell food?"
        />
        <SwitchInput
          checked={isSellingProducts}
          onChange={setIsSellingProducts}
          title="Do you sell products?"
        />
      </Container>
      {loading
        ? (
          <Container flex center>
            <Loader />
          </Container>
        )
        : (
          <ButtonGlow
            className={classes.button}
            onPress={onSubmit}
          >
            {buttonText}
          </ButtonGlow>
        )}
    </Container>
  );
};

SiteOrganisationInputs.defaultProps = {
  className: null,
  name: '',
  domain: '',
  registrationNo: '',
  taxRegistrationNo: '',
  tax: false,
  errors: null,
  goods: [],
  terms: '',
};

SiteOrganisationInputs.propTypes = {
  loading: PropTypes.bool.isRequired,
  className: PropTypes.string,
  onPress: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  name: PropTypes.string,
  domain: PropTypes.string,
  registrationNo: PropTypes.string,
  taxRegistrationNo: PropTypes.string,
  tax: PropTypes.bool,
  goods: PropTypes.array,
  terms: PropTypes.string,
  errors: PropTypes.object,
};

export default SiteOrganisationInputs;
