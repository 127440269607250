import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Layout from '../../../components/Layout';
import Constants from '../../../constants';
import { warehousesIndexRequest } from '../../../providers/redux/actions/Warehouses';
import Container from '../../../components/Base/Container';
import WarehouseCard from '../../../components/Warehouse/WarehouseCard';
import PageHeader from '../../../components/PageHeader';
import useNavigate from '../../../services/useNavigate';
import CardScroll from '../../../components/CardScroll';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'block',
  },
  warehouses: {
    '& > *:first-child': {
      [theme.breakpoints.down('lg')]: {
        paddingLeft: theme.spacing(6.5),
      },
      [theme.breakpoints.up('lg')]: {
        paddingLeft: theme.spacing(12.5),
      },
    },
    '& > *:last-child': {
      [theme.breakpoints.down('lg')]: {
        paddingRight: theme.spacing(6.5),
      },
      [theme.breakpoints.up('lg')]: {
        paddingRight: theme.spacing(12.5),
      },
    },
  },
}));

const ScreenWarehouses = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data } = useSelector(
    state => ({
      loading: state.warehousesReducer.index.loading,
      data: state.warehousesReducer.index.data,
    }),
  );

  useEffect(() => {
    dispatch(
      warehousesIndexRequest(),
    );
  }, []);

  const onAdd = () => navigate(
    `${Constants.routes.Warehouses}${Constants.routes.Add}`,
  );

  const goToWarehouse = id => navigate(
    `${Constants.routes.Warehouses}/${id}`,
  );

  return (
    <Layout scroll>
      <Container maxHeight maxWidth>
        <Container className={classes.header}>
          <PageHeader
            title="Your warehouses"
            action={onAdd}
            actionText="Add new"
          />
        </Container>
        <CardScroll innerWrapperClass={classes.warehouses}>
          {data.map(
            ({
              id: warehouseId,
              name: warehouseName,
            }) => (
              <WarehouseCard
                key={warehouseId}
                onPress={() => goToWarehouse(warehouseId)}
                id={warehouseId}
                name={warehouseName}
              />
            ),
          )}
        </CardScroll>
      </Container>
    </Layout>
  );
};

export default ScreenWarehouses;
