import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';
import CountUp from 'react-countup';
import Container from '../Base/Container';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  details: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  summary: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  total: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  change: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  priceLabel: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  label: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  leftLabel: {

  },
  rightLabel: {
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  price: {
    flex: 1,
    display: 'flex',
    marginLeft: theme.spacing(4),
  },
  leftPrice: {
    justifyContent: 'flex-start',
  },
  rightPrice: {
    justifyContent: 'flex-end',
  },
}));

const duration = 0.8;

const DisplayFooter = ({
  className, discount, voucher, total, change,
}) => {
  const classes = useStyles();
  const [previousTotal, setPreviousTotal] = useState(0);
  const [currentTotal, setCurrentTotal] = useState(total);

  useEffect(() => {
    setPreviousTotal(currentTotal);
    setCurrentTotal(total);
  }, [total]);

  return (
    <Container className={classNames([classes.container, className])}>
      <Container className={classes.details}>
        <Container className={classes.priceLabel}>
          <Typography
            className={classNames([classes.label, classes.leftLabel])}
            variant="h5"
          >
            Discount
          </Typography>
          <Typography
            className={classNames([classes.price, classes.leftPrice])}
            variant="h4"
          >
            <CountUp
              duration={duration}
              prefix="$"
              decimals={2}
              separator=","
              start={0}
              end={discount}
            />
          </Typography>
        </Container>
        <Container className={classes.priceLabel}>
          <Typography
            className={classNames([classes.label, classes.leftLabel])}
            variant="h5"
          >
            Voucher
          </Typography>
          <Typography
            className={classNames([classes.price, classes.leftPrice])}
            variant="h4"
          >
            <CountUp
              duration={duration}
              prefix="$"
              decimals={2}
              separator=","
              start={0}
              end={voucher}
            />
          </Typography>
        </Container>
      </Container>
      <Container className={classes.summary}>
        <Container className={classes.total}>
          <Container className={classes.priceLabel}>
            <Typography
              className={classNames([classes.label, classes.rightLabel])}
              variant="h4"
            >
              Total
            </Typography>
            <Typography
              className={classNames([classes.price, classes.rightPrice])}
              variant="h3"
            >
              <CountUp
                duration={duration}
                prefix="$"
                decimals={2}
                separator=","
                start={previousTotal}
                end={currentTotal}
              />
            </Typography>
          </Container>
        </Container>
        <Container className={classes.change}>
          <Container className={classes.priceLabel}>
            <Typography
              className={classNames([classes.label, classes.rightLabel])}
              variant="h4"
            >
              Change
            </Typography>
            <Typography
              className={classNames([classes.price, classes.rightPrice])}
              variant="h3"
            >
              <CountUp
                duration={duration}
                prefix="$"
                decimals={2}
                separator=","
                start={0}
                end={change || 0}
              />
            </Typography>
          </Container>
        </Container>
      </Container>
    </Container>
  );
};

DisplayFooter.defaultProps = {
  className: null,
};

DisplayFooter.propTypes = {
  className: PropTypes.string,
  discount: PropTypes.number.isRequired,
  voucher: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  change: PropTypes.number.isRequired,
};

export default DisplayFooter;
