import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Delete } from '@material-ui/icons';
import {
  Typography, CardActions, Tooltip, CardContent, makeStyles,
} from '@material-ui/core';
import { connect } from 'react-redux';
import RolesSelect from './RolesSelect';
import Container from './Base/Container';
import Loader from './Base/Loader';
import StoresSelect from './StoresSelect';
import CustomCard from './CustomCard';
import {
  usersDelete,
  usersEditRequest,
} from '../providers/redux/actions/Users';
import Dialog from './Base/Dialog';
import IconButton from './Base/Button/IconButton/IconButton';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'inline-block',
    minWidth: 400,
  },
  card: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  actions: {
    justifyContent: 'flex-end',
  },
}));

const UserCard = ({
  id,
  roles: initialRoles,
  stores: initialStores,
  name,
  currentUser,
  processing,
  usersEditRequest: usersEditRequestAction,
  usersDelete: usersDeleteAction,
}) => {
  const classes = useStyles();

  const [rolesOpen, setRolesOpen] = useState(false);
  const [rolesLoading, setRolesLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [storesLoading, setStoresLoading] = useState(false);
  const [storesOpen, setStoresOpen] = useState(false);
  const [stores, setStores] = useState(initialStores);
  const [roles, setRoles] = useState(initialRoles);
  const [dialog, setDialog] = useState(false);

  const disabled = currentUser.id === id;

  useEffect(() => {
    if (!processing) {
      setStoresLoading(false);
      setRolesLoading(false);
      setDeleting(false);
    }
  }, [processing]);

  useEffect(() => {
    if (deleting) {
      setDialog(false);
      usersDeleteAction({ id });
    }
  }, [deleting]);

  const closeRoles = () => {
    setRolesOpen(false);
    setRolesLoading(true);
    usersEditRequestAction({ id, roles });
  };

  const closeStores = () => {
    setStoresOpen(false);
    setStoresLoading(true);
    usersEditRequestAction({ id, stores });
  };

  return (
    <Container className={classes.container}>
      <Dialog
        title="Remove this user"
        open={dialog}
        close={() => setDialog(false)}
        callback={() => setDeleting(true)}
        reject="Please no"
        accept="Do it"
        loading={processing}
      >
        Are you sure you want to unlink this user?
        You will have to invite the user again if you wish to add this
        user back to your organisation.
      </Dialog>
      <CustomCard className={classes.card}>
        <CardContent>
          <Container>
            <Typography variant="body1">{name}</Typography>
          </Container>
          <Container>
            {roles && (
              <RolesSelect
                icon={rolesLoading ? props => <Loader {...props} size={20} /> : null}
                selected={roles}
                onChange={setRoles}
                onOpen={() => !rolesLoading && setRolesOpen(true)}
                onClose={closeRoles}
                disabled={disabled}
                open={rolesOpen}
              />
            )}
          </Container>
          <Container>
            {stores && (
              <StoresSelect
                icon={storesLoading
                  ? props => <Loader {...props} size={20} />
                  : null}
                selected={stores}
                onChange={setStores}
                onOpen={() => !storesLoading && setStoresOpen(true)}
                onClose={closeStores}
                disabled={currentUser.id === id}
                open={storesOpen}
              />
            )}
          </Container>
        </CardContent>
        <CardActions className={classes.actions}>
          {!deleting
            ? (
              <Tooltip title="Delete user from organisation">
                <IconButton
                  onPress={() => setDeleting(true)}
                  disabled={disabled}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            )
            : <Loader />
          }
        </CardActions>
      </CustomCard>
    </Container>
  );
};

UserCard.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  roles: PropTypes.array.isRequired,
  stores: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired,
  processing: PropTypes.bool.isRequired,
  usersEditRequest: PropTypes.func.isRequired,
  usersDelete: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  currentUser: state.authReducer.user,
  processing: state.usersReducer.edit.processing,
});

export default connect(
  mapStateToProps,
  { usersEditRequest, usersDelete },
)(UserCard);
