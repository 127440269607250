import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import TextInput from '../../../components/Base/Input/TextInput';
import Container from '../../../components/Base/Container';
import Layout from '../../../components/Layout';
import ButtonBack from '../../../components/Base/Button/ButtonBack';
import Constants from '../../../constants';
import useNavigate from '../../../services/useNavigate';
import PageHeader from '../../../components/PageHeader';
import ButtonGlow from '../../../components/Buttons/ButtonGlow';
import {
  warehousesStoreRequest,
  warehousesStoreReset,
} from '../../../providers/redux/actions/Warehouses';
import Approval from '../../../components/Base/Dialog/Approval';

const useStyles = makeStyles(theme => ({
  inputs: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(6),
    maxWidth: 400,
    width: '100%',
  },
  backContainer: {
    padding: theme.spacing(6),
  },
  button: {
    marginTop: theme.spacing(7),
  },
  input: {
    marginBottom: theme.spacing(2),
  },
}));

const ScreenWarehouseCreate = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, success, errors } = useSelector(
    state => ({
      loading: state.warehousesReducer.store.loading,
      success: state.warehousesReducer.store.success,
      errors: state.warehousesReducer.store.errors,
    }),
  );

  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');

  const toWarehousesList = () => navigate(Constants.routes.Warehouses);

  const submit = () => dispatch(
    warehousesStoreRequest({
      name,
    }),
  );

  const onOpen = () => setOpen(true);

  const onClose = () => setOpen(false);

  useEffect(() => {
    if (!loading) {
      onClose();
    }
  }, [loading]);

  useEffect(() => {
    if (success) {
      toWarehousesList();
      dispatch(
        warehousesStoreReset(),
      );
    }
  }, [success]);

  return (
    <Layout flex>
      <Approval
        processing={loading}
        closeDialog={onClose}
        content={`Are you sure you want to create a new warehouse? Adding a new warehouse will increase the cost of your current plan by S$${process.env.REACT_APP_WAREHOUSE_PRICE} per month. If you have not started your trial, it will begin as soon as you create your first warehouse.`}
        open={open}
        title="Add a new warehouse"
        triggerEvent={submit}
      />
      <Container className={classes.backContainer}>
        <ButtonBack onPress={toWarehousesList} />
      </Container>
      <Container>
        <PageHeader
          title="Add a new warehouse"
          subtitle={`Adding a new warehouse will increase the cost of your current plan by S$${process.env.REACT_APP_WAREHOUSE_PRICE} per month`}
        />
        <Container className={classes.inputs}>
          <TextInput
            className={classes.input}
            label="Name"
            onChange={setName}
            value={name}
            errors={errors.name}
            shrink
          />
          <ButtonGlow className={classes.button} onPress={onOpen}>
            Add warehouse
          </ButtonGlow>
        </Container>
      </Container>
    </Layout>
  );
};

export default ScreenWarehouseCreate;
