export const actions = {
  PUT: 'MEASUREMENTS_PUT',
  GET: 'MEASUREMENTS_GET',
  RESET: 'MEASUREMENTS_RESET',
};

export const getMeasurements = () => ({
  type: actions.GET,
});

export const putMeasurements = ({ data }) => ({
  type: actions.PUT,
  payload: {
    data,
  },
});

export const resetMeasurements = () => ({
  type: actions.RESET,
});
