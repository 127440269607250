const displayStoreTransformer = (item) => {
  if (item.exists) {
    const {
      discount, voucher, total, change,
    } = item.data();
    return {
      discount,
      voucher,
      total,
      change: change || 0,
    };
  }

  return {
    total: 0,
    voucher: 0,
    discount: 0,
    change: 0,
  };
};

export default displayStoreTransformer;
