import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Checkbox,
  FormControl,
  makeStyles,
  Typography,
} from '@material-ui/core';
import Container from '../Base/Container';
import FormatPrice from '../Format/FormatPrice';

const styles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'left',
    alignItems: 'center',
    padding: theme.spacing(0.5),
  },
  notOptionalContainer: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
  },
  nameContainer: {
    flex: 1,
  },
  name: {
    fontSize: '1.6rem',
    fontWeight: 500,
  },
  priceContainer: {
    flex: 1,
    textAlign: 'right',
  },
  price: {
    fontSize: '1.6rem',
    fontWeight: 'bold',
  },
  notOptional: {
    marginLeft: 47.14,
  },
}));

const PackageRow = ({
  item, value, isOptional, onCheck, checked,
}) => {
  const classes = styles();
  return (
    <FormControl
      className={
        classNames([
          classes.container,
          !isOptional ? classes.notOptionalContainer : null,
        ])
      }
    >
      <Container className={!isOptional ? classes.notOptional : null}>
        { isOptional && (
          <Checkbox onChange={() => onCheck(!checked)} checked={checked} />
        )}
      </Container>
      <Container className={classes.nameContainer}>
        <Typography className={classes.name} variant="body1">{item}</Typography>
      </Container>
      <Container className={classes.priceContainer}>
        <FormatPrice className={classes.price} variant="body1">
          {value}
        </FormatPrice>
      </Container>
    </FormControl>
  );
};

PackageRow.defaultProps = {
  isOptional: false,
  onCheck: null,
  checked: null,
};

PackageRow.propTypes = {
  item: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  isOptional: PropTypes.bool,
  onCheck: PropTypes.func,
  checked: PropTypes.bool,
};

export default PackageRow;
