import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Input, InputAdornment } from '@material-ui/core';

const BareText = forwardRef(({
  startAdornment,
  endAdornment,
  className,
  placeholder,
  onChange,
  fullWidth,
  value,
  disableUnderline,
}, ref) => (
  <Input
    startAdornment={(
      <InputAdornment position="end">
        {startAdornment}
      </InputAdornment>
    )}
    endAdornment={endAdornment}
    className={className}
    placeholder={placeholder}
    onChange={event => onChange(event.target.value)}
    fullWidth={fullWidth}
    value={value}
    inputRef={ref}
    disableUnderline={disableUnderline}
  />
));

BareText.defaultProps = {
  placeholder: null,
  className: null,
  fullWidth: null,
  disableUnderline: null,
  startAdornment: null,
  endAdornment: null,
};

BareText.propTypes = {
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.func,
  fullWidth: PropTypes.bool,
  disableUnderline: PropTypes.bool,
  startAdornment: PropTypes.element,
  endAdornment: PropTypes.element,
};

export default BareText;
