import printers from './printers';
import measurements from './measurements';
import routes from './routes';
import firestore from './firestore';
import periods from './periods';
import alerts from './alerts';
import goods from './goods';
import table from './table';
import value from './value';
import roles from './roles';
import maps from './maps';

const Constants = {
  printers,
  measurements,
  routes,
  firestore,
  periods,
  alerts,
  goods,
  value,
  roles,
  maps,
  table,
};

export default Constants;
