const categoryTransformer = ({
  id,
  name,
  otf,
  image,
  is_menu: isMenu,
}) => ({
  id,
  name,
  otf,
  image: image ? `${process.env.REACT_APP_IMG_BUCKET}/${image}` : null,
  isMenu: isMenu === 1,
});

export default categoryTransformer;
