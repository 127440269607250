import React from 'react';
import PropTypes from 'prop-types';

import Input from '@material-ui/core/Input';
import QuantityFormat from '../QuantityFormat';

const StoreProductListItemQuantityInput = ({
  className,
  onChange,
  measurement,
  value,
  disabled,
}) => (
  <Input
    className={className}
    inputComponent={QuantityFormat(measurement)}
    onChange={e => onChange(e.target.value)}
    value={value}
    placeholder="0"
    disabled={disabled}
  />
);

StoreProductListItemQuantityInput.defaultProps = {
  className: null,
  measurement: null,
  disabled: false,
};

StoreProductListItemQuantityInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  measurement: PropTypes.object,
  disabled: PropTypes.bool,
};

export default StoreProductListItemQuantityInput;
