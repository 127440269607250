import React from 'react';
import PropTypes from 'prop-types';
import { DialogActions } from '@material-ui/core';
import Loader from '../../Loader';
import DoneButton from '../../Button/Done';

const ModelInteractorCreateActions = ({ onClick, processing }) => (
  <DialogActions>
    { processing ? <Loader /> : (
      <DoneButton
        onClick={onClick}
        color="secondary"
        label="Create"
      />
    )}
  </DialogActions>
);

ModelInteractorCreateActions.propTypes = {
  processing: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ModelInteractorCreateActions;
