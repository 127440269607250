import {
  put, call, all, takeEvery,
} from 'redux-saga/effects';
import { printerRequest } from '../actions/Printer';
import {
  snackbarDefaultError,
  snackbarDefaultSuccess,
} from '../actions/Snackbar';
import {
  actions,
  barcodesPrintErrors,
  barcodesPrintSuccess,
} from '../actions/Barcodes';
import { Instance, Resources } from '../../API';
import hasErrors from '../../hasErrors';

const printBarcodeCall = async ({
  productId, printerId, storeId, quantity,
}) => Instance.post(
  `${Resources.products}/print`, {
    product_id: productId,
    printer_id: printerId,
    store_id: storeId,
    quantity,
  },
);

/**
 * loadOrganisationSaga
 * @param name
 * @returns {IterableIterator<*>}
 */
function* printBarcodeSaga({
  payload: {
    productId, storeId, quantity, printerId,
  },
}) {
  try {
    const { data: responseData } = yield call(
      printBarcodeCall,
      {
        productId,
        storeId,
        quantity,
        printerId,
      },
    );

    const { data } = responseData;

    if (data.label) {
      yield put(
        printerRequest({ label: data.label }),
      );
    }

    yield put(snackbarDefaultSuccess({ data: responseData }));
    yield put(barcodesPrintSuccess());
  } catch (e) {
    const errors = hasErrors(e);
    if (errors) {
      yield put(
        barcodesPrintErrors({
          errors: {
            store: errors.store_id,
            printer: errors.printer_id,
            quantity: errors.quantity,
          },
        }),
      );
    }

    yield put(snackbarDefaultError({ e }));
  }
}

export default function* Barcodes() {
  yield all([
    takeEvery(actions.PRINT.REQUEST, printBarcodeSaga),
  ]);
}
