export const actions = {
  INDEX: {
    REQUEST: 'STORES_PRODUCTS_UPDATE_REQUEST',
    SUCCESS: 'STORES_PRODUCTS_UPDATE_SUCCESS',
    ERRORS: 'STORES_PRODUCTS_UPDATE_ERRORS',
    RESET: 'STORES_PRODUCTS_UPDATE_RESET',
  },
  PAGE: {
    NEXT: 'PRODUCTS_PAGE_NEXT',
  },
};

export const productsIndexRequest = storeId => ({
  type: actions.INDEX.REQUEST,
  payload: storeId,
});

export const productsIndexSuccess = data => ({
  type: actions.INDEX.SUCCESS,
  payload: data,
});

export const productsPageNext = () => ({
  type: actions.PAGE.NEXT,
});
