import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles, Typography } from '@material-ui/core';
import useNavigate from '../../../services/useNavigate';
import Constants from '../../../constants';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: theme.spacing(2),
  },
}));

const SiteOrganisationItem = ({ id, name, username }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const link = `${Constants.routes.Organisations}/${id}`;
  return (
    <MenuItem className={classes.container} onClick={() => navigate(link)}>
      <Typography variant="h6">{name}</Typography>
      <Typography variant="caption">{`${username}.aios.sg`}</Typography>
    </MenuItem>
  );
};

SiteOrganisationItem.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
};

export default SiteOrganisationItem;
