const collectionTransformer = transformer => (items) => {
  const data = [];
  items.forEach((item) => {
    const displayItem = transformer(item);
    data.push(displayItem);
  });
  return data;
};

export default collectionTransformer;
