import { actions } from '../actions/Dashboard';

const initialState = {
  revenue: null,
  expenses: null,
  stores: [],
  settlements: [],
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.STATISTICS.PUT:
      return {
        ...state,
        revenue: action.payload.revenue,
        expenses: action.payload.expenses,
      };
    case actions.STORES.PUT:
      return {
        ...state,
        stores: action.payload,
      };
    case actions.SETTLEMENTS.PUT:
      return {
        ...state,
        settlements: action.payload,
      };
    default:
      return state;
  }
};

export default dashboardReducer;
