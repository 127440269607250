import React from 'react';
import PropTypes from 'prop-types';
import TextInput from '../../../../../components/Base/Input/TextInput';

const NameInput = ({
  onEnter, onChange, name, errors,
}) => (
  <TextInput
    label="Name"
    className="input"
    onChange={onChange}
    value={name}
    InputLabelProps={{
      shrink: true,
    }}
    errors={errors}
    placeholder="Aios (maybe, you never know.)"
    onEnter={onEnter}
    fullWidth
    shrink
  />
);

NameInput.defaultProps = {
  errors: null,
  onEnter: null,
};

NameInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  errors: PropTypes.string,
  onEnter: PropTypes.func,
};

export default NameInput;
