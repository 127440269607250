import moment from 'moment';

const actionTransformer = (action, translations) => {
  const { id } = action;
  const {
    user_id: userId, name, key, timestamp, data,
  } = action.data();

  data.user = name;
  let translation = key.split('.').reduce((o, i) => o[i], translations);
  Object.entries(data).forEach(
    ([replaceKey, replace]) => {
      translation = translation.replace(`:${replaceKey}`, replace);
    },
  );

  return {
    id,
    snapshot: action,
    userId,
    action: translation,
    timestamp: moment(timestamp.seconds * 1000),
  };
};

export default actionTransformer;
