import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Tooltip, makeStyles,
} from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';

import Typography from '@material-ui/core/Typography';
import Container from '../../../../../components/Base/Container';
import BarcodeItem from './BarcodeItem';
import IconButton
  from '../../../../../components/Base/Button/IconButton/IconButton';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    flex: 1,
  },
  addButton: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    margin: theme.spacing(2.5),
  },
}));

const BarcodesList = ({
  className, addItem, deleteItem, barcodes, onChange,
}) => {
  const classes = useStyles();
  return (
    <Container className={classNames(classes.container, className)}>
      <Container className={classes.header}>
        <Typography className={classes.title} variant="h6">Barcodes</Typography>
        {deleteItem && (
          <Container className={classes.action}>
            <Tooltip title="Add new">
              <IconButton onPress={addItem} label="Add new">
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Container>
        )}
      </Container>
      <Container>
        {!!barcodes && barcodes.map((barcode, index) => (
          <BarcodeItem
            key={index.toString()}
            index={index}
            onChange={onChange}
            barcode={barcode}
            deleteItem={deleteItem ? () => deleteItem(index) : null}
          />
        ))}
      </Container>
    </Container>
  );
};

BarcodesList.defaultProps = {
  className: null,
  barcodes: [],
  deleteItem: null,
};

BarcodesList.propTypes = {
  className: PropTypes.string,
  addItem: PropTypes.func.isRequired,
  deleteItem: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  barcodes: PropTypes.array,
};

export default BarcodesList;
