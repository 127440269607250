import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import SelectInput from './SelectInput';
import Container from '../Container';

const MeasurementDropdown = ({
  selected, errors, onChange,
}) => {
  const { data } = useSelector(
    state => ({
      data: state.measurementsReducer.data,
    }),
  );

  if (data === null) {
    return (
      <Container>
        Snap, we couldn&apos;t load the measurements.
      </Container>
    );
  }

  if (data.length > 0) {
    return (
      <SelectInput
        selected={selected}
        suggestions={data}
        placeholder="None"
        label="Measurement"
        renderOption={({ unit }) => <>{unit}</>}
        getOptionLabel={({ unit }) => unit}
        errors={errors}
        onChange={onChange}
      />
    );
  }

  return null;
};

MeasurementDropdown.defaultProps = {
  selected: null,
  errors: null,
};

MeasurementDropdown.propTypes = {
  selected: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.object,
  }),
  errors: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

export default MeasurementDropdown;
