import {
  all, takeLatest, fork, select, put,
} from 'redux-saga/effects';
import { firestore } from 'firebase/app';
import 'firebase/firestore';
import { actions, displaysPut, displaysPutStore } from '../actions/Displays';
import firebase from '../../firebase';
import Constants from '../../../constants';
import displayItemTransformer from '../transformers/DisplayItem';
import displayStoreTransformer from '../transformers/DisplayStore';
import { snackbarDefaultError } from '../actions/Snackbar';
import collectionTransformer
  from '../transformers/firebase/CollectionTransformer';

const getUser = state => ({
  user: state.authReducer.user,
});

function* syncDisplaySaga({ payload: storeId }) {
  try {
    const { user } = yield select(getUser);
    yield fork(
      firebase.firestore.syncCollection,
      firestore().collection(Constants.firestore.collections.users)
        .doc(user.id.toString())
        .collection(Constants.firestore.collections.stores)
        .doc(storeId.toString())
        .collection(Constants.firestore.collections.items)
        .orderBy('created_at', 'desc'),
      {
        successActionCreator: displaysPut,
        transform: collectionTransformer(displayItemTransformer),
      },
    );

    yield fork(
      firebase.firestore.syncDocument,
      firestore().collection(Constants.firestore.collections.users)
        .doc(user.id.toString())
        .collection(Constants.firestore.collections.stores)
        .doc(storeId.toString()),
      {
        successActionCreator: displaysPutStore,
        transform: displayStoreTransformer,
      },
    );
  } catch (e) {
    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

export default function* Displays() {
  yield all([
    takeLatest(actions.SYNC, syncDisplaySaga),
  ]);
}
