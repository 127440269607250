import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Container from '../Base/Container';

const useStyles = makeStyles(
  () => ({
    indicator: {
      width: 20,
      height: 20,
      borderRadius: 4,
      transition: 'background-color 300ms ease-in-out',
    },
    2: {
      backgroundColor: '#ff6961',
    },
    1: {
      backgroundColor: '#fee879',
    },
    0: {
      backgroundColor: '#88df64',
    },
  }),
);

const MapStoreStatus = ({ status }) => {
  const classes = useStyles();
  return (
    <Container className={classNames(classes.indicator, classes[status])} />
  );
};

MapStoreStatus.propTypes = {
  status: PropTypes.number.isRequired,
};

export default MapStoreStatus;
