import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Dialog as MaterialDialog, DialogActions, makeStyles } from '@material-ui/core';

import Container from './Base/Container';
import CloseIconButton from './Base/Button/CloseIcon';
import DoneButton from './Base/Button/Done';
import Loader from './Base/Loader';

const styles = makeStyles(theme => ({
  dialogPaper: {
    overflow: 'visible',
  },
  content: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

const ModalForm = ({
  open, close, title, className, confirm, loading, children,
}) => {
  const classes = styles();
  return (
    <MaterialDialog
      maxWidth="sm"
      PaperProps={{ className: classes.dialogPaper }}
      className="dialog"
      open={open}
      aria-labelledby="form-dialog-title"
      scroll="body"
      onClose={close}
      disableBackdropClick={loading}
      fullWidth
    >
      <Container className="dialog-title" flex alignCenter>
        <Container className="dialog-title-close">
          <CloseIconButton onClick={close} />
        </Container>
        <Container>{title}</Container>
      </Container>
      <Container className={classNames([classes.content, className])}>
        {children}
      </Container>
      <DialogActions>
        {loading
          ? (<Loader />)
          : (
            <DoneButton
              onClick={confirm}
              color="secondary"
              label="Create"
            />
          )
        }
      </DialogActions>
    </MaterialDialog>
  );
};

ModalForm.defaultProps = {
  className: null,
  loading: false,
};

ModalForm.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  className: PropTypes.string,
  confirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default ModalForm;
