import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Logo from './Logo';

const styles = makeStyles(() => ({
  container: {
    position: 'relative',
  },
  logo: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 100,
    msFilter: 'blur(0.5px)',
    filter: 'blur(0.5px)',
  },
}));

const AuraLogo = ({ size }) => {
  const classes = styles();
  return (
    <div className={classes.container} style={{ width: size, height: size }}>
      <Logo className={classes.logo} size={size} />
      <Logo className="aura" size={size} />
    </div>
  );
};

AuraLogo.defaultProps = {
  size: 200,
};

AuraLogo.propTypes = {
  size: PropTypes.number,
};

export default AuraLogo;
