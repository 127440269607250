import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';
import { Fade } from 'react-reveal';
import PackageRow from './PackageRow';
import Container from '../Base/Container';
import FormatPrice from '../Format/FormatPrice';

const styles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  packageTitle: {
    fontWeight: 500,
  },
  packageDetails: {
    marginTop: 64,
    marginBottom: 64,
    display: 'flex',
    maxWidth: 600,
    width: '100%',
    alignItems: 'center',
  },
}));

const tabletCost = parseInt(process.env.REACT_APP_COST_TABLET, 10);

const receiptPrinter = parseInt(process.env.REACT_APP_COST_RECEIPT_PRINTER, 10);

const cashDrawer = parseInt(process.env.REACT_APP_COST_CASH_DRAWER, 10);

const customerDisplay = parseInt(process.env.REACT_APP_COST_DISPLAY_SCREEN, 10);

const setup = parseInt(process.env.REACT_APP_COST_SETUP, 10);

const storePrice = parseInt(process.env.REACT_APP_STORE_PRICE, 10);

const orbitScanner = parseInt(process.env.REACT_APP_COST_ORBIT_BARCODE_SCANNER, 10);

const handheldScanner = parseInt(process.env.REACT_APP_COST_HANDHELD_SCANNER, 10);

const labelPrinter = parseInt(process.env.REACT_APP_COST_LABEL_PRINTER, 10);

const basePrice = tabletCost + receiptPrinter + cashDrawer + setup + (storePrice * 12);

const Package = ({ className }) => {
  const classes = styles();
  const [price, setPrice] = useState(basePrice);
  const [checkedOrbit, setCheckedOrbit] = useState(0);
  const [checkedHandheld, setCheckedHandheld] = useState(0);
  const [checkedLabelPrinter, setCheckedLabelPrinter] = useState(0);

  useEffect(() => {
    setPrice(basePrice + checkedOrbit + checkedLabelPrinter + checkedHandheld);
  }, [checkedOrbit, checkedLabelPrinter, checkedHandheld]);

  const setChecked = (setFunction, amount, isChecked) => setFunction(
    isChecked ? amount : 0,
  );

  const checked = value => value !== 0;

  return (
    <Container className={className}>
      <Typography variant="h4">
        Starting from
        {' '}
        <FormatPrice decimalScale={0}>{basePrice}</FormatPrice>
      </Typography>
      <Container className={classes.packageDetails}>
        <div className={classes.container}>
          <Fade left>
            <PackageRow item="POS hardware" value={tabletCost} />
            <PackageRow item="Set-up cost" value={setup} />
            <PackageRow item="Software (per year)" value={storePrice * 12} />
            <PackageRow item="Customer display" value={customerDisplay} />
            <PackageRow item="Cash box" value={cashDrawer} />
            <PackageRow item="Receipt printer" value={receiptPrinter} />
            <PackageRow
              item="Standing scanner"
              onCheck={isChecked => setChecked(
                setCheckedOrbit,
                orbitScanner,
                isChecked,
              )}
              checked={checked(checkedOrbit)}
              value={orbitScanner}
              isOptional
            />
            <PackageRow
              item="Handheld scanner"
              onCheck={isChecked => setChecked(
                setCheckedHandheld,
                handheldScanner,
                isChecked,
              )}
              checked={checked(checkedHandheld)}
              value={handheldScanner}
              isOptional
            />
            <PackageRow
              item="Label printer"
              onCheck={isChecked => setChecked(
                setCheckedLabelPrinter,
                labelPrinter,
                isChecked,
              )}
              checked={checked(checkedLabelPrinter)}
              value={labelPrinter}
              isOptional
            />
          </Fade>
        </div>
      </Container>
      <Typography variant="h4">
        <FormatPrice decimalScale={0}>{price}</FormatPrice>
      </Typography>
      <Typography>
        Check the boxes to add on additional hardware
      </Typography>
    </Container>
  );
};

Package.defaultProps = {
  className: null,
};

Package.propTypes = {
  className: PropTypes.string,
};

export default Package;
