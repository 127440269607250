import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Add, Delete } from '@material-ui/icons';
import uuid from 'uuid';
import Container from '../Base/Container';
import TextInput from '../Base/Input/TextInput';
import OptionChoice from './OptionChoice';
import IconButton from '../Base/Button/IconButton/IconButton';
import Constants from '../../constants';

const useStyles = makeStyles(
  () => ({
    container: {
      display: 'flex',
      flexDirection: 'row',
    },
    name: {
      flex: 1,
    },
    optionName: {
      fontSize: 24,
      fontWeight: 'bold',
    },
  }),
);

const Option = ({
  uuid: optionUuid,
  name,
  onChangeName,
  choices,
  onChangeChoices,
  onDestroy,
  onEnter,
  isSetMenu,
}) => {
  const classes = useStyles();

  const onChoiceDestroy = (key) => {
    const newChoices = [...choices];
    newChoices.splice(key, 1);
    onChangeChoices(newChoices);
  };

  const onChoicePriceChange = (index, newPrice) => {
    const newChoices = [...choices];
    const choice = newChoices[index];
    choice.price = newPrice;
    newChoices[index] = choice;
    onChangeChoices(newChoices);
  };

  const onChoiceNameChange = (index, newChoice) => {
    const newChoices = [...choices];

    if (newChoice === null) {
      return;
    }

    const choice = {
      ...newChoices[index],
    };

    if (typeof newChoice === 'string') {
      choice.name = newChoice;
      choice.foodId = null;
      choice.food = null;
    } else if (typeof newChoice === 'object') {
      choice.name = newChoice.name;
      choice.foodId = newChoice.id;
      choice.food = newChoice;
    }
    newChoices[index] = choice;
    onChangeChoices(newChoices);
  };

  const onAdd = () => {
    const newChoices = [...choices];
    newChoices.push({
      uuid: uuid(),
      ...Constants.value.choice,
    });
    onChangeChoices(newChoices);
  };

  const move = useCallback(
    (dragIndex, hoverIndex) => {
      const newChoices = [...choices];
      const dragChoice = newChoices[dragIndex];
      newChoices.splice(dragIndex, 1);
      newChoices.splice(hoverIndex, 0, dragChoice);
      onChangeChoices(newChoices);
    },
    [choices],
  );

  useEffect(() => {
    if (choices.length === 0) {
      onDestroy();
    }
  }, [choices]);

  return (
    <Container>
      <Container className={classes.container}>
        <TextInput
          inputClassName={classes.optionName}
          placeholder="Toppings, add-ons, etc."
          onChange={onChangeName}
          value={name}
          onEnter={onEnter}
          disableUnderline
        />
        <Container>
          <IconButton onPress={onAdd}>
            <Add />
          </IconButton>
        </Container>
        <Container>
          <IconButton onPress={onDestroy}>
            <Delete />
          </IconButton>
        </Container>
      </Container>
      <Container>
        {
          choices.map(
            ({
              uuid: choiceUuid,
              name: choiceName,
              price: choicePrice,
              food: choiceFood,
            }, key) => (
              <OptionChoice
                optionUuid={optionUuid}
                key={choiceUuid}
                id={choiceUuid}
                index={key}
                onDrag={move}
                onDestroy={() => onChoiceDestroy(key)}
                price={choicePrice}
                onPriceChange={
                  newChoicePrice => onChoicePriceChange(key, newChoicePrice)
                }
                name={choiceFood || choiceName}
                onNameChange={
                  newChoiceName => onChoiceNameChange(key, newChoiceName)
                }
                onEnter={onEnter}
                isSetMenu={isSetMenu}
              />
            ),
          )
        }
      </Container>
    </Container>
  );
};

Option.defaultProps = {
  isSetMenu: false,
};

Option.propTypes = {
  uuid: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  choices: PropTypes.array.isRequired,
  onChangeName: PropTypes.func.isRequired,
  onChangeChoices: PropTypes.func.isRequired,
  onDestroy: PropTypes.func.isRequired,
  onEnter: PropTypes.func.isRequired,
  isSetMenu: PropTypes.bool,
};

export default Option;
