export const actions = {
  GET: 'QUOTES_GET',
  PUT: 'QUOTES_PUT',
  SUCCESS: 'QUOTES_SUCCESS',
  PUT_SINGLE: 'QUOTES_PUT_SINGLE',
  PUT_ITEMS: 'QUOTES_PUT_ITEMS',
  DELETE: 'QUOTES_DELETE',
  RESET: 'QUOTES_RESET',
  CREATE: {
    REQUEST: 'QUOTES_CREATE_REQUEST',
    SUCCESS: 'QUOTES_CREATE_SUCCESS',
    PROCESSING: 'QUOTES_CREATE_PROCESSING',
    PROCESSED: 'QUOTES_CREATE_PROCESSED',
    ERRORS: 'QUOTES_CREATE_ERRORS',
  },
  UPDATE: {
    REQUEST: 'QUOTES_UPDATE_REQUEST',
    SUCCESS: 'QUOTES_UPDATE_SUCCESS',
    ERRORS: 'QUOTES_UPDATE_ERRORS',
    RESET: 'QUOTES_UPDATE_RESET',
  },
  ITEM: {
    SELECT: 'QUOTES_ITEM_SELECT',
    RESET: 'QUOTES_ITEM_RESET',
    PUT: 'QUOTES_ITEM_PUT',
  },
  AUTOCOMPLETE: {
    GET: 'QUOTES_AUTOCOMPLETE_GET',
    PUT: 'QUOTES_AUTOCOMPLETE_PUT',
    CLEAR: 'QUOTES_AUTOCOMPLETE_CLEAR',
  },
  DESTROY: {
    REQUEST: 'QUOTES_DESTROY_REQUEST',
    SUCCESS: 'QUOTES_DESTROY_SUCCESS',
    ERRORS: 'QUOTES_DESTROY_ERRORS',
    RESET: 'QUOTES_DESTROY_RESET',
  },
};

export const quotesGet = ({ search, filters, reset } = {}) => ({
  type: actions.GET,
  payload: {
    search,
    filters,
    reset,
  },
});

export const quotesPut = ({
  page, timestamp, data, hasMore, search, total,
}) => ({
  type: actions.PUT,
  payload: {
    total,
    page,
    timestamp,
    data,
    hasMore,
    search,
  },
});

export const quotesPutItems = items => ({
  type: actions.PUT_ITEMS,
  payload: items,
});

export const quotesSuccess = () => ({
  type: actions.SUCCESS,
});

export const quotesPutSingle = ({
  quote,
}) => ({
  type: actions.PUT_SINGLE,
  payload: quote,
});

export const quotesReset = () => ({
  type: actions.RESET,
});

export const quotesCreateRequest = ({
  name,
  supplier,
  cost,
  productId,
}) => ({
  type: actions.CREATE.REQUEST,
  payload: {
    name,
    supplier,
    cost,
    productId,
  },
});

export const quotesCreateProcessing = () => ({
  type: actions.CREATE.PROCESSING,
});

export const quotesCreateProcessed = () => ({
  type: actions.CREATE.PROCESSED,
});

export const quotesCreateSuccess = () => ({
  type: actions.CREATE.SUCCESS,
});

export const quotesCreateErrors = ({ errors }) => ({
  type: actions.CREATE.ERRORS,
  payload: errors,
});

export const quotesItemSelect = ({ id }) => ({
  type: actions.ITEM.SELECT,
  payload: id,
});

export const quotesItemReset = () => ({
  type: actions.ITEM.RESET,
});

export const quotesItemPut = ({ item }) => ({
  type: actions.ITEM.PUT,
  payload: item,
});

export const quotesAutocompleteGet = ({ text }) => ({
  type: actions.AUTOCOMPLETE.GET,
  payload: text,
});

export const quotesAutocompletePut = ({ data }) => ({
  type: actions.AUTOCOMPLETE.PUT,
  payload: data,
});

export const quotesAutocompleteClear = () => ({
  type: actions.AUTOCOMPLETE.CLEAR,
});

export const quotesDestroyRequest = id => ({
  type: actions.DESTROY.REQUEST,
  payload: id,
});

export const quotesDestroySuccess = () => ({
  type: actions.DESTROY.SUCCESS,
});

export const quotesDestroyErrors = errors => ({
  type: actions.DESTROY.ERRORS,
  payload: errors,
});

export const quotesDestroyReset = () => ({
  type: actions.DESTROY.RESET,
});

export const quotesUpdateRequest = (
  id,
  {
    name,
    cost,
  },
) => ({
  type: actions.UPDATE.REQUEST,
  payload: {
    id,
    name,
    cost,
  },
});

export const quotesUpdateSuccess = () => ({
  type: actions.UPDATE.SUCCESS,
});

export const quotesUpdateErrors = errors => ({
  type: actions.UPDATE.ERRORS,
  payload: errors,
});

export const quotesUpdateReset = () => ({
  type: actions.UPDATE.RESET,
});
