import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Layout from '../../../components/Layout';
import {
  warehousesDestroyRequest,
  warehousesShowRequest,
  warehousesShowReset, warehousesUpdateRequest,
} from '../../../providers/redux/actions/Warehouses';
import useRouter from '../../../services/useRouter';
import Loader from '../../../components/Base/Loader';
import ButtonBack from '../../../components/Base/Button/ButtonBack';
import useNavigate from '../../../services/useNavigate';
import Constants from '../../../constants';
import Container from '../../../components/Base/Container';
import PlaceStatus from '../../../components/PlaceStatus';
import PlaceActionBar from '../../../components/PlaceActionBar';
import Form from '../../../components/Form';
import TextInput from '../../../components/Base/Input/TextInput';
import Approval from '../../../components/Base/Dialog/Approval';

const useStyles = makeStyles(
  theme => ({
    container: {
      padding: theme.spacing(4),
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    back: {
      margin: theme.spacing(4),
    },
    name: {
      fontWeight: 'bold',
    },
    toolbar: {
      marginTop: theme.spacing(3),
    },
  }),
);

const ScreenWarehouse = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { match } = useRouter();
  const navigate = useNavigate();
  const [edit, setEdit] = useState(false);
  const [name, setName] = useState('');
  const [destroy, setDestroy] = useState(false);
  const { id } = match.params;
  const {
    warehouse,
    updateSuccess,
    updateErrors,
    updateLoading,
    destroySuccess,
    destroyLoading,
  } = useSelector(
    state => ({
      loading: state.warehousesReducer.show.loading,
      warehouse: state.warehousesReducer.show.data,
      updateLoading: state.warehousesReducer.update.loading,
      updateSuccess: state.warehousesReducer.update.success,
      updateErrors: state.warehousesReducer.update.errors,
      destroyLoading: state.warehousesReducer.destroy.loading,
      destroySuccess: state.warehousesReducer.destroy.success,
    }),
  );

  const goBack = () => navigate(Constants.routes.Warehouses);

  const openEdit = () => setEdit(true);

  const closeEdit = () => setEdit(false);

  const openDestroy = () => setDestroy(true);

  const closeDestroy = () => setDestroy(false);

  const updateWarehouse = () => dispatch(
    warehousesUpdateRequest(
      id,
      {
        name,
      },
    ),
  );

  const destroyWarehouse = () => dispatch(
    warehousesDestroyRequest(id),
  );

  useEffect(() => {
    dispatch(
      warehousesShowRequest(id),
    );

    return () => dispatch(
      warehousesShowReset(),
    );
  }, []);

  useEffect(() => {
    if (warehouse !== null) {
      const { name: warehouseName } = warehouse;
      setName(warehouseName);
    }
  }, [warehouse]);

  useEffect(() => {
    if (updateSuccess) {
      closeEdit();
    }
  }, [updateLoading]);

  useEffect(() => {
    if (destroySuccess) {
      closeDestroy();
      goBack();
    }
  }, [destroyLoading]);

  if (!warehouse) {
    return (
      <Layout>
        <Container flex center alignCenter>
          <Loader />
        </Container>
      </Layout>
    );
  }

  const { name: warehouseName } = warehouse;

  const { name: nameErrors } = updateErrors;

  return (
    <Layout>
      <Approval
        open={destroy}
        title="Delete"
        content={`Are you sure you want to delete this warehouse? We will reduce your subscription by SGD$${process.env.REACT_APP_WAREHOUSE_PRICE} every month.`}
        closeDialog={closeDestroy}
        triggerEvent={destroyWarehouse}
        processing={destroyLoading}
      />
      <Form
        label="Edit details"
        loading={updateLoading}
        onSubmit={updateWarehouse}
        onClose={closeEdit}
        open={edit}
      >
        <TextInput
          label="Name"
          onChange={setName}
          onEnter={updateWarehouse}
          value={name}
          errors={nameErrors}
          shrink
        />
      </Form>
      <Container className={classes.back}>
        <ButtonBack onPress={goBack} />
      </Container>
      <Container className={classes.container}>
        <Container className={classes.header}>
          <Container>
            <PlaceStatus status="normal" />
            <Typography className={classes.name} variant="h3">
              {warehouseName}
            </Typography>
          </Container>
        </Container>
        <Container>
          <PlaceActionBar
            className={classes.toolbar}
            onEdit={openEdit}
            onProducts={() => {}}
            onSend={() => {}}
            onReceive={() => {}}
            onDestroy={openDestroy}
          />
        </Container>
      </Container>
    </Layout>
  );
};

export default ScreenWarehouse;
