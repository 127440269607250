import { MuiThemeProvider } from '@material-ui/core/styles';
import * as Sentry from '@sentry/browser';
import React, { useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import WebFont from 'webfontloader';
import TagManager from 'react-gtm-module';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import ErrorBoundary from './components/Base/ErrorBoundary';
import store from './providers/redux/store';
import Controller from './routes/Controller';

import './styles/fonts';

import ImportFA from './styles/IconLibrary';
import theme from './styles/theme';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENVIRONMENT,
  });
}

if (process.env.REACT_APP_HOTJAR_ID) {
  hotjar.initialize(process.env.REACT_APP_HOTJAR_ID);
}

if (process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID) {
  TagManager.initialize({
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
  });
}

const App = () => {
  useEffect(() => {
    ImportFA();
    WebFont.load({
      custom: {
        families: ['RNS Sanz'],
      },
    });
  }, []);

  return (
    <Provider store={store}>
      <DndProvider backend={Backend}>
        <MuiThemeProvider theme={theme}>
          <ErrorBoundary>
            <BrowserRouter>
              <Controller />
            </BrowserRouter>
          </ErrorBoundary>
        </MuiThemeProvider>
      </DndProvider>
    </Provider>
  );
};

export default App;
