import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TableToolbar from './Base/Table/TableToolbar';
import Container from './Base/Container';
import Loader from './Base/Loader';
import BaseTable from './Base/Table/BaseTable';
import {
  inventoriesIndexRequest,
} from '../providers/redux/actions/Places/Products/Inventories';
import PlaceInventoryListItem from './PlaceInventoryListItem';

const PlaceInventoriesList = ({
  quantity, storeName, placeId, productId,
}) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const {
    inventories, loading,
  } = useSelector(
    state => ({
      destroyLoading: state.placeProductInventoriesReducer.destroy.loading,
      destroySuccess: state.placeProductInventoriesReducer.destroy.success,
      loading: state.placeProductInventoriesReducer.index.loading,
      inventories: state.placeProductInventoriesReducer.index.data,
    }),
  );

  useEffect(() => {
    dispatch(
      inventoriesIndexRequest(placeId, productId),
    );
  }, [productId, placeId]);

  return (
    <>
      <TableToolbar
        title={(
          <NumberFormat
            value={quantity}
            displayType="text"
            thousandSeparator
            renderText={value => `${value} in stock for ${storeName}`}
          />
        )}
      />
      {loading
        ? (
          <Container center alignCenter flex>
            <Loader />
          </Container>
        )
        : (
          <BaseTable
            key={inventories.length}
            total={inventories.length}
            data={inventories}
            columns={[
              { name: 'Supplier', dataKey: 'supplier' },
              { name: 'Quantity', dataKey: 'quantity', numeric: true },
              { name: 'Added', dataKey: 'added' },
              { dataKey: 'delete' },
            ]}
            page={page}
            setPage={setPage}
            TableItem={({
              id, className, supplier, quantity: itemQuantity, createdAt,
            }) => (
              <PlaceInventoryListItem
                key={id}
                className={className}
                id={id}
                supplier={supplier}
                quantity={itemQuantity}
                createdAt={createdAt}
              />
            )}
          />
        )
      }
    </>
  );
};

PlaceInventoriesList.propTypes = {
  productId: PropTypes.number.isRequired,
  placeId: PropTypes.number.isRequired,
  storeName: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
};

export default PlaceInventoriesList;
