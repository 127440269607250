import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import { LinearProgress, Grow, makeStyles } from '@material-ui/core';

import Container from '../Container';
import Loader from '../Loader';
import SearchInput from './Search';

const styles = makeStyles(theme => ({
  listHeader: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
  },
  emptyState: {
    padding: theme.spacing(4),
  },
}));

const timeout = 1200;
const ListView = ({
  className,
  children,
  loadNext,
  hasMore,
  startPage,
  reset,
  loading,
  groups,
}) => {
  const classes = styles();
  const searchTimer = useRef(null);
  const firstMount = useRef(true);
  const [search, setSearch] = useState('');
  const [searching, setSearching] = useState(true);

  useEffect(() => {
    if (!searching) {
      setSearching(true);
    }

    if (searchTimer.current !== null) {
      clearTimeout(searchTimer.current);
    }

    if (firstMount.current === true) {
      firstMount.current = false;
    } else {
      searchTimer.current = setTimeout(() => {
        reset();
        loadNext(1, search === '' ? null : search, true);
      }, timeout);
    }

    return () => {
      if (searchTimer.current !== null) {
        clearTimeout(searchTimer.current);
      }
    };
  }, [search]);

  useEffect(() => {
    if (searching && !loading) {
      setSearching(false);
    }
  }, [loading]);

  return (
    <Container className={className} flex horizontal>
      <Container className={classes.listHeader}>
        <Grow in={searching || loading}>
          <LinearProgress color="secondary" variant="query" />
        </Grow>
        <SearchInput
          onChange={setSearch}
          groups={groups}
          value={search}
        />
      </Container>
      <Container flex scroll>
        <InfiniteScroll
          key={search}
          pageStart={startPage}
          className="scroller"
          loadMore={page => !loading && loadNext(page, search === '' ? null : search)}
          hasMore={!loading && hasMore}
          loader={(
            <Container key={search} flex alignCenter center>
              <Loader />
            </Container>
          )}
          useWindow={false}
        >
          <Container>
            {!searching && !loading && children !== null && children.length === 0 ? (
              <Container className={classes.emptyState} center alignCenter flex>
                  No items found.
              </Container>
            ) : null}
            {!searching && children !== null && children.length > 0 ? children : null}
            {(searching || loading) && (
              <Container className={classes.emptyState} center alignCenter flex>
                <Loader />
              </Container>
            )}
          </Container>
        </InfiniteScroll>
      </Container>
    </Container>
  );
};

ListView.defaultProps = {
  loading: false,
  loadNext: false,
  groups: null,
  className: null,
  children: null,
  hasMore: false,
};

ListView.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  reset: PropTypes.func.isRequired,
  startPage: PropTypes.number.isRequired,
  loadNext: PropTypes.func,
  hasMore: PropTypes.bool,
  loading: PropTypes.bool,
  groups: PropTypes.array,
};

export default ListView;
