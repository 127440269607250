export const actions = {
  INDEX: {
    REQUEST: 'TRANSLATIONS_INDEX_REQUEST',
    SUCCESS: 'TRANSLATIONS_INDEX_SUCCESS',
    ERRORS: 'TRANSLATIONS_INDEX_ERRORS',
    RESET: 'TRANSLATIONS_INDEX_RESET',
  },
};

export const translationsIndexRequest = () => ({
  type: actions.INDEX.REQUEST,
});

export const translationsIndexErrors = errors => ({
  type: actions.INDEX.ERRORS,
  data: errors,
});

export const translationsIndexSuccess = data => ({
  type: actions.INDEX.SUCCESS,
  payload: data,
});
