import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import SelectInput from './SelectInput';

const StoreDropdown = ({
  label, selected, errors, onChange, exclusions, onEnter,
}) => {
  const { stores } = useSelector(state => ({
    stores: state.authReducer.user.stores
      .filter(({ id }) => exclusions.indexOf(id) === -1),
  }));

  useEffect(() => {
    if (stores.length === 1) {
      onChange(stores[0]);
    }
  }, []);

  if (stores.length === 1) {
    return null;
  }

  return (
    <SelectInput
      selected={selected}
      suggestions={stores}
      placeholder="Store"
      label={label}
      errors={errors}
      onChange={onChange}
      onEnter={onEnter}
    />
  );
};

StoreDropdown.defaultProps = {
  label: 'Store',
  selected: null,
  errors: [],
  exclusions: [],
  onEnter: null,
};

StoreDropdown.propTypes = {
  label: PropTypes.string,
  selected: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
  }),
  errors: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onEnter: PropTypes.func,
  exclusions: PropTypes.array,
};

export default StoreDropdown;
