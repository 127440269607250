import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Card } from '@material-ui/core';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import Container from '../Base/Container';

const useStyles = makeStyles(
  theme => ({
    card: {
      borderRadius: theme.spacing(2),
      minWidth: 300,
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      padding: theme.spacing(4),
    },
    name: {
      marginRight: theme.spacing(4),
    },
    nameText: {
      fontWeight: 'bold',
    },
    content: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1,
    },
    image: {
      height: 120,
    },
  }),
);

const MenuItem = ({
  onPress, name, image, className,
}) => {
  const classes = useStyles();
  return (
    <Card className={classNames(classes.card, className)}>
      <CardActionArea className={classes.container} onClick={onPress}>
        <Container className={classNames(classes.content, classes.name)}>
          <Typography className={classes.nameText} variant="h6">
            {name}
          </Typography>
        </Container>
        <Container className={classes.content}>
          {image && (
            <img
              className={classes.image}
              src={image}
              alt={name}
            />
          )}
        </Container>
      </CardActionArea>
    </Card>
  );
};

MenuItem.defaultProps = {
  image: null,
  className: null,
};

MenuItem.propTypes = {
  className: PropTypes.string,
  onPress: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.string,
};

export default MenuItem;
