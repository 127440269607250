import { actions } from '../../../actions/Places/Products/Alerts';

const initialState = {
  update: {
    loading: false,
    success: false,
    errors: {},
  },
  destroy: {
    loading: false,
    success: false,
    errors: {},
  },
};

const placesProductsAlertsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE.REQUEST:
      return {
        ...state,
        update: {
          ...state.update,
          loading: true,
        },
      };
    case actions.UPDATE.SUCCESS:
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
          success: true,
        },
      };
    case actions.UPDATE.ERRORS:
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
          success: false,
          errors: action.payload,
        },
      };
    case actions.UPDATE.RESET:
      return {
        ...state,
        update: {
          ...initialState.update,
        },
      };
    case actions.DESTROY.REQUEST:
      return {
        ...state,
        destroy: {
          ...state.destroy,
          loading: true,
        },
      };
    case actions.DESTROY.SUCCESS:
      return {
        ...state,
        destroy: {
          ...state.destroy,
          loading: false,
          success: true,
        },
      };
    case actions.DESTROY.ERRORS:
      return {
        ...state,
        destroy: {
          ...state.destroy,
          loading: false,
          success: false,
          errors: action.payload,
        },
      };
    case actions.DESTROY.RESET:
      return {
        ...state,
        destroy: {
          ...initialState.destroy,
        },
      };
    default:
      return state;
  }
};

export default placesProductsAlertsReducer;
