export const actions = {
  GET: 'CATEGORIES_GET',
  PUT: 'CATEGORIES_PUT',
  UPDATE: 'CATEGORIES_UPDATE',
  DELETE: 'CATEGORIES_DELETE',
  RESET: 'CATEGORIES_RESET',
  CREATE: {
    REQUEST: 'CATEGORIES_CREATE_REQUEST',
    SUCCESS: 'CATEGORIES_CREATE_SUCCESS',
    PROCESSING: 'CATEGORIES_CREATE_PROCESSING',
    PROCESSED: 'CATEGORIES_CREATE_PROCESSED',
    ERRORS: 'CATEGORIES_CREATE_ERRORS',
  },
  EDIT: {
    REQUEST: 'CATEGORIES_EDIT_REQUEST',
    SUCCESS: 'CATEGORIES_EDIT_SUCCESS',
    PROCESSING: 'CATEGORIES_EDIT_PROCESSING',
    PROCESSED: 'CATEGORIES_EDIT_PROCESSED',
    ERRORS: 'CATEGORIES_EDIT_ERRORS',
  },
  ITEM: {
    SELECT: 'CATEGORIES_ITEM_SELECT',
    RESET: 'CATEGORIES_ITEM_RESET',
    PUT: 'CATEGORIES_ITEM_PUT',
  },
  AUTOCOMPLETE: {
    GET: 'CATEGORIES_AUTOCOMPLETE_GET',
    PUT: 'CATEGORIES_AUTOCOMPLETE_PUT',
    CLEAR: 'CATEGORIES_AUTOCOMPLETE_CLEAR',
  },
};

export const categoriesGet = ({ search, filters, reset }) => ({
  type: actions.GET,
  payload: {
    search,
    filters,
    reset,
  },
});

export const categoriesPut = ({
  page, timestamp, data, hasMore, search,
}) => ({
  type: actions.PUT,
  payload: {
    page,
    timestamp,
    data,
    hasMore,
    search,
  },
});

export const categoriesPutSingle = ({
  category,
}) => ({
  type: actions.UPDATE,
  payload: category,
});

export const categoriesDelete = ({ id }) => ({
  type: actions.DELETE,
  payload: id,
});

export const categoriesReset = () => ({
  type: actions.RESET,
});

export const categoriesEditRequest = ({
  id,
  name,
  otf,
  isMenu,
  image,
}) => ({
  type: actions.EDIT.REQUEST,
  payload: {
    id,
    name,
    otf,
    isMenu,
    image,
  },
});

export const categoriesEditProcessing = () => ({
  type: actions.EDIT.PROCESSING,
});

export const categoriesEditProcessed = () => ({
  type: actions.EDIT.PROCESSED,
});

export const categoriesEditSuccess = () => ({
  type: actions.EDIT.SUCCESS,
});

export const categoriesEditErrors = ({ errors }) => ({
  type: actions.EDIT.ERRORS,
  payload: errors,
});

export const categoriesCreateRequest = ({
  name,
  otf,
  isMenu,
  image,
}) => ({
  type: actions.CREATE.REQUEST,
  payload: {
    name,
    otf,
    isMenu,
    image,
  },
});

export const categoriesCreateProcessing = () => ({
  type: actions.CREATE.PROCESSING,
});

export const categoriesCreateProcessed = () => ({
  type: actions.CREATE.PROCESSED,
});

export const categoriesCreateSuccess = () => ({
  type: actions.CREATE.SUCCESS,
});

export const categoriesCreateErrors = ({ errors }) => ({
  type: actions.CREATE.ERRORS,
  payload: errors,
});

export const categoriesItemSelect = ({ id }) => ({
  type: actions.ITEM.SELECT,
  payload: id,
});

export const categoriesItemReset = () => ({
  type: actions.ITEM.RESET,
});

export const categoriesItemPut = ({ item }) => ({
  type: actions.ITEM.PUT,
  payload: item,
});

export const categoriesAutocompleteGet = ({ text }) => ({
  type: actions.AUTOCOMPLETE.GET,
  payload: text,
});

export const categoriesAutocompletePut = ({ data }) => ({
  type: actions.AUTOCOMPLETE.PUT,
  payload: data,
});

export const categoriesAutocompleteClear = () => ({
  type: actions.AUTOCOMPLETE.CLEAR,
});
