import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ProductItem from '../../../products/components/Item';

const ProductsListItem = ({ product, history }) => (
  <ProductItem
    item={product}
    onClick={() => history.push(`/products/${product.id.toString()}`)}
    selected={false}
  />
);

ProductsListItem.propTypes = {
  product: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(ProductsListItem);
