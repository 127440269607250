export const actions = {
  INDEX: {
    REQUEST: 'GOODS_INDEX_REQUEST',
    SUCCESS: 'GOODS_INDEX_SUCCESS',
  },
};

export const goodsIndexRequest = () => ({
  type: actions.INDEX.REQUEST,
});

export const goodsIndexSuccess = data => ({
  type: actions.INDEX.SUCCESS,
  payload: data,
});
