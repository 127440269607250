import { actions } from '../actions/Suppliers';

const initialState = {
  processing: false,
  loading: false,
  timestamp: null,
  page: null,
  data: null,
  hasMore: true,
  selected: null,
  search: '',
  create: {
    processing: false,
    success: false,
    errors: {},
  },
  edit: {
    processing: false,
    success: false,
    errors: {},
  },
  autocomplete: {
    loading: false,
    data: [],
  },
};

const suppliersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET:
      return {
        ...state,
        loading: true,
      };
    case actions.PUT:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actions.ITEM.RESET:
      return {
        ...state,
        edit: {
          ...state.edit,
          errors: {},
        },
        selected: null,
      };
    case actions.ITEM.PUT:
      return {
        ...state,
        edit: {
          ...state.edit,
          errors: {},
        },
        selected: action.payload,
      };
    case actions.EDIT.PROCESSING:
      return {
        ...state,
        edit: {
          ...state.edit,
          success: false,
          processing: true,
        },
      };
    case actions.EDIT.PROCESSED:
      return {
        ...state,
        edit: {
          ...state.edit,
          processing: false,
        },
      };
    case actions.EDIT.ERRORS:
      return {
        ...state,
        edit: {
          ...state.edit,
          errors: action.payload,
        },
      };
    case actions.EDIT.SUCCESS:
      return {
        ...state,
        edit: {
          ...state.edit,
          errors: {},
          success: true,
        },
      };
    case actions.CREATE.PROCESSING:
      return {
        ...state,
        create: {
          ...state.create,
          success: false,
          processing: true,
        },
      };
    case actions.CREATE.PROCESSED:
      return {
        ...state,
        create: {
          ...state.create,
          processing: false,
        },
      };
    case actions.CREATE.ERRORS:
      return {
        ...state,
        create: {
          ...state.create,
          errors: action.payload,
        },
      };
    case actions.CREATE.SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          errors: {},
          success: true,
        },
      };
    case actions.RESET:
      return {
        ...state,
        data: null,
        page: null,
        hasMore: true,
        timestamp: null,
      };
    case actions.AUTOCOMPLETE.GET:
      return {
        ...state,
        autocomplete: {
          ...state.autocomplete,
          loading: true,
        },
      };
    case actions.AUTOCOMPLETE.PUT:
      return {
        ...state,
        autocomplete: {
          loading: false,
          data: action.payload,
        },
      };
    case actions.AUTOCOMPLETE.CLEAR:
      return {
        ...state,
        autocomplete: {
          loading: false,
          data: [],
        },
      };
    default:
      return state;
  }
};

export default suppliersReducer;
