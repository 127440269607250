import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  makeStyles, Typography, IconButton,
} from '@material-ui/core';
import { Done as DoneIcon, Delete as DeleteIcon } from '@material-ui/icons';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import Container from '../Base/Container';
import Button from '../Base/Button';
import Approval from '../Base/Dialog/Approval';
import {
  movementsApproveRequest,
  movementsRejectRequest,
} from '../../providers/redux/actions/Movements';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.spacing(2),
    marginBottom: theme.spacing(2),
    background: theme.palette.common.white,
    boxShadow: '0 3px 6px rgba(0,0,0,.16)',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  icon: {
    color: '#57D147',
  },
  reject: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    background: '#FF6961',
  },
  rejectIcon: {
    color: theme.palette.common.white,
  },
  actions: {
    width: 140,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  approve: {
    margin: theme.spacing(),
  },
  text: {
    flex: 1,
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  quantity: {
    textAlign: 'right',
  },
}));

const MovementsRecord = ({
  id, product, quantity, fromStore, fromUser, createdAt,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [confirmReject, setConfirmReject] = useState(false);
  const [confirmApprove, setConfirmApprove] = useState(false);
  const {
    rejectSuccess, approveSuccess, rejecting, approving,
  } = useSelector(state => ({
    rejecting: state.movementsReducer.reject.processing,
    approving: state.movementsReducer.approve.processing,
    rejectSuccess: state.movementsReducer.reject.success,
    approveSuccess: state.movementsReducer.approve.success,
  }));

  useEffect(() => {
    if (rejectSuccess) {
      setConfirmReject(false);
    }
  }, [rejectSuccess]);

  useEffect(() => {
    if (approveSuccess) {
      setConfirmApprove(false);
    }
  }, [approveSuccess]);

  const promptApprove = () => setConfirmApprove(true);

  const closeApprovePrompt = () => setConfirmApprove(false);

  const approve = () => dispatch(
    movementsApproveRequest({
      id,
    }),
  );

  const promptReject = () => setConfirmReject(true);

  const closeRejectPrompt = () => setConfirmReject(false);

  const reject = () => dispatch(movementsRejectRequest(id));

  const createdMoment = moment(createdAt);

  return (
    <Container className={classes.container}>
      <Approval
        open={confirmReject}
        processing={rejecting}
        title="Reject"
        content="Are you sure you want to reject this request?"
        closeDialog={closeRejectPrompt}
        triggerEvent={reject}
      />
      <Approval
        open={confirmApprove}
        processing={approving}
        title="Approve"
        content="Approve this request?"
        closeDialog={closeApprovePrompt}
        triggerEvent={approve}
      />
      <Container className={classes.content}>
        <Typography className={classes.text}>{product}</Typography>
        <Typography className={classes.text}>{fromStore}</Typography>
        <Typography className={classes.text}>{fromUser}</Typography>
        <Typography
          className={
            classNames([
              classes.text,
              classes.quantity,
            ])
          }
        >
          {quantity}
        </Typography>
        <Typography className={classes.text}>
          {createdMoment.fromNow()}
        </Typography>
      </Container>
      <Container className={classes.actions}>
        <IconButton className={classes.approve} onClick={promptApprove}>
          <DoneIcon className={classes.icon} />
        </IconButton>
        <Button
          className={classes.reject}
          onClick={promptReject}
          color="primary"
        >
          <DeleteIcon className={classes.rejectIcon} />
        </Button>
      </Container>
    </Container>
  );
};

MovementsRecord.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  product: PropTypes.string.isRequired,
  fromStore: PropTypes.string.isRequired,
  fromUser: PropTypes.string.isRequired,
  quantity: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  createdAt: PropTypes.string.isRequired,
};

export default MovementsRecord;
