import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import {
  Tooltip, Paper, IconButton, makeStyles,
} from '@material-ui/core';

import { useDispatch } from 'react-redux';
import Loader from '../Base/Loader';
import PrinterRow from './PrinterRow';
import PrintersCreate from './PrintersCreate';
import TableToolbar from '../Base/Table/TableToolbar';
import Container from '../Base/Container';
import BaseTable from '../Base/Table/BaseTable';
import PrintersDelete from './PrintersDelete';
import { printersRemoveRequest } from '../../providers/redux/actions/Printers';

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  loader: {
    padding: theme.spacing(4),
  },
  addButton: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    margin: theme.spacing(2.5),
  },
  empty: {
    userSelect: 'none',
    padding: theme.spacing(3),
    color: '#CCCCCC',
  },
}));

const PrintersListItems = ({ data, deleteRow }) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  return (
    data.length > 0
      ? (
        <BaseTable
          total={data.length}
          data={data.map((row, index) => ({ ...row, index }))}
          columns={[
            { name: 'Name', dataKey: 'name' },
            { name: 'Location', dataKey: 'location' },
            { name: 'Mode', dataKey: 'mode' },
            { dataKey: 'delete' },
          ]}
          page={page}
          setPage={setPage}
          TableItem={({
            id, name, location, mode,
          }) => (
            <PrinterRow
              id={id}
              name={name}
              location={location}
              mode={mode}
              deleteRow={deleteRow}
            />
          )}
        />
      )
      : (
        <Container className={classes.empty} flex center alignCenter>
          No printers found for this store.
        </Container>
      )
  );
};

PrintersListItems.propTypes = {
  data: PropTypes.array.isRequired,
  deleteRow: PropTypes.func.isRequired,
};

const PrintersList = ({ className, data }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [deletingId, setDeletingId] = useState(null);

  const showCreate = () => setIsCreateOpen(true);
  const hideCreate = () => setIsCreateOpen(false);

  const showDelete = id => setDeletingId(id);
  const hideDelete = () => setDeletingId(null);

  const deletePrinter = () => dispatch(
    printersRemoveRequest({ id: deletingId }),
  );

  return (
    <Container className={className}>
      <PrintersCreate
        open={isCreateOpen}
        setClose={hideCreate}
      />
      <PrintersDelete
        onDelete={deletePrinter}
        open={deletingId !== null}
        setClose={hideDelete}
      />
      <Paper className={classes.paper}>
        <TableToolbar
          title="Printers"
          actions={(
            <React.Fragment>
              <Tooltip title="Add new">
                <IconButton
                  color="secondary"
                  onClick={showCreate}
                  aria-label="Add new"
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </React.Fragment>
)}
        />
        {data === null
          ? (
            <Container className={classes.loader} flex center alignCenter>
              <Loader />
            </Container>
          )
          : (
            <PrintersListItems
              data={data}
              classes={classes}
              deleteRow={showDelete}
            />
          )}
      </Paper>
    </Container>
  );
};

PrintersList.defaultProps = {
  className: null,
  data: [],
};

PrintersList.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array,
};

export default PrintersList;
