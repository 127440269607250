import React from 'react';
import PropTypes from 'prop-types';
import { Done } from '@material-ui/icons';

import FAB from '../FAB';
import Styles from '../../../styles/components/Styles';

const DoneButton = ({
  className, style, onClick, label, size, disabled,
}) => {
  const classes = Styles();
  return (
    <FAB
      className={className}
      style={style}
      onClick={onClick}
      color="secondary"
      classes={{ root: classes.success }}
      label={label}
      size={size}
      disabled={disabled}
    >
      <Done />
    </FAB>
  );
};

DoneButton.defaultProps = {
  className: null,
  size: null,
  disabled: false,
  style: null,
};

DoneButton.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  style: PropTypes.object,
};

export default DoneButton;
