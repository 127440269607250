import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dialog as MaterialDialog, makeStyles } from '@material-ui/core';
import Container from '../../../../components/Base/Container';
import NameInput from './Inputs/NameInput';
import CloseIconButton from '../../../../components/Base/Button/CloseIcon';
import SwitchInput from '../../../../components/Base/Input/SwitchInput';
import { categoriesCreateRequest } from '../../../../providers/redux/actions/Categories';
import ModelInteractorCreateActions
  from '../../../../components/Base/ModelInteractor/Components/ModelInteractorCreateActions';
import ImagePicker from '../../../../components/Base/Image/ImagePicker';

const styles = makeStyles(
  theme => ({
    dialogPaper: {
      overflow: 'visible',
    },
    content: {
      display: 'flex',
      flexDirection: 'row',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    inputs: {
      flex: 1,
      marginLeft: theme.spacing(2),
    },
  }),
);

const CategoryCreate = ({
  open,
  close,
  processing,
  success,
  errors,
  categoriesCreateRequest: categoriesCreateRequestAction,
}) => {
  const classes = styles();
  const [image, setImage] = useState(null);
  const [name, setName] = useState('');
  const [otf, setOtf] = useState(false);
  const [food, setFood] = useState(false);

  const reset = () => {
    setName('');
    setOtf(false);
    setFood(false);
  };

  useEffect(() => {
    if (open && success) {
      close();
      reset();
    }
  }, [success]);

  const submit = () => categoriesCreateRequestAction({
    name,
    otf,
    food,
    image,
  });

  return (
    <MaterialDialog
      maxWidth="sm"
      PaperProps={{ className: classes.dialogPaper }}
      className="dialog"
      open={open}
      aria-labelledby="form-dialog-title"
      scroll="body"
      onClose={close}
      fullWidth
    >
      <Container className="dialog-title" flex alignCenter>
        <Container className="dialog-title-close">
          <CloseIconButton onClick={close} />
        </Container>
        <Container>Add a new category</Container>
      </Container>
      <Container className={classes.content}>
        <ImagePicker
          onChange={setImage}
          height={100}
          width={100}
          shape="square"
          image={image}
        />
        <Container className={classes.inputs}>
          <NameInput
            onChange={setName}
            errors={errors.name}
            name={name}
            onEnter={submit}
          />
          <SwitchInput
            title="Enable this category for on the fly?"
            onChange={setOtf}
            checked={otf === 1 || otf === true}
          />
          <SwitchInput
            title="Enable this category for food?"
            onChange={setFood}
            checked={food === 1 || food === true}
          />
        </Container>
      </Container>
      <ModelInteractorCreateActions
        onClick={submit}
        processing={processing}
      />
    </MaterialDialog>
  );
};

CategoryCreate.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  processing: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  categoriesCreateRequest: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  processing: state.categoriesReducer.create.processing,
  success: state.categoriesReducer.create.success,
  errors: state.categoriesReducer.create.errors,
});

export default connect(mapStateToProps, { categoriesCreateRequest })(CategoryCreate);
