import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import TextInput from '../../../../components/Base/Input/TextInput';

const NameInput = forwardRef(({
  name, errors, onChange, onEnter, autoFocus, disabled,
}, ref) => (
  <TextInput
    ref={ref}
    label="Name"
    className="input"
    onChange={onChange}
    onEnter={onEnter}
    value={name}
    errors={errors}
    placeholder="DB-100 10MM DRILL BIT"
    autoFocus={autoFocus}
    disabled={disabled}
    fullWidth
    shrink
  />
));

NameInput.defaultProps = {
  errors: null,
  onEnter: null,
  autoFocus: false,
  disabled: false,
};

NameInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onEnter: PropTypes.func,
  name: PropTypes.string.isRequired,
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default NameInput;
