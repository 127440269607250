import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  TableCell,
  TableRow,
} from '@material-ui/core';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import TableToolbar from '../Base/Table/TableToolbar';
import StoreFilters
  from '../../routes/app/products/components/Inventories/StoreFilters';
import Container from '../Base/Container';
import Loader from '../Base/Loader';
import BaseTable from '../Base/Table/BaseTable';

const useStyles = makeStyles(
  theme => ({
    container: {
      width: '100%',
    },
    loading: {
      padding: theme.spacing(3),
    },
  }),
);

const MovementsList = ({ className, movements }) => {
  const classes = useStyles();
  const [store, setStore] = useState('');
  const [page, setPage] = useState(0);
  const { stores } = useSelector(state => ({
    stores: !!state.authReducer.user.stores
    && state.authReducer.user.stores.length > 1 ? [
        {
          name: 'All',
        },
        ...state.authReducer.user.stores,
      ] : null,
  }));

  const displayStores = stores
    ? [...stores.map(listStore => listStore.name)]
    : [];

  return (
    <Paper className={classNames(classes.container, className)}>
      <TableToolbar
        title="Movements"
        helpers={!!stores && stores.length > 1
          ? (
            <StoreFilters
              filters={displayStores}
              onFiltersChange={setStore}
              selected={store}
            />
          )
          : null}
      />

      {!movements
        ? (
          <Container className={classes.loading} center alignCenter flex>
            <Loader />
          </Container>
        )
        : (
          <BaseTable
            key={movements.length}
            total={movements.length}
            data={movements}
            page={page}
            setPage={setPage}
            columns={[
              { name: 'From', dataKey: 'fromStore' },
              { name: 'To', dataKey: 'toStore' },
              { name: 'Quantity', dataKey: 'quantity', numeric: true },
              { name: 'Approved at', dataKey: 'updatedAt' },
            ]}
            TableItem={row => (
              <TableRow className={row.className} key={row.id}>
                <TableCell component="th" scope="row">
                  {row.fromStore}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.toStore}
                </TableCell>
                <TableCell align="right">{row.quantity}</TableCell>
                <TableCell>{row.updatedAt.fromNow()}</TableCell>
              </TableRow>
            )}
          />
        )
      }
    </Paper>
  );
};

MovementsList.defaultProps = {
  className: null,
};

MovementsList.propTypes = {
  className: PropTypes.string,
  movements: PropTypes.array.isRequired,
};

export default MovementsList;
