import {
  all, takeLatest, call, put, select,
} from 'redux-saga/effects';
import {
  actions,
  printersCreateErrors,
  printersCreateSuccess,
  printersIndexErrors,
  printersIndexSuccess,
  printersRemoveErrors,
  printersRemoveSuccess,
} from '../actions/Printers';
import {
  snackbarDefaultError, snackbarDefaultSuccess,
} from '../actions/Snackbar';
import { Instance, Resources } from '../../API';
import { storesItemPut } from '../actions/Stores';
import hasErrors from '../../hasErrors';

const getSelectedStore = state => ({
  store: state.storesReducer.selected,
});

const callPrintersIndex = placeId => Instance.get(
  `${Resources.places}/${Resources.printers}`,
  {
    params: {
      place_id: placeId,
    },
  },
);

const printersCreateCall = (placeId, name, location, mode) => Instance.post(
  `${Resources.places}/${Resources.printers}`,
  {
    place_id: placeId,
    name,
    location,
    mode,
  },
);

const printersRemoveCall = async id => Instance.delete(
  `${Resources.places}/${Resources.printers}/${id}`,
);

function* printersCreateSaga({
  payload: {
    name, location, mode,
  },
}) {
  try {
    const { store } = yield select(getSelectedStore);
    const { data: responseData } = yield call(
      printersCreateCall,
      store.placeId,
      name,
      location,
      mode ? mode.id : null,
    );

    const { data } = responseData;
    const { printers } = store;

    const newPrinters = [...printers];
    newPrinters.push(data);
    yield put(
      storesItemPut({
        item: {
          ...store,
          printers: newPrinters,
        },
      }),
    );

    yield put(snackbarDefaultSuccess({ data: responseData }));
    yield put(printersCreateSuccess());
  } catch (e) {
    const errors = hasErrors(e);
    if (errors) {
      yield put(
        printersCreateErrors({ errors }),
      );
    }

    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

function* printersRemoveSaga({ payload: id }) {
  try {
    const { data } = yield call(printersRemoveCall, id);

    const { store } = yield select(getSelectedStore);
    const { printers } = store;
    yield put(
      storesItemPut({
        item: {
          ...store,
          printers: printers.filter(({ id: printerId }) => printerId !== id),
        },
      }),
    );

    yield put(snackbarDefaultSuccess({ data }));
    yield put(printersRemoveSuccess());
  } catch (e) {
    const errors = hasErrors(e);
    if (errors) {
      yield put(printersRemoveErrors({ errors }));
    }
    yield put(snackbarDefaultError({ e }));
  }
}

function* sagaPrintersIndex({ payload: placeId }) {
  try {
    const { data } = yield call(callPrintersIndex, placeId);
    const { data: items } = data;

    yield put(
      printersIndexSuccess(items),
    );
  } catch (e) {
    const errors = hasErrors(e);
    if (errors) {
      yield put(
        printersIndexErrors(errors),
      );
    }

    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

export default function* Printers() {
  yield all([
    takeLatest(actions.REMOVE.REQUEST, printersRemoveSaga),
    takeLatest(actions.CREATE.REQUEST, printersCreateSaga),
    takeLatest(actions.INDEX.REQUEST, sagaPrintersIndex),
  ]);
}
