export const actions = {
  GET: 'PRODUCTS_GET',
  PUT: 'PRODUCTS_PUT',
  DELETE: 'PRODUCTS_DELETE',
  RESET: 'PRODUCTS_RESET',
  CREATE: {
    REQUEST: 'PRODUCTS_CREATE_REQUEST',
    SUCCESS: 'PRODUCTS_CREATE_SUCCESS',
    PROCESSING: 'PRODUCTS_CREATE_PROCESSING',
    PROCESSED: 'PRODUCTS_CREATE_PROCESSED',
    ERRORS: 'PRODUCTS_CREATE_ERRORS',
  },
  EDIT: {
    REQUEST: 'PRODUCTS_EDIT_REQUEST',
    SUCCESS: 'PRODUCTS_EDIT_SUCCESS',
    PROCESSING: 'PRODUCTS_EDIT_PROCESSING',
    PROCESSED: 'PRODUCTS_EDIT_PROCESSED',
    ERRORS: 'PRODUCTS_EDIT_ERRORS',
    RESET: 'PRODUCTS_EDIT_RESET',
  },
  ITEM: {
    SELECT: 'PRODUCTS_ITEM_SELECT',
    RESET: 'PRODUCTS_ITEM_RESET',
    PUT: 'PRODUCTS_ITEM_PUT',
  },
  MOVEMENTS: {
    PUT: 'PRODUCTS_MOVEMENTS_PUT',
  },
  HISTORY: {
    INDEX: {
      REQUEST: 'PRODUCTS_HISTORY_INDEX_REQUEST',
      NEXT_PAGE: 'PRODUCTS_HISTORY_INDEX_NEXT_PAGE',
      PREVIOUS_PAGE: 'PRODUCTS_HISTORY_INDEX_PREVIOUS_PAGE',
      SUCCESS: 'PRODUCTS_HISTORY_INDEX_SUCCESS',
    },
  },
};

export const productsGet = ({ search, filters, reset }) => ({
  type: actions.GET,
  payload: {
    search,
    filters,
    reset,
  },
});

export const productsPut = ({
  page, timestamp, data, hasMore, search,
}) => ({
  type: actions.PUT,
  payload: {
    page,
    timestamp,
    data,
    hasMore,
    search,
  },
});

export const productsDelete = ({ id }) => ({
  type: actions.DELETE,
  payload: id,
});

export const productsReset = () => ({
  type: actions.RESET,
});

export const productsEditRequest = ({
  id,
  name,
  alias,
  brand,
  category,
  barcodes,
  price,
  quantity,
  measurement,
  prices,
  fallBelowQuantity,
  replenishQuantity,
  image,
}) => ({
  type: actions.EDIT.REQUEST,
  payload: {
    id,
    name,
    alias,
    brand,
    category,
    barcodes,
    price,
    quantity,
    measurement,
    prices,
    fallBelowQuantity,
    replenishQuantity,
    image,
  },
});

export const productsEditProcessing = () => ({
  type: actions.EDIT.PROCESSING,
});

export const productsEditProcessed = () => ({
  type: actions.EDIT.PROCESSED,
});

export const productsEditSuccess = () => ({
  type: actions.EDIT.SUCCESS,
});

export const productsEditReset = () => ({
  type: actions.EDIT.RESET,
});

export const productsEditErrors = ({ errors }) => ({
  type: actions.EDIT.ERRORS,
  payload: errors,
});

export const productsCreateRequest = ({
  name,
  alias,
  brand,
  category,
  barcodes,
  price,
  quantity,
  measurement,
  fallBelowQuantity,
  replenishQuantity,
  image,
}) => ({
  type: actions.CREATE.REQUEST,
  payload: {
    name,
    alias,
    brand,
    category,
    barcodes,
    price,
    quantity,
    measurement,
    fallBelowQuantity,
    replenishQuantity,
    image,
  },
});

export const productsCreateProcessing = () => ({
  type: actions.CREATE.PROCESSING,
});

export const productsCreateProcessed = () => ({
  type: actions.CREATE.PROCESSED,
});

export const productsCreateSuccess = () => ({
  type: actions.CREATE.SUCCESS,
});

export const productsCreateErrors = ({ errors }) => ({
  type: actions.CREATE.ERRORS,
  payload: errors,
});

export const productsItemSelect = ({ id }) => ({
  type: actions.ITEM.SELECT,
  payload: id,
});

export const productsItemReset = () => ({
  type: actions.ITEM.RESET,
});

export const productsItemPut = ({ item }) => ({
  type: actions.ITEM.PUT,
  payload: item,
});

export const productsMovementsPut = movements => ({
  type: actions.MOVEMENTS.PUT,
  payload: movements,
});

export const productsHistoryIndexNext = () => ({
  type: actions.HISTORY.INDEX.NEXT_PAGE,
});

export const productsHistoryIndexPrevious = () => ({
  type: actions.HISTORY.INDEX.PREVIOUS_PAGE,
});

export const productsHistoryIndexRequest = (page = 1) => ({
  type: actions.HISTORY.INDEX.REQUEST,
  payload: page,
});

export const productsHistoryIndexSuccess = (data, page, { maxPage } = {}) => ({
  type: actions.HISTORY.INDEX.SUCCESS,
  payload: {
    data,
    page,
    maxPage,
  },
});
