import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Container from '../Base/Container';
import BarcodeSvg from '../../assets/icons/ic-barcode.svg';

const BarcodeIcon = ({ className }) => (
  <Container
    className={classNames([
      className,
      'icon barcode',
    ])}
    center
    alignCenter
    flex
  >
    <img src={BarcodeSvg} width="100%" alt="Barcode" />
  </Container>
);

BarcodeIcon.defaultProps = {
  className: null,
};

BarcodeIcon.propTypes = {
  className: PropTypes.string,
};

export default BarcodeIcon;
