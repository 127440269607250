import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CardActionArea from '@material-ui/core/CardActionArea';
import Slide from '@material-ui/core/Slide';
import AlertIcon from './AlertIcon';
import Constants from '../constants';

const useStyles = makeStyles(
  theme => ({
    card: {
      borderRadius: 20,
      boxShadow: '0 3px 6px rgba(0,0,0,.16)',
      marginRight: theme.spacing(2),
    },
    content: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    icon: {
      marginRight: theme.spacing(),
    },
    message: {
      flex: 1,
      fontWeight: 'bold',
    },
  }),
);

const Alert = ({ onPress, level, message }) => {
  const classes = useStyles();
  return (
    <Slide in timeout={500} direction="left" unmountOnExit>
      <Card className={classes.card}>
        <CardActionArea onClick={onPress}>
          <CardContent className={classes.content}>
            <AlertIcon className={classes.icon} level={level} />
            <Typography
              className={classes.message}
              variant="body1"
            >
              { message }
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Slide>
  );
};

Alert.defaultProps = {
  onPress: null,
};

Alert.propTypes = {
  level: PropTypes.oneOf([
    Constants.alerts.critical,
    Constants.alerts.warning,
    Constants.alerts.normal,
  ]).isRequired,
  onPress: PropTypes.func,
  message: PropTypes.string.isRequired,
};

export default Alert;
