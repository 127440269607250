import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Switch } from '@material-ui/core';

const LabelledSwitch = ({ label, checked, onChange }) => (
  <FormControlLabel
    control={(
      <Switch
        checked={checked}
        onChange={onChange}
        color="primary"
      />
)}
    label={label}
  />
);

LabelledSwitch.propTypes = {
  checked: PropTypes.bool.isRequired,
  label: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default LabelledSwitch;
