import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Button as MaterialButton,
  makeStyles,
  Typography,
} from '@material-ui/core';

const styles = makeStyles(() => ({
  button: {
    borderRadius: 100,
    boxShadow: '0 2px 5px rgba(0,0,0,0.16)',
    textTransform: 'capitalize',
    fontWeight: 'normal',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const Button = ({
  onClick,
  className,
  textClassName,
  variant,
  disabled,
  fullWidth,
  color,
  children,
  textVariant,
}) => {
  const classes = styles();
  return (
    <MaterialButton
      href={null}
      onClick={onClick}
      className={
        classNames([
          classes.button,
          className,
        ])
      }
      variant={variant}
      color={color}
      disabled={disabled}
      fullWidth={fullWidth}
    >
      {typeof children === 'string'
        ? (
          <Typography className={textClassName} variant={textVariant}>
            {children}
          </Typography>
        )
        : children}
    </MaterialButton>
  );
};

Button.defaultProps = {
  className: null,
  textClassName: null,
  disabled: false,
  variant: 'contained',
  fullWidth: false,
  color: 'secondary',
  textVariant: 'body1',
};

Button.propTypes = {
  className: PropTypes.string,
  textClassName: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  variant: PropTypes.string,
  textVariant: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'default',
  ]),
  children: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
};

export default Button;
