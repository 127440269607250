import { useSelector } from 'react-redux';
import UserCan from '../UserCan';

const usePermissions = () => {
  const { permissions } = useSelector(state => ({
    permissions: state.authReducer.permissions,
  }));

  return UserCan(permissions);
};

export default usePermissions;
