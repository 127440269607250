import { makeStyles, Slide, Typography } from '@material-ui/core';
import { yellow } from '@material-ui/core/colors';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from './Base/Button';
import { authEmailResend } from '../providers/redux/actions/Auth';

const useStyles = makeStyles(theme => ({
  banner: {
    background: yellow[700],
    color: theme.palette.common.white,
    textAlign: 'center',
    padding: theme.spacing(3),
  },
  resend: {
    background: 'transparent',
    marginLeft: theme.spacing(2),
    boxShadow: 'none',
  },
}));

const BannerVerification = () => {
  const classes = useStyles();
  const { authenticated, verified } = useSelector(state => ({
    authenticated: state.authReducer.authenticated,
    verified: state.authReducer.user.verified,
  }));

  const dispatch = useDispatch();

  if (!authenticated) {
    return null;
  }

  const resend = () => dispatch(authEmailResend());

  return (
    <Slide direction="down" in={!verified} unmountOnExit mountOnEnter>
      <div className={classes.banner}>
        <Typography variant="caption">
          Your e-mail hasn&apos;t been verified.
          Please check your e-mail for the verification e-mail.
        </Typography>
        <Button className={classes.resend} onClick={resend}>
          <Typography variant="caption">
            Resend your verification
          </Typography>
        </Button>
      </div>
    </Slide>
  );
};

export default BannerVerification;
