import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery, useTheme } from '@material-ui/core';
import Container from './Base/Container';
import IconLoader from './Base/IconLoader';
import { siteOpenSidebar } from '../providers/redux/actions/Site';

const Boot = ({ children }) => {
  const dispatch = useDispatch();
  const { roles } = useSelector(state => ({
    roles: state.rolesReducer.data,
  }));

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    if (matches) {
      dispatch(siteOpenSidebar());
    }
  }, [matches]);

  return roles ? children : (
    <Container flex center horizontal alignCenter>
      <IconLoader color />
    </Container>
  );
};

Boot.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Boot;
