import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import ModalForm from '../ModalForm';
import TextInput from '../Base/Input/TextInput';
import PrinterModeDropdown from '../Base/Input/PrinterModeDropdown';
import { printersCreateRequest } from '../../providers/redux/actions/Printers';

const PrintersCreate = ({ open, setClose }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [location, setLocation] = useState('');
  const [mode, setMode] = useState(null);

  const { success, processing, errors } = useSelector(state => ({
    success: state.printersReducer.create.success,
    processing: state.printersReducer.create.processing,
    errors: state.printersReducer.create.errors,
  }));

  const {
    name: nameErrors,
    location: locationErrors,
    mode: modeErrors,
  } = errors;

  useEffect(() => {
    if (success) {
      setClose();
    }
  }, [success, setClose]);

  const submit = () => dispatch(
    printersCreateRequest({
      name,
      location,
      mode,
    }),
  );

  return (
    <ModalForm
      title="Add a printer"
      open={open}
      close={setClose}
      confirm={submit}
      loading={processing}
    >
      <TextInput
        label="Name"
        onChange={setName}
        value={name}
        errors={nameErrors}
        shrink
      />
      <TextInput
        label="Location"
        onChange={setLocation}
        value={location}
        errors={locationErrors}
        shrink
      />
      <PrinterModeDropdown
        onChange={setMode}
        selected={mode}
        errors={modeErrors}
      />
    </ModalForm>
  );
};

PrintersCreate.propTypes = {
  open: PropTypes.bool.isRequired,
  setClose: PropTypes.func.isRequired,
};

export default PrintersCreate;
