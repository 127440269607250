import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

import TextInput from './TextInput';

const NumberFormatComponent = ({
  inputRef, onChange, placeholder, ...other
}) => (
  <NumberFormat
    {...other}
    getInputRef={inputRef}
    placeholder={placeholder}
    onValueChange={(values) => {
      onChange({
        target: {
          value: values.value,
        },
      });
    }}
    maxLength={6}
  />
);

NumberFormatComponent.defaultProps = {
  placeholder: null,
};

NumberFormatComponent.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

const InputPostalCode = ({
  label, placeholder, value, onChange, errors,
}) => (
  <TextInput
    label={label}
    errors={errors}
    value={value}
    onChange={onChange}
    inputComponent={NumberFormatComponent}
    placeholder={placeholder}
    shrink
  />
);

InputPostalCode.defaultProps = {
  errors: null,
  placeholder: null,
};

InputPostalCode.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.array,
};

export default InputPostalCode;
