import React, { useEffect } from 'react';
import { Fade } from 'react-reveal';
import { makeStyles, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import HomeLayout from '../../../../components/Layout/HomeLayout';
import Container from '../../../../components/Base/Container';
import SiteAppNavigation from '../../../../components/Site/SiteAppNavigation';
import SiteOrganisationInputs
  from '../../../../components/Site/SiteOrganisation/SiteOrganisationInputs';
import {
  organisationCreateRequest, organisationCreateReset,
} from '../../../../providers/redux/actions/Organisation';
import ButtonBack from '../../../../components/Base/Button/ButtonBack';
import useNavigate from '../../../../services/useNavigate';
import Constants from '../../../../constants';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(8),
    maxWidth: 1200,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  title: {
    fontWeight: 'bold',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
  },
  data: {
    maxWidth: 400,
    width: '100%',
  },
  back: {
    marginRight: theme.spacing(2),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const ScreenOrganisationAdd = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { success, loading, errors } = useSelector(state => ({
    loading: state.organisationReducer.create.loading,
    success: state.organisationReducer.create.success,
    errors: state.organisationReducer.create.errors,
  }));

  const submit = payload => !loading && dispatch(
    organisationCreateRequest(payload),
  );

  const backToList = () => navigate(Constants.routes.Organisations);

  useEffect(() => {
    if (success) {
      backToList();
      dispatch(organisationCreateReset());
    }
  }, [success]);

  return (
    <HomeLayout>
      <Container className={classes.container}>
        <SiteAppNavigation />
        <Container className={classes.content}>
          <Container className={classes.back}>
            <ButtonBack onPress={backToList} />
          </Container>
          <Container className={classes.form} flex>
            <Fade bottom>
              <Typography className={classes.title} variant="h4">
                Add a new organisation
              </Typography>
              <SiteOrganisationInputs
                loading={loading}
                className={classes.data}
                onPress={submit}
                errors={errors}
                buttonText="Make it"
              />
            </Fade>
          </Container>
        </Container>
      </Container>
    </HomeLayout>
  );
};

export default ScreenOrganisationAdd;
