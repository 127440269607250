import {
  takeLatest, all, call, put, select, putResolve,
} from 'redux-saga/effects';
import {
  actions,
  inventoriesCreateErrors,
  inventoriesCreateSuccess,
  inventoriesDeleteErrors,
  inventoriesDeleteSuccess,
} from '../actions/Inventories';
import { Instance, Resources } from '../../API';
import {
  snackbarDefaultError,
  snackbarDefaultSuccess,
} from '../actions/Snackbar';
import {
  productsItemSelect,
} from '../actions/Products';
import { printerRequest } from '../actions/Printer';
import hasErrors from '../../hasErrors';

const getCurrentProduct = state => ({
  product: state.productsReducer.selected,
});

const inventoriesPostCall = async ({
  placeId,
  supplierId,
  quantity,
  productId,
  printerId,
}) => Instance.post(
  Resources.inventories,
  {
    place_id: placeId,
    supplier_id: supplierId,
    quantity,
    product_id: productId,
    printer_id: printerId,
  },
);

const inventoriesDeleteCall = async ({ inventoryId }) => Instance.delete(
  `${Resources.inventories}/${inventoryId}`,
);


function* createInventorySaga({
  payload: {
    placeId,
    supplierId,
    quantity,
    productId,
    printerId,
    print,
  },
}) {
  try {
    const { data: responseData } = yield call(
      inventoriesPostCall,
      {
        placeId,
        supplierId,
        quantity,
        productId,
        printerId: print ? printerId : null,
      },
    );

    const { data } = responseData;

    if (print && data.label) {
      yield put(
        printerRequest({ label: data.label }),
      );
    }

    yield putResolve(
      productsItemSelect({ id: productId }),
    );

    yield put(
      snackbarDefaultSuccess({ data: responseData }),
    );

    yield put(
      inventoriesCreateSuccess(),
    );
  } catch (e) {
    const errors = hasErrors(e);
    if (errors) {
      yield put(
        inventoriesCreateErrors({ errors }),
      );
    }

    yield put(
      snackbarDefaultError({ e }),
    );
  }
}

function* deleteInventorySaga({ payload: inventoryId }) {
  try {
    const { data } = yield call(
      inventoriesDeleteCall, { inventoryId },
    );

    const { product } = yield select(getCurrentProduct);

    yield putResolve(
      productsItemSelect({ id: product.id }),
    );

    yield put(
      snackbarDefaultSuccess({ data }),
    );

    yield put(
      inventoriesDeleteSuccess(),
    );
  } catch (e) {
    yield put(
      snackbarDefaultError({ e }),
    );

    const errors = hasErrors(e);
    if (errors) {
      yield put(
        inventoriesDeleteErrors({ errors }),
      );
    }
  }
}

export default function* Inventories() {
  yield all([
    takeLatest(actions.CREATE.REQUEST, createInventorySaga),
    takeLatest(actions.DELETE.REQUEST, deleteInventorySaga),
  ]);
}
