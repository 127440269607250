import { actions } from '../actions/Warehouses';

const initialState = {
  index: {
    loading: false,
    data: [],
    search: '',
    errors: {},
  },
  show: {
    loading: false,
    success: false,
    data: null,
    errors: {},
  },
  update: {
    loading: false,
    success: false,
    errors: {},
  },
  store: {
    loading: false,
    success: false,
    errors: {},
  },
  destroy: {
    loading: false,
    success: false,
    errors: {},
  },
};

const warehousesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.INDEX.REQUEST:
      return {
        ...state,
        index: {
          ...state.index,
          loading: true,
        },
      };
    case actions.INDEX.SUCCESS:
      return {
        ...state,
        index: {
          ...state.index,
          loading: false,
          search: action.payload.search,
          data: action.payload.data,
        },
      };
    case actions.INDEX.ERRORS:
      return {
        ...state,
        index: {
          ...state.index,
          loading: false,
          errors: action.payload,
        },
      };
    case actions.INDEX.RESET:
      return {
        ...state,
        index: {
          ...initialState.index,
        },
      };
    case actions.STORE.REQUEST:
      return {
        ...state,
        store: {
          ...state.store,
          loading: true,
        },
      };
    case actions.STORE.SUCCESS:
      return {
        ...state,
        store: {
          ...state.store,
          loading: false,
          success: true,
        },
      };
    case actions.STORE.ERRORS:
      return {
        ...state,
        store: {
          ...state.store,
          loading: false,
          success: false,
          errors: action.payload,
        },
      };
    case actions.STORE.RESET:
      return {
        ...state,
        store: {
          ...initialState.store,
        },
      };
    case actions.SHOW.REQUEST:
      return {
        ...state,
        show: {
          ...state.show,
          loading: true,
        },
      };
    case actions.SHOW.SUCCESS:
      return {
        ...state,
        show: {
          ...state.show,
          loading: false,
          success: true,
          data: {
            ...state.show.data,
            ...action.payload,
          },
        },
      };
    case actions.SHOW.ERRORS:
      return {
        ...state,
        show: {
          ...state.show,
          loading: false,
          success: false,
          errors: action.payload,
        },
      };
    case actions.SHOW.RESET:
      return {
        ...state,
        show: {
          ...initialState.show,
        },
      };
    case actions.UPDATE.REQUEST:
      return {
        ...state,
        update: {
          ...state.update,
          loading: true,
        },
      };
    case actions.UPDATE.SUCCESS:
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
          success: true,
        },
      };
    case actions.UPDATE.ERRORS:
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
          success: false,
          errors: action.payload,
        },
      };
    case actions.UPDATE.RESET:
      return {
        ...state,
        update: {
          ...initialState.update,
        },
      };
    case actions.DESTROY.REQUEST:
      return {
        ...state,
        destroy: {
          ...state.destroy,
          loading: true,
        },
      };
    case actions.DESTROY.SUCCESS:
      return {
        ...state,
        destroy: {
          ...state.destroy,
          loading: false,
          success: true,
        },
      };
    case actions.DESTROY.ERRORS:
      return {
        ...state,
        destroy: {
          ...state.destroy,
          loading: false,
          success: false,
          errors: action.payload,
        },
      };
    case actions.DESTROY.RESET:
      return {
        ...state,
        destroy: {
          ...initialState.destroy,
        },
      };
    default:
      return state;
  }
};

export default warehousesReducer;
