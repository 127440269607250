const routes = {
  Home: '/',
  Register: '/register',
  Login: '/login',
  Profile: '/profile',
  Organisations: '/organisations',
  Stores: '/stores',
  Add: '/add',
  Verify: '/verify',
  Warehouses: '/warehouses',
  Menu: '/menu',
  Foods: '/foods',
  Sets: '/sets',
};

export default routes;
