import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Instance, Resources } from '../../../providers/API';
import SelectInput from './SelectInput';

const SupplierDropdown = ({
  productId, selected, errors, onChange, autoFocus, onEnter,
}) => {
  const { organisation } = useSelector(
    state => ({
      organisation: state.organisationReducer.data,
    }),
  );
  const [data, setData] = useState([]);

  const suppliers = (search) => {
    const params = {
      search: search !== '' ? search : null,
      organisation_id: organisation.id,
      paginate: 0,
    };

    if (productId) {
      params.product_id = productId;
    }

    return Instance.get(
      productId
        ? `${Resources.products}/${Resources.suppliers}`
        : Resources.suppliers,
      { params },
    );
  };

  const search = async (searchTerm) => {
    const response = await suppliers(searchTerm);

    if (response.data && response.data.data.length > 0) {
      const suppliersData = [...response.data.data];

      setData(suppliersData);
      if (suppliersData.length === 1) {
        onChange(suppliersData[0]);
      }

      return;
    }

    setData([]);
  };

  useEffect(() => {
    search('');
  }, []);

  if (data.length === 1) {
    return null;
  }

  return (
    <SelectInput
      selected={selected}
      suggestions={data}
      placeholder="ABC Supplier Pte Ltd"
      label="Supplier"
      errors={errors}
      onChange={onChange}
      onEnter={onEnter}
      autoFocus={autoFocus}
    />
  );
};

SupplierDropdown.defaultProps = {
  productId: null,
  selected: null,
  errors: null,
  autoFocus: false,
  onEnter: null,
};

SupplierDropdown.propTypes = {
  productId: PropTypes.number,
  selected: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
  }),
  errors: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onEnter: PropTypes.func,
  autoFocus: PropTypes.bool,
};

export default SupplierDropdown;
