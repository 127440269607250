import CardActionArea from '@material-ui/core/CardActionArea';
import moment from 'moment';
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { ReactComponent as IconSleep } from '../../assets/icons/ic-sleep.svg';
import usePermissions from '../../providers/hooks/usePermissions';
import Permissions from '../../providers/Permissions';
import StatisticsValue from '../Statistics/StatisticsValue';
import StoreCardValue
  from './StoreCard/StoreCardValue';
import Constants from '../../constants';
import AlertIcon from '../AlertIcon';

const useStyles = makeStyles(theme => ({
  card: {
    position: 'relative',
    display: 'inline-block',
    borderRadius: 40,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    padding: 0,
    color: '#181818',
    transition: 'background-color 0.8s ease-out',
    boxSizing: 'content-box',
    userSelect: 'none',
    whiteSpace: 'normal',
    [theme.breakpoints.down('lg')]: {
      width: 300,
      height: 350,
    },
    [theme.breakpoints.up('lg')]: {
      width: 400,
      height: 450,
    },
  },
  animation: {
    flex: 1,
    width: '100%',
  },
  stats: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    padding: theme.spacing(2),
  },
  nameContainer: {
    width: '100%',
    paddingRight: 60,
  },
  name: {
    color: '#181818',
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: '2.8428571428571423rem',
    },
  },
  value: {
    flex: 1,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  amount: {
    fontWeight: 'bold',
  },
  closed: {
    backgroundColor: '#181818',
  },
  nameClosed: {
    color: '#343434',
  },
  amountClosed: {
    color: '#C7C7C7',
  },
  valueClosed: {
    flex: 1,
    color: '#8D8D8D',
  },
  cash: {
    color: '#88DF64',
  },
  icon: {
    position: 'absolute',
    top: theme.spacing(4),
    right: theme.spacing(4),
    width: 56,
    height: 56,
  },
  actionArea: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(2),
    height: '100%',
  },
}));

const StoreCard = ({
  className,
  id,
  onPress,
  name,
  open,
  revenue,
  openingTime,
  transactionsCount,
  alert,
}) => {
  const userCan = usePermissions();
  const { settlements } = useSelector(state => ({
    settlements: state.dashboardReducer.settlements,
  }));

  const now = moment();
  const buffer = openingTime.clone();
  const settlement = settlements.find(({ id: storeId }) => id === storeId);
  const isClosed = !open && settlement !== undefined;
  const isOpening = now.isSameOrAfter(buffer.subtract(2, 'hours'));
  const classes = useStyles();

  return (
    <Card
      className={
        classNames([
          classes.card,
          className,
          isClosed && classes.closed,
        ])
      }
    >
      <CardActionArea onClick={onPress} className={classes.actionArea}>
        {(alert === Constants.alerts.normal && isClosed) && (
          <IconSleep className={classes.icon} />
        )}
        {alert !== Constants.alerts.normal && (
          <AlertIcon className={classes.icon} level={alert} />
        )}
        <CardContent className={classes.nameContainer}>
          <Typography
            className={
              classNames([
                classes.name,
                isClosed && classes.nameClosed,
              ])
            }
            variant="h3"
          >
            {name}
          </Typography>
        </CardContent>
        <CardContent className={classes.animation} />
        {userCan(Permissions.viewRevenue) && (
          <CardContent className={classes.stats}>
            <StoreCardValue
              classes={classes}
              isClosed={isClosed}
              isOpening={isOpening}
              transactionsCount={transactionsCount}
              settlement={settlement}
              openingTime={openingTime}
            />
            <StatisticsValue
              className={isClosed ? classes.valueClosed : classes.value}
              valueClassName={isClosed ? classes.amountClosed : classes.amount}
              valueVariant="h6"
              value={revenue}
              label="Revenue"
            />
          </CardContent>
        )}
      </CardActionArea>
    </Card>
  );
};

StoreCard.defaultProps = {
  className: null,
  onPress: null,
  alert: Constants.alerts.normal,
};

StoreCard.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  revenue: PropTypes.number.isRequired,
  openingTime: PropTypes.object.isRequired,
  transactionsCount: PropTypes.number.isRequired,
  onPress: PropTypes.func,
  alert: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default StoreCard;
