import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog as MaterialDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
} from '@material-ui/core';

import Loader from '../Loader';

const styles = makeStyles(theme => ({
  actions: {
    padding: theme.spacing(),
  },
}));

const Dialog = ({
  open, title, close, callback, reject, accept, loading, children,
}) => {
  const classes = styles();
  return (
    <MaterialDialog
      open={open}
      onClose={close}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      disableBackdropClick={loading}
      fullWidth
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions className={classes.actions}>
        {loading
          ? (<Loader />)
          : (
            <React.Fragment>
              <Button href={null} onClick={close} color="primary">
                {reject}
              </Button>
              <Button href={null} onClick={callback} color="primary" variant="contained">
                {accept}
              </Button>
            </React.Fragment>
          )
        }
      </DialogActions>
    </MaterialDialog>
  );
};

Dialog.defaultProps = {
  loading: false,
};

Dialog.propTypes = {
  accept: PropTypes.node.isRequired,
  callback: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
  close: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  reject: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
};

export default Dialog;
