import { actions } from '../actions/Measurements';

const initialState = {
  loading: false,
  data: null,
};

const measurementsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET:
      return {
        ...state,
        loading: true,
      };
    case actions.PUT:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actions.RESET:
      return {
        ...state,
        data: null,
      };
    default:
      return state;
  }
};

export default measurementsReducer;
