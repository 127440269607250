import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { Instance, Resources } from '../../API';
import {
  actions,
  brandsGet,
  brandsItemPut,
  brandsItemReset,
  brandsItemSelect,
  brandsEditProcessed,
  brandsEditProcessing,
  brandsPut,
  brandsPutSingle,
  brandsReset,
  brandsEditSuccess,
  brandsEditErrors,
  brandsCreateErrors,
  brandsCreateProcessed,
  brandsCreateSuccess,
  brandsCreateProcessing,
  brandsAutocompleteClear, brandsAutocompletePut,
} from '../actions/Brands';
import {
  snackbarDefaultError,
  snackbarDefaultSuccess,
} from '../actions/Snackbar';
import hasErrors from '../../hasErrors';

const resource = Resources.brands;

const brandsCreateCall = ({
  organisationId,
  name,
}) => Instance.post(
  resource,
  {
    organisation_id: organisationId,
    name,
  },
);

export const brandsAutocompleteCall = ({
  organisationId, text,
}) => Instance.get(
  `${resource}/${Resources.autocomplete}`,
  {
    params: {
      organisation_id: organisationId,
      search: text !== '' ? text : null,
    },
  },
);

export const brandsIndexCall = ({
  organisationId, page, timestamp, search, filters,
}) => Instance.get(
  resource,
  {
    params: {
      organisation_id: organisationId,
      page: page !== 1 ? page : null,
      search: search !== '' ? search : null,
      timestamp: timestamp || null,
      filters: filters || null,
      paginate: 1,
    },
  },
);

export const brandsViewCall = ({ id }) => Instance.get(`${resource}/${id}`);

export const brandsPutCall = ({
  id,
  name,
}) => Instance.put(
  `${resource}/${id}`,
  {
    id,
    name,
  },
);

export const brandsDeleteCall = ({ id }) => Instance.delete(`${resource}/${id}`);

const siteData = state => ({
  organisationId: state.organisationReducer.data.id,
});

const brandsData = state => ({
  data: state.brandsReducer.data,
});

const brandsMetaData = state => ({
  page: state.brandsReducer.page,
  timestamp: state.brandsReducer.timestamp,
  search: state.brandsReducer.search,
});

/**
 * loadBrandsSaga
 * @returns {IterableIterator<*>}
 */
function* loadBrandsSaga({
  payload: {
    search, filters, reset,
  },
}) {
  try {
    const { organisationId } = yield select(siteData);
    const {
      search: originalSearch,
      page,
      timestamp,
    } = yield select(brandsMetaData);

    if (search !== originalSearch) {
      yield put(brandsReset());
    }

    const { data } = yield call(
      brandsIndexCall,
      {
        organisationId,
        page: reset || !page || search !== originalSearch ? 1 : page + 1,
        timestamp,
        search: search === '' ? null : search,
        filters,
      },
    );

    const {
      data: {
        page: newPage,
        timestamp: newTimestamp,
        items,
      },
    } = data;

    let newData;
    if (newPage === 1) {
      newData = [...items];
    } else {
      const { data: originalData } = yield select(brandsData);
      newData = originalData ? [...originalData, ...items] : [...items];
    }

    yield put(brandsPut({
      page: newPage,
      timestamp: newTimestamp,
      data: newData,
      hasMore: items.length > 0,
      search,
    }));
  } catch (e) {
    yield put(snackbarDefaultError({ e }));
  }
}

/**
 * createBrandsSaga
 * @param brand
 */
function* createBrandsSaga({
  payload: {
    name,
  },
}) {
  try {
    yield put(brandsCreateProcessing());
    const { organisationId } = yield select(siteData);
    const { data } = yield call(
      brandsCreateCall,
      {
        organisationId,
        name,
      },
    );
    yield put(brandsPutSingle({ brand: data.data }));
    yield put(brandsGet({ reset: true }));
    yield put(brandsCreateSuccess());
    yield put(brandsItemSelect({ id: data.data.id }));
    yield put(snackbarDefaultSuccess({ data }));
  } catch (e) {
    const errors = hasErrors(e);
    if (errors) {
      yield put(
        brandsCreateErrors({ errors }),
      );
    }
    yield put(snackbarDefaultError({ e }));
  } finally {
    yield put(brandsCreateProcessed());
  }
}

/**
 * editBrandsSaga
 * @param brand
 */
function* editBrandsSaga({
  payload: {
    id,
    name,
  },
}) {
  try {
    yield put(brandsEditProcessing());
    const { data } = yield call(
      brandsPutCall,
      {
        id,
        name,
      },
    );
    yield put(brandsItemPut({ item: data.data }));
    yield put(brandsPutSingle({ brand: data.data }));
    yield put(brandsEditProcessed());
    yield put(snackbarDefaultSuccess({ data }));
    yield put(brandsEditSuccess());
  } catch (e) {
    const errors = hasErrors(e);
    if (errors) {
      yield put(
        brandsEditErrors({ errors }),
      );
    }
    yield put(snackbarDefaultError({ e }));
  } finally {
    yield put(brandsEditProcessed());
  }
}

/**
 * deleteBrandsSaga
 * @param id
 */
function* deleteBrandsSaga({ payload: id }) {
  try {
    yield put(brandsEditProcessing());
    const response = yield call(brandsDeleteCall, { id });

    const { data } = yield select(brandsData);
    const newData = [...data];
    yield put(
      brandsPut({
        data: newData.filter(({ id: brandId }) => brandId !== id),
      }),
    );
    yield put(brandsItemReset());
    yield put(brandsEditSuccess());
    yield put(snackbarDefaultSuccess({ data: response.data }));
  } catch (e) {
    yield put(snackbarDefaultError({ e }));
  } finally {
    yield put(brandsEditProcessed());
  }
}

/**
 * selectBrandSaga
 * @param id
 */
function* selectBrandSaga({ payload: id }) {
  try {
    const { data: { data } } = yield call(brandsViewCall, { id });
    yield put(brandsItemPut({ item: data }));
  } catch (e) {
    yield put(snackbarDefaultError({ e }));
  }
}

/**
 * updateBrandsSaga
 * @param brand
 */
function* updateBrandsSaga({ payload: brand }) {
  const { data } = yield select(brandsData);
  const index = data.findIndex(({ id }) => brand.id === id);
  if (index !== undefined) {
    data[index] = { ...data[index], ...brand };
  } else {
    data.unshift(brand);
  }

  yield put(brandsPut({ data }));
}

/**
 * @returns {IterableIterator<*>}
 */
function* brandsAutocompleteGetSaga({ payload: text }) {
  try {
    if (text === '') {
      yield put(brandsAutocompleteClear());
      return;
    }

    const { organisationId } = yield select(siteData);
    const { data } = yield call(brandsAutocompleteCall, {
      organisationId,
      text,
    });

    yield put(brandsAutocompletePut({ data: data.data }));
  } catch (e) {
    yield put(snackbarDefaultError({ e }));
  }
}

export default function* Brands() {
  yield all([
    takeLatest(actions.GET, loadBrandsSaga),
    takeLatest(actions.DELETE, deleteBrandsSaga),
    takeLatest(actions.ITEM.SELECT, selectBrandSaga),
    takeEvery(actions.UPDATE, updateBrandsSaga),
    takeLatest(actions.EDIT.REQUEST, editBrandsSaga),
    takeLatest(actions.CREATE.REQUEST, createBrandsSaga),
    takeLatest(actions.AUTOCOMPLETE.GET, brandsAutocompleteGetSaga),
  ]);
}
