import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  AutoSizer, InfiniteLoader, List,
} from 'react-virtualized';
import Loader from '../Base/Loader';
import Container from '../Base/Container';
import StoreProductListItem from './StoreProductListItem';

const useStyles = makeStyles(
  theme => ({
    header: {
      fontWeight: 'bold',
    },
    icon: {
      textAlign: 'center',
    },
    loading: {
      textAlign: 'center',
      padding: theme.spacing(4),
    },
    flexContainer: {
      display: 'flex',
      alignItems: 'center',
      boxSizing: 'border-box',
    },
    tableRow: {
      cursor: 'pointer',
    },
  }),
);

const StoreProductList = ({
  className, placeId, loading, data, onNext,
}) => {
  const classes = useStyles();
  const height = 80;
  return (
    <Container className={className} maxWidth>
      <Paper>
        { loading && (
          <Container className={classes.loading}>
            <Loader />
          </Container>
        )}
        { !loading && (
          <InfiniteLoader
            isRowLoaded={({ index }) => typeof data[index] !== 'undefined'}
            loadMoreRows={!loading ? () => { onNext(); } : () => {}}
            threshold={10}
            rowCount={data.length + 1}
          >
            {({ onRowsRendered, registerChild }) => (
              <AutoSizer disableHeight>
                {({ width }) => (
                  <List
                    ref={registerChild}
                    height={500}
                    onRowsRendered={onRowsRendered}
                    rowCount={data.length}
                    rowHeight={height}
                    rowRenderer={({ index, key, style }) => {
                      if (index >= data.length - 1) {
                        return (
                          <Container className={classes.loading}>
                            <Loader />
                          </Container>
                        );
                      }

                      const {
                        productId,
                        alertMessage,
                        name,
                        quantity,
                        alert,
                        price,
                        replenishQuantity,
                        fallBelowQuantity,
                      } = data[index];

                      return (
                        <StoreProductListItem
                          key={key}
                          className={style.row}
                          placeId={placeId}
                          productId={productId}
                          style={style}
                          price={price}
                          alertMessage={alertMessage}
                          alert={alert}
                          name={name}
                          quantity={quantity}
                          replenishQuantity={replenishQuantity}
                          fallBelowQuantity={fallBelowQuantity}
                        />
                      );
                    }}
                    width={width}
                  />
                )}
              </AutoSizer>
            )}
          </InfiniteLoader>
        )}
      </Paper>
    </Container>
  );
};

StoreProductList.defaultProps = {
  className: null,
};

StoreProductList.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  onNext: PropTypes.func.isRequired,
  placeId: PropTypes.number.isRequired,
};

export default StoreProductList;
