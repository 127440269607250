export const actions = {
  GET: 'ROLES_GET',
  PUT: 'ROLES_PUT',
  RESET: 'ROLES_RESET',
};

export const rolesGet = () => ({
  type: actions.GET,
});

export const rolesPut = ({ data }) => ({
  type: actions.PUT,
  payload: data,
});

export const rolesReset = () => ({
  type: actions.RESET,
});
