export const actions = {
  MESSAGE: {
    PUSH: 'SNACKBAR_MESSAGE_PUSH',
    CLOSE: 'SNACKBAR_MESSAGE_CLOSE',
    REMOVE: 'SNACKBAR_MESSAGE_REMOVE',
  },
  SET: 'SNACKBAR_SET',
  CLEAR: 'SNACKBAR_CLEAR',
  DEFAULT: {
    SUCCESS: 'SNACKBAR_DEFAULT_SUCCESS',
    ERROR: 'SNACKBAR_DEFAULT_ERROR',
  },
};

export const snackbarMessagePush = ({ message, variant }) => ({
  type: actions.MESSAGE.PUSH,
  payload: {
    message,
    variant,
  },
});

export const snackbarSet = ({ messages }) => ({
  type: actions.SET,
  payload: messages,
});

export const snackbarMessageClose = ({ key }) => ({
  type: actions.MESSAGE.CLOSE,
  payload: key,
});

export const snackbarMessageRemove = ({ key }) => ({
  type: actions.MESSAGE.REMOVE,
  payload: key,
});

export const snackbarClear = () => ({
  type: actions.CLEAR,
});

export const snackbarDefaultError = ({ e }) => ({
  type: actions.DEFAULT.ERROR,
  payload: e,
});

export const snackbarDefaultSuccess = ({ data }) => ({
  type: actions.DEFAULT.SUCCESS,
  payload: data,
});
