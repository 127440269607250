import { makeStyles, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Fade } from 'react-reveal';
import { useDispatch, useSelector } from 'react-redux';
import Container from '../../../components/Base/Container';
import HomeLayout from '../../../components/Layout/HomeLayout';
import SiteAppNavigation from '../../../components/Site/SiteAppNavigation';
import SiteOrganisationItem
  from '../../../components/Site/SiteOrganisation/SiteOrganisationItem';
import ButtonGlow from '../../../components/Buttons/ButtonGlow';
import useNavigate from '../../../services/useNavigate';
import Constants from '../../../constants';
import { organisationListLoad } from '../../../providers/redux/actions/Organisation';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(8),
    maxWidth: 1200,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
  },
  add: {
    marginLeft: theme.spacing(6),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  empty: {
    marginLeft: theme.spacing(2),
  },
  title: {
    marginLeft: theme.spacing(2),
    fontWeight: 'bold',
  },
  data: {
    marginTop: theme.spacing(4),
  },
}));

const ScreenOrganisations = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { organisations } = useSelector(state => ({
    loading: state.organisationReducer.list.loading,
    organisations: state.organisationReducer.list.data,
  }));

  const toNewPage = () => navigate(
    `${Constants.routes.Organisations}${Constants.routes.Add}`,
  );

  useEffect(() => {
    dispatch(organisationListLoad());
  }, []);

  return (
    <HomeLayout>
      <Container className={classes.container}>
        <SiteAppNavigation />
        <Container>
          <Fade bottom>
            <Container className={classes.header}>
              <Typography className={classes.title} variant="h4">
                Organisations
              </Typography>
              <ButtonGlow
                className={classes.add}
                onPress={toNewPage}
                color="secondary"
              >
                Add New
              </ButtonGlow>
            </Container>
            <Container className={classes.data}>
              {organisations.length === 0 && (
                <Container className={classes.empty}>
                  You don&apos;t have any organisations yet.
                </Container>
              )}
              {organisations.map(({ id, name, username }) => (
                <SiteOrganisationItem
                  key={id}
                  id={id}
                  name={name}
                  username={username}
                />
              ))}
            </Container>
          </Fade>
        </Container>
      </Container>
    </HomeLayout>
  );
};

export default ScreenOrganisations;
