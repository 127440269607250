import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Add } from '@material-ui/icons';
import {
  Card, CardContent, CardHeader, makeStyles,
} from '@material-ui/core';
import Layout from '../../../components/Layout';
import Container from '../../../components/Base/Container';
// import ImagePicker from '../../../components/Base/Image/ImagePicker';
import TextInput from '../../../components/Base/Input/TextInput';
import SwitchInput from '../../../components/Base/Input/SwitchInput';
import Invitations from '../../../components/Organisation/Invitations';
import Row from '../../../components/Base/Container/Row';
import SubscriptionDetails from '../../../components/Subscription/SubscriptionDetails';
import {
  organisationItemSelect,
  organisationPaymentsStoreRequest,
  organisationUpdateRequest,
} from '../../../providers/redux/actions/Organisation';
import IconButton from '../../../components/Base/Button/IconButton/IconButton';
import Toolbar
  from '../../../components/Base/ModelInteractor/Components/Toolbar';
import Loader from '../../../components/Base/Loader';
import Constants from '../../../constants';

const useStyles = makeStyles(theme => ({
  wrapper: {
    justifyContent: 'center',
  },
  container: {
    paddingTop: theme.spacing(8),
    maxWidth: 800,
    width: '100%',
  },
  avatar: {
    width: 160,
  },
  details: {
    padding: theme.spacing(3),
  },
  card: {
    padding: theme.spacing(),
    marginBottom: theme.spacing(3),
  },
}));

const ScreenOrganisation = () => {
  const {
    loading, current, organisation, updateLoading,
  } = useSelector(state => ({
    current: state.organisationReducer.data,
    loading: state.organisationReducer.selected.loading,
    organisation: state.organisationReducer.selected.data,
    updateLoading: state.organisationReducer.update.loading,
  }));

  const { id } = current;

  const {
    id: originalId,
    name: originalName,
    tax: originalTax,
    registrationNo: originalRegistrationNo,
    taxRegistrationNo: originalTaxRegistrationNo,
    goods: defaultGoods,
    terms: defaultTerms,
  } = organisation;

  const classes = useStyles();
  const dispatch = useDispatch();
  const [name, setName] = useState(originalName || '');
  const [tax, setTax] = useState(originalTax || false);
  const [registration, setRegistration] = useState(
    originalRegistrationNo || '',
  );

  const [
    taxRegistration,
    setTaxRegistration,
  ] = useState(originalTaxRegistrationNo || '');
  const [isSellingFood, setIsSellingFood] = useState(
    defaultGoods
      ? defaultGoods.indexOf(Constants.goods.food) !== -1
      : false,
  );
  const [isSellingProducts, setIsSellingProducts] = useState(
    defaultGoods
      ? defaultGoods.indexOf(Constants.goods.product) !== -1
      : false,
  );
  const [terms, setTerms] = useState(defaultTerms || '');

  const [invitations] = useState(0);
  const [invited] = useState([]);
  const [open, setOpen] = useState(false);
  const { paymentMethods } = organisation;

  const updatePayments = (token, isDefault) => {
    dispatch(
      organisationPaymentsStoreRequest({
        token,
        isDefault,
      }),
    );
  };

  const onOpen = () => setOpen(true);

  const onClose = () => setOpen(false);

  const onUpdate = () => {
    const goods = [];
    if (isSellingFood) {
      goods.push(Constants.goods.food);
    }

    if (isSellingProducts) {
      goods.push(Constants.goods.product);
    }

    dispatch(
      organisationUpdateRequest(
        organisation.id, {
          name,
          tax,
          registrationNo: registration,
          taxRegistrationNo: taxRegistration,
          goods,
          terms,
        },
      ),
    );
  };

  useEffect(() => {
    if (id) {
      dispatch(
        organisationItemSelect(id),
      );
    }
  }, [id]);

  useEffect(() => {
    if (originalId) {
      setName(originalName);
      setTax(originalTax);
      setRegistration(originalRegistrationNo);
      setTaxRegistration(originalTaxRegistrationNo);
      setTax(originalTax);
      setIsSellingProducts(
        defaultGoods
          ? defaultGoods.indexOf(Constants.goods.product) !== -1
          : false,
      );
      setIsSellingFood(
        defaultGoods
          ? defaultGoods.indexOf(Constants.goods.food) !== -1
          : false,
      );
      setTerms(defaultTerms || '');
    }
  }, [originalId]);

  if (loading) {
    return (
      <Layout center>
        <Loader />
      </Layout>
    );
  }

  if (!originalId) {
    return (
      <Layout center />
    );
  }

  return (
    <Layout center>
      <Container scroll flex>
        <Container className={classes.wrapper} flex>
          <Container className={classes.container}>
            <Container className="organisation-details" flex>
              {/* <Container className={classes.avatar}> */}
              {/*  <ImagePicker */}
              {/*    initials={name.charAt(0) || <CloudUpload />} */}
              {/*    width={100} */}
              {/*    height={100} */}
              {/*    fontSize={32} */}
              {/*  /> */}
              {/* </Container> */}
              <Container className={classes.details} flex horizontal>
                <Row>
                  <TextInput
                    label="Name"
                    onChange={setName}
                    value={name}
                    fullWidth
                    required
                    shrink
                  />
                </Row>
                <Row>
                  <SwitchInput
                    title="Are you tax registered?"
                    onChange={setTax}
                    checked={tax}
                  />
                </Row>
                <Row>
                  <TextInput
                    label="Registration number"
                    onChange={setRegistration}
                    value={registration}
                    fullWidth
                    shrink
                  />
                </Row>
                <Row>
                  <TextInput
                    label="Tax registration number"
                    onChange={setTaxRegistration}
                    value={taxRegistration}
                    fullWidth
                    shrink
                  />
                </Row>
                <Row>
                  <TextInput
                    label="Terms & Conditions"
                    onChange={setTerms}
                    value={terms}
                    maxLength={255}
                    fullWidth
                    shrink
                  />
                </Row>
                <Row>
                  <SwitchInput
                    checked={isSellingFood}
                    onChange={setIsSellingFood}
                    title="Do you sell food?"
                  />
                </Row>
                <Row>
                  <SwitchInput
                    checked={isSellingProducts}
                    onChange={setIsSellingProducts}
                    title="Do you sell products?"
                  />
                </Row>
              </Container>
            </Container>
            <Card className={classes.card}>
              <CardHeader
                title="Your subscription details"
                action={(
                  <IconButton onPress={onOpen}>
                    <Add />
                  </IconButton>
            )}
              />
              <CardContent>
                <SubscriptionDetails
                  methods={paymentMethods}
                  onUpdate={updatePayments}
                  onClose={onClose}
                  open={open}
                />
              </CardContent>
            </Card>
            <Card className={classes.card}>
              <CardContent>
                <Invitations count={invitations} invited={invited} />
              </CardContent>
            </Card>
          </Container>
        </Container>
      </Container>
      <Toolbar
        fireEdit={onUpdate}
        processing={updateLoading}
        edit
      />
    </Layout>
  );
};

export default ScreenOrganisation;
