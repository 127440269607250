import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { foodsIndexRequest } from '../../../providers/redux/actions/Foods';

const FoodsDropdown = ({
  label, value, onChange, onEnter,
}) => {
  const dispatch = useDispatch();
  const { data } = useSelector(
    state => ({
      data: state.foodsReducer.index.data,
    }),
  );

  const onValueChange = (e, newVal) => onChange(newVal);

  const onInputChange = (e, newVal, reason) => {
    if (reason !== 'reset') {
      onChange(newVal);
    }
  };

  useEffect(() => {
    dispatch(
      foodsIndexRequest(),
    );
  }, []);
  return (
    <Autocomplete
      options={data}
      getOptionLabel={
        ({ name }) => name
      }
      onChange={onValueChange}
      onInputChange={onInputChange}
      onKeyDown={(e) => {
        if (onEnter && e.key === 'Enter') {
          onEnter();
        }
      }}
      renderOption={({ name }) => (
        <React.Fragment>
          {name}
        </React.Fragment>
      )}
      renderInput={params => (
        <TextField
          {...params}
          InputLabelProps={{
            shrink: true,
          }}
          label={label}
          placeholder="Choice"
          margin="normal"
        />
      )}
      inputValue={typeof value === 'object' ? value.name : value}
      value={typeof value === 'object' ? value : null}
      autoHighlight
      freeSolo
    />
  );
};

FoodsDropdown.defaultProps = {
  label: 'Choice',
  errors: [],
  onEnter: null,
};

FoodsDropdown.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  errors: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onEnter: PropTypes.func,
};

export default FoodsDropdown;
