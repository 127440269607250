import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  StripeProvider,
  Elements,
} from 'react-stripe-elements';
import { makeStyles, Typography } from '@material-ui/core';
import SubscriptionDialog from './SubscriptionDialog';
import Container from '../Base/Container';
import PaymentCard from '../Payment/PaymentCard';

const useStyles = makeStyles(theme => ({
  methods: {
    marginBottom: theme.spacing(4),
  },
  empty: {
    textAlign: 'center',
  },
}));

const SubscriptionDetails = ({
  className, onUpdate, onClose, open, methods,
}) => {
  const [stripe, setStripe] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    if (window.Stripe) {
      setStripe(
        window.Stripe(process.env.REACT_APP_STRIPE_KEY),
      );
      return;
    }

    document.querySelector('#stripe')
      .addEventListener('load', () => {
        setStripe(
          window.Stripe(process.env.REACT_APP_STRIPE_KEY),
        );
      });
  }, []);

  if (!stripe) {
    return null;
  }

  return (
    <StripeProvider stripe={stripe}>
      <Container>
        <Elements>
          <SubscriptionDialog
            className={className}
            onUpdate={onUpdate}
            onClose={onClose}
            open={open}
          />
        </Elements>
        <Container className={classes.methods}>
          { methods.length === 0 && (
            <Container className={classes.empty}>
              <Typography>No payment methods set up yet.</Typography>
            </Container>
          )}
          { methods.length > 0 && (
            methods.map(({
              token, brand, lastFour, expiryMonth, expiryYear, isDefault,
            }) => (
              <PaymentCard
                token={token}
                key={lastFour}
                brand={brand}
                lastFour={lastFour}
                expiryMonth={expiryMonth}
                expiryYear={expiryYear}
                isDefault={isDefault}
              />
            ))
          )}
        </Container>
      </Container>
    </StripeProvider>
  );
};

SubscriptionDetails.defaultProps = {
  className: null,
};

SubscriptionDetails.propTypes = {
  className: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  methods: PropTypes.array.isRequired,
};

export default SubscriptionDetails;
